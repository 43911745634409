import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ThreeDots } from "react-loader-spinner";
import UseEditDiscount from "../../../hooks/superAdmin/UseEditDiscount";

function UpdateDiscountSetting(props) {
    const discountDetails = props.updateDiscount;
    const [discountGet , setDiscountGet] = useState({})
    const [loadingGet, setLoadingGet] = useState(false);
    const [loading, setLoading] = useState(false);
    const[couponCode,setCouponCode]= useState('');
    const[discountPercentage,setDiscountPercentage]= useState('');
    const[discountStatus,setDiscountStatus]= useState('');

    useEffect(() => {
        fetchDiscount();
    }, [discountDetails]);

    const fetchDiscount = () => {
        setLoadingGet(false);
        setDiscountGet(discountDetails);
    }

    const onCouponCodeChanges = (e) => {
        const updatedDiscountDetail = { ...discountGet };
        updatedDiscountDetail.coupon_code = e.target.value;
        setCouponCode(e.target.value);
        setDiscountGet(updatedDiscountDetail);
    };
    
    const onDiscountPercentageChanges = (e) => {
        const updatedDiscountDetail = { ...discountGet };
        updatedDiscountDetail.discount_percentage = e.target.value;
        setDiscountPercentage(e.target.value);
        setDiscountGet(updatedDiscountDetail);
    };
    
    const onDiscountStatusChanges = (e) => {
        const updatedDiscountDetail = { ...discountGet };
        const status = e.target.checked ? 'active' : 'inactive';
        updatedDiscountDetail.discount_status = status;
        setDiscountStatus(status); // Update the status state with the current status
        setDiscountGet(updatedDiscountDetail);
    };
    
    const {isUpdateDiscount, UpdateDiscountData} = UseEditDiscount();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
            try {
                const data = {
                    discount_id: discountGet.id,
                    coupon_code: couponCode || discountGet.coupon_code,
                    discount_percentage: discountPercentage || discountGet.discount_percentage,
                    discount_status: discountStatus || discountGet.discount_status,
                };
        
                const{
                    responseCode:updateDiscount
                } = await UpdateDiscountData(data);
                
                if (updateDiscount.status === 'success') {
                    window.location.href = '/super-admin/discount-list'
                }else{
                    console.log('Something went wrong .');
                }
            }catch (error) {
                console.log('Something went wrong 11.');
            }
            setLoading(false);
       
    };
    
    return (
        <>
            <Modal
                show={props.show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=""
            >
                <Modal.Body>
                {!loadingGet &&
                    <>
                    
                    <div className="row coupon-code-div mt-3">
                        <div className="col-md-6 col-6 mt-3 mb-3"><label>Coupon Code :</label></div>
                        <div className="col-md-6 col-6 mt-3 mb-3"><input type="text"  name="couponCode" id="coupon_code" onChange={onCouponCodeChanges} value={discountGet.coupon_code} readOnly /></div>

                        <div className="col-md-6 col-6 mt-3 mb-3"><label>Discount Percentage :</label></div>
                        <div className="col-md-6 col-6 mt-3 mb-3"><input type="text" maxLength='2'  name="discountPercentage" id="discount_percentage"  onChange={onDiscountPercentageChanges} value={discountGet.discount_percentage} readOnly /></div>

                        <div className="col-md-6 col-6 mt-3"><label>Discount Status :</label></div>
                        <div className="col-md-6 col-6"><Form.Check type="switch" id="discount-status-switch" label="" className="new-discout-switch mt-3"  name="discountStatus" onChange={onDiscountStatusChanges}  checked={discountGet.discount_status === "active"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member mt-5">
                                <button className="remove-no-btn" onClick={props.onHide}>Cancel</button> &nbsp;

                                <button type="submit" className="remove-yes-btn" onClick={handleSubmit} disabled={loading}>
                                    {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Update"}
                                </button>
                            </div>
                        </div>
                    </div>
                    </>
                }
                </Modal.Body>
            </Modal>
        </>

    )
}

export default UpdateDiscountSetting;