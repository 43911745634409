import {useState} from "react";

import Team from '../api/Teams';

const UseSelfAssignAdd = () => {
    const [isAddSelftCertificateAssign, setIsAddSelftCertificateAssign] = useState(false);
  
    const AddSelfAssignCertificate = async (data) => {
      setIsAddSelftCertificateAssign(true);
  
      let responseCode;
  
      try {
          responseCode = await Team.createSelfAssign(data);
      } catch (error) {
        responseCode = error.response;
      }
  
      setIsAddSelftCertificateAssign(false);
  
      return { responseCode };
    };
  
    return { isAddSelftCertificateAssign, AddSelfAssignCertificate };
  };


export default UseSelfAssignAdd;