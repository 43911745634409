import { useState } from "react";

import manager from "../../api/manager/manager";

const UseCertificateDateUpdate = () => {

    const [isCertificateDate, setIsCertificateDate] = useState(false);
    const CertificateUserDate = async (body) => {
        setIsCertificateDate(true);
    
        let responseCode;
    
        try {
          const response = await manager.certificateDateUpdate(body);
          responseCode = response.data;
        } catch (error) {
          responseCode = error.response.status;
        }
    
        setIsCertificateDate(false);
    
        return { responseCode };
      };
    
      return { isCertificateDate, CertificateUserDate };
}


export default UseCertificateDateUpdate;