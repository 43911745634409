import React, { useState,useRef } from "react";
import Header from "../../../components/website/Layouts/Header";
import Doctor from '../../../assets/web-images/images/Doctor-Image.png'
import ScrollToTop from 'react-scroll-to-top';
import Joint from '../../../assets/web-images/images/Joint-Commission-logo.png';
import American from '../../../assets/web-images/images/american.svg';
import nurses from '../../../assets/web-images/images/Nurse-and-doctors.png';
import WhiteBLS from '../../../assets/web-images/images/White-BLS.png';
import WhiteACLS from '../../../assets/web-images/images/White-ACLS.png';
import WhitePALS from '../../../assets/web-images/images/White-PALS.png';
import Cprtestsection from '../../../assets/web-images/images/CPR-Test-Section1.png';
import XMoreTest from '../../../assets/web-images/images/X_more_ section_list.png';
import AlgorithmsMockup from '../../../assets/web-images/images/Algorithms Mockup.png';
import Checkmark from '../../../assets/web-images/images/Checkmark.png';
import CertificationsMockup from '../../../assets/web-images/images/Certifications-mockup.png';
import laptopMockup from '../../../assets/web-images/images/laptop-mockup.png';
import MobileMockup from '../../../assets/web-images/images/Mobile-mockup.png';
import CardStep4 from '../../../assets/web-images/images/Cards-Step1.png';
import Certified from '../../../assets/web-images/images/Certified.png';
import GreenCheckbox from '../../../assets/web-images/images/Green-checkbox.png';
import GuaranteeGraphic from '../../../assets/web-images/images/Guarantee graphic.png';
import PulseLogo from '../../../assets/web-images/images/Pulse-Logo.png';
import { HomeCerificateCard } from "../../../components/website/HomeCerificateCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HomePage() {

    const [startDate, setStartDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    
    const seamlessCertification = useRef(null);
    const viewCourses = useRef(null);

    const scrollToSeamlessCertification = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const scrollToViewCourses = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleInputDate = (date) => {
        setStartDate(date);
        setIsDatePickerOpen(true);
    };
    const handleDatePickerClose = () => {
        setIsDatePickerOpen(false);
    };

    return (
        <>
            <Header />

            <section className="hero-section ptb-120 text-white bg-gradient" id="main-class" >
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-lg-7 mt-5">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className='main-title Stress-out'>We Take the Stress Out of<span className='line-break main-title Stress-out'>Healthcare Certifications</span></h1>

                                <div className="row sub-title-row mt-4">
                                    <p className="main-text-home-page">
                                        {/* Get your ACLS, BLS, and PALS certification the simple, <br />convenient way. Perfect for busy physicians, nurses, paramedics, <br /> and all other medical personnel. */}
                                        Get your ACLS, BLS, and PALS certification the simple, convenient way. Perfect for busy physicians, nurses, paramedics,  and all other medical personnel.
                                    </p>
                                    <ul className="content-list list-unstyled p-0 main-ui-home-page">
                                        <li className="bls-li">100% online</li>
                                        <li className="bls-li">Accepted nationwide</li>
                                        <li className="bls-li">Instant Card Access</li>
                                    </ul>
                                </div>
                                <div className="row sub-title-row">
                                    <div className="col-md-5 view-course-home">
                                        <button onClick={() => scrollToViewCourses(viewCourses)} >View Courses</button>
                                    </div>
                                    <div className="col-md-7 home-page-it-works">
                                        <button onClick={() => scrollToSeamlessCertification(seamlessCertification)}>Show Me How it Works</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5">
                            <div className="hero-img position-relative circle-shape-images">

                                <img src={Doctor} alt="doctor-img" className="image-doctor" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <h1 className="certifications-trust">Certifications You Can Trust</h1>
                    <p className="home-based">Based on the <a className="text-latest-line">Latest Guidelines </a> and <a className="text-latest-line">Standards from </a></p>
                    <div className="text-center mt-3">
                        <img src={Joint} alt="images" width='287px' height='60px' />
                    </div>
                    <div className="text-center mt-3">
                        <img src={American} alt="images" width='228px' height='70px' />
                    </div>
                    <div className="text-center"></div>
                </div>
            </section>

            <section className="hero-section text-white bg-gradient mt-5">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-lg-6 mt-5">
                            <div className="nurses-image">
                                <img src={nurses} alt="doctor-img" className="image-nurses" />
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <p className='home-page-healthcare'>The 24/7 life of a healthcare worker never slows down</p>

                                <div className="row sub-title-row mt-4">
                                    <p className="main-Juggling-home-page">
                                        Juggling patients around the clock. Ever-changing guidelines on little sleep. Stepping in for colleagues’ shifts with no warning.
                                    </p>
                                    <p className="main-Juggling-home-page">
                                        Staying current with the latest protocols can feel like an impossible task. It can also mean losing or saving lives, depending on you.
                                    </p>
                                    <p className="main-Juggling-home-page">
                                        You need options that fit your schedule.
                                    </p>
                                    <p className="main-Juggling-home-page">
                                        Even if that means freedom to complete your certification during those rare breaks at work.
                                    </p>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="Juggling-patients">
                <div className="container">
                    <h1 className="h1-certified-anywhere">Get Certified Anywhere. Anytime. On Your Time.</h1>
                </div>
            </section>
            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    <HomeCerificateCard />
                </div>
            </section>


            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <h1 className="need-certifications">Who Needs These Certifications?</h1>
                    <div className="row need-certifications-rows">
                        <div className="col-lg-4">
                            <div className="card">
                                <div class="card-content">
                                    <div className="needs-bls-certificate">
                                        <img src={WhiteBLS} width='50px' height='50px' />
                                        <span>BLS</span>
                                    </div>
                                    <div className="crtificate-name-needs">
                                        <p>Basic Life Support</p>
                                    </div>
                                    <div className="needs-crtifcate-ul">
                                        <ul>
                                            <li>Nurses</li>
                                            <li>Physicians</li>
                                            <li>Medical assistants</li>
                                            <li>Physical therapists</li>
                                            <li>Paramedics/EMTs</li>
                                            <li>Dentists</li>
                                            <li>Dental hygienists</li>
                                            <li>Pharmacists</li>
                                            <li>Lifeguards</li>
                                            <li>Coaches</li>
                                            <li>Personal trainers</li>
                                            <li>Teachers</li>
                                            <li>Daycare providers</li>
                                            <li>Police officers</li>
                                            <li>Firefighters</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div class="card-content">
                                    <div className="needs-bls-certificate">
                                        <img src={WhiteACLS} width='50px' height='50px' />
                                        <span>ACLS</span>
                                    </div>
                                    <div className="acls-certificate-needs">
                                        <p>Advanced Cardiovascular <br /> Life Support</p>
                                    </div>
                                    <div className="needs-crtifcate-ul">
                                        <ul>
                                            <li>Cardiologists</li>
                                            <li>Physicians</li>
                                            <li>Nurses</li>
                                            <li>Paramedics</li>
                                            <li>Anesthesiologists</li>
                                            <li>Respiratory therapists</li>
                                            <li>Cath lab techs</li>
                                            <li>Flight nurses</li>
                                            <li>Emergency responders</li>
                                            <li>Emergency room <br /> professionals</li>
                                            <li>Intensive care <br /> department professionals</li>
                                            <li>Critical care department <br /> professionals</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div class="card-content">
                                    <div className="needs-bls-certificate">
                                        <img src={WhitePALS} width='50px' height='50px' />
                                        <span>PALS</span>
                                    </div>
                                    <div className="crtificate-name-needs">
                                        <p>Pediatric Life Support</p>
                                    </div>
                                    <div className="needs-crtifcate-ul">
                                        <ul>
                                            <li>Pediatricians</li>
                                            <li>Nurses</li>
                                            <li>Paramedics</li>
                                            <li>Emergency healthcare <br /> professionals</li>
                                            <li>Teachers</li>
                                            <li>Daycare providers</li>
                                            <li>Foster parents</li>
                                            <li>Sports coaches</li>
                                            <li>School nurses</li>
                                            <li>Social workers</li>
                                            <li>Child therapists</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light p-4 never-miss section-height-mb" style={{ height: "420px" }}>
                <div className="container">
                    <div className="never-miss-div">
                        <h1>Never Miss Your Renewal <br /> Deadline Again</h1>
                        <div className="row">
                            {/* <div className="col-lg-5 col-md-5"> */}
                            {/* <p className="never-miss-p">Simply input your email and expiration <br /> date. We will email a reminder before <br /> your certification expires.</p> */}
                            <p className="never-miss-p">Simply input your email and expiration date. We will email a reminder before your certification expires.</p>
                            {/* </div>
                            <div className="col-lg-7 col-md-7"> */}
                            {/* <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-4">
                                    <div class="email-container-home-simply">
                                        <i class="fa fa-envelope email-icon-home-simply"></i>
                                        <input type="email" class="email-input-home-simply" placeholder="Enter your email" />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="datepicker-container-home-simply exp-date-id">
                                        <i class='fa fa-calendar-alt calendar-icon-home-simply'></i>
                                        <DatePicker
                                            className="datepicker-input-home-simply"
                                            placeholderText="__/__/__"
                                            selected={startDate}
                                            onChange={handleInputDate}
                                            onClose={handleDatePickerClose}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <button className="cer-reminder">Get My Reminder</button>
                                </div> */}
                            {/* </div> */}
                            <iframe
                                className="iframe-css-add"
                                src="https://api.leadconnectorhq.com/widget/form/w4ArrZBQS5sVRarABkxG"
                                // style={{
                                //     width: "100%",
                                //     height: "100%",
                                //     border: "none",
                                //     borderRadius: "3px",
                                //     overflow: "hidden",
                                //     position: "absolute",
                                //     left: "0",
                                //     marginTop: "35px"
                                // }}
                                id="inline-w4ArrZBQS5sVRarABkxG"
                                data-layout="{'id':'INLINE'}"
                                data-trigger-type="alwaysShow"
                                data-trigger-value=""
                                data-activation-type="alwaysActivated"
                                data-activation-value=""
                                data-deactivation-type="neverDeactivate"
                                data-deactivation-value=""
                                data-form-name="Renewal Deadline Certificate Form "
                                data-height="400"
                                data-layout-iframe-id="inline-w4ArrZBQS5sVRarABkxG"
                                data-form-id="w4ArrZBQS5sVRarABkxG"
                                title="Renewal Deadline Certificate Form "
                            >
                            </iframe>
                            <script src="https://link.msgsndr.com/js/form_embed.js"></script>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5 in-person-skills" >
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 cer-email-send-home">
                            <img src={XMoreTest} alt="x-more" width='45px' height='45px' />
                        </div>
                        <div className="col-lg-5 col-md-9">
                            <div className="hero-content-wrap mt-lg-0 mt-xl-0 cprtestsection-text-div">
                                <h1 className='in-Person-test'>No In-Person Skills Test Necessary</h1>

                                <p className="in-Person-test-p">Our megacodes bring realistic medical scenarios to you. </p>
                                <p className="in-Person-test-p">This means you will have everything you need to know about the patient in question. </p>
                                <p className="in-Person-test-p">Thus, you can make life-saving decisions as you would in any real-life situation.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="text-center cprtestsection-img-div">
                                <img src={Cprtestsection} alt="doctor-img" width='332px' height='222px' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light cprtestsection-img-div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center">
                            <img src={AlgorithmsMockup} alt="algo" className="algorithms-mockup-img" />
                        </div>
                        <div className="col-lg-6 mt-5">
                            <p className="things-simple">We keep things simple so you can swiftly navigate through your certification process</p>
                            <p className="cheat-sheets-p">With these easy “cheat sheets,” memorizing the algorithms is a breeze.</p>
                            <p className="cheat-sheets-p">You’ll have a clear game plan for anything you’ll be tested on, plus...</p>
                            <ul className="remember-ul-test">
                                <li>Remember important steps easier</li>
                                <li>Boost your knowledge of concepts</li>
                                <li>Increase your confidence in patient care</li>
                                <li>Become a better decision-maker on your toes</li>
                                <li>Pass your test with certainty</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light pt-5 mt-5  pb-5 card-acces-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="row cards-ml-18">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">Instant Card Access</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row cards-ml-10">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">Practice Tests Available</p>
                                </div>
                            </div>
                        </div><div className="col-lg-4">
                            <div className="row cards-ml-10">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">Receive CME/CEU Credits</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row cards-row-mt">
                        <div className="col-lg-4">
                            <div className="row cards-ml-18">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">Guaranteed Acceptance</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row cards-ml-10">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">Valid for 2 Years</p>
                                </div>
                            </div>
                        </div><div className="col-lg-4">
                            <div className="row cards-ml-10">
                                <div className="col-lg-2 col-3 text-end">
                                    <img src={Checkmark} alt='image' width='40px' height='37px' />
                                </div>
                                <div className="col-lg-10 col-9">
                                    <p className="instant-card-access">100% Online Class & Exam</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5 pb-5">
                <div className="container">
                    {/* <p className="discover-homes" ref={seamlessCertification}>Discover Your Quick Path to <br /> Seamless Certification</p> */}
                    <p className="discover-homes" ref={seamlessCertification}>Discover Your Quick Path to <span className="seamless-certification">Seamless Certification</span></p>
                    <div className="row mt-5">
                        <div className="col-lg-7 col-md-7 step-1-css">
                            <h3>Step 1:</h3>
                            <p>Select the certification that matches your needs.</p>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <img src={CertificationsMockup} alt='img' className="certifications-mockup-img" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5 pb-5 step2-section">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-6 col-md-5 text-end">
                            <img src={laptopMockup} alt='img' className="certifications-mockup-img" />
                        </div>
                        <div className="col-lg-6 col-md-7 step-2-css">
                            <h3>Step 2:</h3>
                            <p>Sign up for your free account.</p>
                        </div>

                    </div>
                </div>
            </section>
            <section className="feature-promo bg-light pt-5 pb-5 step3-section">
                <div className="container">
                    <div className="row ">

                        <div className="col-lg-7 col-md-7 step-1-css">
                            <h3>Step 3:</h3>
                            <p>You take your test from any device and pass with an 80% or higher. (Unlimited retests allowed)</p>
                        </div>
                        <div className="col-lg-5 col-md-5 mobile-mockup-div">
                            <img src={MobileMockup} alt='img' className="mobile-mockup-img" />
                        </div>

                    </div>
                </div>
            </section>
            <section className="feature-promo bg-light pt-5 pb-5 step2-section">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-6 col-md-5 text-end">
                            <img src={CardStep4} alt='img' className="certifications-mockup-img" />
                        </div>
                        <div className="col-lg-6 col-md-7 step-2-css">
                            <h3>Step 4:</h3>
                            {/* <p>You’ll gain instant access to your printable <br /> e-card. Plus, you will get an automated <br /> email with your certification.</p> */}
                            <p>You’ll gain instant access to your printable <span className="seamless-certification">e-card. Plus, you will get an automated</span> <span className="seamless-certification"> email with your certification. </span></p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5 pb-5 step3-section">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-7 col-md-7 step-1-css">
                            <h3>Step 5:</h3>
                            <p>Use your card in emergencies and <br /> confidently submit your certification to <br /> your employer or school.</p>
                        </div>
                        <div className="col-lg-5 col-md-5 certified-div">
                            <img src={Certified} alt='img' className="certified-img" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5 pb-5 fail-proof">
                <div className="container">
                    <h1 className="its-fail-proof-h1 mb-4">It's Nearly Fail-Proof</h1>
                    <p>• Practice tests closely match the final exam</p>
                    <p>• No trick questions</p>
                    <p>• Unlimited retakes</p>
                    <p>• No time restrictions</p>
                    <p>• Straightforward process</p>
                </div>
            </section>
            <section className="feature-promo bg-light pt-5 pb-5 finally-credentials">
                <div className="container">
                    <div className="card mb-5">
                        <p className="Finally-p">Finally, You Can Update or Acquire Your Credentials Without Disrupting Your Day</p>
                    </div>
                    <div className="row no-more-now-you-can">
                        <div className="col-lg-6 no-more-b-l">
                            <p className="no-more-p">No more...</p>
                            <div className="row">
                                <div className="col-lg-1 col-2 p-0"><img src={XMoreTest} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Paying higher fees for initial classes.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={XMoreTest} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Getting yanked from the schedule for non-compliance.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={XMoreTest} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Time spent struggling to locate in-person classes.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={XMoreTest} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Losing hard-earned PTO until you are back in good standing.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={XMoreTest} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Dealing with the stress of last-minute cancelations.</span></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="now-you-can-p">Now you can...</p>
                            <div className="row">
                                <div className="col-lg-1 col-2 p-0"><img src={GreenCheckbox} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Enjoy cost-efficient online classes.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={GreenCheckbox} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Ensure continuous employment eligibility with immediate online certification updates.</span></div>
                                <div className="col-lg-1  col-2 p-0"><img src={GreenCheckbox} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Easily find and complete your courses online at your own pace.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={GreenCheckbox} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Preserve your PTO for rest, not catching up on certifications.</span></div>
                                <div className="col-lg-1 col-2 p-0"><img src={GreenCheckbox} width='20' className="round-check" alt="Round" /></div>
                                <div className="col-lg-11 col-10 no-more-span"><span className="section-sub-title-1">Embrace reliable online courses, free from cancellations.</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="Juggling-patients">
                <div className="container">
                    <h1 className="self-paced">Select Your Self-Paced Certification Below</h1>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set" ref={viewCourses}>
                <div className="container">
                    <HomeCerificateCard />
                </div>
            </section>

            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="text-center">
                        <img src={GuaranteeGraphic} alt="img" width='115px' height='105px' />
                        <p className="Guarantee-p">We proudly offer a full money-back refund if your facility <span className="seamless-certification"> does not accept our certification cards. </span></p>
                    </div>
                </div>
            </section>
            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 text-center">
                            <img src={PulseLogo} alt="img" />
                        </div>
                        <div className="col-lg-7">
                            {/* <a href="">Groups</a>
                            <a>About Us</a>
                            <a>Privacy Policy</a>
                            <a>Contact Us</a> */}
                            <ul className="mt-5">
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/groups">Groups</a></li>
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/about-us">About Us</a></li>
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/privacy-policy">Privacy Policy</a></li>
                                <li className="list-inline-item footer-title"><a href="mailto:greg@pulsecertifications.com" className="sub-title-footer">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Footer /> */}
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>
    );
}

export default HomePage;
