import React from "react";
import Header from "../../../components/website/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import SampleText from '../../../assets/web-images/images/Sample test-15.svg';
import BlsIcon from '../../../assets/web-images/images/BLS-grey.svg';
import AclsIcon from '../../../assets/web-images/images/ACLS-grey.svg';
import PalsIcon from '../../../assets/web-images/images/PALS-grey.svg';
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../components/website/CertificateCard";
export default function SampleTest() {
   
    return (

        <>
            <Header />

                <section className="pt-10 feature-promo bg-light">
                    <div className="container">
                        <div className="sample-contact-us">
                            <div className="row text-center sample-group-contact">
                                <h1 className="c-title"> <img src={SampleText} height='40' width='40' alt="SampleText" /> Sample Tests</h1>
                            </div>

                            <div className="m-5 text-types-point">
                                <div className="row">
                                    <div className="container test-type">

                                        <div className="d-flex">
                                            <a className="sample-box text-start add-btn-test" href="sample-test/basic_life_support">
                                                <div className="row">
                                                    <div className="col-lg-1 col-2 col-md-2 TextCenter">
                                                    <img src={BlsIcon} height='40' width='40' alt="BlsIcon" /> 
                                                    </div>
                                                    <div className="col-lg-11 col-10 col-md-10 mt-2">
                                                  <span><b>BLS</b> (Basic Life Support)</span>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        
                                        <div className="d-flex">
                                            <a className="sample-box  text-start mt-3 add-btn-test" href="sample-test/advanced_cardiovascular_life_support">
                                            <div className="row">
                                                    <div className="col-lg-1 col-2 col-md-2 TextCenter">
                                                        <img src={AclsIcon} height='40' width='40' alt="aclsIcon" /> 
                                                    </div>
                                                  <div className="col-lg-11 col-10 col-md-10 mt-2">
                                                  <span><b>ACLS</b> (Advanced Cardiovascular Life Support)</span>
                                                  </div>
                                                </div>
                                            </a>
                                        </div>

                                        
                                        <div className="d-flex">
                                            <a className="sample-box  text-start mt-3 add-btn-test" href="sample-test/pediatric_advanced_life_support">
                                                <div className="row">
                                                    <div className="col-lg-1 col-2 col-md-2 TextCenter">
                                                        <img src={PalsIcon} height='40' width='40'alt="palsIcon" />   
                                                    </div> 
                                                    <div className="col-lg-11 col-10 col-md-10 mt-2">
                                                    <span><b>PALS </b> (Pediatric Advanced Life Support)</span>
                                                    </div> 
                                                </div>
                                                
                                            </a>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>
           
            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    
                    <CertificateCard />
                </div>
            </section>


            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>

    )

}