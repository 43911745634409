import { useState, useEffect } from "react";

import Question from '../api/Questions';

const UseFinalExamQuestions = ({test_name}) => {
    const [getFinalQuestions, setFinalQuestions] = useState([]);
  
    useEffect(() => {
        const getFinalExamQuestion = async () => {
            try {
            const response = await Question.getFinalExam({
                params: { test_name },
            });
            setFinalQuestions(response.data);
            } catch (error) {
            console.log(error);
            }
        };
      getFinalExamQuestion();
    }, []);
  
    return {getFinalQuestions };
   
}



export default UseFinalExamQuestions;