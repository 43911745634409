import React, { useEffect, useState } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import UseManagerUsers from "../../../hooks/superAdmin/UseManagerUsers";
import { useParams } from 'react-router-dom';
import { Watch } from "react-loader-spinner";
import ScrollToTop from 'react-scroll-to-top';

function ManagerEmployeeList() {

    const { manager_id } = useParams();
    const [managerUsersList, setManagerUsersList] = useState({});
    const [managerUsers, setManagerUsers] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { getManagerUsers, loading, recallApiWithNewParams, loadMoreLoading } = UseManagerUsers({ manager_id }, pageParams);


    useEffect(() => {
        if (getManagerUsers.status === 'success') {
            setManagerUsersList(getManagerUsers.data)
            // setManagerUsers(getManagerUsers.manager_user_list.data)

            const combinedArray = [...managerUsers, ...getManagerUsers.manager_user_list.data];
            const uniqueEmailMap = new Map();
            combinedArray.forEach(user => {
                const email = user.email;
                if (!uniqueEmailMap.has(email)) {
                    uniqueEmailMap.set(email, user);
                }
            });
            const uniqueArray = Array.from(uniqueEmailMap.values());
            setManagerUsers(uniqueArray);
            setPerPage(getManagerUsers.manager_user_list);
            setCurrentPage(getManagerUsers.manager_user_list.current_page);
        }

    }, [getManagerUsers, currentPage]);


    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        recallApiWithNewParams(params);


        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }
    return (
        <>
            <Header />

            <section className="pt-10 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-lg-8 col-4 progress-search-col">

                            {!loading &&
                                <>

                                    <p className="manager-detals-user-page text-capitalize">Name: {managerUsersList.first_name} {managerUsersList.last_name} </p>
                                    <p className="manager-detals-user-page">Email: {managerUsersList.email} </p>
                                    <p className="manager-detals-user-page text-capitalize">Organization: {managerUsersList.organization}</p>
                                </>
                            }
                        </div>
                        <div className="col-lg-4 text-end">
                            <a className="add-user-super-admin" href={`/super-admin/${manager_id}/add-manager-employee`}>Add Employee</a>
                        </div>

                    </div>

                    <div className="row pt-5">
                        <div className="tab-content">
                            <div id="tab1" className="tab-pane fade show active">
                                <table className="table" id="todo-list-table">
                                    <thead className="thead-course">
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Title</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading && managerUsers != '' && (
                                            <>
                                                {
                                                    managerUsers.map((ele) => {
                                                        return (
                                                            <tr className="tr-user" key={ele.user_id}>
                                                                <td className="text-center text-capitalize" data-label="Name">{ele.first_name} {ele.last_name}</td>
                                                                <td className="text-center" data-label='Title'>{ele.title !== null ? <>{ele.title}</> : <>-</>}</td>
                                                                <td className="text-center" data-label='Status'>{ele.user_status}</td>
                                                                <td className="text-center" data-label='Email'>{ele.email}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )}
                                    </tbody>

                                </table>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loading && (
                                        <Watch
                                            height="80"
                                            width="80"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loadMoreLoading && !loading && (
                                                <Watch
                                                    height="70"
                                                    width="70"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>
                                {!loading && !loadMoreLoading &&  (
                                    <>
                                        {managerUsers.length === 0 && (
                                            <>
                                                <div className="text-center">
                                                    <h5 className="valid-till">No Employee Found.</h5>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                {!loading &&
                                    <>

                                        {managerUsers.length > 0 &&
                                            <>
                                                {currentPage < perPage.last_page &&
                                                    (<button onClick={loadMore} className="load-more" type="button">
                                                        {loadMoreLoading ? 'Loading...' : 'Load More'}
                                                    </button>)
                                                }
                                            </>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>
    )

}

export default ManagerEmployeeList;
