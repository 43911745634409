import { useState, useEffect } from "react";

import Help from "../../api/Help";
const UseGetUserFund = () => {
    const [userFundDetails, setUserFundDetails] = useState([]);
  
    useEffect(() => {
        const getFund = async () => {
            try {
            const response = await Help.getUserFund();
            setUserFundDetails(response.data);
            } catch (error) {
            console.log(error);
            }
        };
      getFund();
    }, []);
  
    return { userFundDetails };
   
}



export default UseGetUserFund;