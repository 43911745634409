import { useState, useEffect } from "react";

import Question from '../api/Questions';

const UseFinalExamRessult = ({test_name}) => {
    const [getQuestions, setQuestions] = useState([]);
  
    useEffect(() => {
      const getQuestion = async () => {
        try {
          const response = await Question.getFinalExamResult({
            params: { test_name },
          });
          setQuestions(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getQuestion();
    }, []);
  
    return {getQuestions };
   
}



export default UseFinalExamRessult;