import { useState, useEffect } from "react";

import Help from '../api/Help';

const UseIndividualPurchaseHistory = (initialParams) => {
    const [getHistory, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadMoreLoading,setLoadMoreLoading] = useState(true);

    // Load data on initial render
    useEffect(() => {
        getPurchaseHistory(initialParams);
    }, [initialParams]);

    const getPurchaseHistory = async (params) => {
        try {
            const response = await Help.individualPurchaseHistory(params);
            setLoading(false);
            setLoadMoreLoading(false);
            setHistory(response.data);
        } catch (error) {
            setLoading(false);
            setLoadMoreLoading(false);
            console.log(error);
        }
    };

    // Function to recall API with new parameters
    const recallApiWithNewParams = (newParams) => {
        setLoadMoreLoading(true);
        getPurchaseHistory(newParams);
    };

    return { getHistory, loading, recallApiWithNewParams,loadMoreLoading };
};

export default UseIndividualPurchaseHistory;