import { useEffect, useState } from 'react'
import Superadmin from '../../api/SuperAdmin/Superadmin'

function UseToDoList(initialParams) {
    const [allToDo, setAllToDo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadMoreLoading,setLoadMoreLoading] = useState(true);

    useEffect(() => {
        getToDo(initialParams);
    }, [initialParams]);

    const getToDo = async(params)=>{
        try{
            const response = await Superadmin.ToDoList(params)
            setLoading(false);
            setLoadMoreLoading(false);
            setAllToDo(response.data);
        }catch(error){
            setLoading(false);
            setLoadMoreLoading(false);
            // console.log(error)
        }
    };

    const recallApiWithNewParams = (newParams) => {
        setLoadMoreLoading(true);
        getToDo(newParams);
    };
    
  return { allToDo, loading, recallApiWithNewParams,loadMoreLoading };
}

export default UseToDoList