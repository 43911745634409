import { useState } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";
const UseUpdateProfile = () => {

    const [isUpdateProfile, setIsUpdateProfile] = useState(false);
    const UpdateUserProfile = async (body) => {
        setIsUpdateProfile(true);
    
        let responseCode;
    
        try {
          const response = await Superadmin.updateProfile(body);
          responseCode = response.data;
        } catch (error) {
          responseCode = error.response.status;
        }
    
        setIsUpdateProfile(false);
    
        return { responseCode };
      };
    
      return { isUpdateProfile, UpdateUserProfile };
}


export default UseUpdateProfile;