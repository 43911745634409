import React from "react";
import { Link } from "react-router-dom";

export default function Footer(){

    const currentYear = new Date().getFullYear();

    return(
        <>
            <footer className="footer-section">
            <div className="footer-top footer-light footer-section-1">
                <div className="container">
                    <div className="row text-center">
                        <div>
                            <ul className="">
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/groups">Groups</a></li>
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/about-us">About Us</a></li>
                                <li className="list-inline-item footer-title"><a className="sub-title-footer" href="/privacy-policy">Privacy Policy</a></li>
                                <li className="list-inline-item footer-title"><a href="mailto:greg@pulsecertifications.com" className="sub-title-footer">Contact Us</a></li>
                            </ul>   
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-bottom footer-light py-4 footer-section-2">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                            <div className="copyright-text text-center">
                                <p className="mb-lg-0 mb-md-0">&copy; {currentYear} Pulse Certification LLC. All Rights Reserved.</p>
                            </div>
                        
                    </div>
                </div>
            </div>
            
        </footer>
        
        </>
    )
}