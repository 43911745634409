import React,{Component} from 'react'
import Header from "../../../components/emplyoeeSite/Layouts/Header";
import LoginImg from "../../../assets/web-images/images/forgot-password.png";
import {ThreeDots} from "react-loader-spinner";
import axiosConfig from "../../../api/Config";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const userDetails = cookies.get("user") ? cookies.get("user") : '';
class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: {
                newPassword: false,
                confirmPassword: false,
                passwordMissMatch: false,
            }
        }
    }

    validation = (target) => {
        let success = true;
        let error = this.state.error;
        error['newPassword'] = false;
        error['confirmPassword'] = false;
        error['passwordMissMatch'] = false;

        if (!target.new_password.value) {
            error['newPassword'] = true;
            success = false;
        }
        if (!target.c_new_password.value) {
            error['confirmPassword'] = true;
            success = false;
        }

        if (target.new_password.value !== "" && target.c_new_password.value !== "") {
            if (target.new_password.value !== target.c_new_password.value) {
                error['passwordMissMatch'] = true;
                success = false;
            }
        }
        this.setState(error);
        return success;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validation(e.target)) {
            this.setState({loading: true});
            const changePassword = await axiosConfig.post("/change-password",{
                new_password: e.target.new_password.value,
                confirm_password: e.target.c_new_password.value,
            }).then((res) => {
               return res.data;
            }).catch((err) => {
                return {status: 'error', message: err.messsage}
            });

            if (changePassword.status === "success") {
                this.setState({loading: false});
                toast.success(changePassword.message);
                
                if (userDetails.user_role !== undefined) {
                    if (userDetails.user_role.role_id === 2) {
                        cookies.remove('token');
                        cookies.remove('user');
                        cookies.remove('role');
                        window.location.href = '/login';
                    }else{
                        cookies.remove('token');
                        cookies.remove('user');
                        cookies.remove('role');
                        window.location.href = '/login';
                    }
                }



            } else {
                this.setState({loading: false});
                toast.error(changePassword.message);
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="sign-up-in-section bg-white ptb-120">
                    <div className="container">
                        <div className="card shadow-lg fpc">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 fp-main">
                                    <div className="card fpb">
                                        <img src={LoginImg} alt="login-image4" className="reset-password-img" height='466' />
                                    </div>
                                </div>
                                <div className="col-lg-6 fp-padding">
                                    <h3 className="welcome-title mt-5">Change your Password</h3>
                                    <p className="fpf-titles">Change your password to log into your account with your own password.</p>

                                    <form onSubmit={(e) => { this.handleSubmit(e) }} className="register-form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="password" className="mb-1 password-label">New Password </label>
                                                <div className="input-group mb-3">
                                                    <input type="password" className="form-control" name="new_password"  id="new_password" maxLength="15"/>
                                                </div>
                                                {this.state.error.newPassword && <div className="error">New password field is required</div>}
                                            </div>
                                            <div className="col-sm-12">
                                                <label htmlFor="password" className="mb-1 password-label">Confirm New Password </label>
                                                <div className="input-group mb-3">
                                                    <input type="password" className="form-control" name="c_new_password"  id="c_new_password" maxLength="15"/>
                                                </div>
                                                {this.state.error.confirmPassword && <div className="error">Confirm password field is required</div>}
                                            </div>

                                            {this.state.error.passwordMissMatch && <div className="error">New password and Confirm password are not matched.</div>}

                                            <div className="col-12 text-center btn-submit mt-3">
                                                <button type="submit" className="btn btn-login" disabled={this.state.loading}>
                                                    {this.state.loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Change Password"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer autoClose={2000} />
            </>
        )
    }
}

export default ChangePassword;