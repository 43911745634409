import React, { useEffect, useState } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import Search from '../../../assets/manager-images/images/Search.svg';
import Arrow from '../../../assets/super-admin-images/images/Arrow.svg';
import UseCouponCodeList from "../../../hooks/superAdmin/UseCouponCodeList";
import { Watch } from "react-loader-spinner";
import CouponCodeModal from "../../../components/superAdmin/CouponCodeModal/CouponCodeModal";
import ScrollToTop from 'react-scroll-to-top';

function CouponCodeList() {

    const [listCouponCode, setListCouponCode] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [showData, setShowData] = useState([]);
    const { allCouponCode, loading, recallApiWithNewParams,loadMoreLoading } = UseCouponCodeList(searchParams, pageParams);

    useEffect(() => {
        if (allCouponCode.status === "success") {
            if (allCouponCode.data.data.length > 0) {
                setListCouponCode([...listCouponCode, ...allCouponCode.data.data]);
                setPerPage(allCouponCode.data);
                setCurrentPage(allCouponCode.data.current_page);
            }
        }
    }, [allCouponCode]);



    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }

    const handleTabChange = (e) => {
        setListCouponCode([]);

        const params = {
            user_name: e.target.value
        }
        recallApiWithNewParams(params);
    }

    const openEditModal = (data) => {
        setModalShow(true);
        setShowData(data);
    }

    return (
        <>
            <Header />
            <section className="pt-10 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-lg-4 col-4 progress-search-col">
                            <div className="d-flex" >
                                <span className="track-progress-span">
                                    <img src={Search} width='20' height='20' alt="search" />
                                </span>
                                <input type="text" className="progress-search-btn" placeholder="Search" onChange={handleTabChange} />
                            </div>
                        </div>
                        
                    </div>

                    <div className="row pt-5">
                        <div className="tab-content">
                            <div id="tab1" className="tab-pane fade show active">
                                <table className="table" id="todo-list-table">
                                    <thead className="thead-course">
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Coupon Code</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!loading && (
                                            <>
                                                
                                                {
                                                    listCouponCode.map((code) => {
                                                        return (
                                                            <tr className="tr-user" key={code.id}>
                                                                <td className="text-center text-capitalize" data-label="Name">{code.first_name} {code.last_name}</td>
                                                                <td className="text-center" data-label='Email'>{code.email}</td>
                                                                <td className="text-center" data-label='Coupon Code'>{code.discount_code}</td>
                                                                <td className="text-center" data-label='Action'><a className="edit-coupon-page" href="#" onClick={() =>openEditModal(code)}><i className="fa fa-pencil" aria-hidden="true"></i></a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )}

                                    </tbody>
                                </table>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loading && (
                                        <Watch
                                            height="80"
                                            width="80"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>
                               
                                {listCouponCode.length > 0 &&
                                    <>
                                        {currentPage < perPage.last_page &&
                                            (<button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button>)
                                        }
                                    </>
                                }

                                {!loading && (
                                    <>
                                    {listCouponCode.length === 0 && (
                                                    <>
                                                        <div className="text-center">
                                                            <h5 className="valid-till">No Coupon Code details Found.</h5>
                                                        </div>
                                                    </>
                                                )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CouponCodeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                showData={showData}
            />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )

}

export default CouponCodeList;
