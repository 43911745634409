import React, { useEffect, useState } from 'react'
import Header from '../../../components/emplyoeeSite/Layouts/Header'
import Footer from '../../../components/website/Layouts/Footer'
import UseIndividualPurchaseHistory from '../../../hooks/UseIndividualPurchaseHistory';
import print from '../../../assets/web-images/images/Print-order.svg';
import view from '../../../assets/web-images/images/view.png';
import html2pdf from 'html2pdf.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from "moment";


export default function PurchaseHistory() {

    const [getPurchaseHistory, setPurchaseHistory] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { getHistory, loading, recallApiWithNewParams, loadMoreLoading } = UseIndividualPurchaseHistory(pageParams);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        if (getHistory.status === "success") {
            if (getHistory.data.data.length > 0) {
                setPurchaseHistory([...getPurchaseHistory, ...getHistory.data.data]);
                setPerPage(getHistory.data);
                setCurrentPage(getHistory.data.current_page);
            }
        }
    }, [getHistory]);

    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setModalContent('');
        setIsModalOpen(false);
    };

    // view receipt
    const handleViewReceipt = (UserData) => {

        let FundsContent = "";
        FundsContent = `<tr>
        <td class=text-center>${UserData.full_name}</td>
        <td class=text-center>$${Number(UserData.price).toFixed(2)}</td>
        </tr>`;
        const formattedDate = UserData.assign_date ? moment(UserData.assign_date).format('M/D/YYYY HH:mm') : 'N/A';
        const invoiceContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Receipt</title>
            <style>
                .invoice-container {
                    max-width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    border: 1px solid #e5e5e5;
                    border-radius: 15px;
                }
        
                .invoice-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                }
        
                .invoice-details {
                    margin-top: 4%;
                    text-align: right;
                    font-size: 40px;
                    color: #00386c;
                }
        
                .invoice-details div {
                    margin-bottom: 10px;
                }
        
                .invoice-info {
                    margin-bottom: 40px;
                }
        
                .invoice-info table {
                    width: 100%;
                    border-collapse: collapse;
                }
        
                .invoice-info th,
                .invoice-info td {
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: left;
                }
        
                .invoice-info th {
                    background-color: #f7f7f7;
                }
        
                .invoice-total {
                    text-align: right;
                }
                td.order-price-data{
                    text-align: end;
                    border: none;
                }
                th.remove-right-border {
                    border-right: 0;
                }
                th.remove-left-border {
                    border-left: 0;
                }
            </style>
        </head>
        <body>
            <div class="invoice-container">
                <div class="invoice-header">
                    <div class="invoice-details">
                        <div>Receipt</div>
                    </div>
                    <div class="invoice-logo">
                        <img src=${`${process.env.REACT_APP_IMAGES}/assets/Pulse-Logo.png`}
                            alt="Company Logo" width="228px" height="90px" />
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="flex: 1; ">
                        <p>Name: ${UserData.first_name} ${UserData.last_name}</p>
                        <p>Email: ${UserData.email}</p>
                        <p>Assign Datetime: ${formattedDate}</p>
                    </div>
                       
                </div>
                <br />
                <div class="invoice-info">
                    <table>
                        <tr>
                            
                            <th class='text-center '>Certificate Name</th>
                            <th class='text-center remove-left-border'>Price</th>
                        </tr>
                        ${FundsContent}
                    </table>
                </div>
            </div>
        </body>
        
        </html>  
        `;
        openModal(invoiceContent);
    };

    // pdf receipt
    const handleFundPrint = (UserData) => {
        let FundsContent = "";
        FundsContent = `<tr>
        <td class=text-center>${UserData.full_name}</td>
        <td class=text-center>$${Number(UserData.price).toFixed(2)}</td>
        </tr>`;
        const formattedDate = UserData.assign_date ? moment(UserData.assign_date).format('M/D/YYYY HH:mm') : 'N/A';
        const invoiceContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Receipt</title>
            <style>
                .invoice-container {
                    max-width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    border: 1px solid #e5e5e5;
                    border-radius: 15px;
                }
        
                .invoice-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                }
        
                .invoice-details {
                    margin-top: 4%;
                    text-align: right;
                    font-size: 40px;
                    color: #00386c;
                }
        
                .invoice-details div {
                    margin-bottom: 10px;
                }
        
                .invoice-info {
                    margin-bottom: 40px;
                }
        
                .invoice-info table {
                    width: 100%;
                    border-collapse: collapse;
                }
        
                .invoice-info th,
                .invoice-info td {
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: left;
                }
        
                .invoice-info th {
                    background-color: #f7f7f7;
                }
        
                .invoice-total {
                    text-align: right;
                }
                td.order-price-data{
                    text-align: end;
                    border: none;
                }
                th.remove-right-border {
                    border-right: 0;
                }
                th.remove-left-border {
                    border-left: 0;
                }
            </style>
        </head>
        <body>
            <div class="invoice-container mt-5">
                <div class="invoice-header">
                    <div class="invoice-details">
                        <div>Receipt</div>
                    </div>
                    <div class="invoice-logo">
                        <img src=${`${process.env.REACT_APP_IMAGES}/assets/Pulse-Logo.png`}
                            alt="Company Logo" width="225" height="90" />
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="flex: 1; ">
                        <p>Name: ${UserData.first_name} ${UserData.last_name}</p>
                        <p>Email: ${UserData.email}</p> 
                    </div>
                    <div style="flex: 1; text-align: right;">
                        <p>Datetime: ${formattedDate}</p>
                    </div>
                </div>
                <br />
                <div class="invoice-info">
                    <table>
                        <tr>
                            <th class='text-center '>Certificate Name</th>
                            <th class='text-center remove-left-border'>Price</th>
                        </tr>
                        ${FundsContent}
                    </table>
                </div> 
            </div>
        </body>
        </html>  
        `;
        const input = document.createElement('div');
        input.innerHTML = invoiceContent;
        html2pdf().from(input).save("Purchase History " + UserData.full_name + " Receipt.pdf");
    };

    return (
        <>
            <Header />
            <section className="ptb-120 feature-promo bg-light mt-5 IndividualPurchaseHistory-mb">
                <div className="container">
                    <table className="table" id="history-table">
                        <thead >
                            <tr className="tr-border-bg i-purchase-history">
                                <th className="history-table-th text-center">Course Name</th>
                                <th className="history-table-th text-center">Cost</th>
                                <th className="history-table-th text-center">View Receipt</th>
                                <th className="history-table-th text-center">Print Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && (
                                <>
                                    {getPurchaseHistory.map((item) =>
                                        <tr className="tr-border-bg-1">
                                            <td className="history-table-td text-center" data-label="Course">
                                                {item.full_name === 'Basic Life Support' && <>BLS </>}
                                                {item.full_name === 'Advanced Cardiovascular Life Support' && <>ACLS</>}
                                                {item.full_name === 'Pediatric Advanced Life Support' && <>PALS</>}
                                                {item.full_name === 'Basic Life Support Re-Certification' && <>BLS Re-Certification </>}
                                                {item.full_name === 'Advanced Cardiovascular Life Support Re-Certification' && <>ACLS Re-Certification </>}
                                                {item.full_name === 'Pediatric Advanced Life Support Re-Certification' && <>PALS Re-Certification </>}

                                            </td>
                                            <td className="history-table-td text-center" data-label="Qty">
                                                ${(item.price).toFixed(2)}
                                            </td>
                                            <td className="history-table-td text-center" data-label="Amount">
                                                <button type="button" className="user-purchase-history view-wallet-btn" onClick={() => handleViewReceipt(item)} >
                                                    {/* <img src={view} alt="logo" width='20' height='15' /> */}
                                                   <i className="fa fa-eye" style={{ fontSize: '20px' }}></i>
                                                </button>
                                            </td>
                                            <td className="history-table-td text-center" data-label="Amount">
                                                <button type="button" className="user-purchase-history view-wallet-btn" onClick={() => handleFundPrint(item)} >
                                                    {/* <img src={print} alt="logo" width='20' height='15' /> */}
                                                    <i className="fa fa-print" style={{ fontSize: '20px' }}></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>

                    {getHistory.status === 'error' &&
                        <>
                            <p className="current-balance-title text-center">No course purchase history list found.</p>
                        </>}

                    {currentPage < perPage.last_page &&
                        (<div className="pb-3"><button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button></div>)
                    }

                    <Modal show={isModalOpen} onHide={closeModal} dialogClassName="modal-90w">
                        <Modal.Body>
                            <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={closeModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
            <Footer />
        </>
    )

}