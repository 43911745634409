import axiosConfig from './Config';


const Help ={
    getState: () => axiosConfig.get(`get-state`),
    getCity: ({state_id }) => axiosConfig.get(`get-city/${state_id}`),
    getDiscountLevel: () => axiosConfig.get(`get-count`),
    getDiscount: ({managerId}) => axiosConfig.get(`get-manager-discount/${managerId}`),
    getUserFund: () => axiosConfig.get(`get-funds`),
    sendFundDetails: (data) => axiosConfig.post(`send-funds-receipt`,data),
    individualPurchaseHistory: (params) => axiosConfig.post(`get-individual-purchase-history`,params),
}

export default Help;