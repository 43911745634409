import React, { useState } from 'react'
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import ExpiredConfirmBalanceDebited from "./ExpiredConfirmBalanceDebited";

function SelectReAssignCourseModal(props) {

    // const [confirmModal,setConfirmModal] = useState(false);

    // const selectCertificate = () => {
    //     setConfirmModal(!confirmModal);
    //     props.onHide();
    // }

    // const handleOnChange = (e) => {
    //     let value = e.target.value;
    //     let name = e.target.name;
    //     localStorage.setItem(name,value);
    // }

    const [confirmModal, setConfirmModal] = useState(false)
    const [selectedBlsCourse, setSelectedBlsCourse] = useState(null);
    const [selectedAclsCourse, setSelectedAclsCourse] = useState(null);
    const [selectedPalsCourse, setSelectedPalsCourse] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const selectCertificate = () => {
        // Check if a selection has been made for BLS certification and BLS recertification
        if (props.certificateName === 'bls_certificate_e_date' && !selectedBlsCourse) {
            setErrorMessage("Please select which course to assign.");
        } else if (props.certificateName === 'acls_certificate_e_date' && !selectedAclsCourse) {
            // Check if a selection has been made for ACLS certification or ACLS recertification
            setErrorMessage("Please select which course to assign.");
        } else if (props.certificateName === 'pals_certificate_e_date' && !selectedPalsCourse) {
            // Check if a selection has been made for PALS certification or PALS recertification
            setErrorMessage("Please select which course to assign.");
        } else {
            setErrorMessage(""); // Clear the error message when validations pass
            setConfirmModal(!confirmModal);
            props.onHide();
        }
    }

    const handleOnChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        // Update the corresponding selectedCourse state based on the category
        if (name === "select_bls_certificate") {
            setSelectedBlsCourse(value);
            setErrorMessage("");
        } else if (name === "select_acls_certificate") {
            setSelectedAclsCourse(value);
            setErrorMessage("");
        } else if (name === "select_pals_certificate") {
            setSelectedPalsCourse(value);
            setErrorMessage("");
        }

        localStorage.setItem(name, value);
    }

    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                <Modal.Body>
                    <div className="row">
                        <p className="add-new-team-member-title">Select Course </p>
                        {props.certificateName === 'bls_certificate_e_date' && (<>
                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_bls_certificate"
                                                id="bls_certificate_selete"
                                                value="bls-certification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> BLS Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_bls_certificate"
                                                id="bls_certificate_selete"
                                                value="bls-recertification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> BLS Re-Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        {props.certificateName === 'acls_certificate_e_date' && (<>
                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_acls_certificate"
                                                id="acls_certificate_selete"
                                                value="acls-certification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> ACLS Certification
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_acls_certificate"
                                                id="acls_certificate_selete"
                                                value="acls-recertification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> ACLS Re-Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        {props.certificateName === 'pals_certificate_e_date' && (<>
                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_pals_certificate"
                                                id="pals_certificate_selete"
                                                value="pals-certification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> PALS Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" id="team-datePicker">
                                <div className="row">
                                    <div className="col-lg-4 ">
                                        <label className="label-active-text">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="select_pals_certificate"
                                                id="pals_certificate_selete"
                                                value="pals-recertification"
                                                onChange={(e) => { handleOnChange(e) }}
                                            /> PALS Re-Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        <p className="error mt-3 ml-5">{errorMessage}</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-yes-btn" onClick={selectCertificate}>Ok</button>
                                <button className="remove-no-btn" onClick={props.onHide}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ExpiredConfirmBalanceDebited
                show={confirmModal}
                onHide={() => { setConfirmModal(!confirmModal) }}
                CertificateType={props.CertificateType}
                certificateName={props.certificateName}
                manager_id={props.manager_id}
            />
        </>
    )
}

export default SelectReAssignCourseModal;
