import React, { useEffect, useState } from "react";
import UsePurchaseHistory from "../../../hooks/manager/UsePurchaseHistory";
import moment from "moment";

function PurchaseHistory() {
    const [getPurchaseHistory, setPurchaseHistory] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { getHistory, loading, recallApiWithNewParams, loadMoreLoading } = UsePurchaseHistory(pageParams);

    useEffect(() => {
        if (getHistory.status === "success") {
            if (getHistory.data.data.length > 0) {
                setPurchaseHistory([...getPurchaseHistory, ...getHistory.data.data]);
                setPerPage(getHistory.data);
                setCurrentPage(getHistory.data.current_page);
            }
        }
    }, [getHistory]);



    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }


    return (
        <>
            {!loading && (

                <>
                    {getHistory.status === 'error' ?
                        <>
                            <p className="current-balance-title text-center">No course assign history list found.</p>
                        </> :
                        <>
                            <table className="table" id="history-table">
                                <thead >
                                    <tr className="tr-border-bg">
                                        <th className="history-table-th text-center">Name</th>
                                        <th className="history-table-th text-center">Course</th>
                                        <th className="history-table-th text-center">Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && (
                                        <>
                                            {getPurchaseHistory.map((item) =>

                                                <tr className="tr-border-bg-1">
                                                    <td className="history-table-td text-center text-capitalize" data-label="Name" >{item.first_name} {item.last_name}</td>

                                                    <td className="history-table-td text-center" data-label="Course">
                                                        {item.full_name === 'Basic Life Support' && <>BLS </>}
                                                        {item.full_name === 'Advanced Cardiovascular Life Support' && <>ACLS</>}
                                                        {item.full_name === 'Pediatric Advanced Life Support' && <>PALS</>}
                                                        {item.full_name === 'Basic Life Support Re-Certification' && <>BLS Re-Certification </>}
                                                        {item.full_name === 'Advanced Cardiovascular Life Support Re-Certification' && <>ACLS Re-Certification </>}
                                                        {item.full_name === 'Pediatric Advanced Life Support Re-Certification' && <>PALS Re-Certification </>}

                                                    </td>

                                                    <td className="history-table-td text-center" data-label="Qty">
                                                        ${(item.price).toFixed(2)}
                                                        {/* ${(item.amount).toFixed(2)} */}
                                                    </td>

                                                </tr>
                                            )}
                                        </>
                                    )}

                                </tbody>
                            </table>
                            {currentPage < perPage.last_page &&
                                (<div className="pb-3"><button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button></div>)
                            }
                        </>
                    }

                </>
            )}


        </>
    )
}

export default PurchaseHistory;