import { useState, useEffect } from "react";

import manager from "../../api/manager/manager";

const UseUserCurrentBalance = () => {
    const [getUserCurrentBalance, setUserCurrentBalance] = useState([]);
  
    useEffect(() => {
      const getAssignUser = async () => {
        try {
          const response = await manager.getCurrentBalance();
          setUserCurrentBalance(response.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      getAssignUser();
    }, []);
  
    return {getUserCurrentBalance};
}



export default UseUserCurrentBalance;