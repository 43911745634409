import { useState, useEffect } from "react";

import manager from '../../api/manager/manager';

const UseManagerDashboardData = (initialParams) => {
    const [getDashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDashData = async (params) => {
        try {
            const response = await manager.getDashboardData(params);
            setDashboardData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // Load data on initial render
    useEffect(() => {
        getDashData(initialParams);
    }, [initialParams]);

    // Function to recall API with new parameters
    const recallApiWithNewParams = (newParams) => {
        setLoading(true);
        getDashData(newParams);
    };

    return { getDashboardData, loading, recallApiWithNewParams };
};

export default UseManagerDashboardData;