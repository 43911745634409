import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import All from "./SubPages/All";
import Bls from "./SubPages/Bls";
import Pals from "./SubPages/Pals";
import Acls from "./SubPages/Acls";
import Header from "../../../components/website/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../components/website/CertificateCard";
export default function Algorithms() {

    
    return (
        <>
            <Header />
            <section className="ptb-120 feature-promo bg-light" id='tab-listing'>
                <div className="container">
                    <div className="row pt-5">
                        <Tabs
                            defaultActiveKey="all"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="all" title="All">
                                <All />
                            </Tab>
                            <Tab eventKey="bls" title="BLS">
                                <Bls />
                            </Tab>
                            <Tab eventKey="acls" title="ACLS">
                                <Acls />
                            </Tab>
                            <Tab eventKey="pals" title="PALS">
                                <Pals />
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    
                    <CertificateCard />
                </div>
            </section>

            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>
    )


}