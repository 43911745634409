import React, { useState, useEffect } from "react";
import Right from '../../../../assets/manager-images/images/Right.svg'
import Close from '../../../../assets/manager-images/images/Close.svg'
import Error from '../../../../assets/manager-images/images/Error.svg'
import Assigned from '../../../../assets/manager-images/images/Assigned.svg'
import AssignedExpired from '../../../../assets/manager-images/images/Assigne_Expired.svg'
import Mail from '../../../../assets/manager-images/images/Mail.svg'
import moment from "moment/moment";
import SendNotificationModal from "../../../../components/managerSite/Dashboard/SendNotificationModal";
import UseSendReNotify from "../../../../hooks/manager/UseSendReNotify";
import { Oval } from "react-loader-spinner";

function CertificateCourseDetails(props) {
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isReNotify, sendReNotification } = UseSendReNotify();
    const [lastNotifiedDate, setLastNotifiedDate] = useState(props.item.last_notify_date);


    const onSendReNotification = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const {
                responseCode: reNotify
            } = await sendReNotification({ 'certificate_id': props.item.certificate_type_id, 'user_id': props.item.assign_to, 'uc_id':props.item.id });

            if (reNotify.data.status === 'success') {
                setModalShow(true);
                setLastNotifiedDate(new Date());
            } else {
                console.log('Something went wrong .');
            }
        } catch (error) {
            console.log('Something went wrong .');
        }
        setLoading(false);
    }

    useEffect(() => {
        setLastNotifiedDate(props.item.last_notify_date);
    }, [props.item.last_notify_date]);


    return (
        <>
            {props.item.certificate_status !== 'n/a' &&
                <>
                    <tbody>
                        <tr className="tr-border-bg">
                            <td data-label="Name">
                                <p className="sub-course-detail">{`${props.item.first_name} ${props.item.last_name}`}</p>
                            </td>
                            <td data-label="BLS">
                                <div className="text-center">

                                    {props.item.certificate_status === 'active' && (<img src={Right} width='40' alt="close-icon" />)}
                                    {props.item.certificate_status === 'expired' && (<img src={Close} width='40' alt="close-icon" />)}
                                    {props.item.certificate_status === 'expiring_soon' && (<img src={Error} width='40' alt="close-icon" />)}
                                    {props.item.certificate_status === 'assigned' && (<img src={Assigned} width='40' alt="close-icon" />)}
                                    {props.item.certificate_status === "assign_expired" && (<img src={AssignedExpired} width='40' alt="close-icon" />)}
                                </div>
                            </td>
                            <td data-label="Assigned Date">

                                {props.item.certificate_status === 'active' && (<p className="sub-course-detail">{moment(props.item.active_date).format('M/D/YYYY')}</p>)}
                                {props.item.certificate_status === 'expired' && (<p className="sub-course-detail">{moment(props.item.expiration_date).format('M/D/YYYY')}</p>)}
                                {props.item.certificate_status === 'expiring_soon' && (<p className="sub-course-detail">{moment(props.item.expiration_date).format('M/D/YYYY')}</p>)}
                                {props.item.certificate_status === 'assigned' && (<p className="sub-course-detail">{moment(props.item.assign_date).format('M/D/YYYY')}</p>)}
                                {props.item.certificate_status === 'assign_expired' && (<p className="sub-course-detail">{moment(props.item.assign_date).format('M/D/YYYY')}</p>)}
                            </td>

                            <td data-label="Last Notified Date">
                                <p className="sub-course-detail">

                                    {lastNotifiedDate != null ?
                                        <>
                                            {moment(lastNotifiedDate).format('M/D/YYYY')}
                                        </>
                                        :
                                        <> - </>
                                    }
                                </p>
                            </td>
                            {props.item.certificate_status === 'assigned' ||  props.item.certificate_status === 'assign_expired' ?
                                <>
                                    <td data-label="">
                                        <div className="float-right">
                                            <button type="button" className="re-notify" onClick={onSendReNotification} disabled={loading}>
                                                <img src={Mail} alt="arrow-view-details" width='23' /> &nbsp; {loading ? <Oval color="#000" wrapperClass="d-block" height={30} width={40} /> : "Re-Notify Now"}
                                            </button>
                                        </div>
                                    </td>
                                </>
                                :
                                <>
                                    <td className=""></td>
                                </>
                            }


                        </tr>
                    </tbody>
                </>
            }

            <SendNotificationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default CertificateCourseDetails;