import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Print from '../../../../assets/web-images/images/Print.svg'
import { ThreeDots } from "react-loader-spinner";

export default function PrintAlgorithm(props) {

    const [loading ,setLoading] = useState(false);

    const handlePrintClick = () => {
        const printWindow = window.open('', '', 'width=1200,height=1200');
        setLoading(true);
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>${props.printName}</title>
                    <style>
                        @media print {
                            .print-only {
                                display: block !important;
                            }
                            .no-print {
                                display: none !important;
                            }
                        }
                    </style>
                </head>
                <body>
                    <img src="${props.printImageUrl}" alt="${props.printName}" class="print-only" />
                    <!-- Other content you want to print -->
                    <script type="text/javascript">
                        window.onload = function () {
                            window.print();
                            window.onafterprint = function () {
                                printWindow.close();
                            };
                        }
                    </script>
                </body>
            </html>
        `);
        
        setLoading(false);
        printWindow.document.close();
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="print-name" id="contained-modal-title-vcenter">
                        {props.printName}
                    </div>
                </Modal.Header>
                <Modal.Body>
                   <div className="align-center">
                    
                    <div className="card modal-prints no-print">
                        <img src={props.printImageUrl} alt="bls-1" className="modal-print-img" />
                    </div>
                   </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="print-title-div-modal">
                        <a className="print-title" onClick={handlePrintClick} disabled={loading}>
                            <img src={Print} alt="print" width='20' height='30' />  {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Print"}
                        </a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
