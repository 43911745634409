import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import ReAssignCourseModal from "./ReAssignCourseModal";
import axiosConfig from "../../../../api/Config";
import Cookies from 'universal-cookie'

function ReAssignCourseConfirmation(props) {
    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const [openAssignCourse, setOpenAssignCourse] = useState(false);
    const [discount, setDiscount] = useState(0);
    const certificateName = props.certificateName;

    useEffect( () => {
        const fetchDataAndCleanup = async () => {
            await fetchData();
        };

        fetchDataAndCleanup();
    },[]);

    const onConfirm = () => {
        setOpenAssignCourse(!openAssignCourse);
        props.onHide();
    }

    let certification = props.CertificateType;
    let amount1, amount2, amount3;

    const fetchData = async () => {

        if (userDetails.user_role !== undefined) {
            if (userDetails.user_role.role.name === 'super_admin') {
                try {
                    const response = await axiosConfig.get(`/get-manager-discount/${props.manager_id}`);
                    const getDiscount = response.data.data;
                    if (getDiscount.discount > 0) {
                        const discount = getDiscount.discount;
                        setDiscount(discount);
                    }
                } catch (error) {
                    console.log(error);
                }
            }else{
                try {
                    const response = await axiosConfig.get('/get-count');
                    const getDiscount = response.data.data;
                    if (getDiscount.discount > 0) {
                        const discount = getDiscount.discount;
                        setDiscount(discount);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        
    };

    certification.forEach((citem, index) => {
        if (index === 0) {
            if (discount > 0){
                const originalAmount = citem.amount;
                const discountedPrice = originalAmount * (discount / 100);
                amount1 = originalAmount - discountedPrice;
            } else {
                amount1 = citem.amount;
            }
        } else if (index === 1) {
            if (discount > 0){
                const originalAmount = citem.amount;
                const discountedPrice = originalAmount * (discount / 100);
                amount2 = originalAmount - discountedPrice;
            } else {
                amount2 = citem.amount;
            }
        } else if (index === 2) {
            if (discount > 0){
                const originalAmount = citem.amount;
                const discountedPrice = originalAmount * (discount / 100);
                amount3 = originalAmount - discountedPrice;
            } else {
                amount3 = citem.amount;
            }
        }
    });


    return (
        <>
            <Modal
                show={props.show ?? false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                <Modal.Body>
                    <p className="remove-class-title">
                        Would you like to assign the Recertification course?
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-yes-btn" onClick={onConfirm}>Yes</button>
                                <button className="remove-no-btn" onClick={props.onHide}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ReAssignCourseModal
                show={openAssignCourse}
                certificateName={certificateName}
                onHide={() => {
                    setOpenAssignCourse(!openAssignCourse)
                }}
                amount1={amount1}
                amount2={amount2}
                amount3={amount3}
            />

        </>
    )
}

export default ReAssignCourseConfirmation;