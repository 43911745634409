import { useState } from "react";

import Question from '../api/Questions';

  const UseEmployeeQuestionsAnswer = () => {
    const [isCreating, setIsCreating] = useState(false);
  
    const createAnswer = async (answers) => {
      setIsCreating(true);
  
      let responseCode;
  
      try {
        const response = await Question.create(answers);
        responseCode = response.data;
      } catch (error) {
        responseCode = {status: "error"};
      }
  
      setIsCreating(false);
  
      return { responseCode };
    };
  
    return { isCreating, createAnswer };
  };


export default UseEmployeeQuestionsAnswer;