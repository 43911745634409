import { useState, useEffect } from "react";

import CertificateType from '../api/CertificateTypes';

const UseCertificate = () => {
    const [certificateTypes, setCertificateTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const getCertificateTypes = async () => {
        try {
          const response = await CertificateType.getCertificate();
          // if (response.data.status === "success") {
          //   setCertificateTypes(response.data.data);
          // }
          setCertificateTypes(response.data)
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
  
      };
  
      getCertificateTypes();
    }, []);
  
    return {certificateTypes,loading };
   
}



export default UseCertificate;