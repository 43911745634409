import { useState } from "react";

import manager from "../../api/manager/manager";

const UseRemoveMember = () => {

    const [isRemoveMember, setIsRemoveMember] = useState(false);
    const RemoveMemberData = async (body) => {
        setIsRemoveMember(true);
    
        let responseCode;
    
        try {
          const response = await manager.removeMember(body);
          responseCode = response.data;
        } catch (error) {
          responseCode = error.response.status;
        }
    
        setIsRemoveMember(false);
    
        return { responseCode };
      };
    
      return { isRemoveMember, RemoveMemberData };
}


export default UseRemoveMember;