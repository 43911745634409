import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import Print from '../../../assets/web-images/images/Print.svg';
import Right from '../../../assets/manager-images/images/Right.svg';
import Error from '../../../assets/manager-images/images/Error.svg';
import Close from '../../../assets/manager-images/images/Close.svg';
import ClockRight from '../../../assets/super-admin-images/images/Clock-Right.svg';
import Exportcolor from '../../../assets/super-admin-images/images/Export-color.svg';
import { useParams } from 'react-router-dom';
import UseToDouserDetails from "../../../hooks/superAdmin/UseToDouserDetails";
import moment from "moment";
import UseSendCertificate from "../../../hooks/UseSendCertificate";
import SendviaEmail from "../../../components/emplyoeeSite/Certificate/SendviaEmail";
import PhysicallyAssignedCertificateModal from "../../../components/superAdmin/ToDoModal/PhysicallyAssignedCertificateModal";
import { ThreeDots } from "react-loader-spinner";
import ScrollToTop from 'react-scroll-to-top';

function ToDoViewDetails() {

    const { user_id } = useParams();
    const { certificate_id } = useParams();
    const [sendMaliLoading, setSendMaliLoading] = useState(false);
    const [certificateSend, setCertificateSend] = useState(false);
    const [email, setEmail] = useState("");
    const [todoDetails, setToDoDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const certificateRef = useRef(null);
    const certificateRefBack = useRef(null);

    const [modalShow, setModalShow] = useState(false);

    const { getTodoDetails: todoUserDeatilsApi } = UseToDouserDetails({
        user_id, certificate_id
    });


    useEffect(() => {
        fetchMemberDeatis();
    }, [todoUserDeatilsApi]);

    const fetchMemberDeatis = () => {
        if (todoUserDeatilsApi.status === 'success') {
            setLoading(false);
            setToDoDetails(todoUserDeatilsApi.data)
        }
    }


    const onClickPrintBtn = (certificateType) => {
        let getFront = "";
        let getBack = "";
        getFront = certificateRef.current.innerHTML;
        getBack = certificateRefBack.current.innerHTML;
        if (certificateType === "bls_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        } else if (certificateType === "acls_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        } else if (certificateType === "pals_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        } else if (certificateType === "bls_re_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        } else if (certificateType === "acls_re_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        } else if (certificateType === "pals_re_certification") {
            getFront = certificateRef.current.innerHTML;
            getBack = certificateRefBack.current.innerHTML;
        }

        const printWindow = window.open('', '', 'width=800,height=900');
        const frontDocument = `
        <!DOCTYPE html>
        <html>
            <head>
                <style type="text/css">
                    #bls-front-img {
                            background-color: #afdadb;
                            border: 0;
                            border-radius: 30px;
                        }
                        #bls-front-img.aclsCertificate {
                            background-color: #fbd3d1 !important;
                            border: 0;
                            border-radius: 30px;
                        }
                        .text-center {
                            text-align: center !important;
                        }
                        .card {
                            --bs-card-spacer-y: 1rem;
                            --bs-card-spacer-x: 1rem;
                            --bs-card-title-spacer-y: 0.5rem;
                            --bs-card-title-color: ;
                            --bs-card-subtitle-color: ;
                            --bs-card-border-width: var(--bs-border-width);
                            --bs-card-border-color: var(--bs-border-color-translucent);
                            --bs-card-border-radius: var(--bs-border-radius);
                            --bs-card-box-shadow: ;
                            --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
                            --bs-card-cap-padding-y: 0.5rem;
                            --bs-card-cap-padding-x: 1rem;
                            --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
                            --bs-card-cap-color: ;
                            --bs-card-height: ;
                            --bs-card-color: ;
                            --bs-card-bg: var(--bs-body-bg);
                            --bs-card-img-overlay-padding: 1rem;
                            --bs-card-group-margin: 0.75rem;
                            position: relative;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            min-width: 0;
                            height: var(--bs-card-height);
                            word-wrap: break-word;
                            background-color: var(--bs-card-bg);
                            background-clip: border-box;
                            border: var(--bs-card-border-width) solid var(--bs-card-border-color);
                            border-radius: var(--bs-card-border-radius);
                        }
                        #bls-front-img h5 {
                            color: #00386c;
                            font-family: 'Helvetica75';
                            font-size: 25px;
                            margin: 0;
                        }
                        #bls-front-img p {
                            color: #231f20;
                            font-family: 'Helvetica';
                            margin-left: 7%;
                            margin-right: 7%;
                            text-align: center;
                            font-size: 21px;
                        }
                        #bls-front-img .text-name-bls-h6 {
                            text-align: left;
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            padding: 1%;
                            background-color: #d7eced;
                            color: #231f20 !important;
                            padding-left: 20px;
                            font-size: 15px;
                            font-family: 'Helvetic55';
                            font-weight: 500 !important;
                            margin-bottom: 4%;
                        }
                        #bls-front-img .flex-div {
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            background-color: #d7eced;
                            color: #231f20 !important;
                            padding-left: 20px;
                            margin-bottom: 3%;
                            justify-content: space-between;
                            padding-right: 10%;
                        }
                        #bls-front-img h4 {
                            margin-left: 12%;
                            margin-right: 12%;
                            background-color: #00386c;
                            color: #fff;
                            font-family: 'Helvetica75';
                            border: 1px solid #00386c;
                            margin-bottom: 0;
                            border-bottom: 0;
                            padding: 7px;
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                            font-size: 19px;
                        }
                        #bls-front-img .flex-div {
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            background-color: #d7eced;
                            color: #231f20 !important;
                            padding-left: 20px;
                            margin-bottom: 3%;
                            justify-content: space-between;
                            padding-right: 10%;
                        }
                        .flex-div h6 {
                            font-size: 15px;
                            font-family: 'Helvetic55';
                            font-weight: 500 !important;
                            padding: 1%;
                            margin-bottom: 0;
                            margin-top: 0;
                        }
                        .flex-div span {
                            color: #231f20 !important;
                            font-family: 'Helvetic';
                            font-weight: 500 !important;
                        }
                        .d-flex {
                            display: -webkit-box !important;
                            display: -ms-flexbox !important;
                            display: flex !important;
                        }
                        #bls-front-img.aclsCertificate .text-name-bls-h6 {
                            text-align: left;
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            padding: 1%;
                            background-color: #fde9e8;
                            color: #231f20 !important;
                            padding-left: 20px;
                            font-size: 15px;
                            font-family: 'Helvetic55';
                            font-weight: 500 !important;
                            margin-bottom: 4%;
                        }
                        #bls-front-img.aclsCertificate .flex-div {
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            background-color: #fde9e8;
                            color: #231f20 !important;
                            padding-left: 20px;
                            margin-bottom: 3%;
                            justify-content: space-between;
                            padding-right: 10%;
                        }
                        #bls-front-img.aclsCertificate h4 {
                            margin-left: 12%;
                            margin-right: 12%;
                            background-color: #ec1c24;
                            color: #fff;
                            font-family: 'Helvetica75';
                            border: 1px solid #ec1c24;
                            margin-bottom: 0;
                            border-bottom: 0;
                            padding: 7px;
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                            font-size: 19px;
                        }
                        #bls-front-img.aclsCertificate h5 {
                            color: #ec1c24;
                            font-family: 'Helvetica75';
                            font-size: 25px;
                        }
                        #bls-front-img.palsCertificate {
                            background-color: #dfd5e9 !important;
                            border: 0;
                            border-radius: 30px;
                        }
                        #bls-front-img.palsCertificate h5 {
                            color: #8b63a9;
                            font-family: 'Helvetica75';
                            font-size: 25px;
                        }
                        #bls-front-img.palsCertificate .text-name-bls-h6 {
                            text-align: left;
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            padding: 1%;
                            background-color: #efeaf4;
                            color: #231f20 !important;
                            padding-left: 20px;
                            font-size: 15px;
                            font-family: 'Helvetic55';
                            font-weight: 500 !important;
                            margin-bottom: 4%;
                        }
                        #bls-front-img.palsCertificate .flex-div {
                            margin-left: 12%;
                            margin-right: 12%;
                            border-radius: 30px;
                            background-color: #efeaf4;
                            color: #231f20 !important;
                            padding-left: 20px;
                            margin-bottom: 3%;
                            justify-content: space-between;
                            padding-right: 10%;
                        }
                        #bls-front-img.palsCertificate h4 {
                            margin-left: 12%;
                            margin-right: 12%;
                            background-color: #8b63a9;
                            color: #fff;
                            font-family: 'Helvetica75';
                            border: 1px solid #8b63a9;
                            margin-bottom: 0;
                            border-bottom: 0;
                            padding: 7px;
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                            font-size: 19px;
                        }
                        @media print {
                            body {
                                -webkit-print-color-adjust: exact;
                                -moz-print-color-adjust: exact;
                                -ms-print-color-adjust: exact;
                                print-color-adjust: exact;
                            }
                        }
                        @page {
                            size: A4;
                            margin: 1cm; /* Adjust the margin as needed */
                        }
                        body {
                            margin: 0;
                        }                     
                </style>
            </head>
            <body>
                
                <div class="front-top" style="height: 100vh">
                    <br /> <br />
                    ${getFront} 
                    <br/><br/>
                    ${getBack}
                </div>
                
            </body>
        </html>`;

        // Write the HTML content to the print window
        printWindow.document.open();

        printWindow.document.write(frontDocument);
        printWindow.document.close();

        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }

    const { isSendCertificateApi, sendCertificateApi } = UseSendCertificate();

    const handleCertificate = async (certificate_id, email) => {
        setSendMaliLoading(true);

        const {
            responseCode: sendCertifications
        } = await sendCertificateApi({ 'userId': user_id, 'certificate_id': certificate_id, 'disabledCertificate': 0 });

        if (sendCertifications.data.status === 'success') {
            setCertificateSend(true);
            setEmail(email);
        }
        setSendMaliLoading(false);
    };

    const handlePrintClick1 = () => {
        setLoading(true);
        const printWindow = window.open('', '', 'width=1200,height=1200');
        printWindow.document.open();
        printWindow.document.write(
            `<html><head>
                <title>Survey List</title>
                <style>
                .survey-qus {
                    color: #777777;
                    font-size: 18px;
                    font-family: 'avenir-lt-55-roman';
                    font-weight: 500;
                    margin-left: 20px;
                    margin-top: 0px;
                    margin-bottom: -6px;
                }
                .survey-ans {
                    color: #3e3e3e;
                    font-size: 18px;
                    font-family: 'avenir-lt-55-roman';
                    font-weight: 500;
                    margin-left: 20px;
                    
                }
                .name-letter{
                    text-transform: capitalize;
                }
                @media print {
                    @page {
                        size: letter;
                        margin: 0.50in; /* Adjust margin as needed */
                    }
                    .print-content {
                        transform: scale(1); 
                        transform-origin: top left;
                        page-break-after: always; 
                      }
                    #header,
                    #footer,
                    
                }
                </style>
            </head><body><div class="print-content" style="margin: 20px">

            
            <div>
            ${!loading &&
            `
                    <div class="col-lg-6">
                    <p class="survey-qus">Name: <span class="name-letter text-capitalize">${todoDetails.toDoData.first_name} ${todoDetails.toDoData.last_name}</span></p>
                    </div>
                    <div class="col-lg-6">
                    <p class="survey-qus">Email: <span class="name-letter text-capitalize">${todoDetails.toDoData.email}</span></p>
                    </div>
                    <div class="col-lg-6">
                    <p class="survey-qus">Phone Number: <span class="name-letter text-capitalize">${todoDetails.toDoData.phone_number !== null ? <>+{todoDetails.toDoData.phone_number} </> : <><b className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;-</b></>}</span></p>
                    </div>
                    <div class="col-lg-6">
                    <p class="survey-qus">Address: <span class="name-letter text-capitalize">${todoDetails.toDoData.address_line1 !== null && todoDetails.toDoData.address_line2 !== null ? <>{todoDetails.toDoData.address_line1} {todoDetails.toDoData.address_line2} </> : <><b className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;-</b></>}</span></p>
                    </div>
                `
            }
            <p class="survey-qus">
            </div>
            
            `
        );

        todoDetails.SurveyQuestions.forEach((question, index) => {
            printWindow.document.write(
                `<div><p class="survey-qus">${index + 1}. ${question.question}</p>
                    <p class="survey-ans"> Answer :- &nbsp; ${question.value !== null ? question.value : ''
                } ${question.other_details !== null ? question.other_details : ''
                }
                    </p>
                </div>`
            );
        });
        printWindow.document.write('</div></body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
        setLoading(false);
    };


    const handlePrintClick = () => {
        setLoading(true);
        const printWindow = window.open('', '', 'width=1200,height=1200');
        printWindow.document.open();
        printWindow.document.write(`
            <html>
            <head>
                <title>Survey List</title>
                <style>
                .survey-qus {
                    color: #777777;
                    font-size: 17px;
                    font-family: 'avenir-lt-55-roman';
                    font-weight: 500;
                    margin-left: 20px;
                    margin-top: 0px;
                    margin-bottom: -6px;
                }
                .survey-ans {
                    color: #3e3e3e;
                    font-size: 17px;
                    font-family: 'avenir-lt-55-roman';
                    font-weight: 500;
                    margin-left: 20px;
                    
                }
                .name-letter{
                    text-transform: capitalize;
                }
                @media print {
                    @page {
                        size: letter;
                        margin: 0.50in; /* Adjust margin as needed */
                    }
                    .print-content {
                        transform: scale(1); 
                        transform-origin: top left;
                        page-break-after: always; 
                      }
                    #header,
                    #footer,
                    
                }
                </style>
            </head>
            <body>
                <div class="print-content" style="margin: 20px">
        `);

        if (!loading) {
            printWindow.document.write(`
            <div style="display: flex;">
            <div style="width: 50%;">
                <p class="survey-qus">Name: <span class="name-letter text-capitalize">${todoDetails.toDoData.first_name} ${todoDetails.toDoData.last_name}</span></p>
            </div>
            <div style="width: 50%;">
                <p class="survey-qus">Email: <span class="name-letter text-capitalize">${todoDetails.toDoData.email}</span></p>
            </div>
        </div> <br />
        <div style="display: flex;">
            <div style="width: 50%;">
                <p class="survey-qus">Phone Number: <span class="name-letter text-capitalize">${todoDetails.toDoData.phone_number !== null ? `+${todoDetails.toDoData.phone_number}` : '-'}</span></p>
            </div>
            <div style="width: 50%;">
                <p class="survey-qus">Address: <span class="name-letter text-capitalize">${todoDetails.toDoData.address_line1 !== null && todoDetails.toDoData.address_line2 !== null ? `${todoDetails.toDoData.address_line1} ${todoDetails.toDoData.address_line2}` : '-'}</span></p>
            </div>
        </div> <br />
        
            ${todoDetails.userCertificates.map((certificate, index) => `
            <div style="display: flex;">
                <div style="width: 50%;">
                <p class="survey-qus">Certificates: ${todoDetails.userCertificates.map((certificate, index) => {
                let certificateName = '';
                switch (certificate.short_name) {
                    case 'bls_certification':
                        certificateName = 'BLS';
                        break;
                    case 'acls_certification':
                        certificateName = 'ACLS';
                        break;
                    case 'pals_certification':
                        certificateName = 'PALS';
                        break;
                    case 'bls_re_certification':
                        certificateName = 'BLS Re-Certificate';
                        break;
                    case 'acls_re_certification':
                        certificateName = 'ACLS Re-Certificate';
                        break;
                    case 'pals_re_certification':
                        certificateName = 'PALS Re-Certificate';
                        break;
                    default:
                        certificateName = 'Unknown Certificate';
                }

                return `${certificateName}${index < todoDetails.userCertificates.length - 1 ? ', ' : ''}`;
            }).join('')}</p>
                </div>
                <div style="width: 50%;">
                    <p class="survey-qus">Amount: <span class="name-letter text-capitalize">$${certificate.amount}</span></p>
                </div>
            </div>
            <br />
            <div style="display: flex;">
            <div style="width: 50%;">
                <p class="survey-qus">Phone Number: <span class="name-letter text-capitalize">${moment(certificate.active_date).format('M/D/YYYY')}</span></p>
            </div>
            <div style="width: 50%;">
                <p class="survey-qus">Address: <span class="name-letter text-capitalize">${certificate.updated_at.split('T')[1].slice(0, 5)}</span></p>
            </div>
            </div> 
        `)}
         <br /><br />
        
            `);
        }

        todoDetails.SurveyQuestions.forEach((question, index) => {
            printWindow.document.write(`
                <div>
                    <p class="survey-qus">${index + 1}. ${question.question}</p>
                    <p class="survey-ans"> Answer: ${question.value !== null ? question.value : ''} ${question.other_details !== null ? question.other_details : ''}</p>
                </div>
            `);
        });

        printWindow.document.write('</div></body></html>');
        printWindow.document.close();
        // printWindow.print();
        // printWindow.close();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
        setLoading(false);
    };


    return (
        <>
            <Header />
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="card todo-view">
                        <div className="row pt-3 pb-3">
                            <div className="col-lg-1">

                                {!loading && <div className="admin-todo-list">{`${todoDetails.toDoData.first_name.charAt(0).toUpperCase()}${todoDetails.toDoData.last_name.charAt(0).toUpperCase()}`}</div>}
                            </div>
                            <div className="col-lg-5 border-todo-view">
                                {!loading &&
                                    <>
                                        <p>Name: <span className="text-capitalize">{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></p>
                                        <p>Email: <span>{todoDetails.toDoData.email}</span></p>
                                        <p>Status: <span className="text-capitalize">{todoDetails.toDoData.user_status}</span></p>
                                        <p>Phone Number: <span>{todoDetails.toDoData.phone_number !== null ? <>+{todoDetails.toDoData.phone_number} </> : <><b className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;-</b></>}</span></p>
                                        <p>Address: <span>{todoDetails.toDoData.address_line1 !== null && todoDetails.toDoData.address_line2 !== null ? <>{todoDetails.toDoData.address_line1} {todoDetails.toDoData.address_line2} </> : <><b className="text-center">&nbsp;&nbsp;&nbsp;&nbsp;-</b></>}</span></p>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6">
                                {!loading &&
                                    <>

                                        {todoDetails.userCertificates.map((certificate) =>
                                            <>
                                                <p>Total Cerification:
                                                    <span>{todoDetails.userCertificates.length}
                                                        (<>
                                                            {certificate.short_name === 'bls_certification' &&
                                                                <>BLS</>
                                                            }
                                                            {certificate.short_name === 'acls_certification' &&
                                                                <>ACLS</>
                                                            }
                                                            {certificate.short_name === 'pals_certification' &&
                                                                <>PALS</>
                                                            }
                                                            {certificate.short_name === 'bls_re_certification' &&
                                                                <>BLS Re-Certificate</>
                                                            }
                                                            {certificate.short_name === 'acls_re_certification' &&
                                                                <>ACLS Re-Certificate</>
                                                            }
                                                            {certificate.short_name === 'pals_re_certification' &&
                                                                <>PALS Re-Certificate</>
                                                            }
                                                        </>)</span>
                                                </p>
                                                {/* <p>Total Amount: <span>${certificate.amount}</span></p> */}
                                                <p>Total Amount: <span>{certificate.price && `$${Number(certificate.price).toFixed(2)}`}</span></p>
                                                <p>Cerification Completion Date: <span>{moment(certificate.active_date).format('M/D/YYYY')}</span></p>
                                                <p>Certification Completion Time: <span>{certificate.updated_at.split('T')[1].slice(0, 5)}</span></p>

                                            </>
                                        )}
                                    </>
                                }

                            </div>
                        </div>
                    </div>


                    {!loading &&
                        <>
                            {todoDetails.userCertificates.map((item) =>
                                <>
                                    <div className="d-flex pt-5 move-export">
                                        <div className="">
                                            {item.is_physically_assigned !== 1 && <button className="move-to-com" onClick={() => setModalShow(true)}>Move to Completed </button>}
                                        </div>
                                        <div className="">
                                            {/* <button className="export-to-email" onClick={()=>handleCertificate(item.type_id,todoDetails.toDoData.email)} disabled={sendMaliLoading}><img src={Exportcolor} className="export-img-sales" width='20' alt="export-color" />Export to email</button> */}
                                            <button type="button" className="export-to-email" onClick={() => handleCertificate(item.type_id, todoDetails.toDoData.email)} disabled={sendMaliLoading}>
                                                {sendMaliLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : <> <img src={Exportcolor} className="export-img-sales" width='20' alt="export-color" />Export to email</>}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="card mt-5 mb-5">
                                        <div className="row">

                                            <>
                                                <div className="col-lg-12" >
                                                    <h6 className="bls-cerification-view-todo">
                                                        {(item.short_name === 'bls_certification') && <>BLS Cerification </>}
                                                        {(item.short_name === 'acls_certification') && <>ACLS Cerification </>}
                                                        {(item.short_name === 'pals_certification') && <>PALS Cerification </>}
                                                        {(item.short_name === 'bls_re_certification') && <>BLS Re-Cerification </>}
                                                        {(item.short_name === 'acls_re_certification') && <>ACLS Re-Cerification </>}
                                                        {(item.short_name === 'pals_re_certification') && <>PALS Re-Cerification </>}
                                                    </h6>
                                                </div>

                                                {/* bls & bls re-certificate */}
                                                {item.short_name === "bls_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>BASIC LIFE SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Basic Life Support (BLS)
                                                                        course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">BASIC LIFE SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {item.short_name === "bls_re_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>BASIC LIFE SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Basic Life Support (BLS)
                                                                        course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">BASIC LIFE SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {/* acls & acls re-certificate */}

                                                {item.short_name === "acls_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card aclsCertificate" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Advanced Cardiovascular Life
                                                                        Support (ACLS) course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card aclsCertificate todo-view-page todo-list-card-height" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {item.short_name === "acls_re_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card aclsCertificate" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Advanced Cardiovascular Life
                                                                        Support (ACLS) course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card aclsCertificate todo-view-page todo-list-card-height" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {/* pals & pals re-certificate */}
                                                {item.short_name === "pals_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card palsCertificate" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Pediatric Advanced Life
                                                                        Support (PALS) course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card palsCertificate todo-view-page todo-list-card-height" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {item.short_name === "pals_re_certification" &&
                                                    <>
                                                        <div className="col-lg-6 pb-3" ref={certificateRef}>
                                                            <div className="card palsCertificate" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5>PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>
                                                                    <p className="super-admin-class">This card certifies that this individual has successfully completed the
                                                                        course requirements and cognitive evaluation in accordance with the
                                                                        curriculum of the Pulse Certifications Pediatric Advanced Life
                                                                        Support (PALS) course, based on ECC guidelines.</p>
                                                                    <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{todoDetails.toDoData.first_name} {todoDetails.toDoData.last_name}</span></h6>
                                                                    <div className="d-flex flex-div">
                                                                        <h6>Issued: <span >{moment(item.final_exam_pass_date).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(item.final_exam_pass_date).add(2, 'years').format('M/D/YYYY')}</span></h6>
                                                                    </div>

                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pb-3" ref={certificateRefBack}>
                                                            <div className="card palsCertificate todo-view-page todo-list-card-height" id="bls-front-img">
                                                                <div className="text-center">
                                                                    <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                                                                    <h5 className="bls-back-h5">PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>

                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Certification Number: <span className="text-name-bls-span" >{item.certificate_id}</span></h6>
                                                                    <h6 className="text-name-bls-h6 bls-back-h6">Holder's Signature: <span className="text-name-bls-span" ></span></h6>
                                                                    <h4>PULSECERTIFICATIONS.COM</h4>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6">
                                                                <div className="row ">

                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Right} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Valid till:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={ClockRight} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Completion Date:</span> <br />
                                                                                <span className="todo-span">{moment(item.active_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="row mt-5 mb-5">


                                                                    <div className="col-lg-6">
                                                                        <div className="row to-do-listing-clock">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Error} alt="r-bg" width='60' />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Assigned on:</span> <br />
                                                                                <span className="todo-span">{moment(item.assign_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-3">
                                                                                <img src={Close} width='60' alt="cls12" />
                                                                            </div>
                                                                            <div className="col-lg-8 col-9">
                                                                                <span className="valid-till-todo">Expired On:</span> <br />
                                                                                <span className="todo-span">{moment(item.expiration_date).format('M/D/YYYY')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="col-lg-6 todo-list-print">
                                                                <a className="admin-view-details-print" onClick={() => { onClickPrintBtn(item.short_name) }}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>

                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>

                                            <br />
                                        </div>
                                    </div>

                                </>

                            )}
                        </>
                    }

                    {!loading &&
                        <>
                            {todoDetails.SurveyQuestions.length > 0 &&
                                <>
                                    <div className="card mt-5 mb-5">
                                        <div className="row">

                                            <>
                                                <div className="col-lg-12" >
                                                    <h6 className="bls-cerification-view-todo">Survey List</h6>
                                                </div>
                                                {todoDetails.SurveyQuestions.map((question, index) =>
                                                    <>
                                                        <div className="col-lg-12">
                                                            <p className="survey-qus">{index + 1}. {question.question}</p>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <p className="survey-ans"> Answer :- &nbsp; {question.value != null && <> {question.value} </>} {question.other_details != null && <> {question.other_details} </>}

                                                            </p>
                                                        </div>
                                                        <hr />
                                                        <br />
                                                    </>
                                                )}
                                                <div className="col-lg-12 mb-4 todo-list-print">
                                                    {todoDetails.SurveyQuestions.length > 0 &&
                                                        <>
                                                            <a className="admin-view-details-print" onClick={handlePrintClick}> <img src={Print} className="print-img-mb" alt="arrow-view-details" width='20' /> Print </a>
                                                        </>
                                                    }

                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </>

                            }
                        </>
                    }
                </div>
            </section>

            <SendviaEmail
                show={certificateSend}
                onHide={() => setCertificateSend(false)}
                email={email}

            />
            <PhysicallyAssignedCertificateModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                UserId={user_id}
                CertificateId={certificate_id}
            />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>
    )

}

export default ToDoViewDetails;