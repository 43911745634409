import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import SelectReAssignCourseModal from "./SelectReAssignCourseModal";

function ExpiredCertificateModal(props) {
    const [openCourseModal,setOpenCourseModal] = useState(false);
    const onConfirm = () => {
        setOpenCourseModal(!openCourseModal);
        props.onHide();
    }

    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                <Modal.Body>
                    <p className="remove-class-title">
                    Would you like to assign the course now?
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-yes-btn" onClick={() => { onConfirm() }}>Yes</button>
                                <button className="remove-no-btn" onClick={props.onHide}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>          
            <SelectReAssignCourseModal
                show={openCourseModal}
                certificateName={props.certificateName}
                onHide={() => { setOpenCourseModal(!openCourseModal) }}
                CertificateType={props.CertificateType}
                manager_id= {props.manager_id}
            />
        </>
    )
}

export default ExpiredCertificateModal;