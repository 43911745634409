import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Bls from '../../assets/web-images/images/01-BLS-10.svg'
import Acls from '../../assets/web-images/images/02-ACLS-10.svg';
import Pacl from '../../assets/web-images/images/03-PALS-10.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import Info from '../../assets/web-images/images/info.svg';
import Apb from '../../assets/web-images/images/04-Advanced Provider Bundle-10.svg';
import blsCertificate from '../../assets/web-images/images/Bls-small.svg';
import aclsCertificate from '../../assets/web-images/images/Acls-small.svg';
import palsCertificate from '../../assets/web-images/images/Pals-small.svg';
import CardsSteps from '../../assets/web-images/images/Cards-Step1.png';

export const HomeCerificateCard = () => {
    return (
        <>
            <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">

                                <div className="setup-process-wrap">
                                    <ul className="setup-process-list d-block list-unstyled mb-0 box-shadow-ul ">
                                        <div className="row ">
                                            <div className="col-lg-3 box-border-add">
                                                <li className="setup-process-item pb-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <div className="row text-center">
                                                                <p className="cards-top-saving-add">Most Popular</p>
                                                            </div>
                                                            <div className="row section-bls-modules">
                                                                <div className="d-flex">
                                                                    <div className="col-lg-2 col-2 col-md-1">
                                                                        <img src={Bls} width='40' alt="bls" />
                                                                    </div>
                                                                    <div className="col-lg-8 col-9">
                                                                        <span className="session-2-title-bls">
                                                                            BLS
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-1 col-md-2">
                                                                        <OverlayTrigger key='bottom'
                                                                            placement='bottom' overlay={<Tooltip id="tooltip-bottom" className="tooltip-titles" style={{ backgroundColor: 'white' }} ><h3>Basic Life Support</h3></Tooltip>}>
                                                                            <span className="d-inline-block tooltip-span">
                                                                                <img src={Info} alt="info" width='30' style={{ pointerEvents: 'none' }} className="info-icon" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row certificate-card-row mb-2'>
                                                                <div className="col-lg-2 col-2 col-md-2"></div>
                                                                <div className='col-lg-10 col-10'>
                                                                    <img src={blsCertificate} alt='certificate-img' />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-2 col-md-1"></div>
                                                                <div className="col-lg-10 col-10">
                                                                    <p className="session-2-sub-title sub-title-new-add">Get your Basic Life Support certification or renewal.100% online.</p>

                                                                    <div className="view-certificate-home">
                                                                        <a href='/bls'>View Certifications</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>

                                            <div className="col-lg-3 box-border-add Pacl-11">
                                                <li className="setup-process-item py-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <div className="row text-center">
                                                                <p className="cards-top-saving-add"></p>
                                                            </div>
                                                            <div className="row section-bls-modules">
                                                                <div className="d-flex">
                                                                    <div className="col-lg-2 col-2 col-md-1">
                                                                        <img src={Acls} width='40' alt="acls" />
                                                                    </div>
                                                                    <div className="col-lg-8 col-9">
                                                                        <span className="session-2-title-bls">
                                                                            ACLS
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-1 col-md-2">
                                                                        <OverlayTrigger key='bottom'
                                                                            placement='bottom' overlay={<Tooltip id="tooltip-bottom" className="tooltip-titles" style={{ backgroundColor: 'white' }} ><h3>Advanced Cardiovascular Life Support</h3></Tooltip>}>
                                                                            <span className="d-inline-block tooltip-span">
                                                                                <img src={Info} alt="info" width='30' style={{ pointerEvents: 'none' }} className="info-icon" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row certificate-card-row mb-2'>
                                                                <div className="col-lg-2 col-2 col-md-2"></div>
                                                                <div className='col-lg-10 col-10'>
                                                                    <img src={aclsCertificate} alt='certificate-img' />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-2 col-md-1"></div>
                                                                <div className="col-lg-10 col-10">
                                                                    <p className="session-2-sub-title sub-title-new-add">Get your Advanced Cardiovascular Life Support certification or renewal. 100% online.</p>

                                                                    <div className="view-certificate-home">
                                                                        <a href='/acls'>View Certifications</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>

                                            <div className="col-lg-3 box-border-add">
                                                <li className="setup-process-item py-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <div className="row text-center">
                                                                <p className="cards-top-saving-add"></p>
                                                            </div>
                                                            <div className="row section-bls-modules">
                                                                <div className="d-flex">
                                                                    <div className="col-lg-2 col-2 col-md-1">
                                                                        <img src={Pacl} width='40' alt="pacl" />
                                                                    </div>
                                                                    <div className="col-lg-8 col-9">
                                                                        <span className="session-2-title-bls">
                                                                            PALS
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-1 col-md-2">
                                                                        <OverlayTrigger key='bottom'
                                                                            placement='bottom' overlay={<Tooltip id="tooltip-bottom" className="tooltip-titles" style={{ backgroundColor: 'white' }} ><h3>Pediatric Advanced Life Support</h3></Tooltip>}>
                                                                            <span className="d-inline-block tooltip-span">
                                                                                <img src={Info} alt="info" width='30' style={{ pointerEvents: 'none' }} className="info-icon" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row certificate-card-row mb-2'>
                                                                <div className="col-lg-2 col-2 col-md-2"></div>
                                                                <div className='col-lg-10 col-10'>
                                                                    <img src={palsCertificate} alt='certificate-img' />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-2 col-2 col-md-1"></div>
                                                                <div className="col-lg-10 col-10">

                                                                    <p className="session-2-sub-title sub-title-new-add">Get your Pediatric Advanced Life Support certification or renewal. 100 % online.</p>

                                                                    <div className="view-certificate-home">
                                                                        <a href='/pals'>View Certifications</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>

                                            <div className="col-lg-3 box-border-add Pacl-11 bb-hidden">
                                                <li className="setup-process-item pb-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <div className="row text-center">
                                                                <p className="cards-top-saving-add mb-css-add">Most Savings</p>
                                                            </div>
                                                            <div className="row section-bls-modules">
                                                                <div className="d-flex">
                                                                    <div className="col-lg-2 col-2 col-md-1">
                                                                        <img src={Apb} width='40' alt="apb" />
                                                                    </div>
                                                                    <div className="col-lg-8 col-9">
                                                                        <span className="session-2-title-bls">
                                                                            Advanced Provider Bundle
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-1 col-md-2">
                                                                        <OverlayTrigger key='bottom'
                                                                            placement='bottom' overlay={<Tooltip id="tooltip-bottom" className="tooltip-titles" style={{ backgroundColor: 'white' }} ><h3>Free BLS certification with our bundle package.</h3></Tooltip>}>
                                                                            <span className="d-inline-block tooltip-span">
                                                                                <img alt="info" src={Info} width='30' style={{ pointerEvents: 'none' }} className="info-icon" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row certificate-card-row mb-2'>
                                                                <div className="col-lg-2 col-2 col-md-2"></div>
                                                                <div className='col-lg-10 col-10 mt-3'>
                                                                    <img src={CardsSteps} alt='certificate-img' className='cards-steps-img' />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-2 col-md-1"></div>
                                                                <div className="col-lg-10 col-10">
                                                                    <p className="session-2-sub-title sub-title-new-add">Get your BLS, ACLS, and PALS life support certifications or renewals with our bundle deal. 100% online.</p>
                                                                    <div className="view-certificate-home">
                                                                        <a href='/apb'>View Certifications</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )
}
