import { useState, useEffect } from "react";

import CertificateSurvey from "../api/CertificateSurvey";
const UseCertificateSurvey = () => {
    const [surveyQuestion, setSurveyQuestion] = useState([]);
    useEffect(() => {
      const getCertificateSurvey = async () => {
        try {
          const response = await CertificateSurvey.get();
          if (response.data.status === "success") {
            setSurveyQuestion(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getCertificateSurvey();
    }, []);
  
    return { surveyQuestion };
   
}



export default UseCertificateSurvey;