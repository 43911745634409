import React, {useEffect, useState} from "react";
import Header from "../../../../components/website/Layouts/Header";
import Footer from "../../../../components/website/Layouts/Footer";
import Cookies from 'universal-cookie'


export default function  PaymentError() {

    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';

    const [removeLocalstorage, setRemoveLocalStorage] = useState(localStorage.getItem('certificateCart'));

    useEffect(() => {
        localStorage.removeItem('addTeamData');
    }, []);

    const onClick = () =>{
        setRemoveLocalStorage(localStorage.clear());

        if (userDetails.user_role !== undefined) {
            if (userDetails.user_role.role_id === 2) {
                window.location.href = '/manager';
            }else{
                window.location.href = '/employee';
            }
        } else {
            window.location.href = '/';
        }
        
    }

    return(
        <>
            <div className="login-header">
                <Header />
            </div>


            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row payment-success-row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <div className="payment-titles">
                                    <h1><strong>Error!</strong></h1>
                                    <h6> <strong>Something is wrong while making payment, Please contact admin.</strong> </h6>
                                     <hr />
                                    <a className="btn btn-primary btn-sm" href='#' onClick={onClick} role="button">Continue to Home page</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        
        </>
    )

}