import React, { useState,useEffect } from "react";
import Select from 'react-select';
import LoginImg from '../../../assets/web-images/images/signupImage.png';
import Header from "../../../components/website/Layouts/Header";
import UseUserOtherDetail from '../../../hooks/UseUserOtherDetail';
import { useNavigate }  from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";
import UseState from "../../../hooks/UseState";
import Help from "../../../api/Help";
import axiosConfig from "../../../api/Config";
import Cookies from 'universal-cookie'


function OtherDetail() {

    const [loading, setLoading] = useState(false);
    const[address_line1, setAddressLine1]= useState('');
    const[addres1Error, setAddres1Error]= useState('');
    const[address_line2, setAddressLine2]= useState('');
    const [city, setSelectedCity] = useState(null);
    const [selectedCityError, setSelectedCityError] = useState(null);
    const [state, setSelectedState] = useState(null);
    const [selectedStateError, setSelectedStateError] = useState(null);
    const[zipcode, setZipCode]= useState('');
    const [ZipCodeError, setSZipCodeError] = useState(null);
    const[organization, setOrganization]= useState('');
    const [getStates , setState] = useState([]);
    const [getCity , setCity] = useState([]);

     const cookies = new Cookies();
    const userToken = cookies.get("token") ? cookies.get("token") : '';

    const {isUserDetailsApi , userDetailApi} = UseUserOtherDetail();
    const navigate = useNavigate();

    const organizationChange = (e) => {
        setOrganization(e.target.value);
    };
    const addressLin1Change = (e) => {
        setAddressLine1(e.target.value);
        setAddres1Error('');
        
    };
    const addressLin2Change = (e) => {
        setAddressLine2(e.target.value);
        // setAddres2Error('');
    };
    const zipCodeChange = (e) => {
        setZipCode(e.target.value);
        setSZipCodeError('');
    };

    // state api 

    const { state: getState } = UseState();

    useEffect(() => {
        fetchState();
    }, [getState]);

    const fetchState = () => {
        setState(getState);
    }
    
    const stateChange = (selectedStateopt) => {
        const selectedState = selectedStateopt ? selectedStateopt.value : '';
    
        setSelectedState(selectedState);
        setSelectedStateError('');
        setSelectedCity(null); // Reset city when state changes
    
        const stateString = selectedState.toString();
        fetchCity(selectedState);
      };

    
    const fetchCity = async (selectedState) => { // Accept selectedState as a parameter
        if (selectedState !== null) {
          const getCityList = await Help.getCity({ state_id: selectedState }).then((res) => {
              return res.data;
              
            }).catch((err) => { return err.message; });
            if (getCityList.status === 'success') {
              setCity(getCityList.data)
            
          }
      
        }
      }
   
   
    const cityChange = (selectedOption) => {
        const selectedCity = selectedOption ? selectedOption.value : '';
    
        setSelectedCity(selectedCity);
        setSelectedCityError('');
    
        const cityString = selectedCity.toString();
    };
        
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!address_line1) {
            setAddres1Error('Address line 1 is required');
        }
        
        if (!city) {
            setSelectedCityError('City is required');
        }
        if (!state) {
            setSelectedStateError('State is required');
        }
        if (!zipcode) {
            setSZipCodeError('Zipcode is required');
        }

        if (address_line1 && city && state && zipcode) {
            setLoading(true);

            try {

                const data = {
                    address_line1,address_line2,city, state,zipcode,organization
                }
                const{
                    responseCode:userDetail1
                } = await userDetailApi(data);
                
                if (userDetail1.data.status === 'success') {
                    const previousLocation = JSON.parse(localStorage.getItem('CourseCart'));
                    if (previousLocation) {
                        handleCheckout(userToken);
                    }else{
                        window.location.href = '/employee'
                    }
                    
                }else{
                    toast.error('Something went wrong .');
                }
            }catch (error) {
                toast.error('Something went wrong .');
            }
            setLoading(false);
        }
       
    };

    const handleCheckout = async (bearerToken) => {
        try {
            const sessionData = localStorage.getItem('certificateCart');
            const localStorageIds = JSON.parse(sessionData);
            const couponCodeGet = localStorage.getItem('CouponCode') ? localStorage.getItem('CouponCode') : '';

            const data = {
                certificate_type_id: localStorageIds,
                coupon_code: couponCodeGet,
            }
            const doPayment = await axiosConfig.post(`/payment-methods`,data,{
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                console.log(err);
            });

            if (doPayment) {
                localStorage.removeItem('certificateCart');
                localStorage.removeItem('CourseCart');
                localStorage.removeItem('CouponCode');
                window.location.href = doPayment.url;
            }
        } catch (error) {
            console.error('Error during payment:', error);
            // Handle error case here
        }

    };

    return (
        <>

            <div className="login-header">
                <Header />
            </div>
            <section className="sign-up-in-section bg-white ptb-120">
                <div className="container">
                    <div className="card signup-card-1">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 img-col-6-padding">
                                <div className="card signup-img">
                                    <img src={LoginImg} alt="login-logo" height='754' />
                                </div>
                            </div>
                            <div className="col-lg-6 form-col-6-padding">
                                <div className="">
                                    <div className="row">
                                        <h1 className="welcome-title mt-5 other-details">Other Details</h1><br />
                                        <p className="login-titles other-details">Please provide your details</p>
                                    </div>
                                    <form onSubmit={handleSubmit} className="mt-5 register-form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="organization" className="mb-1">Organization Name</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" onChange={organizationChange} id="organization" name="organization" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <label htmlFor="address" className="mb-1">Address Line 1</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" id="address_line1" onChange={addressLin1Change} name="address_line1" />
                                                </div>
                                                {addres1Error && <div className="error">{addres1Error}</div>}

                                            </div>
                                            <div className="col-sm-12">
                                                <label htmlFor="address" className="mb-1">Address Line 2</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" id="address_line2" onChange={addressLin2Change} name="address_line2" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label htmlFor="address" className="mb-1">State</label>
                                                <div className="mb-3 dropdown-city">
                                                    <Select
                                                        id="state"
                                                        name="state"
                                                        value={getStates.find(option => option.value === state)}
                                                        onChange={(selectedOption) => stateChange(selectedOption)}
                                                        options={getStates}
                                                    />
                                                </div>
                                                {selectedStateError && <div className="error">{selectedStateError}</div>}
                                            </div>
                                            <div className="col-sm-6">
                                                <label htmlFor="address" className="mb-1">City</label>
                                                <div className="mb-3 dropdown-city">
                                                    <Select
                                                        id="city"
                                                        name="city"
                                                        value={getCity.find(option => option.lable === city)} 
                                                        onChange={cityChange}
                                                        options={getCity}
                                                        // isDisabled={!state} 
                                                    />
                                                </div>
                                                {selectedCityError && <div className="error">{selectedCityError}</div>}
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label htmlFor="address" className="mb-1">Zip code</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" onChange={zipCodeChange} id="zipcode" name="zipcode" maxLength='8' />
                                                </div>
                                                {ZipCodeError && <div className="error">{ZipCodeError}</div>}
                                            </div>


                                            <div className="submit-btn-add-team">
                                                <button type="submit" className="btn btn-primary mt-4" disabled={loading}>
                                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Submit"}
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OtherDetail;