import React, {useEffect, useState} from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import Footer from '../../../components/website/Layouts/Footer';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Watch} from 'react-loader-spinner';
import CertificateCard from "./SubPages/CertificateCard";
import ScrollToTop from 'react-scroll-to-top';
import UseManagerDashboardData from "../../../hooks/manager/UseManagerDashboardData";

export default function DashboardPage() {

    const [dashboardData, setDashboardData] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    const [certificateName, setCertificateName] = useState('');
    const {getDashboardData, loading, recallApiWithNewParams} = UseManagerDashboardData(filterParams);

    useEffect(() => {
        if (getDashboardData.status === "success") {
            setDashboardData(getDashboardData.data);
        }
    }, [getDashboardData]);

    const handleTabChange = (selectedTab) => {
        console.log(selectedTab);
        if (selectedTab !== certificateName) {
            setCertificateName(selectedTab)
            const params = {
                type: selectedTab
            }
            setFilterParams(params);
            recallApiWithNewParams(params);
        }
    }

    return (<>
            <Header/>

            <section className="pt-10 feature-promo bg-light manager-tab-listing" id='tab-listing'>
                <div className="container">
                    <div className="row pt-5">
                        <Tabs
                            defaultActiveKey="all"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                            onSelect={handleTabChange}
                        >
                            {['all', 'bls', 'acls', 'pals'].map((tab) => (
                                <Tab key={tab} eventKey={tab} title={tab.toUpperCase()}>
                                    <div className="container d-flex align-items-center justify-content-center vh-80">
                                        {loading && (
                                            <Watch
                                                height="80"
                                                width="80"
                                                radius="48"
                                                color="#2f99a9 "
                                                ariaLabel="watch-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                    {!loading && (
                                        <section className="pt-3 text-black">
                                            <div className="container">
                                                <div className="row pt-5 dashboard-row">
                                                    {dashboardData.map((item) => (
                                                        <CertificateCard key={item.id} item={item} />
                                                    ))}
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </section>

            <Footer/>
            <br/>
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>)
}
