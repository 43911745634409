import { useState } from "react";

import RequestInformation from "../api/RequestInformation";

const UseRequestInformation = () => {
    const [isRequestInfo, setIsRequestInfo] = useState(false);

    const sendRequestInformation = async (boddy) => {
        setIsRequestInfo(true);

        let responseCode;

        try {
            const response = await RequestInformation.SendRequestInfo(boddy);
            responseCode = response;
        } catch (error) {
            responseCode = error.response;
        }

        setIsRequestInfo(false);

        return { responseCode };
    };

    return { isRequestInfo, sendRequestInformation };
};


export default UseRequestInformation;