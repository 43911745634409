import React,{useState,useEffect} from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import UseUserProfile from "../../../hooks/superAdmin/UseUserProfile";
import UseUpdateProfile from "../../../hooks/superAdmin/UseUpdateProfile";
import Cookies from 'universal-cookie';
import {ThreeDots} from "react-loader-spinner";

function Profile() {

    const [profileDetail, setprofileDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const[firstName,setFirstName]= useState('');
    const[lastName,setLastName]= useState('');
    const[email,setEmail]= useState('');
    const[phone,setPhone]= useState('');
    const[address1,setAddress1]= useState('');
    const[address,setAddress]= useState('');
    const [editLoading, setEditLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({ firstName: "", lastName: "",email: "",phone: "",address1: "",address: "" })

    const cookies = new Cookies();

    const userDetails = cookies.get("user") ? cookies.get("user") : '';


    const { getUserProfile: getProfileApi } = UseUserProfile();

    useEffect(() => {
        fetchUserProfile();
    }, [getProfileApi]);

    const fetchUserProfile = () => {
        setLoading(false);
        setprofileDetail(getProfileApi);
    }

    const onFirstNameChanges = (e) => {
        const updatedProfileDetail = { ...profileDetail };
        updatedProfileDetail.first_name = e.target.value;
        setFirstName(e.target.value);
        setprofileDetail(updatedProfileDetail);

        setErrorMessages({ ...errorMessages, firstName: '' });

    };
    const onLastNameChanges = (e) =>{
        const updatedProfileDetail = { ...profileDetail };
        updatedProfileDetail.last_name = e.target.value;
        setLastName(e.target.value);
        setprofileDetail(updatedProfileDetail);

        setErrorMessages({ ...errorMessages, lastName: '' });

    }
    const onEmailChanges = (e) =>{
        const updatedProfileDetail = { ...profileDetail };
        updatedProfileDetail.email = e.target.value;
        setEmail(e.target.value);
        setprofileDetail(updatedProfileDetail);
        setErrorMessages({ ...errorMessages, email: '' });

    }
    
    const onPhoneChanges = (e) => {
        const updatedProfileDetail = { ...profileDetail };
        const inputValue = e.target.value;
    
        // Validate if the input is a number or an empty string
        if (/^\d*$/g.test(inputValue) || inputValue === "") {
            updatedProfileDetail.phone_number = inputValue;
            setPhone(inputValue);
            setprofileDetail(updatedProfileDetail);
            setErrorMessages({ ...errorMessages, phone: '' });
        } else {
            setErrorMessages({ ...errorMessages, phone: 'Phone must contain only numbers' });
        }
    };



    const onOrganizationChanges = (e) =>{
        const updatedProfileDetail = { ...profileDetail };
        updatedProfileDetail.address_line2 = e.target.value;
        setAddress1(e.target.value);
        setprofileDetail(updatedProfileDetail);
        setErrorMessages({ ...errorMessages, address1: '' });

    }
    const onAddressChanges = (e) =>{
        const updatedProfileDetail = { ...profileDetail };
        updatedProfileDetail.address_line1 = e.target.value;
        setAddress(e.target.value);
        setprofileDetail(updatedProfileDetail);
        setErrorMessages({ ...errorMessages, address: '' });

    }

    const validateField = async () => {
        let success = true;
        let errors = errorMessages;
        if (profileDetail.first_name === '') {
            errors.firstName = 'First Name is Required';
            success = false;
        }
        if (profileDetail.last_name === '') {
            errors.lastName = 'Last Name is Required';
            success = false;
        }

        if (profileDetail.email === '') {
            errors.email = 'Email is Required';
            success = false;
        }

        if (profileDetail.phone_number === '') {
            errors.phone = 'Phone is Required';
            success = false;
        } else if (!/^\d+$/.test(profileDetail.phone_number) === '') {
            errors.phone = 'Phone must contain only numbers';
            success = false;
        }

        if (profileDetail.address_line2 === '') {
            errors.address1 = 'Address line 2 is Required';
            success = false;
        }
        if (profileDetail.address_line1 === '') {
            errors.address = 'Address line 1 is Required';
            success = false;
        }
        setErrorMessages({ ...errorMessages, ...errors });
        return success;
    };
   
    const {isUpdateProfile , UpdateUserProfile} = UseUpdateProfile();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await validateField()) {
            setEditLoading(true);
            try {
                const data = {
                    user_id: userDetails.id,
                    first_name: firstName || profileDetail.first_name,
                    last_name: lastName || profileDetail.last_name,
                    phone: phone || profileDetail.phone_number,
                    email: email || profileDetail.email,
                    address: address || profileDetail.address_line1,
                    address1: address1 || profileDetail.address_line2,
                };
        
                const{
                    responseCode:updateProfile
                } = await UpdateUserProfile(data);
                
                if (updateProfile.status === 'success') {
                    // window.location.href = '/super-admin/profile'
                }else{
                    setEditLoading(false);
                    console.log('Something went wrong .');
                }
            }catch (error) {
                setEditLoading(false);
                console.log('Something went wrong 11.');
            }
            setEditLoading(false);
        }
       
    };
    

    return (
        <>
            <Header />

            <section className="pt-10 pt-5 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                       
                        <div className="col-lg-1"></div>

                        <div className="col-lg-10 pt-10">
                            <div className="card  profile-card-1">
                            <p className="edit-profile text-left">Edit your profile</p>
                            {!loading &&
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row edit-profile-lable">
                                            <div className="col-sm-6 mt-4">
                                                <lable>First Name</lable>
                                                <input type="text" className="form-control" id="firstName" name="first_name" aria-label="First name"  onChange={onFirstNameChanges} value={profileDetail.first_name} />
                                                <p className="error">{errorMessages.firstName}</p>
                                            </div>
                                            <div className="col-sm-6 mt-4 ">
                                                <lable>Last Name</lable>
                                                <input type="text" className="form-control" id="lastName" name="last_name" aria-label="Last name"  onChange={onLastNameChanges} value={profileDetail.last_name} />
                                                <p className="error">{errorMessages.lastName}</p>
                                            </div>
                                            <div className="col-sm-6 mt-4">
                                                <lable>Phone Number</lable>
                                                <input type="text" className="form-control" id="phone" name="phone_number"  aria-label="Phone"  onChange={onPhoneChanges} value={profileDetail.phone_number} />
                                                <p className="error">{errorMessages.phone}</p>
                                            </div>
                                            <div className="col-sm-6 mt-4">
                                                <lable>Email</lable>
                                                <input type="text" className="form-control" id="email" name="email" aria-label="Email"  onChange={onEmailChanges} value={profileDetail.email}  readOnly/>
                                                <p className="error">{errorMessages.email}</p>
                                            </div>
                                            <div className="col-sm-6 mt-4">
                                                <lable>Address Line 1</lable>
                                                <input type="text" className="form-control" id="address" name="address_line1"   aria-label="Address"  onChange={onAddressChanges} value={profileDetail.address_line1} />
                                                <p className="error">{errorMessages.address}</p>
                                            </div>
                                            <div className="col-sm-6 mt-4">
                                                <lable>Address Line 2</lable>
                                                <input type="text" className="form-control" id="address1" name="address_line2" aria-label="Address 1"  onChange={onOrganizationChanges} value={profileDetail.address_line2} />
                                                <p className="error">{errorMessages.address1}</p>
                                            </div>


                                            <div className="submit-btn-add-team">
                                                {/* <button type="submit" className="btn btn-primary mt-4">Submit</button> */}
                                            <button type="button" className="btn btn-primary mt-4 edit-profile-btn-wh" onClick={handleSubmit} disabled={editLoading}>
                                {editLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Submit"}
                            </button>
                                            </div>
                                        </div>
                                    </form>
                                
                                </>
                            }
                            </div>
                        </div>

                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Profile;