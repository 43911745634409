import { useState } from "react";

import Auth from '../api/Auth';

const UseResetPassword = () => {
    const [isResetPassword, setIsResetPassword] = useState(false);
  
    const resetPasswordApi = async (data) => {
      setIsResetPassword(true);
  
      let responseCode;
  
      try {
        const response = await Auth.resetPassword(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsResetPassword(false);
  
      return { responseCode };
    };
  
    return { isResetPassword, resetPasswordApi };
  };


export default UseResetPassword;