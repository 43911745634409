import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let ApiUrl = process.env.REACT_APP_API_PATH;

if (process.env.REACT_APP_ENV === "prod") {
    ApiUrl = process.env.REACT_APP_API_LIVE;
}

const Config = axios.create({
    baseURL: ApiUrl
});


Config.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('token')}`;


export default Config;
