import { useState, useEffect } from "react";

import Payment from "../api/Payment";
const UseOrderDetails = () => {
    const [orderDetails, setOrderDetails] = useState([]);
  
    useEffect(() => {
        const getorder = async () => {
            try {
            const response = await Payment.getOrderDetails();
            setOrderDetails(response.data);
            } catch (error) {
            console.log(error);
            }
        };
      getorder();
    }, []);
  
    return {orderDetails };
   
}



export default UseOrderDetails;