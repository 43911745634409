import axiosConfig from './Config';


const SampleTest ={
    get: ({ params: {short_name} }) => axiosConfig.get(`get-sample-test-list/${short_name}`),
    createSampleTest: (body) => axiosConfig.post(`add-sample-test-answer`, body),
    getSampleTestResult: ({ params: {name_slug ,employee_id} }) => axiosConfig.get(`get-sample-questions-answer/${name_slug}/${employee_id}`),

}

export default SampleTest;