import { useState, useEffect } from "react";

import manager from '../../api/manager/manager';

const UseMemberDeatils = ({user_id}) => {
    const [getMemberDetails, setGetMemberDetails] = useState([]);
  
    useEffect(() => {
      const getgetMemberDetails = async () => {
        try {
          const response = await manager.getMemberDetail({
            params: { user_id },
          });
  
          setGetMemberDetails(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getgetMemberDetails();
    }, []);
  
    return {getMemberDetails };
   
}



export default UseMemberDeatils;