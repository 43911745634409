import React from 'react'
import ErrorPage from '../assets/404-Error.png';
import ErrorPage1 from '../assets/404-Error-01.png';
import Header from '../components/website/Layouts/Header';
const PageNotFound = () => {
    return (
        <>

            <div className="login-header">
                <Header />
            </div>
            <div className='text-center'>
                <img className='page-not-found' src={ErrorPage1} alt='error-page' />
                <h6 className='not-found-h6'>Look like you're lost</h6>
                <h6 className='not-found-h6'>the page you are looking for not avaible!</h6> <br />
                <a className='go-to-home-not-found' href='/'>Go to Home</a>
            </div>
        </>
    )
}

export default PageNotFound