import React, { useEffect, useState } from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import info from '../../../assets/web-images/images/info.svg';
import Plus from '../../../assets/manager-images/images/Plus.svg'
import Assigned from '../../../assets/manager-images/images/Assigned.svg'
import AssignedExpired from '../../../assets/manager-images/images/Assigne_Expired.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Right from '../../../assets/manager-images/images/Right.svg'
import Close from '../../../assets/manager-images/images/Close.svg'
import Error from '../../../assets/manager-images/images/Error.svg'
import UseMyTeam from "../../../hooks/manager/UseMyTeam";
import { Watch } from 'react-loader-spinner';
import moment from "moment/moment";
import axiosConfig from '../../../api/Config';
import ScrollToTop from 'react-scroll-to-top';

function Myteam() {

    const [teams, setTeams] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { getTeamData, loading, recallApiWithNewParams,loadMoreLoading } = UseMyTeam(pageParams);

    const [userRoles ,setUserRoles] = useState([]);
    const [loadingUser ,setLoadingUser] = useState(false);


    useEffect(() => {
        if (getTeamData.status === "success") {
            if (getTeamData.data.data.length > 0) {
                setTeams([...teams, ...getTeamData.data.data]);
                setPerPage(getTeamData.data);
                setCurrentPage(getTeamData.data.current_page);
            }
        }
    }, [getTeamData]);

    useEffect(() => {
        
        const getUserData = async () => {
            const response = await axiosConfig.get('/get-user-role');
            setLoadingUser(true);
            if (response.data.status === 'success') {
                setUserRoles(response.data.status);
            }
            setLoadingUser(false);
        };
        getUserData();
        
    }, []);


    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        // setPageParams(params);
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }



    const renderCertificate = (item, certificateName) => {
        
        let certification = item.certification;
        let user_certificate = undefined;
        if (certificateName === "basic_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'basic_life_support_re_certification')
        }

        if (certificateName === "advanced_cardiovascular_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'advanced_cardiovascular_life_support_re_certification')
        }

        if (certificateName === "pediatric_advanced_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'pediatric_advanced_life_support_re_certification')
        }
        if (user_certificate === undefined) {
            user_certificate = certification.find((citem) => citem.name_slug === certificateName);
        }

        if (user_certificate !== undefined) {
            return (
                <div className="text-center">
                    {user_certificate.certificate_status === 'n/a' && (
                        <span className="sub-course-detail">N/A</span>
                    )}
                    {user_certificate.certificate_status === 'assigned' && (
                        <>
                            <a href={`/manager/view-teams/${user_certificate.assign_to}`}>
                                <img src={Assigned} width="20" alt="right" /> <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.assign_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}
                    {user_certificate.certificate_status === 'active' && (
                        <>
                            <a href={`/manager/view-teams/${user_certificate.assign_to}`}>
                                <img src={Right} width="20" alt="right" /> <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}
                    {user_certificate.certificate_status === 'expiring_soon' && (
                        <>
                            <a href={`/manager/view-teams/${user_certificate.assign_to}`}>
                                <img src={Error} width="20" alt="right" /> <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}
                    {user_certificate.certificate_status === 'expired' && (
                        <>
                            <a href={`/manager/view-teams/${user_certificate.assign_to}`}>
                                <img src={Close} width="20" alt="Close" style={{ pointerEvents: 'none' }} />
                                <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}
                    {user_certificate.certificate_status === 'assign_expired' && (
                        <>
                            <a href={`/manager/view-teams/${user_certificate.assign_to}`}>
                                <img src={AssignedExpired} width="20" alt="Close" style={{ pointerEvents: 'none' }} />
                                <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}
                </div>
            )
        }
        return null;
    };

    const loginAsEmployee = localStorage.getItem('self-assign-course') ? localStorage.getItem('self-assign-course') : '';


    return (<>
        <Header />

        <section className="pt-10 pb-10 feature-promo bg-light manager-tab-listing" id='tab-listing'>
            <div className="container">
                <div className="row pt-5">
                    
                    <div className="col-lg-12 col-12">
                        
                    {/* </div> */}
                    {/* <div className="col-sm-1 col-2" id="manager-teams"> */}

                        <OverlayTrigger key='bottom'
                            placement='bottom' overlay={<Tooltip id="myteams-title" className="myteam"
                                style={{ backgroundColor: 'white' }}>
                                <div className="teams-cards">
                                    <ul>
                                        <li><img src={Right} width='25' alt="r-1" className="add-mamber-teams" /><span
                                            className="info-teams">Certified</span></li>
                                        <li><img src={Assigned} width='25' alt="a-1" className="add-mamber-teams" /><span
                                            className="info-teams"> Assigned</span></li>
                                        <li><img src={Error} width='25' alt="er-1" className="add-mamber-teams" /> <span
                                            className="info-teams d-flex">Expiring in less than 60 days</span></li>
                                        <li><img src={Close} width='25' alt="cl-1" className="add-mamber-teams" /><span
                                            className="info-teams"> Expired</span></li>
                                        <li className="info-teams">N/A Not required</li>
                                    </ul>
                                </div>
                            </Tooltip>}>
                            <span className="d-inline-block info-teams-right" id="add-infos">
                                <img src={info} alt="info-1" width='30' style={{ pointerEvents: 'none' }}
                                    className="info-img-teams" /> <br />
                            </span>
                        </OverlayTrigger> 

                        <a className="add-new-member" href="/manager/add-member"><img src={Plus} width='18' alt="pluse-1" /> Add New Member</a> 
                        {!loading && userRoles !== '' && userRoles !== 'success' &&
                            <>
                            {/* <a className="add-new-member"  id="add-self-assign"  href="#" onClick={()=>onLoginAsEmployee()} >Login As Employee</a> */}
                            <a className="add-new-member" id="add-self-assign" href="/manager/self-assign-course">Self-Assign Course</a>
                            </>
                        }
                    </div>
                </div>



                <div className="row pt-5" id='my-team-page'>
                    <div className="col-lg-12">
                        <div className="table-p-manager">
                            <table className="table table-striped">
                                <thead className="thead-course">
                                    <tr>
                                        <th className="course-detail-th text-center">Name</th>
                                        <th className="course-detail-th text-center">Title</th>
                                        <th className="course-detail-th text-center">Email</th>
                                        <th className="course-detail-th text-center">User Status</th>
                                        <th className="course-detail-th text-center">BLS</th>
                                        <th className="course-detail-th text-center">ACLS</th>
                                        <th className="course-detail-th text-center">PALS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && (
                                        <>
                                            
                                            {teams.map((team, index) => <tr className="tr-border-bg">
                                                <td data-label="Name">
                                                    <a href={`/manager/view-teams/${team.u_id}`}>
                                                        <p className="sub-course-detail text-capitalize">{`${team.first_name} ${team.last_name}`}</p>
                                                    </a>
                                                </td>
                                                <td data-label="Title">
                                                    <a href={`/manager/view-teams/${team.u_id}`}><p className="sub-course-detail">{team.title}</p></a>
                                                </td>
                                                <td data-label="Email">
                                                    <a href={`/manager/view-teams/${team.u_id}`}><p className="sub-course-detail">{team.email}</p></a>
                                                </td>
                                                <td data-label="Status">
                                                    <a href={`/manager/view-teams/${team.u_id}`}><p className="sub-course-detail">{team.user_status.replace(/\b\w/g, (match) => match.toUpperCase())}</p></a>
                                                </td>

                                              
                                                <td data-label="BLS" className="text-center">
                                                    {
                                                        renderCertificate(team, 'basic_life_support') || <span className="sub-course-detail">-</span>
                                                    }
                                                </td>
                                              
                                                <td data-label="ACLS" className="text-center">
                                                    {
                                                        renderCertificate(team, 'advanced_cardiovascular_life_support') || <span className="sub-course-detail">-</span>
                                                    }
                                                </td>
                                                <td data-label="PALS" className="text-center">
                                                    {
                                                        renderCertificate(team, 'pediatric_advanced_life_support') || <span className="sub-course-detail">-</span>
                                                    }
                                                </td>
                                            </tr>)}
                                        </>

                                    )}
                                </tbody>
                                
                            </table>
                            {!loading && teams.length === 0 && (
                                <>
                                    <div className="text-center">
                                        <h5 className="valid-till">No team list found.</h5>
                                    </div>
                                </>
                            )}
                            {currentPage < perPage.last_page &&
                                (<button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button>)
                            }
                            <div className="container d-flex align-items-center justify-content-center vh-80">
                                {loading && (
                                    <Watch
                                        height="80"
                                        width="80"
                                        radius="48"
                                        color="#2f99a9 "
                                        ariaLabel="watch-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}
                            </div>
                            <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loadMoreLoading && !loading && (
                                                <Watch
                                                    height="70"
                                                    width="70"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>
                        </div>
                    </div>
                </div>


            </div>
            <ScrollToTop className="scroll-to-button"  smooth color="white" width="20" height="20"/>
        </section>

    </>)
}

export default Myteam