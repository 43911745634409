import React, { useState,useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import Calendar from '../../../../assets/manager-images/images/calendar.svg';
import moment from "moment";
import UseCertificateDateUpdate from "../../../../hooks/manager/UseCertificateDateUpdate";

function EditDateModal(props) {
    const commonDateFormat = "MM/dd/Y";

    const initialDate = props.certificateData;

    const [selectedDate, setSelectedDate] = useState('');
   

    useEffect(() => {
        if (initialDate) {
          const parsedDate = new Date(initialDate);
          setSelectedDate(parsedDate);
        }
    }, [initialDate]);

    const onChangeSelectDate = (date) => {
        setSelectedDate(date);
    };

    const {isCertificateDate, CertificateUserDate} = UseCertificateDateUpdate();

    const handleCertificateDateUpdate = async(e) => {
        e.preventDefault();
        const todayDate = moment().format('Y-MM-DD');
        let finalSelectedDate = todayDate;
        finalSelectedDate = moment(selectedDate).format('Y-MM-DD');
          
            const{
                responseCode:EditDate
            } = await CertificateUserDate({'id': props.usercertificateIds,'certificate_status':props.certificateStatus,'certificate_date':finalSelectedDate});

            
            if (EditDate.status === 'success') {
                props.onHide();
                window.location.href = `/manager/view-teams/${props.MemberId}`
            }
    };

    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >

                <Modal.Body>
                    <div className="p-2">
                        <p className="edit-date-view-details">Edit Date</p>
                        <div className="d-flex"  id="team-datePicker">
                        <span className="calendar-img-flex-edit-date">
                                        <img src={Calendar} className="calendar-teams" alt="calendar" />
                                    </span>
                            <DatePicker
                                selected={selectedDate}
                                onChange={onChangeSelectDate}
                                dateFormat={commonDateFormat}
                                className="date-picker pl-3"
                                placeholderText="MM/DD/YYYY" 
                            />
                        </div>
                    </div><br />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="edit-certificate-dates-div">
                                <button className="certificate-btn-update" onClick={handleCertificateDateUpdate} >Update</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditDateModal;