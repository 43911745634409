import React,{useState} from "react";
import logo from '../../../assets/web-images/images/Pulse-Logo.svg'
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

export default function Header() {

    const type = useParams();
    const { short_name } = type;
    const { name_slug } = type;
    const { employee_id } = type;
    
    const cookies = new Cookies();
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const userRole = cookies.get("role") ? cookies.get("role") : '';


    // const logOut = () => {
    //     cookies.remove('token');
    //     cookies.remove('user');
    //     cookies.remove('role');

    //     window.location.href = "/";
    // }

    const logOut = () => {
        cookies.remove('token', { path: '/' });
        cookies.remove('user', { path: '/' });
        cookies.remove('role', { path: '/' });
        localStorage.clear();

        setTimeout(function () {
            window.location.href = "/";
        }, 1000)
    }
    const  onSampleTestPage = () =>{
        if (userDetails === "") {
            window.location.href = '/login';
            localStorage.setItem('SampleTest', JSON.stringify('/sample-test'));
        } else {
            window.location.href = '/sample-test';
        }
    }


    const  onLoginDashboard = () =>{
        if (userRole !== '' ) {         
            if (userRole === 'employee') {
                window.location.href = "/employee";
            }else if(userRole === 'manager'){
                window.location.href = "/manager";
            }else {
                window.location.href = "/super-admin";
            }
        }
    }
    
    return (
        <>

            <header className="main-header position-absolute w-100" id="border-color">
                <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10" id='navbar-padding'>
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src={logo} alt="logo" className="logo-img" height='65' />
                        </Link>
                        <Link className="navbar-toggler position-absolute right-0 border-0" to="#offcanvasWithBackdrop">
                            <i className="flaticon-menu" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"
                                data-bs-toggle="offcanvas" role="button"></i>
                        </Link>
                        <div className="clearfix"></div>
                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li className="nav-item">
                                    <Link className={ (currentPath === '/' || currentPath === '/bls' || currentPath === '/acls' || currentPath === '/pals' || currentPath === '/apb')? "nav-link active" : "nav-link"} to='/' >Courses</Link>
                                </li>                                
                                <li className="nav-item">
                                    <Link className={ currentPath === "/algorithm" ? "nav-link active": "nav-link"} to="/algorithm">Algorithms</Link>

                                </li>

                                <li className="nav-item">
                                    <Link className={ (currentPath === "/sample-test" || currentPath === `/sample-test/${short_name}` || currentPath === `/sample-test-result/${name_slug}/${employee_id}`) ? "nav-link active": "nav-link"} to="/sample-test" >Sample Tests</Link>
                                    {/* <Link className={ currentPath === "/sample-test" ? "nav-link active": "nav-link"} to="#" onClick={onSampleTestPage}>Sample Tests</Link> */}

                                </li>

                                <li className="nav-item">
                                    <Link className={ currentPath === "/groups" ? "nav-link active": "nav-link"} to="/groups">Groups</Link>

                                </li>

                                <li className="nav-item">
                                    {userRole &&
                                        <><a className="nav-link" onClick={onLoginDashboard} href="#" >Dashboard</a></>
                                    }
                                </li>
                                { !userDetails ? 
                                    <>
                                        <li className="nav-item">
                                            <Link className={ currentPath === "/login" ? "nav-link active": "nav-link"} to="/login">Login</Link>
                                        </li>
                                    </> :
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={logOut} href="">Logout</a>
                                        </li>
                                    </>
                                }      
                                                          
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
                    <div className="offcanvas-header d-flex align-items-center mt-4">
                        <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="flaticon-cancel"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body z-10">
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                                <a  className={ currentPath === "/" ? "nav-link active" : "nav-link"} href='/'>Courses</a> 
                            </li>
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/algorithm" ? "nav-link active" : "nav-link"}  href="/algorithm">Algorithms</a> 
                            </li>
                            
                            <li className="nav-item dropdown">
                                {/* <a className={ currentPath === "/sample-test" ? "nav-link active" : "nav-link"} href="/sample-test">Sample Tests</a>  */}
                                <a className={ (currentPath === "/sample-test" || currentPath === `/sample-test/${short_name}` || currentPath === `/sample-test-result/${name_slug}/${employee_id}`) ? "nav-link active": "nav-link"} href="/sample-test" >Sample Tests</a>
                                {/* <a className={ currentPath === "/sample-test" ? "nav-link active": "nav-link"} href="#" onClick={onSampleTestPage}>Sample Tests</a> */}
                            </li>
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/groups" ? "nav-link active" : "nav-link"} href="/groups">Groups</a> 
                            </li>
                            <li className="nav-item">
                                    {userRole &&
                                        <><a className="nav-link" onClick={onLoginDashboard} href="#" >Dashboard</a></>
                                    }
                            </li>
                            {!userDetails ?
                                <>
                                    <li className="nav-item">
                                        <Link className={currentPath === "/login" ? "nav-link active" : "nav-link"} to="/login">Login</Link>
                                    </li>
                                </> :
                                <>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={logOut} href="">logout</a>
                                    </li>
                                </>
                            }   
                           
                        </ul>
                       
                    </div>
                </div>
            </header>

        </>
    )
}