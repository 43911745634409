import React, {useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';


export default function SendviaEmail(props) {

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <p className="remove-class-title">
                        We will send to your file on email: {props.email}
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-no-btn" onClick={props.onHide} >Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )

}