import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import GreenRight from '../../../assets/web-images/images/Right11.svg';
import UsePurchaseAndAssignCourse from "../../../hooks/manager/UsePurchaseAndAssignCourse";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProcessToAddFund from "../PurchaseCredit/ProcessToAddFund";
import Calendar from '../../../assets/manager-images/images/calendar.svg';
import DatePicker from "react-datepicker";
import moment from "moment";
import CryptoJS from "crypto-js";

function PurchaseAndAssignCourse(props) {
    const secretKey = process.env.REACT_APP_API_LIVE;

    const [loading, setLoading] = useState(false);
    const [addFund, setAddFund] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateError, setSelectedDateError] = useState(null);
    // const [formattedAmount, setFormattedAmount] = useState(null);

    const commonDateFormat = "MM/dd/Y";


    const { isPurchase, PurchaseApi } = UsePurchaseAndAssignCourse();

    const onChangeSelectDate = (date) => {
        setSelectedDate(date);
        localStorage.setItem('ScheduleAssignment', moment(date).format('Y-MM-DD'));
        setSelectedDateError('');
    };


    const handleSubmit = async () => {
        setLoading(true);
        const get_date = localStorage.getItem('ScheduleAssignment')


        if (props.certificateStatus !== 'expired' && !selectedDate) {
            setSelectedDateError("Please choose a valid date to assign the course.");
            setLoading(false);
            return;
        }
        try {

            const data = {
                'certificateId': props.certificateId,
                'userId': props.userId,
                'amount': props.amount,
                'assign_date': get_date,
                'price': props.amount
            }
            const {
                responseCode: purchaseCourse
            } = await PurchaseApi(data);

            if (purchaseCourse.data.status === 'success') {
                toast.success("This certificate is assign course successfully.");
                localStorage.removeItem('ScheduleAssignment');
                window.location.href = "/manager/assign-courses";
            } else {
                // localStorage.setItem('CertificateAmount', props.amount.toFixed(2));
                const encryptedAmount = CryptoJS.AES.encrypt(props.amount.toString(), secretKey).toString();
                localStorage.setItem('CertificateAmount',encryptedAmount);
                toast.error("You have an insufficient balance for this certification. Please add funds to your wallet.");
                setAddFund(true);
                // localStorage.setItem('addAssignData', JSON.stringify(data));
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
                localStorage.setItem('addAssignData', encryptedData);
                props.onHide();
            }
        } catch (error) {
            toast.error('Something went wrong .');
        }
        setLoading(false);
    }


    const amount = parseFloat(props.amount);
    const formattedAmount = amount.toFixed(2);


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >

                <Modal.Body>
                    {props.certificateStatus === 'expired' &&
                        <>
                            <div>
                                <p className="remove-class-title">
                                    {/* Would you like to purchase and assign this course? ${props.amount} will be subtracted from your available */}
                                    {/* Would you like to purchase and assign this course? ${formattedAmount} will be subtracted from your available */}
                                    Would you like to purchase and assign this course? ${typeof props.amount === 'number' ? props.amount.toFixed(2) : '0.00'} will be subtracted from your available
                                    balance after discount is applied.
                                </p>
                            </div>

                        </>
                    }
                    {props.certificateStatus === 'expiring_soon' &&
                        <>
                            {/* <p className="assign-date-chouse">Choose a date to assign the course</p> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="Expired-trigger" id="Expired-team-datePicker">
                                        <p className="choose-date-assign">
                                            {/* Would you like to purchase and assign this course? ${formattedAmount} will be subtracted from your available */}
                                            Would you like to purchase and assign this course? ${typeof props.amount === 'number' ? props.amount.toFixed(2) : '0.00'} will be subtracted from your available
                                            balance after discount is applied. <br /> <br />
                                            Choose a date to assign the course </p>
                                        <div className="">
                                            <span className="calendar-img-flex-assign-course-1">
                                                <img src={Calendar} className="calendar-teams" alt="calendar" />
                                            </span>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={onChangeSelectDate}
                                                dateFormat={commonDateFormat}
                                                className="date-picker"
                                                placeholderText="__/__/__"
                                            />
                                            {selectedDateError && <div className="error">{selectedDateError}</div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {props.certificateStatus === 'n/a' &&
                        <>
                            {/* <p className="assign-date-chouse">Choose a date to assign the course</p> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="Expired-trigger" id="Expired-team-datePicker">
                                        <p className="choose-date-assign">
                                            {/* Would you like to purchase and assign this course? ${formattedAmount} will be subtracted from your available */}
                                            Would you like to purchase and assign this course? ${typeof props.amount === 'number' ? props.amount.toFixed(2) : '0.00'} will be subtracted from your available
                                            balance after discount is applied. <br /> <br />
                                            Choose a date to assign the course </p>
                                        <div className="">
                                            <span className="calendar-img-flex-assign-course-1">
                                                <img src={Calendar} className="calendar-teams" alt="calendar" />
                                            </span>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={onChangeSelectDate}
                                                dateFormat={commonDateFormat}
                                                className="date-picker"
                                                placeholderText="__/__/__"
                                            />
                                            {selectedDateError && <div className="error">{selectedDateError}</div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="row mt-2">
                        <div className="col-lg-9 col-8 col-9-width-course">
                            <div className="remove-member assign-course-bottom">
                                <button className="remove-no-btn assign-course-btn-no" onClick={props.onHide} >Cancel</button> &nbsp;
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-4 col-3-width-course">
                            <div className="remove-member">
                                {/* <button className="remove-yes-btn" type="submit" onClick={handleSubmit}><img src={GreenRight} width='18' alt="Green Right" className="green-right" />  Confirm </button> */}
                                <button className="remove-yes-btn assign-course-btn-yes" type="submit" onClick={handleSubmit} disabled={loading}>
                                    {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : <><img src={GreenRight} width='18' alt="Green Right" className="green-right" /> Confirm </>}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            <ToastContainer />
            <ProcessToAddFund
                show={addFund}
                onHide={() => setAddFund(false)}
            />
        </>
    )
}

export default PurchaseAndAssignCourse;