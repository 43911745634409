import { useState,useEffect } from "react";

import Payment from '../api/Payment';

const UsePayment = () => {
    
  const [isPaymentApi, setIsPaymentApi] = useState(false);
  
  const PaymentData = async (data) => {
    setIsPaymentApi(true);

    let responseCode;

    try {
      const response = await Payment.PaymentMethod(data);
      responseCode = response.data;

    } catch (error) {
      responseCode = error.response;
    }

    setIsPaymentApi(false);

    return { responseCode };
  };

  return { isPaymentApi, PaymentData };

};


export default UsePayment




// import { useState } from 'react';
// import axios from 'axios';

// const UsePayment = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const PaymentData = async (data = null) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await Payment.PaymentMethod({
//         method,
//         url,
//         data,
//       });

//       setLoading(false);
//       return response.data;
//     } catch (error) {
//       setLoading(false);
//       setError(error.response.data.message || 'Something went wrong');
//       throw error;
//     }
//   };

//   return { loading, error, PaymentData };
// };

// export default UsePayment;
