import { useState, useEffect } from "react";

import Help from '../api/Help';

const UseState = () => {
    const [state, setState] = useState([]);
    useEffect(() => {
      const getState = async () => {
        try {
          const response = await Help.getState();
          if (response.data.status === "success") {
            setState(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getState();
    }, []);
  
    return {state};
   
}



export default UseState;