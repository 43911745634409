import React, { useState, useEffect } from "react";
import logo from '../../../../assets/web-images/images/Pulse-Logo.svg';
import PIM from '../../../../assets/emplyoee-images/images/PIM.svg';
import { Link } from "react-router-dom";
import UseCertificateSurvey from "../../../../hooks/UseCertificateSurvey";
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie'
import UseSurveyAnswer from "../../../../hooks/UseSurveyAnswer";
import { ThreeDots } from "react-loader-spinner";
import ScrollToTop from 'react-scroll-to-top';

export default function CertificationSurvey() {

    const slug = useParams();
    const { test_name } = slug;

    const [loading, setLoading] = useState(true);
    const [certificateSurvey, setCertificateSurvey] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [anwserArray, setAnswerArray] = useState([]);
    const [allAnswerSelected, setAllAnswerSelected] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';

    const { surveyQuestion: CertificateSurveyQuestion } = UseCertificateSurvey();
    const { isSurveyApi, addSurveyApi } = UseSurveyAnswer();


    useEffect(() => {
        fetchCertificateType();
    }, [CertificateSurveyQuestion]);

    const fetchCertificateType = () => {
        setLoading(false);
        setCertificateSurvey(CertificateSurveyQuestion);
    }


    const handleChange = (e, questionId,textArea = false) => {
        const selectedValue = e.target.value;

        const dataArray = [...anwserArray];

        const index = dataArray.findIndex(item => item.question_id === questionId);

        if (index === -1) {
            const newAnswer = {
                employee_id: userDetails.id,
                question_id: questionId,
                answer_id: questionId === 9 ? [selectedValue] : !textArea ? selectedValue : null,
                other_details: textArea ? selectedValue : ''
            };

            dataArray.push(newAnswer);
        } else {
            if (questionId === 9) {
                if (Array.isArray(dataArray[index].answer_id)) {
                    if (e.target.checked) {
                        if (!dataArray[index].answer_id.includes(selectedValue)) {
                            if (!textArea) {
                                dataArray[index].answer_id.push(selectedValue);
                            } else {
                                dataArray[index].other_details.push(selectedValue);
                            }
                        }
                    } else {
                        if (!textArea) {
                            dataArray[index].answer_id = dataArray[index].answer_id.filter(value => value !== selectedValue);
                        } else {
                            dataArray[index].other_details = selectedValue;
                        }
                    }
                } else {
                    dataArray[index].answer_id = [dataArray[index].answer_id];
                    if (e.target.checked) {
                        if (!textArea) {
                            dataArray[index].answer_id.push(selectedValue);
                        } else {
                            dataArray[index].other_details = selectedValue;
                        }
                    }
                }
            } else {
                if (!textArea) {
                    dataArray[index].answer_id = selectedValue;
                } else {
                    dataArray[index].other_details = selectedValue;
                }
            }
        }

        setAnswerArray([...dataArray]);

        const subArrayCounts = certificateSurvey.map((item) => item.subquestions.length);
        const totalSubArrayCountFound = certificateSurvey
            .filter((item) => item.subquestions.length > 0)
            .map((item) => item.subquestions.length);
        const totalSubArrays = subArrayCounts.reduce((acc, count) => acc + count, 0);

        const totalQuestionLength = (certificateSurvey.length + totalSubArrays) - totalSubArrayCountFound.length;

        if (totalQuestionLength === dataArray.length) {
            setAllAnswerSelected(false);
        } else {
            setAllAnswerSelected(true);
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setSubmitting(true);
        const {
            responseCode: surveyAnswers
        } = await addSurveyApi({ 'answers': anwserArray, 'name_slug': test_name });

        if (surveyAnswers.data.status === 'success') {
            window.location.href = "/employee"
        }
        setSubmitting(false);
    };







    return (
        <>
            <header className="main-header position-absolute w-100" id="border-color">
                <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10 surveryformheader" id='navbar-padding'>
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to="#" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src={PIM} alt="logo" className="logo-img" height='110' />
                        </Link>
                        <div className="collapse navbar-collapse justify-content-center surveyfrom">
                            <Link to="#" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                                <img src={logo} alt="logo" className="logo-img" height='65' />
                            </Link>
                        </div>
                    </div>
                </nav>
                <div className="container">
                    {test_name === 'basic_life_support' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form BLS Certification Course <br /> Activity ID: 18075</p></>
                    }
                    {test_name === 'advanced_cardiovascular_life_support' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form ACLS Certification Course <br /> Activity ID: 18074</p></>
                    }
                    {test_name === 'pediatric_advanced_life_support' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form PALS Certification Course <br /> Activity ID: 18076</p></>
                    }
                    {test_name === 'basic_life_support_re_certification' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form BLS Re-Certification Course <br /> Activity ID: 18075</p></>
                    }
                    {test_name === 'advanced_cardiovascular_life_support_re_certification' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form ACLS Re-Certification Course <br /> Activity ID: 18074</p></>
                    }
                    {test_name === 'pediatric_advanced_life_support_re_certification' &&
                        <> <p className="evaluation-form">Evaluation & Credit Request Form PALS Re-Certification Course <br /> Activity ID: 18076</p></>
                    }
                </div>
            </header>
            <br /> <br />
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="card certificate-survey">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 className="survey-complete-qus text-left">Please complete the following evaluation questions to receive your certificate.</h5>
                                </div>
                            </div>
                            <div className="row pt-5">
                                <form>
                                    {certificateSurvey.map((question, index) =>
                                        <>
                                            <div className="row bls-c-survey pb-3">
                                                <div className="bls-survey-1"><label>{index + 1}. {question.question}</label></div>

                                                {question.map_answers.map((item) =>
                                                    <>
                                                        {question.id === 8 ? (
                                                            <>
                                                                <div className="col-lg-12">
                                                                    <input id={`${item.answer.value}_${item.answer.id}`} type="radio" name={`${'question'}_${question.id}`} value={item.answer.id} className="radio-input other-input" onChange={(e) => handleChange(e, question.id)} />
                                                                    <label className="radio-label" htmlFor={`${item.answer.value}_${item.answer.id}`}>
                                                                        {item.answer.value}
                                                                    </label>
                                                                    {item.answer.value === 'Other, please specify:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree"
                                                                                      id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}
                                                                            ></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : question.id === 9 ? (
                                                            <>
                                                                <div className="col-lg-6">
                                                                    <input id={`${item.answer.value}_${item.answer.id}`} type="checkbox" name={`${'question'}_${question.id}`} value={item.answer.id} className="radio-input other-input" onChange={(e) => handleChange(e, question.id)} />
                                                                    <label className="radio-label" htmlFor={`${item.answer.value}_${item.answer.id}`}>
                                                                        {item.answer.value}
                                                                    </label>
                                                                    {item.answer.value === 'Other, please specify:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree"
                                                                                      id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : question.id === 14 ? (
                                                            <>
                                                                <div className="col-lg-12">
                                                                    <input id={`${item.answer.value}_${item.answer.id}`} type="radio" name={`${'question'}_${question.id}`} value={item.answer.id} className="radio-input other-input" onChange={(e) => handleChange(e, question.id)} />
                                                                    <label className="radio-label" htmlFor={`${item.answer.value}_${item.answer.id}`}>
                                                                        {item.answer.value}
                                                                    </label>
                                                                    {item.answer.value === 'Other, please specify:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree"
                                                                                      id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) :
                                                        question.id === 11 ? (
                                                            <>
                                                                <div className="col-lg-6">
                                                                    <input id={`${item.answer.value}_${item.answer.id}`} type="radio" name={`${'question'}_${question.id}`} value={item.answer.id} className="radio-input other-input" onChange={(e) => handleChange(e, question.id)} />
                                                                    <label className="radio-label" htmlFor={`${item.answer.value}_${item.answer.id}`}>
                                                                        {item.answer.value}
                                                                    </label>
                                                                    {item.answer.value === 'Other, please specify:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree"
                                                                                      id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}
                                                                            ></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                <div className="col-lg-3">
                                                                    <input id={`${item.answer.value}_${item.answer.id}`} type="radio" name={`${'question'}_${question.id}`} value={item.answer.id} className="radio-input other-input" onChange={(e) => handleChange(e, question.id)}  required />
                                                                    <label className="radio-label" htmlFor={`${item.answer.value}_${item.answer.id}`}>
                                                                        {item.answer.value}
                                                                    </label>

                                                                    {item.answer.value === 'Other, please specify:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree" id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}></textarea>
                                                                        </>
                                                                    )}

                                                                    {item.answer.value === 'No, please explain:' && (
                                                                        <>
                                                                            <textarea className="certificate-survey-text-1" name="otherDegree"
                                                                                      id="otherDegreeTextarea"
                                                                                      onChange={(e) => { handleChange(e,question.id,true) }}
                                                                            ></textarea>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                        }



                                                    </>
                                                )}




                                                <div className="row">
                                                    <table className="table table-striped">
                                                        {question.id === 6 &&
                                                            <>
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="text-center">Strongly Agree</th>
                                                                    <th className="text-center">Agree</th>
                                                                    <th className="text-center">Neutral</th>
                                                                    <th className="text-center">Disagree</th>
                                                                    <th className="text-center">Strongly Disagree</th>
                                                                </tr>
                                                            </>
                                                        }
                                                        {question.id === 7 &&
                                                            <>
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="text-center">Strongly Agree</th>
                                                                    <th className="text-center">Agree</th>
                                                                    <th className="text-center">Neutral</th>
                                                                    <th className="text-center">Disagree</th>
                                                                    <th className="text-center">Strongly Disagree</th>
                                                                </tr>
                                                            </>
                                                        }

                                                        {question.subquestions.map((subQus) =>

                                                            <>
                                                                <tr>
                                                                    <td className="text-font-set-survey w-50">{subQus.question}</td>
                                                                    {subQus.map_answers.map((qus) =>
                                                                        <>
                                                                            <td className="text-font-set-survey number-survey">
                                                                                <label className="radio-label-survey" htmlFor={`${qus.answer.value}_${qus.answer.id}`}>

                                                                                    <input id={`${qus.answer.value}_${qus.answer.id}`} type="radio" name={`${'question'}_${subQus.id}`} value={qus.answer.id}
                                                                                        onChange={(e) => handleChange(e, subQus.id)}
                                                                                    />
                                                                                    <span>{qus.answer.value}</span>
                                                                                </label>
                                                                            </td>
                                                                        </>
                                                                    )}

                                                                </tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </div>

                                                {question.id === 13 &&
                                                    <>
                                                        <div className="col-lg-12">
                                                            <textarea className="certificate-survey-text-2"
                                                                      onChange={(e) => { handleChange(e,question.id,true) }} name="issues/problems" ></textarea>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            <hr />
                                            <br />
                                        </>
                                    )}

                                    <div className="button-group-step1">
                                        <>
                                            <div className="d-flex">
                                                <>
                                                    <button type="submit" className={!allAnswerSelected ? "btn-submit-sample" : "btn-submit-sample disabled-btn" } onClick={handleSubmit} disabled={allAnswerSelected || submitting}>
                                                    {submitting ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Submit"}</button>
                                                </>
                                            </div>
                                        </>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )
}