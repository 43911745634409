import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import UseFinalExamQuestions from "../../../hooks/UseFinalExamQuestions";
import UseFinalExamQuestionAnswer from "../../../hooks/UseFinalExamQuestionAnswer";
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie'
import Header from "../../../components/emplyoeeSite/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import BlsImg from '../../../assets/web-images/images/01-BLS-10.svg';
import AclsImg from '../../../assets/web-images/images/02-ACLS-10.svg';
import PaclImg from '../../../assets/web-images/images/03-PALS-10.svg';
import ApbImg from '../../../assets/web-images/images/04-Advanced Provider Bundle-10.svg';
import Info from '../../../assets/web-images/images/info.svg';
import Arrow from '../../../assets/web-images/images/Arrow.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ScrollToTop from 'react-scroll-to-top';
import {ThreeDots} from "react-loader-spinner";
import CertificateCard from "../../../components/website/CertificateCard";


export default function FinalExam() {
    const slug = useParams();
    const { test_name } = slug;
    const [currentStep, setCurrentStep] = useState(1);

    const navigate = useNavigate();


    const cookies = new Cookies();

    const userDetails = cookies.get("user") ? cookies.get("user") : '';

    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [allAnswerSelected, setAllAnswerSelected] = useState(true);
    const [loadingSelected, setLoadingSelected] = useState(false);
    const [anwserArray, setAnswerArray] = useState([]);
    const [errorTestQus, setErrorTestQus] = useState('');
    const [getFinalQuestions, setgetFinalQuestions] = useState([]);

    const { getFinalQuestions: FinalExamQuestionApi } = UseFinalExamQuestions({
        test_name
    });

    const { isCreate, createAnswer } = UseFinalExamQuestionAnswer();

    const handleChange = (e, questionId) => {
        const selectedValue = e.target.value;
        setSelectedAnswer(selectedValue);

        const dataArray = anwserArray;
        const index = dataArray.findIndex(item => item.f_question_id === questionId);
        if (index === -1) {
            const newAnswer = {
                employee_id: userDetails.id,
                f_question_id : questionId,
                answer_value: selectedValue
            };
            dataArray.push(newAnswer);
        } else {
            dataArray[index].answer_value = selectedValue;
        }

        setAnswerArray([...dataArray]);

        const [questions] = getFinalQuestions;
        if (questions.final_questions.length === anwserArray.length) {
            setAllAnswerSelected(false);
        }
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoadingSelected(true);
        if (!allAnswerSelected) {

            const {
                responseCode: createAnswerResponseCode
            } = await createAnswer({ 'answers': anwserArray, 'name_slug': test_name });

            switch (createAnswerResponseCode) {
                case 200:
                    break;
                default:
                    break;
            }

            navigate(`/employee/final-exam-result/${test_name}`)
        }
        setLoadingSelected(false);
    };

    useEffect(() => {
        fetchChapterName();
    });

    const fetchChapterName = async () => {

        if (FinalExamQuestionApi.status === "success") {
            setgetFinalQuestions(FinalExamQuestionApi.data)
        }
    }



    const renderStepContent = (step) => {
        return (
            <>


                <div className='row qus-name-changes'>
                    {
                        getFinalQuestions.map((item) => (
                            <>

                                <div>
                                    
                                    <p className="sample-test-review">{item.full_name} Final Exam</p>
                                    {item.final_questions.map((questionItem, index) => (
                                        <>
                                            <div>
                                                <label className="test-sample">
                                                {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: questionItem.final_exam_questions.question }} />
                                                    {/* {`${index + 1}. ${questionItem.final_exam_questions.question}`} */}
                                                </label>

                                            </div>

                                            <div className="row">
                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                    <div>
                                                        <input id={`${questionItem.id}_${'option1'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"
                                                            value={questionItem.final_exam_questions.option_1}
                                                            onChange={(e) => handleChange(e, questionItem.id)} />
                                                        <label htmlFor={`${questionItem.id}_${'option1'}`} className="radio-custom-label">{questionItem.final_exam_questions.option_1}</label>
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 qus-radio-btn-change'>

                                                    <div>
                                                        <input id={`${questionItem.id}_${'option2'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"

                                                            value={questionItem.final_exam_questions.option_2}

                                                            onChange={(e) => handleChange(e, questionItem.id)} />
                                                        <label htmlFor={`${questionItem.id}_${'option2'}`} className="radio-custom-label">{questionItem.final_exam_questions.option_2}</label>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 qus-radio-btn-change'>

                                                    <div>
                                                        <input id={`${questionItem.id}_${'option3'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.final_exam_questions.option_3}
                                                            onChange={(e) => handleChange(e, questionItem.id)} />
                                                        <label htmlFor={`${questionItem.id}_${'option3'}`} className="radio-custom-label">{questionItem.final_exam_questions.option_3}</label>
                                                    </div>

                                                </div>
                                                <div className='col-lg-6 qus-radio-btn-change'>

                                                    <div>
                                                        <input id={`${questionItem.id}_${'option4'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.final_exam_questions.option_4}
                                                            onChange={(e) => handleChange(e, questionItem.id)} />
                                                        <label htmlFor={`${questionItem.id}_${'option4'}`} className="radio-custom-label">{questionItem.final_exam_questions.option_4}</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr />
                                            <br />
                                            {errorTestQus && <div className="error">{errorTestQus}</div>}

                                        </>
                                    ))}

                                </div>

                            </>

                        ))
                    }


                </div>
            </>
        )
    };


    const stepCount = renderStepContent.length;
    const percentage = (currentStep * 10) / stepCount;

    return (
        <>
            <Header />

            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-5">
                        <div className="card text-center title-section">
                            {/* <p className="title">Basic Life Support</p> */}
                            <p className="title">{test_name.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5 feature-promo bg-light">
                <div className="container">
                    <div className="row ">
                        <div className="step-form">
                            <div className="card">
                                <div className="progress-bar">
                                    
                                </div>
                                {renderStepContent(currentStep)}

                                <div className="button-group-step1">

                                    <>
                                        <div className="d-flex">

                                            <>
                                                <button type="submit" className={!allAnswerSelected ? "btn-submit-sample final-submit-btn-w" : "btn-submit-sample final-submit-btn-w disabled-btn" } onClick={handleSubmit} disabled={allAnswerSelected || loadingSelected}>
                                                {loadingSelected ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Submit Test"}
                                                    
                                                </button>
                                            </>



                                        </div>


                                    </>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                   <CertificateCard />
                </div>
            </section>
            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )
}