import {
    useState
} from "react";

import manager from "../../api/manager/manager";

const UseDeleteUserAssignCertificate = () => {
    const [isDeleteAssignCertificate, setIsDeleteAssignCertificate] = useState(false);
    const CertificateDelete = async (body) => {
        setIsDeleteAssignCertificate(true);
        let responseCode;
        try {
            const response = await manager.deleteUserAssignCertificate(body);
            responseCode = response.data;
        } catch (error) {
            responseCode = error.response.status;
        }
        setIsDeleteAssignCertificate(false);
        return {
            responseCode
        };
    };

    return {isDeleteAssignCertificate, CertificateDelete};
};

export default UseDeleteUserAssignCertificate;