import React, { useEffect, useState } from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import { Tab, Tabs } from 'react-bootstrap';
import { Watch } from "react-loader-spinner";
import UseCertificateCourseDetails from "../../../hooks/manager/UseCertificateCourseDetails";
import { useParams } from 'react-router-dom';
import CertificateCourseDetails from './SubCourseDetail/CertificateCourseDetails';
import ScrollToTop from 'react-scroll-to-top';
function CourseDetails() {

    const { certificate_type } = useParams();

    // const [loading, setLoading] = useState(true);
    const [certificateCourseList, setCertificateCourseList] = useState([]);
    const [filterStatus, setFilterStatus] = useState("");
    const [filterParams, setFilterParams] = useState({});
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [certificateName, setCertificateName] = useState('');
    const { getCourseList, loading, recallApiWithNewParams, loadMoreLoading } = UseCertificateCourseDetails({ certificate_type }, pageParams);

    useEffect(() => {
        fetchCertificateCourse();
    }, [getCourseList]);

    const fetchCertificateCourse = () => {
        if (getCourseList.status === 'success') {
            // setLoading(false);
            // setCertificateCourseList(getCourseList.data.data)
            setCertificateCourseList([...certificateCourseList, ...getCourseList.data.data]);
            setPerPage(getCourseList.data);
            setCurrentPage(getCourseList.data.current_page);
            // console.log(getCourseList.data);
        }
    }

    const handleTabChange = (selectedTab) => {
        // setFilterStatus(selectedTab);
        if (selectedTab !== certificateName) {
            setCertificateCourseList([]);
            setCertificateName(selectedTab)
            const params = {
                certificate_status: selectedTab,
            }
            recallApiWithNewParams(params);
        }
    }

    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
            certificate_status: certificateName,
        }
        // setPageParams(params);
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }


    return (
        <>
            <Header />

            <section className="pt-10 feature-promo bg-light manager-tab-listing" id='tab-listing'>
                <div className="container">
                    <div className="row pt-5 course-padding">
                        <p className="course-detail-title">Course Details</p>
                        <Tabs
                            defaultActiveKey="all"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                            onSelect={handleTabChange}
                        >
                            {['all', 'expiring_soon', 'expired'].map((tab) => (
                                <Tab key={tab} eventKey={tab} title={tab.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}>
                                    <div className="container d-flex align-items-center justify-content-center vh-80">
                                        {loading && (
                                            <>

                                                <>
                                                    <Watch
                                                        height="80"
                                                        width="80"
                                                        radius="48"
                                                        color="#2f99a9 "
                                                        ariaLabel="watch-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </>


                                            </>
                                        )}
                                    </div>

                                    {!loading && (
                                        <>
                                            <section className="feature-promo bg-light manager-tab-listing table-p-hidden" id='tab-listing'>
                                                <div className="container">
                                                    <div className="row pt-5 pb-5">
                                                        <div className="col-lg-9">
                                                            <p className="all-bls-title">{certificate_type.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}</p>
                                                        </div>
                                                        <div className="col-lg-3">
                                                        </div>
                                                        <div className="pt-3 table-p-manager">
                                                            <table className="table">
                                                                <thead className="thead-course">
                                                                    <tr>
                                                                        <th className="course-detail-th text-center">Name </th>
                                                                        {certificate_type === 'bls_certification' && (<th className="course-detail-th text-center">BLS</th>)}
                                                                        {certificate_type === 'acls_certification' && (<th className="course-detail-th text-center">ACLS</th>)}
                                                                        {certificate_type === 'pals_certification' && (<th className="course-detail-th text-center">PALS</th>)}
                                                                        <th className="course-detail-th text-center">Assigned Date</th>
                                                                        <th className="course-detail-th text-center">Last Notified Date</th>
                                                                        <th className="course-detail-th text-center"></th>
                                                                    </tr>
                                                                </thead>
                                                                {certificateCourseList.map((item) => (
                                                                    <>
                                                                        <CertificateCourseDetails key={item.id} item={item} />
                                                                    </>
                                                                ))}

                                                            </table>
                                                            {!loading && !loadMoreLoading && certificateCourseList.length === 0 &&
                                                                <>
                                                                    <div className="text-center">
                                                                        <h5 className="valid-till">No Course Found.</h5>
                                                                    </div>
                                                                </>}
                                                            {currentPage < perPage.last_page &&
                                                                (<button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button>)
                                                            }

                                                            <div className="container d-flex align-items-center justify-content-center vh-80">
                                                                {loadMoreLoading && !loading && (
                                                                    <Watch
                                                                        height="80"
                                                                        width="80"
                                                                        radius="48"
                                                                        color="#2f99a9 "
                                                                        ariaLabel="watch-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </section>
                                            {/*  */}
                                        </>
                                    )}
                                </Tab>
                            ))}




                        </Tabs>
                    </div>
                </div>
            </section>
            <br/>
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>
    )

}


export default CourseDetails;