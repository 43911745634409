import { useState } from "react";

import manager from "../../api/manager/manager";
const UseSendReNotify = () => {
    const [isReNotify, setIsReNotify] = useState(false);

    const sendReNotification = async (boddy) => {
        setIsReNotify(true);

        let responseCode;
        try {
            const response = await manager.SendReNotify(boddy);
            responseCode = response;
        } catch (error) {
            responseCode = error.response;
        }

        setIsReNotify(false);

        return { responseCode };
    };

    return { isReNotify, sendReNotification };
};


export default UseSendReNotify;