import { useState } from "react";

import SampleTest from '../api/SampleTest';

  const UseSampleTestQuestionAnswer = () => {
    const [isCreating, setIsCreating] = useState(false);
  
    const createAnswer = async (answers) => {
      setIsCreating(true);
  
      let responseCode;
  
      try {
        const response = await SampleTest.createSampleTest(answers);
        responseCode = response.data;
      } catch (error) {
        responseCode = error.response.status;
      }
  
      setIsCreating(false);
  
      return { responseCode };
    };
  
    return { isCreating, createAnswer };
  };


export default UseSampleTestQuestionAnswer;