import { useState } from "react";
import CertificateTypes from '../api/CertificateTypes';

const UseSendCertificate = () => {
    const [isSendCertificateApi, setIsSendCertificateApi] = useState(false);
  
    const sendCertificateApi = async (data) => {
      setIsSendCertificateApi(true);
  
      let responseCode;
  
      try {
        const response = await CertificateTypes.SendCertificate(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsSendCertificateApi(false);
  
      return { responseCode };
    };
  
    return { isSendCertificateApi, sendCertificateApi };
  };


export default UseSendCertificate;