import React, {useEffect, useState} from "react";
import Header from "../../../../components/managerSite/Layouts/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from '../../../../assets/manager-images/images/calendar.svg';
import Name from '../../../../assets/manager-images/images/Name.svg';
import Mail from '../../../../assets/manager-images/images/Mail-1.svg';
import Title from '../../../../assets/manager-images/images/Title.svg';
import UseSelfAssignAdd from "../../../../hooks/UseSelfAssignAdd";
import UseCertificateList from "../../../../hooks/UseCertificateList";
import ReAssignCourseConfirmation from "../../../../components/managerSite/MyTeams/Modal/ReAssignCourseConfirmation";
import ExpiredCertificateModal from "../../../../components/managerSite/MyTeams/Modal/ExpiredCertificateModal";
import {ThreeDots} from "react-loader-spinner";
import moment from 'moment-timezone';
import ProcessToAddFund from "../../../../components/managerSite/PurchaseCredit/ProcessToAddFund";
import UseUserCurrentBalance from "../../../../hooks/manager/UseUserCurrentBalance";
import axiosConfig from "../../../../api/Config";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import CryptoJS from "crypto-js";


function SelfAssignAdd() {
    const  secretKey  = process.env.REACT_APP_API_LIVE;
    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const commonDateFormat = "MM/dd/Y";
    
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);
    // certificate value get 
    const [CertificateType, setCertificateTypes] = useState([]);
    const {certificateTypes: CertificateTypeGet} = UseCertificateList();
    useEffect(() => {
        setCertificateTypes(CertificateTypeGet);
    }, [CertificateTypeGet]);

    // get user current balance
    const [getcurrentBalance, setCurrentBalance] = useState({});
    const {getUserCurrentBalance: CurrentBalance} = UseUserCurrentBalance();
    useEffect(() => {
        if (CurrentBalance.status === 'success') {
            setCurrentBalance(CurrentBalance.data)
        }
    }, [CurrentBalance]);

    const {isAddSelftCertificateAssign, AddSelfAssignCertificate} = UseSelfAssignAdd();
    const [loading, setLoading] = useState(false);
    const [postDetails, setPostDetails] = useState({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        title: userDetails.title,
        certificate: [],
    });

    const [addFund, setAddFund] = useState(false);

    const [blsCertificate, setBlsCertificate] = useState({
        certificate_name: "bls",
        is_selected: false,
        active_until: null,
        expired_until: null,
        is_na: false,
    })
    const [aclsCertificate, setAclsCertificate] = useState({
        certificate_name: "acls",
        is_selected: false,
        active_until: null,
        expired_until: null,
        is_na: false,
    })
    const [palsCertificate, setPalsCertificate] = useState({
        certificate_name: "pals",
        is_selected: false,
        active_until: null,
        expired_until: null,
        is_na: false,
    })

    const [activeOpenDate, setActiveOpenDate] = useState(false);
    const [inactiveOpenDate, setInactiveOpenDate] = useState(false);
    const [activeaclsOpenDate, setActiveaclsOpenDate] = useState(false);
    const [inactiveaclsOpenDate, setInctiveaclsOpenDate] = useState(false);
    const [activepalsOpenDate, setActivepalsOpenDate] = useState(false);
    const [inactivepalsOpenDate, setInActivepalsOpenDate] = useState(false);
    const [errorMessages, setErrorMessages] = useState({
        first_name: "",
        email: "",
        title: "",
        certificate: "",
        apiError: "",
    })

    const [selectedCertificate, setSelectedCertificate] = useState('');
    const [openReAssignModal, setOpenReAssignModal] = useState(false);
    const [openExpiredModal, setOpenExpiredModal] = useState(false);
    const [addTeamMemberDetail, setAddTeamMemberDetail] = useState({});


    const handleOnBLSChange = (value, name) => {
        let updatedBlsCertificate = {
            ...blsCertificate,
            active_until: null,
            expired_until: null,
            is_na: false,
        };
    
        const todayDate = moment().format('Y-MM-DD');
        let certificateDate = moment(value).format('Y-MM-DD');
    
        if (name === 'bls_certificate_a_date') {
            setActiveOpenDate(false);
            updatedBlsCertificate = {
                ...updatedBlsCertificate,
                active_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
            const daysDifference = moment(certificateDate).diff(todayDate, 'days');
            if (daysDifference < 60) {
                localStorage.removeItem('bls_certificate_e_date');
                setSelectedCertificate(name);
                setOpenReAssignModal(!openReAssignModal);
            }
        } else if (name === 'bls_certificate_e_date') {
             localStorage.removeItem('bls_certificate_a_date');
            setInactiveOpenDate(false);
            updatedBlsCertificate = {
                ...updatedBlsCertificate,
                expired_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
            setSelectedCertificate(name);
            setOpenExpiredModal(!openExpiredModal);
            // Remove active_until date here
            updatedBlsCertificate.active_until = null;
        } else if (name === 'bls_certificate') {
            updatedBlsCertificate = {
                ...updatedBlsCertificate,
                expired_until: null,
                active_until: null,
                is_na: true,
                is_selected: true,
            };
            setActiveOpenDate(false);
            setInactiveOpenDate(false);
            localStorage.removeItem('bls_certificate_a_date');
            localStorage.removeItem('select_bls_certificate');
            localStorage.removeItem('BLS');
            localStorage.removeItem('BLS_E');
        }
        setBlsCertificate(updatedBlsCertificate);
    };

    const handleACLSChange = (value, name) => {
        let updatedAclsCertificate = {
            ...aclsCertificate,
            active_until: null,
            expired_until: null,
            is_na: false,
        };
        const todayDate = moment().format('Y-MM-DD');
        let certificateDate = moment(value).format('Y-MM-DD');
    
        if (name === 'acls_certificate_a_date') {
            setActiveaclsOpenDate(false);
            updatedAclsCertificate = {
                ...updatedAclsCertificate,
                active_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
    
            const daysDifference = moment(certificateDate).diff(todayDate, 'days');
            if (daysDifference < 60) {
                localStorage.removeItem('acls_certificate_e_date');
                setSelectedCertificate(name);
                setOpenReAssignModal(!openReAssignModal);
            }
        } else if (name === 'acls_certificate_e_date') {
            setInctiveaclsOpenDate(false);
            localStorage.removeItem('acls_certificate_a_date');
            updatedAclsCertificate = {
                ...updatedAclsCertificate,
                expired_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
            setSelectedCertificate(name);
            setOpenExpiredModal(!openExpiredModal);
        } else if (name === 'acls_certificate') {
            updatedAclsCertificate = {
                ...updatedAclsCertificate,
                expired_until: null,
                active_until: null,
                is_na: true,
                is_selected: true,
            };
            setActiveaclsOpenDate(false);
            setInctiveaclsOpenDate(false);
            localStorage.removeItem('acls_certificate_a_date');
            localStorage.removeItem('select_acls_certificate');
            localStorage.removeItem('ACLS');
            localStorage.removeItem('ACLS_E');
        }
        setAclsCertificate(updatedAclsCertificate);
    };
    
    const handleOnPALSChange = (value, name) => {
        let updatedPalsCertificate = {
            ...palsCertificate,
            active_until: null,
            expired_until: null,
            is_na: false,
        };
        const todayDate = moment().format('Y-MM-DD');
        let certificateDate = moment(value).format('Y-MM-DD');
    
        if (name === 'pals_certificate_a_date') {
            updatedPalsCertificate = {
                ...updatedPalsCertificate,
                active_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
            setActivepalsOpenDate(false);
            const daysDifference = moment(certificateDate).diff(todayDate, 'days');
            if (daysDifference < 60) {
                localStorage.removeItem('pals_certificate_e_date');
                setSelectedCertificate(name);
                setOpenReAssignModal(!openReAssignModal);
            }
        } else if (name === 'pals_certificate_e_date') {
            localStorage.removeItem('pals_certificate_a_date');
            setInActivepalsOpenDate(false);
            updatedPalsCertificate = {
                ...updatedPalsCertificate,
                expired_until: moment(value).format('Y-MM-DD'),
                is_selected: true,
            };
            setSelectedCertificate(name);
            setOpenExpiredModal(!openExpiredModal);
        } else if (name === 'pals_certificate') {
            updatedPalsCertificate = {
                ...updatedPalsCertificate,
                expired_until: null,
                active_until: null,
                is_na: true,
                is_selected: true,
            };
            setActivepalsOpenDate(false);
            setInActivepalsOpenDate(false);
            localStorage.removeItem('pals_certificate_a_date');
            localStorage.removeItem('select_pals_certificate');
            localStorage.removeItem('PALS');
            localStorage.removeItem('PALS_E');
        }
        setPalsCertificate(updatedPalsCertificate);
    };

   
    const detailspostdata = postDetails;
    if (blsCertificate.is_selected) {
        const databls = detailspostdata['certificate'].findIndex(obj => obj.certificate_name === "bls");
        if (databls !== -1) {
            detailspostdata['certificate'][databls] = blsCertificate;
        } else (
            detailspostdata['certificate'].push(blsCertificate)
        )
    }
    if (aclsCertificate.is_selected) {
        const databls = detailspostdata['certificate'].findIndex(obj => obj.certificate_name === "acls");
        if (databls !== -1) {
            detailspostdata['certificate'][databls] = aclsCertificate;
        } else (
            detailspostdata['certificate'].push(aclsCertificate)
        )
    }
    if (palsCertificate.is_selected) {
        const databls = detailspostdata['certificate'].findIndex(obj => obj.certificate_name === "pals");
        if (databls !== -1) {
            detailspostdata['certificate'][databls] = palsCertificate;
        } else (
            detailspostdata['certificate'].push(palsCertificate)
        )
    }


    const validation = async () => {
        let success = true;
        let errors = errorMessages;
        

        if (postDetails.certificate.length === 0) {
            errors.certificate = 'At Least One Certificate Required';
            success = false;
        }
        setErrorMessages({...errorMessages, ...errors});
        return success;
    }
    const removeItemsFromLocalStorage = () => {
        localStorage.removeItem('bls_certificate_a_date');
        localStorage.removeItem('select_bls_certificate');
        localStorage.removeItem('acls_certificate_a_date');
        localStorage.removeItem('select_acls_certificate');
        localStorage.removeItem('pals_certificate_a_date');
        localStorage.removeItem('select_pals_certificate');
        localStorage.removeItem('BLS');
        localStorage.removeItem('BLS_E');
        localStorage.removeItem('ACLS');
        localStorage.removeItem('ACLS_E');
        localStorage.removeItem('PALS');
        localStorage.removeItem('PALS_E');
        localStorage.removeItem('addTeamData')
        localStorage.removeItem('selfData')
    }

    const makePostDetails = () => {
        let tempPostDetails = postDetails;

        const reAssignCertificate = [];
        if (localStorage.getItem('bls_certificate_a_date')) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('BLS'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let object = {
                certificate_name: "bls_re",
                active_until: localStorage.getItem('bls_certificate_a_date'),
                expired_until: null,
                isReCertificate: false,
                price:decryptedValue
            }
            reAssignCertificate.push(object);
        }
        if (localStorage.getItem('select_bls_certificate')) {
            let value = localStorage.getItem('select_bls_certificate');
            let findBls = postDetails.certificate.find((item) => item.certificate_name === 'bls');

            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('BLS_E'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let expiredUntil = null;
            if (findBls) {
                expiredUntil = moment(findBls.expired_until).format('Y-MM-DD') ?? null;
            }
            let object = {
                certificate_name: "bls",
                active_until: null,
                expired_until: expiredUntil,
                isReCertificate: false,
                price:decryptedValue
            }
            if (value === 'bls-recertification') {
                object.isReCertificate = true;
            }
            reAssignCertificate.push(object);
        }

        if (localStorage.getItem('acls_certificate_a_date')) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('ACLS'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let object = {
                certificate_name: "acls_re",
                active_until: localStorage.getItem('acls_certificate_a_date'),
                expired_until: null,
                isReCertificate: false,
                price:decryptedValue
            }

            reAssignCertificate.push(object);
        }
        if (localStorage.getItem('select_acls_certificate')) {
            let value = localStorage.getItem('select_acls_certificate');
            let findAcls = postDetails.certificate.find((item) => item.certificate_name === 'acls');
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('ACLS_E'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let expiredUntil = null;
            if (findAcls) {
                expiredUntil = moment(findAcls.expired_until).format('Y-MM-DD') ?? null;
            }
            let object = {
                certificate_name: "acls",
                active_until: null,
                expired_until: expiredUntil,
                isReCertificate: false,
                price:decryptedValue
            }
            if (value === 'acls-recertification') {
                object.isReCertificate = true;
            }
            reAssignCertificate.push(object);
        }

        if (localStorage.getItem('pals_certificate_a_date')) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('PALS'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let object = {
                certificate_name: "pals_re",
                active_until: localStorage.getItem('pals_certificate_a_date'),
                expired_until: null,
                isReCertificate: false,
                price:decryptedValue
            }
            reAssignCertificate.push(object);
        }
        if (localStorage.getItem('select_pals_certificate')) {
            let value = localStorage.getItem('select_pals_certificate');
            let findPals = postDetails.certificate.find((item) => item.certificate_name === 'pals');
            
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('PALS_E'), secretKey);
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            let expiredUntil = null;
            if (findPals) {
                expiredUntil = moment(findPals.expired_until).format('Y-MM-DD') ?? null;
            }
            let object = {
                certificate_name: "pals",
                active_until: null,
                expired_until: expiredUntil,
                isReCertificate: false,
                price:decryptedValue
            }
            if (value === 'pals-recertification') {
                object.isReCertificate = true;
            }
            reAssignCertificate.push(object);
        }
        tempPostDetails.re_assign_certificate = reAssignCertificate;
        return tempPostDetails;
    }

    const handleSubmit = async () => {
        setLoading(true);
        if (await validation()) {
            try {
                let finalPostDetails = makePostDetails();
                // Retrieve values from localStorage and convert them to numbers

                const bls = localStorage.getItem('BLS');
                const blsdecryptedValue = bls ? CryptoJS.AES.decrypt(bls, secretKey).toString(CryptoJS.enc.Utf8) : null;
                const bls_e = localStorage.getItem('BLS_E');
                const bls_edecryptedValue = bls_e? CryptoJS.AES.decrypt(bls_e, secretKey).toString(CryptoJS.enc.Utf8) : null;
                const acls = localStorage.getItem('ACLS');
                const aclsdecryptedValue = acls ? CryptoJS.AES.decrypt(acls, secretKey).toString(CryptoJS.enc.Utf8) : null;
                const acls_e = localStorage.getItem('ACLS_E');
                const acls_edecryptedValue = acls_e ? CryptoJS.AES.decrypt(acls_e, secretKey).toString(CryptoJS.enc.Utf8) : null;
                const pals = localStorage.getItem('PALS');
                const palsdecryptedValue = pals ? CryptoJS.AES.decrypt(pals, secretKey).toString(CryptoJS.enc.Utf8) : null;
                const pals_e = localStorage.getItem('PALS_E');
                const pals_edecryptedValue = pals_e ? CryptoJS.AES.decrypt(pals_e, secretKey).toString(CryptoJS.enc.Utf8) : null;


                const certificateAmount1 = parseFloat(blsdecryptedValue) || 0;
                const certificateAmount2 = parseFloat(bls_edecryptedValue) || 0;
                const certificateAmount3 = parseFloat(aclsdecryptedValue) || 0;
                const certificateAmount4 = parseFloat(acls_edecryptedValue) || 0;
                const certificateAmount5 = parseFloat(palsdecryptedValue) || 0;
                const certificateAmount6 = parseFloat(pals_edecryptedValue) || 0;

                // Sum the values
                const totalAmount = certificateAmount1 + certificateAmount2 + certificateAmount3 + certificateAmount4 + certificateAmount5 + certificateAmount6;
                finalPostDetails.purchase_amount = totalAmount;

                if (getcurrentBalance.current_balance >= totalAmount) {
                    await saveMember(finalPostDetails);
                } else {
                    if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'bls')) {
                        // If not present, set the default value for BLS certificate
                        finalPostDetails.certificate.push({
                            certificate_name: 'bls',
                            is_selected: true,
                            active_until: null,
                            expired_until: null,
                            is_na: true,
                        });
                    }
                
                    // Check if ACLS certificate is not present in the certificate array
                    if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'acls')) {
                        // If not present, set the default value for ACLS certificate
                        finalPostDetails.certificate.push({
                            certificate_name: 'acls',
                            is_selected: true,
                            active_until: null,
                            expired_until: null,
                            is_na: true,
                        });
                    }
                    // Check if Pals certificate is not present in the certificate array
                    if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'pals')) {
                        // If not present, set the default value for pals certificate
                        finalPostDetails.certificate.push({
                            certificate_name: 'pals',
                            is_selected: true,
                            active_until: null,
                            expired_until: null,
                            is_na: true,
                        });
                    }
                    // localStorage.setItem('CertificateAmount',totalAmount.toFixed(2));
                    const encryptedAmount = CryptoJS.AES.encrypt(totalAmount.toString(), secretKey).toString();
                    localStorage.setItem('CertificateAmount',encryptedAmount);
                    setAddTeamMemberDetail(finalPostDetails);
                    toast.error("You have insufficient balance for this certificate please add fund in your wallet.");
                    // localStorage.setItem('selfData',JSON.stringify(finalPostDetails));
                    const selfAssignEncrypted = CryptoJS.AES.encrypt(JSON.stringify(finalPostDetails), secretKey).toString();
                    localStorage.setItem('selfData', selfAssignEncrypted);
                    setAddFund(true);
                }
            } catch (error) {
                setLoading(false);
                setErrorMessages({
                    ...errorMessages,
                    ...{
                        apiError: error.message ?? "Something went wrong while adding member, Please try again or later."
                    }
                });
            }
        }
        setLoading(false);
    }

    const saveMember = async (finalPostDetails) => {
        if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'bls')) {
            // If not present, set the default value for BLS certificate
            finalPostDetails.certificate.push({
                certificate_name: 'bls',
                is_selected: true,
                active_until: null,
                expired_until: null,
                is_na: true,
            });
        }
    
        // Check if ACLS certificate is not present in the certificate array
        if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'acls')) {
            // If not present, set the default value for ACLS certificate
            finalPostDetails.certificate.push({
                certificate_name: 'acls',
                is_selected: true,
                active_until: null,
                expired_until: null,
                is_na: true,
            });
        }
        // Check if Pals certificate is not present in the certificate array
        if (!finalPostDetails.certificate.some(cert => cert.certificate_name === 'pals')) {
            // If not present, set the default value for pals certificate
            finalPostDetails.certificate.push({
                certificate_name: 'pals',
                is_selected: true,
                active_until: null,
                expired_until: null,
                is_na: true,
            });
        }
        const {
            responseCode: addTeamMember
        } = await AddSelfAssignCertificate(finalPostDetails);
        
        if (addTeamMember.data.status === 'success') {
            removeItemsFromLocalStorage();
            // localStorage.setItem('self-assign-course', JSON.stringify(window.location.pathname));
            window.location.href = "/manager/teams";
        } else {
            setErrorMessages({
                ...errorMessages,
                ...{
                    apiError: addTeamMember.data.message
                }
            });
            setLoading(false);
        }
        if (addTeamMember.data.status === 'error') {
            setErrorMessages('');
            toast.error('In order to assign certification course to user please purchase it.');
        }
    }

    const handleRadio = (e) => {
        const value = e.target.value
        setBlsCertificate({
            ...blsCertificate, ...{
                active_until: null,
                expired_until: null,
                is_na: false,
            }
        });
        setActiveOpenDate(false)
        setInactiveOpenDate(false)
        if (value === 'active') {
            setActiveOpenDate(!activeOpenDate)
            setActiveaclsOpenDate(false)
            setActivepalsOpenDate(false)
            setInActivepalsOpenDate(false)
            setInctiveaclsOpenDate(false)
        } else if (value === 'expired') {
            setInactiveOpenDate(!inactiveOpenDate)
            setInActivepalsOpenDate(false)
            setInctiveaclsOpenDate(false)
            setActiveaclsOpenDate(false)
            setActivepalsOpenDate(false)
        } else if (value === 'na') {
            setActivepalsOpenDate(false)
            setInActivepalsOpenDate(false)
            setActiveaclsOpenDate(false)
            setInActivepalsOpenDate(false)
        }
    }
    const handleACLSRadio = (e) => {
        setAclsCertificate({
            ...aclsCertificate, ...{
                active_until: null,
                expired_until: null,
                is_na: false
            }
        })
        const value = e.target.value
        setActiveaclsOpenDate(false)
        setInctiveaclsOpenDate(false)
        if (value == 'active') {
            setActiveaclsOpenDate(!activeaclsOpenDate)
            setActiveOpenDate(false)
            setInactiveOpenDate(false)
            setActivepalsOpenDate(false)
            setInActivepalsOpenDate(false)
        } else if (value == 'expired') {
            setInctiveaclsOpenDate(!inactiveaclsOpenDate)
            setInActivepalsOpenDate(false)
            setInactiveOpenDate(false)
            setActivepalsOpenDate(false)
            setActiveOpenDate(false)

        } else if (value == 'na') {
            setActiveOpenDate(false)
            setInactiveOpenDate(false)
            setActivepalsOpenDate(false)
            setInActivepalsOpenDate(false)
        }
    }
    const handlePALSRadio = (e) => {
        setPalsCertificate({
            ...palsCertificate, ...{
                active_until: null,
                expired_until: null,
                is_na: false
            }
        })
        const value = e.target.value
        setActivepalsOpenDate(false)
        setInActivepalsOpenDate(false)
        if (value == 'active') {
            setActivepalsOpenDate(!activepalsOpenDate)
            setActiveaclsOpenDate(false)
            setActiveOpenDate(false)
            setInactiveOpenDate(false)
            setInctiveaclsOpenDate(false)

        } else if (value == 'expired') {
            setInActivepalsOpenDate(!inactivepalsOpenDate)
            setInactiveOpenDate(false)
            setInctiveaclsOpenDate(false)
            setActiveaclsOpenDate(false)
            setActiveOpenDate(false)
        } else if (value == 'na') {
            setActiveOpenDate(false)
            setInactiveOpenDate(false)
            setActiveaclsOpenDate(false)
            setInActivepalsOpenDate(false)
        }

    }

    return (
        <>
            <Header/>

            <section className="pt-10 pb-10 feature-promo bg-light manager-tab-listing">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-7 col-lg-7">
                            <div className="">
                                <p className="add-new-team-member-title">Add Self assign course </p>
                            </div>
                        </div>
                    </div>


                    {/* text fields */}
                    <div className="row mt-5 ">
                        <div className="col-lg-3 padding-add-teams-0">
                            <div className="d-flex add-member-teams-data">
                                    <span className="name-img-flex">
                                        <img src={Name} className="calendar-teams" alt="name1"/>
                                    </span>

                                <input type="text" className="form-control" id="first_name"
                                    name="first_name"
                                    placeholder="Name:"
                                    aria-label="name"
                                    onChange={(e) => setPostDetails({...postDetails, 'first_name': e.target.value})}
                                    value={`${userDetails.first_name ? userDetails.first_name.charAt(0).toUpperCase() + userDetails.first_name.slice(1) : ''}`}
                                    readOnly
                                />

                            </div>
                            {/* <p className="error">{errorMessages.first_name && (errorMessages.first_name)}</p> */}
                        </div>
                        <div className="col-lg-3 padding-add-teams-0">
                            <div className="d-flex add-member-teams-data">
                                    <span className="name-img-flex">
                                        <img src={Name} className="calendar-teams" alt="name1"/>
                                    </span>

                                <input type="text" className="form-control" id="last_name"
                                    name="last_name"
                                    placeholder="Name:"
                                    aria-label="name"
                                    onChange={(e) => setPostDetails({...postDetails, 'last_name': e.target.value})}
                                    value={`${userDetails.last_name ? userDetails.last_name.charAt(0).toUpperCase() + userDetails.last_name.slice(1) : ''}`}
                                    readOnly
                                />

                            </div>
                            {/* <p className="error">{errorMessages.first_name && (errorMessages.first_name)}</p> */}
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex add-member-teams-data">
                                    <span className="name-img-flex">
                                        <img src={Mail} className="calendar-teams" alt="mail-1"/>
                                    </span>
                                <input type="email" className="form-control" id="email"
                                       name="email"
                                       placeholder="Email:"
                                       aria-label="email"
                                    //    onChange={(e) => setPostDetails({...postDetails, 'email': e.target.value})}
                                    value={userDetails.email} readOnly
                                />
                            </div>
                            {/* <p className="error">{errorMessages.email && (errorMessages.email)}</p> */}

                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex add-member-teams-data">
                                    <span className="name-img-flex">
                                        <img src={Title} className="calendar-teams" alt="titlt-1"/>
                                    </span>
                                <input type="text"
                                       className="form-control"
                                       id="title"
                                       placeholder="Title:"
                                       aria-label="title"
                                    //    onChange={(e) => setPostDetails({...postDetails, 'title': e.target.value})}
                                    value={userDetails.title} readOnly
                                />

                            </div>
                            {/* <p className="error">{errorMessages.title && ('Title is Required')}</p> */}
                        </div>
                    </div>
                    <p className="error">{errorMessages.apiError}</p>
                    {/* certificate fields */}
                    <div className="row pt-5">
                        <p className="error">{errorMessages.certificate}</p>
                        <div className="card">
                            <div>
                                <p className="add-new-team-member-title sub mt-3">BLS (Basic Life Support) </p>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text active-until">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="bls_certificate"
                                                       id="bls_certificate_active"
                                                       value="active"
                                                       checked={!!blsCertificate.active_until}
                                                       onClick={handleRadio}
                                                /> Active Until
                                            </label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                        <a onClick={() => setActiveOpenDate(!activeOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                                alt="Calendar-1" />
                                                        </a>
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    open={activeOpenDate}
                                                    selected={
                                                        blsCertificate.active_until !== null
                                                        ? moment(blsCertificate.active_until).tz('America/New_York').toDate()
                                                        : blsCertificate.active_until
                                                    }
                                                    onChange={(e) => handleOnBLSChange(e, 'bls_certificate_a_date')}
                                                    readOnly={true}
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="bls_certificate"
                                                       id="bsl_certificate_expired"
                                                    onClick={handleRadio}
                                                       checked={!!blsCertificate.expired_until}
                                                       value="expired"
                                                /> Expired
                                            </label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                        <a onClick={() => setInactiveOpenDate(!inactiveOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                                alt="Calendar-1" />
                                                        </a>
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    onChange={(e) => handleOnBLSChange(e, 'bls_certificate_e_date')}
                                                    // selected={blsCertificate.expired_until !== null ? new Date(blsCertificate.expired_until) : blsCertificate.expired_until}
                                                    selected={
                                                        blsCertificate.expired_until !== null
                                                        ? moment(blsCertificate.expired_until).tz('America/New_York').toDate()
                                                        : blsCertificate.expired_until
                                                    }
                                                    open={inactiveOpenDate}
                                                    readOnly={true}
                                                    maxDate={maxDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="radio-dropdown">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="bls_certificate"
                                                       onChange={(e) => handleOnBLSChange(e, 'bls_certificate')}
                                                       id="gridRadios31"
                                                       value="na"
                                                /> N/A</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="card">
                            <div>
                                <p className="add-new-team-member-title sub mt-3">ACLS (Advanced Cardiovascular Life
                                    Support) </p>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text active-until">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="acls_certificate"
                                                       id="gridRadios31"
                                                       checked={!!aclsCertificate.active_until}
                                                    onClick={handleACLSRadio}
                                                       value="active"
                                                /> Active Until</label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                        <a onClick={() => setActiveaclsOpenDate(!activeaclsOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                                alt="Calendar-1" />
                                                        </a>
                                                        
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    open={activeaclsOpenDate}
                                                    onChange={(e) => handleACLSChange(e, 'acls_certificate_a_date')}
                                                    // selected={aclsCertificate.active_until !== null ? new Date(aclsCertificate.active_until) : aclsCertificate.active_until}
                                                    selected={
                                                        aclsCertificate.active_until !== null
                                                        ? moment(aclsCertificate.active_until).tz('America/New_York').toDate()
                                                        : aclsCertificate.active_until
                                                    }
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="acls_certificate"
                                                       id="gridRadios31"
                                                       checked={!!aclsCertificate.expired_until}
                                                       onClick={handleACLSRadio}
                                                       value="expired"
                                                /> Expired</label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                        <a onClick={() => setInctiveaclsOpenDate(!inactiveaclsOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                            alt="Calendar-1" />
                                                        </a>
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    open={inactiveaclsOpenDate}
                                                    onChange={(e) => handleACLSChange(e, 'acls_certificate_e_date')}
                                                    // selected={aclsCertificate.expired_until !== null ? new Date(aclsCertificate.expired_until) : aclsCertificate.expired_until}
                                                    selected={
                                                        aclsCertificate.expired_until !== null
                                                        ? moment(aclsCertificate.expired_until).tz('America/New_York').toDate()
                                                        : aclsCertificate.expired_until
                                                    }
                                                    readOnly
                                                    maxDate={maxDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="radio-dropdown">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="acls_certificate"
                                                       id="gridRadios31"
                                                       onChange={(e) => handleACLSChange(e, 'acls_certificate')}
                                                       value="na"
                                                /> N/A</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="card">
                            <div>
                                <p className="add-new-team-member-title sub mt-3">PALS (Pediatric Advanced Life
                                    Support) </p>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text active-until">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="pals_certificate"
                                                       id="gridRadios31"
                                                       checked={!!palsCertificate.active_until}
                                                    onClick={handlePALSRadio}
                                                       value="active"
                                                /> Active Until </label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                        <a onClick={() => setActivepalsOpenDate(!activepalsOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                                alt="Calendar-1" />
                                                        </a>
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    open={activepalsOpenDate}
                                                    readOnly
                                                    onChange={(e) => handleOnPALSChange(e, 'pals_certificate_a_date')}
                                                    // selected={palsCertificate.active_until !== null ? new Date(palsCertificate.active_until) : palsCertificate.active_until}
                                                    selected={
                                                        palsCertificate.active_until !== null
                                                        ? moment(palsCertificate.active_until).tz('America/New_York').toDate()
                                                        : palsCertificate.active_until
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="team-datePicker">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="pals_certificate"
                                                       id="gridRadios31"
                                                       checked={!!palsCertificate.expired_until}
                                                    onClick={handlePALSRadio}
                                                       value="expired"
                                                /> Expired</label>
                                        </div>
                                        <div className="col-lg-8 position-relative">
                                            <div className="d-flex">
                                                    <span className="calendar-img-flex">
                                                    <a onClick={() => setInActivepalsOpenDate(!inactivepalsOpenDate)}>
                                                            <img src={Calendar} className="calendar-teams"
                                                                alt="Calendar-1" />
                                                        </a>
                                                    </span>
                                                <DatePicker
                                                    dateFormat={commonDateFormat}
                                                    className="date-picker"
                                                    placeholderText="__/__/__"
                                                    onChange={(e) => handleOnPALSChange(e, 'pals_certificate_e_date')}
                                                    open={inactivepalsOpenDate}
                                                    readOnly
                                                    maxDate={maxDate}
                                                    // selected={palsCertificate.expired_until !== null ? new Date(palsCertificate.expired_until) : palsCertificate.expired_until}
                                                    selected={
                                                        palsCertificate.expired_until !== null
                                                        ? moment(palsCertificate.expired_until).tz('America/New_York').toDate()
                                                        : palsCertificate.expired_until
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="radio-dropdown">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="label-active-text">
                                                <input className="form-check-input"
                                                       type="radio"
                                                       name="pals_certificate"
                                                       id="gridRadios31"
                                                       onChange={(e) => handleOnPALSChange(e, 'pals_certificate')}
                                                       value="na"
                                                /> N/A
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="submit-btn-add-team">
                            <button type="submit" className="btn btn-primary mt-4" onClick={handleSubmit} disabled={loading}>
                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Submit"}
                            </button>
                        </div>
                    </div>

                </div>
            </section>
            <ReAssignCourseConfirmation
                show={openReAssignModal}
                certificateName={selectedCertificate}
                onHide={() => {
                    setOpenReAssignModal(!openReAssignModal)
                }}
                CertificateType={CertificateType}
            />
            <ExpiredCertificateModal
                show={openExpiredModal}
                certificateName={selectedCertificate}
                onHide={() => {
                    setOpenExpiredModal(!openExpiredModal)
                }}
                CertificateType={CertificateType}
            />
            <ProcessToAddFund
                addTeamMemberDetail={addTeamMemberDetail}
                show={addFund}
                onHide={() => setAddFund(false)}
                successUrl={`${window.location.origin}/payment-success`}
            />

            <ToastContainer />
        </>
    )

}

export default SelfAssignAdd;
