import {useState} from "react";

import Team from '../api/Teams';

const UseManagerUser = () => {
    const [isAddTeamMember, setIsAddTeamMember] = useState(false);
  
    const AddManagerEmployee = async (data) => {
      setIsAddTeamMember(true);
  
      let responseCode;
  
      try {
          responseCode = await Team.createManagerUser(data);
      } catch (error) {
        responseCode = error.response;
      }
  
      setIsAddTeamMember(false);
  
      return { responseCode };
    };
  
    return { isAddTeamMember, AddManagerEmployee };
  };


export default UseManagerUser;