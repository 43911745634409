import { useState, useEffect } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";

const UseToDouserDetails = ({user_id,certificate_id}) => {
    const [getTodoDetails, setGetTodoDetails] = useState([]);
  
    useEffect(() => {
      const getgetTodoDetails = async () => {
        try {
          const response = await Superadmin.getToDoList({
            params: { user_id ,certificate_id },
          });
  
          setGetTodoDetails(response.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      getgetTodoDetails();
    }, []);
  
    return {getTodoDetails };
   
}



export default UseToDouserDetails;