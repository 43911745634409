import { useState, useEffect } from "react";

import Payment from "../api/Payment";
// const UseSendOrderDetails = () => {
//     const [sendInvoice, setSendInvoice] = useState([]);
  
//     useEffect(() => {
//         const sendOrder = async () => {
//             try {
//             const response = await Payment.sendOrderDetails();
//             setSendInvoice(response.data);
//             } catch (error) {
//             console.log(error);
//             }
//         };
//       sendOrder();
//     }, []);
  
//     return {sendInvoice };
   
// }



// export default UseSendOrderDetails;

// import { useState } from "react";
// import CertificateTypes from '../api/CertificateTypes';

const UseSendCertificate = () => {
    const [sendInvoice, setSendInvoice] = useState(false);
  
    const sendOrder = async (data) => {
      setSendInvoice(true);
  
      let responseCode;
  
      try {
        const response = await Payment.sendOrderDetails(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setSendInvoice(false);
  
      return { responseCode };
    };
  
    return { sendInvoice, sendOrder };
  };


export default UseSendCertificate;