import React, { useState } from "react";
import logo from '../../../assets/web-images/images/Pulse-Logo.svg'
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

export default function Header() {

    const [modalShow, setModalShow] = useState(false);
    const [showData, setShowData] = useState([]);

    const { user_id } = useParams();
    const { certificate_id } = useParams();
    const { manager_id } = useParams();
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const cookies = new Cookies();

    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    
    const logOut = () => {
        cookies.remove('token', { path: '/' });
        cookies.remove('user', { path: '/' });
        cookies.remove('role', { path: '/' });
        localStorage.clear();

        setTimeout(function () {
            window.location.href = "/";
        }, 1000);
    }

    const showUserName = userDetails !== '' ? `${userDetails.first_name.charAt(0)}${userDetails.last_name.charAt(0)}` : 'SA';

    return (
        <>
            <header className="main-header position-absolute w-100" id="border-color">
                <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10" id='navbar-padding'>
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src={logo} alt="logo" className="logo-img" height='65' />
                        </Link>
                        <Link className="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop">
                            <i className="flaticon-menu" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" data-bs-toggle="offcanvas" role="button"></i>
                        </Link>
                        <div className="clearfix"></div>
                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li className="nav-item">
                                    <Link className={(currentPath === '/super-admin' || currentPath === `/super-admin/view-details/${user_id}/${certificate_id}` ) ? 'nav-link active' : 'nav-link'} to="/super-admin">To-Do List</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={currentPath === "/super-admin/sales-report" ? "nav-link active" : "nav-link"} to="/super-admin/sales-report">Sales Report</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={(currentPath === '/super-admin/users' || currentPath === `/super-admin/add-manager` || currentPath === `/super-admin/coupon-code-list`|| currentPath === `/super-admin/manager-users/${manager_id}` || currentPath === `/super-admin/${manager_id}/add-manager-employee`) ? 'nav-link active' : 'nav-link'} to="/super-admin/users">Users</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={(currentPath === '/super-admin/discount-list') ? 'nav-link active' : 'nav-link'} to="/super-admin/discount-list">Settings</Link>
                                </li>
                                <li className="nav-item">
                                    <a className= "nav-link" href="" onClick={logOut}>Logout</a>
                                </li>
                                <li>
                                    <Link className="nav-link user-name" to='/super-admin/profile'>{showUserName}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
                    <div className="offcanvas-header d-flex align-items-center mt-4">
                        <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="flaticon-cancel"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body z-10">
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                                <a className={currentPath === "/super-admin" ? "nav-link active" : "nav-link"} href='/super-admin'>To-Do List</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className={currentPath === "/super-admin/sales-report" ? "nav-link active" : "nav-link"} href="/super-admin/sales-report">Sales Report</a>
                            </li>
                            <li className="nav-item dropdown">
                                {/* <a className={currentPath === "/super-admin/users" ? "nav-link active" : "nav-link"} href="/super-admin/users">User</a> */}
                                <a className={(currentPath === '/super-admin/users' || currentPath === `/super-admin/add-manager`) ? 'nav-link active' : 'nav-link'} href="/super-admin/users">Users</a>
                            </li>
                            <li className="nav-item">
                                    <a className={(currentPath === '/super-admin/discount-list') ? 'nav-link active' : 'nav-link'} href="/super-admin/discount-list">Settings</a>
                                </li>
                            <li className="nav-item dropdown">
                            <a className= "nav-link" onClick={logOut} href="">Logout</a>
                            </li>
                            <li>
                                <a className="nav-link user-name" to='/super-admin/profile'> {showUserName}</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </header>


           
        </>
    )
}