import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Header from "../../../components/emplyoeeSite/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import UseFinalExamRessult from '../../../hooks/UseFinalExamRessult';
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../components/website/CertificateCard";


export default function FianlExamResult() {

    const slug = useParams();
    const { test_name } = slug;
    const [getQuestions, setGetQuestions] = useState([]);
    const [getTotalIncorrect, setGetTotalIncorrect] = useState();
    const [getTotalCorrect, setGetTotalCorrect] = useState();
    const [getCertificateType, setGetCertificateType] = useState();
    const [getExamResult, setGetExamResult] = useState();
    const [statusSurveyExam, setStatusSurveyExam] = useState();


    const { getQuestions: QuestionApi } = UseFinalExamRessult({
        test_name,
    });


    const scrollToBottom = () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight - 1500,
          behavior: 'smooth',
        });
      };

    useEffect(() => {
        fetchChapterName();
    });
    // useEffect(() => {
    //     const scrollTimer = setTimeout(() => {
    //         // scrollToBottom();
    //         window.scrollTo({
    //             top: document.documentElement.scrollHeight - 1100,
    //             behavior: 'smooth',
    //           });
    //       }, 1000);
    //       return () => {
    //         clearTimeout(scrollTimer);
    //       };
    // },[]);

    useEffect(() => {
        const scrollTimer = setTimeout(() => {
          scrollToBottom();
        }, 2000); // Adjust the delay (in milliseconds) as needed
      
        return () => {
          clearTimeout(scrollTimer);
        };
      }, []);
      useEffect(() => {
        const scrollTimer = setTimeout(() => {
          scrollToBottom();
        }, 1000);
      
        return () => {
          clearTimeout(scrollTimer);
        };
      }, []);
            

    const fetchChapterName = async () => {

        if (QuestionApi.status === "success") {
            setGetQuestions(QuestionApi.data);
            setGetTotalIncorrect(QuestionApi.total_incorrect);
            setGetTotalCorrect(QuestionApi.total_correct);
            setGetCertificateType(QuestionApi.certificate_type_slug);
            setGetExamResult(QuestionApi.result);
            setStatusSurveyExam(QuestionApi.status_survey_exam)
        }
    }



    return (
        <>
            <Header />,
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                        <div className="step-form">

                            <div className="card">
                                <>


                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <p className="sample-test-review">
                                                {test_name.replace(/_/g, ' ').replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        getQuestions.map((item, index) => (
                                            <>
                                                <div className="row pt-5">
                                                    <p className="test-res-qus test-sample"> 
                                                    {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: item.question }} />
                                                    </p>

                                                    {
                                                        item.correct_answer === item.answer_value ?
                                                            <>
                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={item.option_1 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={item.option_2 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={item.option_3 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change mb-5'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={item.option_4 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={
                                                                            item.option_1 === item.answer_value ?
                                                                                "rounded-box answer-check-btn wrong-answer"
                                                                                : item.option_1 === item.correct_answer
                                                                                    ? "rounded-box answer-check-btn right-answer"
                                                                                    : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={
                                                                            item.option_2 === item.answer_value ?
                                                                                "rounded-box answer-check-btn wrong-answer"
                                                                                : item.option_2 === item.correct_answer
                                                                                    ? "rounded-box answer-check-btn right-answer"
                                                                                    : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={
                                                                            item.option_3 === item.answer_value ?
                                                                                "rounded-box answer-check-btn wrong-answer"
                                                                                : item.option_3 === item.correct_answer
                                                                                    ? "rounded-box answer-check-btn right-answer"
                                                                                    : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-6 qus-radio-btn-change mb-5'>
                                                                    <div className="res-sample-test-1">
                                                                        <div className={
                                                                            item.option_4 === item.answer_value ?
                                                                                "rounded-box answer-check-btn wrong-answer"
                                                                                : item.option_4 === item.correct_answer
                                                                                    ? "rounded-box answer-check-btn right-answer"
                                                                                    : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }

                                                </div>
                                                <hr />
                                            </>
                                        ))


                                    }

                                    <div className="row">
                                        <div className="col-lg-7"></div>
                                        <div className="col-lg-5">
                                        
                                            <div className="box-row-res start-test-res">
                                                <div>
                                                    <i className="fa fa-check pass-check"></i> <span className="pass-qus">{getTotalCorrect}</span>
                                                </div>
                                                <div className="fail-qus">
                                                    <div className="fail-qus-res ml-15">

                                                        <i className="fa fa-times fail-titme" aria-hidden="true"></i> <span className="pass-qus">{getTotalIncorrect}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-7">
                                            {/* {getExamResult !== 'Failed' &&
                                                (
                                                    <>
                                                    
                                                    <p className="thank-you-title">Thank you for the test!</p>
                                                    <p className="thank-you-sub-title">You will receive certificate on your mailing address.</p>
                                                    </>
                                                )
                                            } */}
                                        </div>
                                        <div className="col-lg-5">

                                            {getExamResult !== 'Failed' ?
                                                <>

                                                    <div className="test-result-btn-green">
                                                        <a>Test Result: Pass</a>
                                                    </div>

                                                </> : <>

                                                    <div className="test-result-btn">
                                                        <a>Test Result: Fail</a>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        <div className="text-center pt-3">

                                            {getExamResult !== 'Failed' &&
                                                <>
                                                {statusSurveyExam  === 'error' ?
                                                    <>
                                                        <a className="btn btn-primary mt-4 home-page-start-test-btn-complete" href={`/employee/certificate-survey/${test_name}`}>Complete survey to get certification card </a>
                                                    </> :
                                                    <>  </>
                                                }
                                                </>
                                            }

                                        </div>
                                        <div className="text-end">
                                        {getExamResult === 'Failed' &&
                                                <>
                                                    {/* <a className="btn btn-primary mt-4 home-page-start-test-btn" href={`/employee/certificate-survey/${test_name}`}>Evaluation & Credit Request Form </a> &nbsp;
                                                    <a className="btn btn-primary mt-4 home-page-start-test-btn" href={`/employee`}>Home</a>
                                                </> : <> */}
                                                <a className="btn btn-primary mt-4 home-page-start-test-btn" href={`/employee/start-final-test/${test_name}`}>Retake Exam</a>
                                                </>
                                            }

                                            
                                            
                                        </div>
                                    </div>
                                </>



                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                   <CertificateCard />
                </div>
            </section>

            <Footer /> <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )
}