import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import UseRemoveMember from "../../../../hooks/manager/UseRemoveMember";

function RemoveMember(props) {
    const [statusChange, setStatusChanges] = useState('inactive');
    const { isRemoveMember, RemoveMemberData } = UseRemoveMember();

    const handleRemoveMember = async (e) => {
        const { MemberId } = props;
        e.preventDefault();

        const {
            responseCode: removeMember
        } = await RemoveMemberData({ 'user_id': MemberId, 'user_status': statusChange });

        if (removeMember.status === 'success') {
            window.history.back();
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >

                <Modal.Body>
                    <p className="remove-class-title">
                        

                        {props.userCertificateStatus.status === 'success' ?
                            <>
                                This team member has assigned courses. Courses must be rescinded before they can be removed from your team.
                            </> :
                            <>
                                Are you sure you want to remove this member from your team?    
                            </>
                        }

                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                {props.userCertificateStatus.status === 'success' ?
                                    <>
                                        <button className="remove-yes-btn" onClick={props.onHide}>Ok</button>
                                    </> :
                                    <>
                                        <button className="remove-yes-btn" onClick={handleRemoveMember}>Yes</button>
                                        <button className="remove-no-btn" onClick={props.onHide} >No</button>
                                    </>
                                }
                               
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default RemoveMember;