import { useState } from "react";

import Login from '../api/Auth';

const UseLogin = () => {
    const [isLoginApi, setIsLoginApi] = useState(false);
  
    const login = async (data) => {
      setIsLoginApi(true);
  
      let responseCode;
  
      try {
        const response = await Login.login(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsLoginApi(false);
  
      return { responseCode };
    };
  
    return { isLoginApi, login };
  };


export default UseLogin;