import axiosConfig from './Config';


const Auth ={
    login: (data) => axiosConfig.post(`login`, data),
    signUp: (data) => axiosConfig.post(`signup`, data),
    otherDetails: (data) => axiosConfig.post(`add-user-details`, data),
    forgotPassword: (data) => axiosConfig.post(`forgot-password`, data),
    checkForgotPassword: (data) => axiosConfig.post(`check-forgotpassword-link`, data),
    resetPassword: (data) => axiosConfig.post(`update-password`, data),


}

export default Auth;