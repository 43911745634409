import React from "react";
import logo from '../../../../assets/web-images/images/Pulse-Logo.svg';
import moment from "moment";

export default function PediatricAdvancedLifeSupport(props) {


    const currentDate = props.activeDate;
    const expirationDate = moment(props.activeDate).add(2, 'years');

    return (
        <>
            <div className="row">
                <div className="col-lg-12 pt-3 pb-3 ">
                    <div className="card  palsCertificate" id="bls-front-img">
                        <div className="text-center">
                            <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                            <h5>PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>
                            <p>This card certifies that this individual has successfully completed the
                                course requirements and cognitive evaluation in accordance with the
                                curriculum of the Pulse Certifications Pediatric Advanced Life
                                Support (PALS) course, based on ECC guidelines.</p>
                            <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{props.item}</span></h6>
                            <div className="d-flex flex-div">
                                <h6>Issued: <span>{moment(currentDate).format('M/D/YYYY')}</span></h6>  <h6>Expires: <span>{moment(expirationDate).format('M/D/YYYY')}</span></h6>
                            </div>

                            <h4>PULSECERTIFICATIONS.COM</h4>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6"></div>

            </div>
        </>
    )
}