import axiosConfig from './Config';


const Questions ={
    create: (body) => axiosConfig.post(`add-start-test-answer`, body),
    getResult: ({ params: {test_name,topic_id} }) => axiosConfig.get(`get-questions-answer/${test_name}/${topic_id}`),
    
    //final exam
    getFinalExam: ({ params: {test_name} }) => axiosConfig.get(`get-final-exam-questions/${test_name}`),
    createFinal: (body) => axiosConfig.post(`add-final-exam-answer`, body),
    getFinalExamResult: ({ params: {test_name} }) => axiosConfig.get(`get-final-questions-answer/${test_name}`),
}


export default Questions;