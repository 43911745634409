import React, { useEffect, useState } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import Search from '../../../assets/manager-images/images/Search.svg';
import Arrow from '../../../assets/super-admin-images/images/Arrow.svg';
import AllUserlisting from "../../../hooks/superAdmin/Userlisting";
import { Watch } from "react-loader-spinner";
import swal from 'sweetalert';
import UseUserDelete from "../../../hooks/superAdmin/UseUserDelete";
import ScrollToTop from 'react-scroll-to-top';

function UserPage() {

    const [listuser, setListuser] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState('');
    const { alluser, loading, recallApiWithNewParams, loadMoreLoading } = AllUserlisting(searchParams, pageParams);
    const [activeTab, setActiveTab] = useState(2);
    const [roleIds, setRoleId] = useState(2);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (alluser.status === "success") {
            if (alluser.data.data.length > 0) {
                // setListuser([...listuser, ...alluser.data.data]);

                const combinedArray = [...listuser, ...alluser.data.data];
                const uniqueEmailMap = new Map();
                combinedArray.forEach(user => {
                    // Use the email as the key in the Map
                    const email = user.email;

                    // If the email is not already in the Map, add it with the user data
                    if (!uniqueEmailMap.has(email)) {
                        uniqueEmailMap.set(email, user);
                    }
                });

                const uniqueArray = Array.from(uniqueEmailMap.values());
                setListuser(uniqueArray);

                setPerPage(alluser.data);
                setCurrentPage(alluser.data.current_page);
            }
        }
    }, [alluser]);



    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
            user_name: userName,
            role_id: roleIds
        }
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }

    const handleSearch = (e) => {

        const searchTerm = e.target.value;
        if (e.keyCode === 13) {
            if (searchTerm.length >= 3) {

                setListuser([]);
                setUserName(e.target.value)
                const params = {
                    user_name: e.target.value,
                    role_id: roleIds
                }
                recallApiWithNewParams(params);
            } else {
                setUserName(searchTerm)
                if (searchTerm === '') {
                    const params = {
                        user_name: e.target.value,
                        role_id: roleIds
                    }
                    recallApiWithNewParams(params);
                }
            }
        }
    }

    const handleTabChanges = (roleId) => {
        if (roleId !== activeTab) {
            setListuser([]);
            setActiveTab(roleId);
            setRoleId(roleId)
            const params = {
                user_name: userName,
                role_id: roleId
            }
            recallApiWithNewParams(params);
        }
    }

    const { isDeleteUser, DeleteUser } = UseUserDelete();

    const OnDeleteUser = async (userId) => {
        const userName = roleIds === 2 ? 'manager' : 'user';

        swal({
            title: "Warning!",
            text: `Are you sure want to delete this ${userName}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {

                    const {
                        responseCode: deleteUserData
                    } = await DeleteUser({ 'user_id': userId });

                    if (deleteUserData.status === 'success') {
                        swal(`The ${userName} has been deleted!`, {
                            icon: "success",
                        }).then(() => {
                            window.location.href = "/super-admin/users";
                        });
                    } else {
                        console.log('error');
                    }

                }
            });
    };

    return (
        <>
            <Header />

            <section className="pt-10 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-lg-4 col-4 progress-search-col">
                            <div className="d-flex" >
                                <span className="track-progress-span">
                                    <img src={Search} width='20' height='20' alt="search" />
                                </span>
                                <input type="text" className="progress-search-btn" placeholder="Search" onKeyDown={handleSearch} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-4 progress-search-col" id="to-do-list-tab">
                            <div className="container">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button className="nav-link active pending-list" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" onClick={() => handleTabChanges(2)}>Manager</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link completed-list" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" onClick={() => handleTabChanges(3)}>User</button>
                                    </li>
                                </ul>


                            </div>
                        </div>
                        <div className="col-lg-4 text-end">
                            {activeTab === 2 && (
                                <>
                                    <a className="add-user-super-admin" href="/super-admin/add-manager">Add Manager</a>
                                    <a className="add-user-super-admin coupon-code-list-btn" href="/super-admin/coupon-code-list">Coupon Code List</a>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="tab-content">
                            <div id="tab1" className="tab-pane fade show active">
                                <table className="table" id="todo-list-table">
                                    <thead className="thead-course">
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Title</th>
                                            <th className="text-center">Organization</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading && (
                                            <>

                                                {
                                                    listuser.map((ele) => {
                                                        return (
                                                            <tr className="tr-user" key={ele.id}>
                                                                <td className="text-center text-capitalize" data-label="Name">{ele.first_name} {ele.last_name}</td>
                                                                <td className="text-center" data-label='Title'>{ele.title !== null ? <>{ele.title}</> : <>-</>}</td>
                                                                <td className="text-center" data-label='Organization'>{ele.organization !== null ? <>{ele.organization}</> : <>-</>}</td>
                                                                <td className="text-center" data-label='Status'>{ele.user_status}</td>
                                                                <td className="text-center" data-label='Email'>{ele.email}</td>
                                                                <td className="text-center" data-label='Action'>
                                                                    <a className="edit-coupon-page" href={`/super-admin/manager-users/${ele.user_id}`}><i className="fa fa-eye" aria-hidden="true"></i></a> &nbsp;&nbsp;
                                                                    <a className="edit-coupon-page" href="#" onClick={() => OnDeleteUser(ele.user_id)} ><i className="fa fa-trash" aria-hidden="true"></i></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )}

                                    </tbody>
                                </table>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loading && (
                                        <Watch
                                            height="80"
                                            width="80"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loadMoreLoading && !loading && (
                                        <Watch
                                            height="70"
                                            width="70"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>

                                {listuser.length > 0 &&
                                    <>
                                        {currentPage < perPage.last_page &&
                                            (<button onClick={loadMore} className="load-more" type="button">
                                                {loadMoreLoading ? 'Loading...' : 'Load More'}
                                            </button>)
                                        }
                                    </>
                                }

                                {!loading && !loadMoreLoading && (
                                    <>
                                        {listuser.length === 0 && (
                                            <>
                                                <div className="text-center">
                                                    <h5 className="valid-till">No Manager Found.</h5>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                            </div>
                            <div id="tab2" className="tab-pane fade">
                                <table className="table" id="todo-list-table">
                                    <thead className="thead-course">
                                        <tr>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Title</th>
                                            <th className="text-center">Organization</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading && (
                                            <>

                                                {
                                                    listuser.map((ele) => {
                                                        return (
                                                            <tr className="tr-user" key={ele.id}>
                                                                <td className="text-center text-capitalize" data-label="Name">{ele.first_name} {ele.last_name}</td>
                                                                <td className="text-center" data-label='Title'>{ele.title !== null ? <>{ele.title}</> : <>-</>}</td>
                                                                <td className="text-center" data-label='Organization'>{ele.organization !== null ? <>{ele.organization}</> : <>-</>}</td>
                                                                <td className="text-center" data-label='Status'>{ele.user_status}</td>
                                                                <td className="text-center" data-label='Email'>{ele.email}</td>
                                                                <td className="text-center" data-label='Action'><a className="edit-coupon-page" href="#" onClick={() => OnDeleteUser(ele.user_id)} ><i className="fa fa-trash" aria-hidden="true"></i></a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )}

                                    </tbody>
                                </table>

                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loading && (
                                        <Watch
                                            height="80"
                                            width="80"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>
                                <div className="container d-flex align-items-center justify-content-center vh-80">
                                    {loadMoreLoading && !loading && (
                                        <Watch
                                            height="70"
                                            width="70"
                                            radius="48"
                                            color="#2f99a9 "
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                </div>

                                {listuser.length > 0 &&
                                    <>
                                        {currentPage < perPage.last_page &&
                                            (<button onClick={loadMore} className="load-more" type="button">
                                                {loadMoreLoading ? 'Loading...' : 'Load More'}
                                            </button>)
                                        }
                                    </>
                                }

                                {!loading && !loadMoreLoading && (
                                    <>
                                        {listuser.length === 0 && (
                                            <>
                                                <div className="text-center">
                                                    <h5 className="valid-till">No User Found.</h5>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </>
    )

}

export default UserPage;
