import React, { useEffect, useState } from "react";
import Header from "../../../components/website/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import Mail from '../../../assets/web-images/images/Mail-White.svg';
import group1 from '../../../assets/web-images/images/Group-page-img.png';
import Plus from '../../../assets/emplyoee-images/images/Plus.svg';
import Minus from '../../../assets/emplyoee-images/images/Minus.svg';
import UseCertificateTypes from "../../../hooks/UseCertificateTypes";
import UseRequestInformation from '../../../hooks/UseRequestInformation';
import { ThreeDots } from "react-loader-spinner";
import RequestInformation from '../../../components/website/Groups/Modal/RequestInformation';
import ScrollToTop from 'react-scroll-to-top';

export default function GroupPage() {

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d+$/;
    const [RequestInfoModal, setRequestInfoModel] = useState(false);

    const [bls_courses, setBlsCourse] = useState(0);
    const [acls_courses, setAclsCourse] = useState(0);
    const [pals_courses, setPalsCourse] = useState(0);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [NameError, setNameError] = useState(null);
    const [organization_name, setOrganization] = useState('');
    const [OrganizationError, setOrganizationError] = useState(null);
    const [email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState(null);
    const [phone_number, setPhone] = useState('');
    const [PhoneError, setPhoneError] = useState(null);
    const [CourseError, setCourseError] = useState('');


    const [RequestInfo, setRequestInfo] = useState(false);
    const [blsCourse, setBlsCourse1] = useState(0);
    const [aclsCourse, setAclsCourse1] = useState(0);
    const [palsCourse, setPalsCourse1] = useState(0);
    const [loading1, setLoading1] = useState(false);
    const [names, setName1] = useState('');
    const [NameError1, setNameError1] = useState(null);
    const [organization, setOrganization1] = useState('');
    const [OrganizationError1, setOrganizationError1] = useState(null);
    const [emails, setEmail1] = useState('');
    const [EmailError1, setEmailError1] = useState(null);
    const [phone, setPhone1] = useState('');
    const [PhoneError1, setPhoneError1] = useState(null);
    const [CourseError1, setCourseError1] = useState('');



    const [listcertificate, setListcertificate] = useState([]);
    const [calculatorTotal, setCalculateTotal] = useState({
        sub_total: 0,
        total_discount: 0,
    });
    const [discount, setDiscount] = useState(0)
    const { certificateTypes: CertificateTypeGet } = UseCertificateTypes()

    const { isRequestInfo, sendRequestInformation } = UseRequestInformation();

    const blsIncrementQuantity = () => {
        setBlsCourse(bls_courses + 1);
        setCourseError('');
    };

    const blsDecrementQuantity = () => {
        if (bls_courses > 0) {
            setBlsCourse(bls_courses - 1);
        }
    };

    const aclsIncrementQuantity = () => {
        setAclsCourse(acls_courses + 1);
        setCourseError('');
    };

    const aclsDecrementQuantity = () => {
        if (acls_courses > 0) {
            setAclsCourse(acls_courses - 1);
        }
    };
    const palsIncrementQuantity = () => {
        setPalsCourse(pals_courses + 1);
        setCourseError('');
    };

    const palsDecrementQuantity = () => {
        if (pals_courses > 0) {
            setPalsCourse(pals_courses - 1);
        }
    };

    const onNameChanges = (e) => {
        setName(e.target.value);
        setNameError('');
    }
    const onOrganizationChanges = (e) => {
        setOrganization(e.target.value);
        setOrganizationError('');
    }
    const onEmailChanges = (e) => {
        const enteredEmail = e.target.value;    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmail(enteredEmail);
        if (!emailPattern.test(enteredEmail)) {
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
    }
    const onPhoneChanges = (e) => {
        const enteredPhone = e.target.value;      
        setPhone(enteredPhone);
        if (!phonePattern.test(enteredPhone)) {
          setPhoneError('Phone must contain only numbers');
        } else {
          setPhoneError('');
        }

    }

    const RequestInformationSubmit = async (e) => {
        e.preventDefault();

        let isValid = true;

        if (!name) {
            setNameError('Name is required');
            isValid = false;
        }
        if (!organization_name) {
            setOrganizationError('Organization is required');
            isValid = false;
        }
        
        if (!email) {
            setEmailError('Email is required');
            isValid = false;
        }else if (!emailPattern.test(email)) {
            setEmailError('Please enter a valid email address');
            isValid = false;
            return;
        }

        if (!phone_number) {
            setPhoneError('Phone is required');
            isValid = false;
        }else if (!phonePattern.test(phone_number)) {
            setPhoneError('Phone must contain only numbers');
            isValid = false;
            return;
        } 

        if (bls_courses < 1 && acls_courses < 1 && pals_courses < 1) {
            setCourseError('Please select At least one course.');
            isValid = false;
        }
        

      
        if (isValid) {
            setLoading(true);
            const total_certificates = bls_courses + acls_courses + pals_courses;
            try {

                const data = {
                    name, organization_name, email, phone_number, bls_courses, acls_courses, pals_courses,total_certificates
                }
                const {
                    responseCode: SendRequestInfo
                } = await sendRequestInformation(data);

                if (SendRequestInfo.data.status === 'success') {
                    setRequestInfoModel(true)
                    setName('');
                    setOrganization('');
                    setEmail('');
                    setPhone('');
                    setBlsCourse('0');
                    setAclsCourse('0');
                    setPalsCourse('0');
                } else {
                    console.log('Something went wrong .');
                }                
            } catch (error) {
                console.log('Something went wrong .');
            }
            setLoading(false);
        }

    }

    // sencode time contact us form

    const blsPlus = () => {
        setBlsCourse1(blsCourse + 1);
        setCourseError1('');
    };

    const blsMinus = () => {
        if (blsCourse > 0) {
            setBlsCourse1(blsCourse - 1);
        }
    };

    const aclsPlus = () => {
        setAclsCourse1(aclsCourse + 1);
        setCourseError1('');
    };

    const aclsMinus = () => {
        if (aclsCourse > 0) {
            setAclsCourse1(aclsCourse - 1);
        }
    };
    const palsPlus = () => {
        setPalsCourse1(palsCourse + 1);
        setCourseError1('');
    };

    const palsMinus = () => {
        if (palsCourse > 0) {
            setPalsCourse1(palsCourse - 1);
        }
    };

    const onName = (e) => {
        setName1(e.target.value);
        setNameError1('');
    }
    const onOrganization = (e) => {
        setOrganization1(e.target.value);
        setOrganizationError1('');
    }
    const onEmail = (e) => {
       
        const enteredEmail = e.target.value;    
        setEmail1(enteredEmail);
        if (!emailPattern.test(enteredEmail)) {
            setEmailError1('Please enter a valid email address');
        } else {
            setEmailError1('');
        }
    }
    const onPhone = (e) => {
        const enteredPhone = e.target.value;      
        setPhone1(enteredPhone);
        if (!phonePattern.test(enteredPhone)) {
            setPhoneError1('Phone must contain only numbers');
        } else {
            setPhoneError1('');
        }
    }

    const onRequestInformationSubmit = async (e) => {
        e.preventDefault();

        let isValidData = true;

        if (!names) {
            setNameError1('Name is required');
            isValidData = false;
        }
        if (!organization) {
            setOrganizationError1('Organization is required');
            isValidData = false;
        }
       

        if (!emails) {
            setEmailError1('Email is required');
            isValidData = false;
        }else if (!emailPattern.test(emails)) {
            setEmailError1('Please enter a valid email address');
            isValidData = false;
            return;
        }

        if (!phone) {
            setPhoneError1('Phone is required');
            isValidData = false;
        }else if (!phonePattern.test(phone)) {
            setPhoneError1('Phone must contain only numbers');
            isValidData = false;
            return;
        } 

        if (blsCourse < 1 && aclsCourse < 1 && palsCourse < 1) {
            setCourseError1('Please select At least one course.');
            isValidData = false;
        }
      
        if (isValidData) {
            setLoading1(true);
            const totalCertificates = blsCourse + aclsCourse + palsCourse;

            try {
                const {
                    responseCode: SendRequestInfo
                } = await sendRequestInformation({"name":names,'organization_name': organization,'email':emails,'phone_number':phone ,'bls_courses':blsCourse , 'acls_courses':aclsCourse ,'pals_courses':palsCourse,'total_certificates':totalCertificates });

                if (SendRequestInfo.data.status === 'success') {
                    setRequestInfo(true)
                    setName1('');
                    setOrganization1('');
                    setEmail1('');
                    setPhone1('');
                    setBlsCourse1('0');
                    setAclsCourse1('0');
                    setPalsCourse1('0');
                } else {
                    console.log('Something went wrong .');
                }                
            } catch (error) {
                console.log('Something went wrong .');
            }
            setLoading1(false);
        }

    }

    //
   
    const handleChangeCount = (index, count, type) => {
        let getList = listcertificate;
        if (count > -1) {
            if (type === 'plus') {
                getList[index].count = getList[index].count + 1;
            } else if (count > 0) {
                getList[index].count = getList[index].count - 1;
            }
        }
        let subTotal = 0;
        let totalQuantity = 0;
        for (let i = 0; i < getList.length; i++) {
            let data = getList[i];
            totalQuantity += data.count
            subTotal += Number(data.amount) * data.count;
        }
        setCalculateTotal({
            sub_total: subTotal,
        });
        if (totalQuantity == 0 || totalQuantity < 5) {
            setDiscount(0)
        }
        if (totalQuantity >= 5) {
            setDiscount(10)
        }
        if (totalQuantity >= 10) {
            setDiscount(15)
        }
        if (totalQuantity >= 15) {
            setDiscount(20)
        }
        if (totalQuantity > 25) {
            setDiscount(30)
        }
        setListcertificate(getList);
    }

    const getlist = async () => {
        if (CertificateTypeGet.length > 0) {
            let certificateData = CertificateTypeGet;
            for (let i = 0; i < certificateData.length; i++) {
                let data = certificateData[i];
                data.count = 0;
            }
            setListcertificate(certificateData)
        }
    }

    useEffect(() => {
        getlist()
    }, [CertificateTypeGet])

    return (
        <>
            <Header />

            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-5">
                        <h1 className="group-title">Free. Simple. Convenient.</h1>
                        <p className="group-title-2">
                            At Pulse Certification, we have built a Learning Management System to easily track and seamlessly manage certification for your healthcare providers.
                            We will input your employee information and you will assign courses to your staff.
                            They'll be notified and enrolled and you will be able to easily track your staff's progress.
                            As your employees pass exam, you will both receive immediate access to a downloadable provider card.
                        </p>
                    </div>
                </div>
            </section>

            <section className="pt-5 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="contact-us">
                        <div className="row text-center group-contact">
                            <h3 className="c-title">Contact Us</h3>
                        </div>

                        <div className=" contact-us-group">
                            <form onSubmit={RequestInformationSubmit}>
                                <div className="m-5 row">
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="firstName" placeholder="Name:" aria-label="name" onChange={onNameChanges} value={name} maxLength='40'/>
                                        {NameError && <div className="error">{NameError}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="firstName" placeholder="Organization Name:" aria-label="organization" value={organization_name} onChange={onOrganizationChanges} maxLength='40' />
                                        {OrganizationError && <div className="error">{OrganizationError}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="email" placeholder="Email:" aria-label="Email" value={email} onChange={onEmailChanges} maxLength='100' />
                                        {EmailError && <div className="error">{EmailError}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="phone" placeholder="Phone Number:" aria-label="Phone" value={phone_number} onChange={onPhoneChanges}  maxLength='15' />
                                        {PhoneError && <div className="error">{PhoneError}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center courses-group-contact">
                                    <h3 className="c-title">Courses Needed</h3>
                                </div>
                                <div className="m-5 row">
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={blsDecrementQuantity} className="minus-btn-group"><img alt="minus-img" className="minus-img11" src={Minus} width='15' /></a>
                                                    </div>
                                                    <div className="col-lg-4 p-0">
                                                        <span className="qty-title-span-group">{bls_courses}</span>
                                                    </div>
                                                    <div className="col-lg-4 p-0">

                                                        <a onClick={blsIncrementQuantity} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img11" /></a>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of BLS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={aclsDecrementQuantity} className="minus-btn-group"><img alt="minus-img" className="minus-img1" src={Minus} width='15' /></a>
                                                    </div>
                                                    <div className="col-lg-4 p-0"> 
                                                        <span className="qty-title-span-group">{acls_courses}</span>
                                                    </div>
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={aclsIncrementQuantity} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img1" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of ACLS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={palsDecrementQuantity} className="minus-btn-group"><img alt="minus-img" className="minus-img1" src={Minus} width='15' /></a>
                                                    </div> 
                                                    <div className="col-lg-4 p-0">
                                                        <span className="qty-title-span-group">{pals_courses}</span>
                                                    </div> 
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={palsIncrementQuantity} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img1" /></a>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of PALS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    {CourseError && <div className="error">{CourseError}</div>}
                                </div>
                                <div className="m-5 row">
                                    <div className="col-lg-8">
                                        <p>*Your information is 100% secure and will never be shared with anyone.</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="text-end">
                                            <button type="submit" className="btn submit-btn request-information-group" disabled={loading}>{loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Request Information"}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <RequestInformation
                            show={RequestInfoModal}
                            onHide={() => setRequestInfoModel(false)}
                        />


                    </div>
                </div>
            </section>



            <section className="pt-5 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row manage-emp">
                        <div className="manage-employee">
                            <h3 className="emp-title">We manage your employee's compliance, so you don't have to.</h3>
                            <hr />
                        </div>
                        <div className="row for-more-grp">
                            <div className="col-lg-6 text-end">
                                <p className="for-more-details-group">For more details:</p>
                            </div>
                            <div className="col-lg-6 text-start for-center">
                                <img src={Mail} alt="mail" width='30' /> <a href="mailto:greg@pulsecertifications.com" className="email-1-group">greg@pulsecertifications.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="pt-5 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 compliance-management">
                            <h2>Take the Stress out of Compliance Management</h2>
                            <p>The Pulse Certification organization portal allows you to easily manage and track employee
                                certification in one straightforward dashboard no matter how big or small the organization.</p>
                        </div>
                        <div className="col-lg-7 mt-5">
                            <div className="compliance-management-ul">
                                <ul>
                                    <li className="flex li-management-1 line-45-1" >
                                        <div className=""><a tabIndex='0' className="pro-num">1</a></div>
                                        <div className="">
                                            <h4>Easy Setup</h4>
                                            <p>Gain access to a private portal within 24 hours.</p>
                                        </div>
                                    </li>
                                    <li className="flex li-management-2" >
                                        <div className=""><a tabIndex='0' className="pro-num">2</a></div>
                                        <div className="">
                                            <h4>Add Employee</h4>
                                            <p>We will add your existing employees for you and you'll be able to easily add future new hires.</p>
                                        </div>
                                    </li>
                                    <li className="flex li-management-1">
                                        <div className=""><a tabIndex='0' className="pro-num">3</a></div>
                                        <div className="">
                                            <h4>Assign Courses</h4>
                                            <p>Enroll your employees quickly and easily into BLS, ACLS and PALS courses. We'll notify them through their personal email immediately.</p>
                                        </div>
                                    </li>
                                    <li className="flex li-management-2">
                                        <div className=""><a tabIndex='0' className="pro-num">4</a></div>
                                        <div className="">
                                            <h4>Track Employee</h4>
                                            <p>Your private portal will provide real-time updates. As your employess complete their exams, you'll receive downloadable copy of their certification card.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div className="col-lg-5 mt-5">
                            <div className="card group-img-management">
                                <img src={group1} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="pt-5 pb-5 feature-promo bg-light" id="new-discount-section-group">
                <div className="container">
                    <div className="row steep-discounts">
                        <div className="card">
                            <div className="col-lg-12 steep-discounts-title">
                                <h3 className="">Steep Discounts with Group Certifications</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row new-discount-1">
                        <div className="col-lg-12">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="course-discount">
                                        <th className="text-center">Courses Assigned</th>
                                        <th className="text-center">Discount Applied</th>
                                    </tr>
                                </thead>
                                <tbody className="course-title">
                                    <tr>
                                        <td className="text-center" data-label='Number of Courses'>5</td>
                                        <td className="text-center" data-label='Discount Applied'>10%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" data-label='Number of Courses'>10</td>
                                        <td className="text-center" data-label='Discount Applied'>15%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" data-label='Number of Courses'>15</td>
                                        <td className="text-center" data-label='Discount Applied'>20%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" data-label='Number of Courses'>20</td>
                                        <td className="text-center" data-label='Discount Applied'>25%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" data-label='Number of Courses'>25</td>
                                        <td className="text-center" data-label='Discount Applied'>30%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>



            <section className="pt-5 pb-5 feature-promo bg-light" id="new-discount-section-group">
                <div className="container">
                    <div className="row steep-discounts">
                        <div className="card">
                            <div className="col-lg-12 steep-discounts-title">
                                <h3 className="">Use the calculator below to get a real-time estimate!</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row new-discount-2">
                        <div className="col-lg-12">
                            <table className="table">
                                <thead>
                                    <tr className="">
                                        <th className="text-left course-left-padding">Courses</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-center">Discount</th>
                                        <th className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="real-time-table-group">
                                    {
                                        listcertificate.map((ele, index) =>
                                            <>
                                                {ele.id != 7 && ele.id != 8 &&
                                                    <>
                                                        <tr key={index}>
                                                            {/* <td className="text-left course-left-padding text-capitalize" data-label='Courses'>{ele.short_name.replace('_'," ")}</td> */}
                                                            <td className="text-left course-left-padding text-capitalize course-name-cal" data-label='Courses'>{ele.short_name === 'bls_certification' && <>BLS Certification </>} {ele.short_name === 'bls_re_certification' && <>BLS Re-Certification </>} {ele.short_name === 'acls_certification' && <>ACLS Certification </>} {ele.short_name === 'acls_re_certification' && <>ACLS Re-Certification </>} {ele.short_name === 'pals_certification' && <>PALS Certification </>} {ele.short_name === 'pals_re_certification' && <>PALS Re-Certification </>} </td>
                                                            <td className="text-center price-cal" data-label='Price'>${ele.amount}</td>
                                                            <td className="text-center qty-cal" data-label='Quantity'>
                                                                <div className="minus-re-certification">
                                                                    <span onClick={() => handleChangeCount(index, ele.count, 'minus')} className="minus-btn-group cur"><img alt="minus-img" className="minus-img" src={Minus} width='15' /></span>
                                                                    <span className="qty-title-span-group">{Number(ele.count)}</span>
                                                                    <span onClick={() => handleChangeCount(index, ele.count, 'plus')} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img" /></span>
                                                                </div>
                                                            </td>
                                                            <td className="text-center discount-cal" data-label='Discount'>{discount}%</td>
                                                            <td className="text-center total-cal" data-label='Total'>${(Number(ele.amount * ele.count) - ((Number(ele.amount) * ele.count) * discount) / 100).toFixed(2)}</td>
                                                        </tr>
                                                    </>
                                                }
                                            </>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-9"></div>
                        <div className="col-lg-3 mt-3 mb-3">
                            <div>
                                <span className="rela-time-discount">Subtotal:</span> <span className="rela-time-sub-total">${(calculatorTotal.sub_total).toFixed(2)} </span> <br />
                                <span className="rela-time-discount" >Discount ({discount}%) :</span> <span className="rela-time-sub-total" style={{ color: 'red' }}>$-{(calculatorTotal.sub_total * discount / 100).toFixed(2)}</span> <br />
                                <hr />
                                <span className="rela-time-discount">Total :</span> <span className="rela-time-sub-total">${((calculatorTotal.sub_total) - (calculatorTotal.sub_total * discount / 100)).toFixed(2)}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*  */}

            <section className="pt-5 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row manage-emp">
                        <div className="manage-employee">
                            <h3 className="emp-title">Never worry about an expired certification again.</h3>
                            <hr />
                        </div>
                        <div className="row for-more-grp">
                            <div className="col-lg-6 text-end ">
                                <p className="for-more-details-group">For more details:</p>
                            </div>
                            <div className="col-lg-6 text-start for-center">
                                <img src={Mail} alt="mail" width='30' /> <a href="mailto:greg@pulsecertifications.com" className="email-1-group">greg@pulsecertifications.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

            <section className="pt-5 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="contact-us">
                        <div className="row text-center group-contact">
                            <h3 className="c-title">Contact Us</h3>
                        </div>

                        <div className=" contact-us-group">
                            <form onSubmit={onRequestInformationSubmit}>
                                <div className="m-5 row">
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="firstName" placeholder="Name:" aria-label="name" onChange={onName} value={names}  maxLength='40'/>
                                        {NameError1 && <div className="error">{NameError1}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="firstName" placeholder="Organization Name:" aria-label="organization" value={organization} onChange={onOrganization} maxLength='40' />
                                        {OrganizationError1 && <div className="error">{OrganizationError1}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="email" placeholder="Email:" aria-label="Email" value={emails} onChange={onEmail} maxLength='100'/>
                                        {EmailError1 && <div className="error">{EmailError1}</div>}
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <input type="text" className="form-control" id="phone" placeholder="Phone Number:" aria-label="Phone" value={phone} onChange={onPhone} maxLength='15' />
                                        {PhoneError1 && <div className="error">{PhoneError1}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center courses-group-contact">
                                    <h3 className="c-title">Courses Needed</h3>
                                </div>
                                <div className="m-5 row">
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={blsMinus} className="minus-btn-group"><img alt="minus-img" className="minus-img1" src={Minus} width='15' /></a>
                                                    </div>
                                                    <div className="col-lg-4 p-0">
                                                        <span className="qty-title-span-group">{blsCourse}</span>
                                                    </div>
                                                    <div className="col-lg-4 p-0"> 
                                                        <a onClick={blsPlus} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img1" /></a>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of BLS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={aclsMinus} className="minus-btn-group"><img alt="minus-img" className="minus-img1" src={Minus} width='15' /></a>
                                                    </div>
                                                    <div className="col-lg-4 p-0">
                                                        <span className="qty-title-span-group">{aclsCourse}</span>
                                                    </div>
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={aclsPlus} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img1" /></a>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of ACLS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 contact-check">
                                        <div className="row">
                                            <div className="col-lg-5 bls-qty-group">
                                                <div className="row">
                                                    <div className="col-lg-4 p-0">
                                                        <a onClick={palsMinus} className="minus-btn-group"><img alt="minus-img" className="minus-img1" src={Minus} width='15' /></a>
                                                    </div>
                                                    <div className="col-lg-4 p-0"> 
                                                        <span className="qty-title-span-group">{palsCourse}</span>
                                                    </div>
                                                    <div className="col-lg-4 p-0"> 
                                                        <a onClick={palsPlus} className="minus-btn-group1"><img alt="minus-img1" src={Plus} width='15' className="minus-img1" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <span className="of-courses-gropup"># of PALS Courses</span>
                                            </div>
                                        </div>
                                    </div>
                                    {CourseError1 && <div className="error">{CourseError1}</div>}
                                </div>
                                <div className="m-5 row">
                                    <div className="col-lg-8">
                                        <p>*Your information is 100% secure and will never be shared with anyone.</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="text-end">
                                            <button type="submit" className="btn submit-btn request-information-group" disabled={loading1}>{loading1 ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Request Information"}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <RequestInformation
                            show={RequestInfo}
                            onHide={() => setRequestInfo(false)}
                        />


                    </div>
                </div>
            </section>
            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>
    )
}