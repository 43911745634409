import { useState } from "react";

import Auth from '../api/Auth';

const UseCheckForgotPassword = () => {
    const [isCheckForgortPasswor, setIsCheckForgortPasswor] = useState(false);
  
    const checkforgortPassword = async (data) => {
      setIsCheckForgortPasswor(true);
  
      let responseCode;
  
      try {
        const response = await Auth.checkForgotPassword(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsCheckForgortPasswor(false);
  
      return { responseCode };
    };
  
    return { isCheckForgortPasswor, checkforgortPassword };
  };


export default UseCheckForgotPassword;