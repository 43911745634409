import React, {useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Print from '../../../assets/web-images/images/Print.svg'
import {Link} from "react-router-dom";
import BasicLifeSupport from "../../../pages/employeeSite/Home/CertificateTypes/BasicLifeSupport";
import BasicLifeSupportBack from "../../../pages/employeeSite/Home/CertificateTypes/BasicLifeSupportBack";
import AdvancedCardiovascularLifeSupport
    from "../../../pages/employeeSite/Home/CertificateTypes/AdvancedCardiovascularLifeSupport";
import AdvancedCardiovascularLifeSupportBack
    from "../../../pages/employeeSite/Home/CertificateTypes/AdvancedCardiovascularLifeSupportBack";
import PediatricAdvancedLifeSupport
    from "../../../pages/employeeSite/Home/CertificateTypes/PediatricAdvancedLifeSupport";
import PediatricAdvancedLifeSupportBack
    from "../../../pages/employeeSite/Home/CertificateTypes/PediatricAdvancedLifeSupportBack";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UseSendCertificate from "../../../hooks/UseSendCertificate";
import {ThreeDots} from "react-loader-spinner";
import SendviaEmail from "./SendviaEmail";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CertificateModal(props) {

    const [loading, setLoading] = useState(false);
    const {isSendCertificateApi, sendCertificateApi} = UseSendCertificate();
    const certificateRef = useRef(null);
    const certificateRefBack = useRef(null);
    const [certificateSend, setCertificateSend] = useState(false);
    const [isFromManger,setIsFromManager] = useState(!!props.isFromManager)
    const handleCertificate = async (e) => {
        e.preventDefault();
        setLoading(true);

        const {
            responseCode: sendCertifications
        } = await sendCertificateApi({'certificate_id': props.certificateId ,'disabledCertificate':1});

        if (sendCertifications.data.status === 'success') {
            setCertificateSend(true);
            props.onHide();
            // window.history.back();
        }else{
            if (sendCertifications.data.status === 'error') {
                toast.error(sendCertifications.data.message);
            }
        }
        setLoading(false);
    };


    const onClickPrintBtn = () => {
        let getFront = "";
        let getBack = "";
        getFront = certificateRef.current.innerHTML;
        getBack = certificateRefBack.current.innerHTML;

        const printWindow = window.open('', '', 'width=800,height=900');
        const frontDocument = `
          <!DOCTYPE html>
          <html>
          <head>
            <style type="text/css">
                #bls-front-img {
                    background-color: #afdadb;
                    border: 0;
                    border-radius: 30px;
                }
                #bls-front-img.aclsCertificate {
                    background-color: #fbd3d1 !important;
                    border: 0;
                    border-radius: 30px;
                }
                .text-center {
                    text-align: center !important;
                }
                .card {
                    --bs-card-spacer-y: 1rem;
                    --bs-card-spacer-x: 1rem;
                    --bs-card-title-spacer-y: 0.5rem;
                    --bs-card-title-color: ;
                    --bs-card-subtitle-color: ;
                    --bs-card-border-width: var(--bs-border-width);
                    --bs-card-border-color: var(--bs-border-color-translucent);
                    --bs-card-border-radius: var(--bs-border-radius);
                    --bs-card-box-shadow: ;
                    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
                    --bs-card-cap-padding-y: 0.5rem;
                    --bs-card-cap-padding-x: 1rem;
                    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
                    --bs-card-cap-color: ;
                    --bs-card-height: ;
                    --bs-card-color: ;
                    --bs-card-bg: var(--bs-body-bg);
                    --bs-card-img-overlay-padding: 1rem;
                    --bs-card-group-margin: 0.75rem;
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    min-width: 0;
                    height: var(--bs-card-height);
                    word-wrap: break-word;
                    background-color: var(--bs-card-bg);
                    background-clip: border-box;
                    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
                    border-radius: var(--bs-card-border-radius);
                }
                #bls-front-img h5 {
                    color: #00386c;
                    font-family: 'Helvetica75';
                    font-size: 25px;
                    margin: 0;
                }
                #bls-front-img p {
                    color: #231f20;
                    font-family: 'Helvetica';
                    margin-left: 7%;
                    margin-right: 7%;
                    text-align: center;
                    font-size: 21px;
                }
                #bls-front-img .text-name-bls-h6 {
                    text-align: left;
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    padding: 1%;
                    background-color: #d7eced;
                    color: #231f20 !important;
                    padding-left: 20px;
                    font-size: 15px;
                    font-family: 'Helvetic55';
                    font-weight: 500 !important;
                    margin-bottom: 4%;
                }
                #bls-front-img .flex-div {
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    background-color: #d7eced;
                    color: #231f20 !important;
                    padding-left: 20px;
                    margin-bottom: 3%;
                    justify-content: space-between;
                    padding-right: 10%;
                }
                #bls-front-img h4 {
                    margin-left: 12%;
                    margin-right: 12%;
                    background-color: #00386c;
                    color: #fff;
                    font-family: 'Helvetica75';
                    border: 1px solid #00386c;
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding: 7px;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    font-size: 19px;
                }
                #bls-front-img .flex-div {
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    background-color: #d7eced;
                    color: #231f20 !important;
                    padding-left: 20px;
                    margin-bottom: 3%;
                    justify-content: space-between;
                    padding-right: 10%;
                }
                .flex-div h6 {
                    font-size: 15px;
                    font-family: 'Helvetic55';
                    font-weight: 500 !important;
                    padding: 1%;
                    margin-bottom: 0;
                    margin-top: 0;
                }
                .flex-div span {
                    color: #231f20 !important;
                    font-family: 'Helvetic';
                    font-weight: 500 !important;
                }
                .d-flex {
                    display: -webkit-box !important;
                    display: -ms-flexbox !important;
                    display: flex !important;
                }
                #bls-front-img.aclsCertificate .text-name-bls-h6 {
                    text-align: left;
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    padding: 1%;
                    background-color: #fde9e8;
                    color: #231f20 !important;
                    padding-left: 20px;
                    font-size: 15px;
                    font-family: 'Helvetic55';
                    font-weight: 500 !important;
                    margin-bottom: 4%;
                }
                #bls-front-img.aclsCertificate .flex-div {
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    background-color: #fde9e8;
                    color: #231f20 !important;
                    padding-left: 20px;
                    margin-bottom: 3%;
                    justify-content: space-between;
                    padding-right: 10%;
                }
                #bls-front-img.aclsCertificate h4 {
                    margin-left: 12%;
                    margin-right: 12%;
                    background-color: #ec1c24;
                    color: #fff;
                    font-family: 'Helvetica75';
                    border: 1px solid #ec1c24;
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding: 7px;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    font-size: 19px;
                }
                #bls-front-img.aclsCertificate h5 {
                    color: #ec1c24;
                    font-family: 'Helvetica75';
                    font-size: 25px;
                }
                #bls-front-img.palsCertificate {
                    background-color: #dfd5e9 !important;
                    border: 0;
                    border-radius: 30px;
                }
                #bls-front-img.palsCertificate h5 {
                    color: #8b63a9;
                    font-family: 'Helvetica75';
                    font-size: 25px;
                }
                #bls-front-img.palsCertificate .text-name-bls-h6 {
                    text-align: left;
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    padding: 1%;
                    background-color: #efeaf4;
                    color: #231f20 !important;
                    padding-left: 20px;
                    font-size: 15px;
                    font-family: 'Helvetic55';
                    font-weight: 500 !important;
                    margin-bottom: 4%;
                }
                #bls-front-img.palsCertificate .flex-div {
                    margin-left: 12%;
                    margin-right: 12%;
                    border-radius: 30px;
                    background-color: #efeaf4;
                    color: #231f20 !important;
                    padding-left: 20px;
                    margin-bottom: 3%;
                    justify-content: space-between;
                    padding-right: 10%;
                }
                #bls-front-img.palsCertificate h4 {
                    margin-left: 12%;
                    margin-right: 12%;
                    background-color: #8b63a9;
                    color: #fff;
                    font-family: 'Helvetica75';
                    border: 1px solid #8b63a9;
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding: 7px;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    font-size: 19px;
                }
                @media print {
                    body {
                        -webkit-print-color-adjust: exact;
                        -moz-print-color-adjust: exact;
                        -ms-print-color-adjust: exact;
                        print-color-adjust: exact;
                    }
                }
                @page {
                    size: A4;
                    margin: 1cm; /* Adjust the margin as needed */
                }
                body {
                    margin: 0;
                }  
            </style>
          </head>
          <body>
            <div style="height: 100vh">
                <br /> <br />
                ${getFront} 
                <br /> <br />
                ${getBack}
            </div>
          </body>
          </html>`;

        // Write the HTML content to the print window
        printWindow.document.open();

        printWindow.document.write(frontDocument);
        printWindow.document.close();

        // Trigger the print dialog for the new window
        // printWindow.print();
        // printWindow.close();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Tabs
                        defaultActiveKey="front"
                        id="fill-tab-example"
                        className="emp-pass-certificate"
                        fill
                    >
                        <Tab eventKey="front" title="Front">
                            <div className="align-center" ref={certificateRef}>
                                <div className="card modal-prints">
                                    {props.printImageUrl === 'Basic Life Support' && (
                                        <BasicLifeSupport props={props.printImageUrl} item={props.userName}
                                                          activeDate={props.certificateActiveDate}
                                                          items={props.finalExamDate}/>
                                    )}
                                    {props.printImageUrl === 'Basic Life Support Re-Certification' && (
                                        <BasicLifeSupport props={props.printImageUrl} item={props.userName}
                                                          activeDate={props.certificateActiveDate}
                                                          items={props.finalExamDate}/>
                                    )}
                                    {props.printImageUrl === 'Advanced Cardiovascular Life Support' && (
                                        <AdvancedCardiovascularLifeSupport props={props.printImageUrl}
                                                                           item={props.userName}
                                                                           activeDate={props.certificateActiveDate}
                                                                           items={props.finalExamDate}/>
                                    )}
                                    {props.printImageUrl === 'Advanced Cardiovascular Life Support Re-Certification' && (
                                        <AdvancedCardiovascularLifeSupport props={props.printImageUrl}
                                                                           item={props.userName}
                                                                           activeDate={props.certificateActiveDate}
                                                                           items={props.finalExamDate}/>
                                    )}
                                    {props.printImageUrl === 'Pediatric Advanced Life Support' && (
                                        <PediatricAdvancedLifeSupport props={props.printImageUrl} item={props.userName}
                                                                    activeDate={props.certificateActiveDate}
                                                                      items={props.finalExamDate}/>
                                    )}
                                    {props.printImageUrl === 'Pediatric Advanced Life Support Re-Certification' && (
                                        <PediatricAdvancedLifeSupport props={props.printImageUrl} item={props.userName}
                                                                    activeDate={props.certificateActiveDate}
                                                                      items={props.finalExamDate}/>
                                    )}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="back" title="Back">
                            <div className="align-center" ref={certificateRefBack}>

                                <div className="card modal-prints">
                                    {props.printImageUrl === 'Basic Life Support' && (
                                        <BasicLifeSupportBack props={props.printImageUrl} item={props.userName}
                                                              items={props.finalExamDate}
                                                              userCertificateId={props.userCertificateId}/>
                                                              )}
                                    {props.printImageUrl === 'Basic Life Support Re-Certification' && (
                                        <BasicLifeSupportBack props={props.printImageUrl} item={props.userName}
                                                              items={props.finalExamDate}
                                                              userCertificateId={props.userCertificateId}/>
                                                              )}
                                    {props.printImageUrl === 'Advanced Cardiovascular Life Support' && (
                                        <AdvancedCardiovascularLifeSupportBack props={props.printImageUrl}
                                        item={props.userName}
                                        items={props.finalExamDate}
                                        userCertificateId={props.userCertificateId}/>
                                        )}
                                    {props.printImageUrl === 'Advanced Cardiovascular Life Support Re-Certification' && (
                                        <AdvancedCardiovascularLifeSupportBack props={props.printImageUrl}
                                        item={props.userName}
                                        items={props.finalExamDate}
                                        userCertificateId={props.userCertificateId}/>
                                        )}
                                    {props.printImageUrl === 'Pediatric Advanced Life Support' && (
                                        <PediatricAdvancedLifeSupportBack props={props.printImageUrl}
                                        item={props.userName}
                                        items={props.finalExamDate}
                                        userCertificateId={props.userCertificateId}/>
                                    )}
                                    {props.printImageUrl === 'Pediatric Advanced Life Support Re-Certification' && (
                                        <PediatricAdvancedLifeSupportBack props={props.printImageUrl}
                                        item={props.userName}
                                        items={props.finalExamDate}
                                        userCertificateId={props.userCertificateId}/>
                                    )}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </Modal.Body>
                <Modal.Footer>
                    {
                        !isFromManger && (
                            <>
                                <div className="review-course-contents">
                                    <Link className="print-title" to={`/employee/${props.certificateSlug}`}>Review the
                                        course
                                        content</Link>
                                </div>
                                <div className="email-send-certificate">

                                    <Link className="print-title send-via-email" onClick={handleCertificate}>
                                        {loading ? <ThreeDots color="#000" wrapperClass="d-block text-center" height={30}
                                                              width={40}/> : "Send via Email"}
                                    </Link>
                                </div>
                            </>
                        )
                    }
                    <div className="print-title-div-modal">
                        <Link className="print-title" onClick={() => {
                            onClickPrintBtn()
                        }}> <img src={Print} alt="print" width='20' height='30'/> Print </Link>
                    </div>
                </Modal.Footer>
            </Modal>
                    
        <SendviaEmail 
            show={certificateSend}
            onHide={() => setCertificateSend(false)}
            email={props.email}
        />
            <ToastContainer />
        </>
    )

}