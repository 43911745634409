import React,{useState} from "react";
import LoginImg from '../../../assets/web-images/images/forgot-password.png'
import Header from "../../../components/website/Layouts/Header";
import UseForgotPassword from "../../../hooks/UseForgotPassword";
import { useNavigate }  from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";
import 'react-toastify/dist/ReactToastify.css'
export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {isForgortPasswordApi , forgortPasswordApi} = UseForgotPassword();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Email is required');
        }
       
        if (email) {
            setLoading(true);

            try {

                const{
                    responseCode:forgortPassword
                } = await forgortPasswordApi({email});
                if (forgortPassword.data.status === 'success') {
                    toast.success(forgortPassword.data.message);
                    setEmail('');
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000);
                }else{
                    toast.error('User not found from this email address.');
                }
            }catch (error) {
                toast.error('User not found from this email address.');
            }
            setLoading(false);

        }
    };
    
    const onEmailChange = (e) =>{
        setEmail(e.target.value);
        setEmailError('');
    }


    return (
        <>
            <div className="login-header">
                <Header />
            </div>

            <section className="sign-up-in-section bg-white ptb-120">
                <div className="container">
                    <div className="card shadow-lg fpc">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 fp-main">
                                <div className="card fpb">
                                    <img src={LoginImg} alt="login-image4" className="forgot-password-img" />
                                </div>
                            </div>
                            <div className="col-lg-6 fp-padding">
                                <h3 className="welcome-title mt-5">Forgot your Password?</h3>
                                <p className="fpf-titles">Don't worry. Type your email and we will send a password recovery link to your email.</p>

                                <form action="#" className=" register-form" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label htmlFor="email" className="mb-1">Email</label>
                                            <div className="input-group mb-3">
                                                <input type="emails" className="form-control" id="email" value={email} name="email1" onChange={onEmailChange} autoComplete="off" />
                                            </div>
                                            {emailError && <div className="error">{emailError}</div>}
                                        </div>

                                        <div className="col-12 text-center btn-submit">
                                            <button type="submit" className="btn btn-login" disabled={loading}>
                                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Submit"}
                                            </button>
                                        <div className="text-end">
                                            <a href="/login"> <p className="forgot-password page-r-login">Login Page</p></a>
                                        </div>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <ToastContainer />
        </>
    )
}