import {useState} from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";

const UseAddDiscount = () => {
    const [isAddDiscount, setIsAddDiscount] = useState(false);
  
    const AddNewDiscount = async (data) => {
      setIsAddDiscount(true);
  
      let responseCode;
  
      try {
          responseCode = await Superadmin.createDiscount(data);
      } catch (error) {
        responseCode = error.response;
      }
  
      setIsAddDiscount(false);
  
      return { responseCode };
    };
  
    return { isAddDiscount, AddNewDiscount };
  };


export default UseAddDiscount;