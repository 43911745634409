import axiosConfig from '../Config';

const manager ={
    getDashboardData: (params) => axiosConfig.post(`get-manager-certificate`,params),
    getMyTeams: (params) => axiosConfig.post('my-team-member',params),
    getMemberDetail: ({ params: {user_id} }) => axiosConfig.get(`get-member-details/${user_id}`),
    removeMember: (body) => axiosConfig.post(`remove-member`, body),
    // getCourseDetails: (params) => axiosConfig.post(`get-certificate-course-list/${params.certificate_type}`,{
    //         certificate_status: params.certificate_status
    // }),  
    getCourseDetails: (certificate_type,params) => axiosConfig.post(`get-certificate-course-list/${certificate_type}`,params),  
    SendReNotify: (body) => axiosConfig.post(`send-re-notification`, body),  
    processToAddFund: (body) => axiosConfig.post(`process-to-add-fund`, body),  
    getCurrentBalance: () => axiosConfig.get(`get-current-balance`),
    purchaseAndAssignCourse: (body) => axiosConfig.post(`purchase-and-assign-course`, body),  
    purchaseHistory: (params) => axiosConfig.post(`get-purchase-history`,params),
    getManagerCurrentBalance: ({ params: {manager_id} }) => axiosConfig.get(`get-manager-current-balance/${manager_id}`),
    certificateDateUpdate: (body) => axiosConfig.post(`update-certificate-date`, body),
    walletHistory: (params) => axiosConfig.post(`get-wallet-history`,params),
    deleteUserAssignCertificate: (body) => axiosConfig.post(`delete-user-assign-certificate`, body),
}

export default manager;