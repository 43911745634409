import React,{useState,useEffect} from "react";
import logo from '../../../assets/web-images/images/Pulse-Logo.svg'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axiosConfig from '../../../api/Config';
import CryptoJS from 'crypto-js';

export default function Header() {
    const secretKey = process.env.REACT_APP_API_LIVE;

    const slug = useParams();
    const { test_name } = slug;

    const type = useParams();
    const {sub_type} = type ;
    
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const cookies = new Cookies();
    const [userRoles ,setUserRoles] = useState([]);
    const [individualUser ,setIndividualUser] = useState({});
    const [loading ,setLoading] = useState(false);

    const logOut = () => {
        cookies.remove('token', { path: '/' });
        cookies.remove('user', { path: '/' });
        cookies.remove('role', { path: '/' });
        localStorage.clear();
        
        setTimeout(function () {
            window.location.href = "/";
        }, 1000)
    }

    useEffect(() => {
        const getUserData = async () => {
            const response = await axiosConfig.get('/get-user-role');
            setLoading(true);
            if (response.data.status === 'success') {
                setUserRoles(response.data.status);
            }
            setLoading(false);
        };
        getUserData();

        const checkindividualUser = async () =>{
            const checkUser = await axiosConfig.get('/individual-user-check')
            setLoading(true);
            setIndividualUser(checkUser.data.status);
            setLoading(false);
        }
        checkindividualUser();
    }, []);

    const  onLoginAsEmployee = () =>{
        if (userRoles === 'success') {         
            const updatedUserCookie = 'manager';    
            const encryptedRole = CryptoJS.AES.encrypt(updatedUserCookie, secretKey).toString();
            cookies.set('role', encryptedRole, { path: '/' });
            const encryptedUserRole = cookies.get('role') || '';
            const decryptedRoleBytes = encryptedUserRole ? CryptoJS.AES.decrypt(encryptedUserRole, secretKey) : null;
            const role = decryptedRoleBytes ? decryptedRoleBytes.toString(CryptoJS.enc.Utf8) : '';
            if (role === 'manager') {
                window.location.href = "/manager";
            }
        }
    }
    
    return (
        <>

            <header className="main-header position-absolute w-100" id="border-color">
                <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10" id='navbar-padding'>
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src={logo} alt="logo" className="logo-img" height='65' />
                        </Link>
                        <Link className="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop">
                            <i className="flaticon-menu" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"
                                data-bs-toggle="offcanvas" role="button"></i>
                        </Link>
                        <div className="clearfix"></div>
                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li className="nav-item">
                                    <Link className={ (currentPath === '/employee' || currentPath === `/employee/${sub_type}` || currentPath === `/employee/start-test/${test_name}` || currentPath === `/employee/test-result/${test_name}` )? "nav-link active" : "nav-link"} to="/employee" >Home</Link>
                                </li>                                
                                {/* <li className="nav-item">
                                    <Link className={ currentPath === "/employee/start-test/:test_name" ? "nav-link active": "nav-link"} to="/employee/start-test/:test_name">Start Test</Link>
                                </li> */}
                                <li className="nav-item">
                                    {!loading && userRoles !== '' && userRoles === 'success' &&
                                        <><a className="nav-link"  href="#" onClick={()=>onLoginAsEmployee()}>Switch to Manager</a></>
                                    }
                                </li>
                                <li className="nav-item">     
                                    {!loading && individualUser !== '' && individualUser === 'error' &&
                                        <><a className={ (currentPath === '/employee/purchase-history' )? "nav-link active" : "nav-link"}  href="/employee/purchase-history" >Purchase History</a></>
                                    }
                                </li>
                                <li className="nav-item">
                                    <a className= "nav-link" href="" onClick={logOut}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
                    <div className="offcanvas-header d-flex align-items-center mt-4">
                        <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="flaticon-cancel"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body z-10">
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                                <a  className={ (currentPath === '/employee' || currentPath === `/employee/start-test/${test_name}`)? "nav-link active" : "nav-link"} href="/employee">Home</a> 
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className={ currentPath === "/employee/start-test" ? "nav-link active" : "nav-link"}  href="/employee/start-test">Start Test</a> 
                            </li> */}
                            <li className="nav-item">      
                                {!loading && userRoles !== '' && userRoles === 'success' &&
                                    <><a className="nav-link"  href="#" onClick={()=>onLoginAsEmployee()}>Switch to Manager</a></>
                                }
                            </li>
                            <li className="nav-item">     
                                {!loading && individualUser !== '' && individualUser === 'error' &&
                                    <><a className={ (currentPath === '/employee/purchase-history' )? "nav-link active" : "nav-link"}  href="/employee/purchase-history" >Purchase History</a></>
                                }
                            </li>
                            <li className="nav-item dropdown">
                            <a className= "nav-link" onClick={logOut} href="">Logout</a>
                            </li>
                           
                        </ul>
                       
                    </div>
                </div>
            </header>

        </>
    )
}