import { useParams } from 'react-router-dom'
import axiosConfig from '../Config'


const Superadmin = {
    getuserlist:(params)=> axiosConfig.post(`get-user-list`,params),
    createUser: (data) => axiosConfig.post(`super-admin/add-member`, data),
    ToDoList: (params) => axiosConfig.post(`get-to-do-list`,params),
    getToDoList: ({ params: {user_id,certificate_id} }) => axiosConfig.get(`to-do-list-details/${user_id}/${certificate_id}`),
    moveCertificateCompleted: (body) => axiosConfig.post(`move-certificate-completed`, body),
    getSalesReportList: (params) => axiosConfig.post(`get-sales-report`,params),
    updateProfile: (body) => axiosConfig.post(`edit-profile`, body),
    getProfile: () => axiosConfig.get(`get-profile`),
    getCouponCodelist:(params)=> axiosConfig.post(`get-coupon-code`,params),
    updateDiscoutPercentage: (body) => axiosConfig.post(`edit-discount-percentage`, body),
    userDelete: (body) => axiosConfig.post(`delete-user`, body),
    managerResetPaymentLink: (body) => axiosConfig.post(`reset-payment-link`, body),
    getManagerUsersList: (manager_id,params) => axiosConfig.get(`manager-users-list/${manager_id}`,{params}),
    exportSalesReportList: (params) => axiosConfig.post(`export-sales-report`,params),
    getDiscountList:(params)=> axiosConfig.post(`get-discount-list`,params),
    createDiscount: (data) => axiosConfig.post(`add-discount`, data),
    updateDiscount: (data) => axiosConfig.post(`edit-discount`, data),


}

export default Superadmin