import { useState, useEffect } from "react";

import SampleTest from '../api/SampleTest';

const UseSampleTest = ({short_name}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [getQuestions, setQuestions] = useState([]);
  
    useEffect(() => {
      const getQuestion = async () => {
        try {
          const response = await SampleTest.get({
            params: { short_name },
          });
          setQuestions(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getQuestion();
    }, []);

    
  
    return {getQuestions };
   
}



export default UseSampleTest;