import React from "react";
import Header from '../../components/website/Layouts/Header';
import Footer from '../../components/website/Layouts/Footer';
import privacy from '../../assets/web-images/images/privacy-policy.png'
import ScrollToTop from 'react-scroll-to-top';

export default function PrivacyPolicy()
{
    return(
        <>
            <Header/>
            
            <section className="hero-section ptb-120 text-white bg-gradient" id="main-class" >
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-lg-4 mt-5">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className='main-title'>Privacy Policy</h1> <hr /> <br />
                                <div className="row sub-title-row">
                                <p className="pulse-about-us-page">We are committed to respect your privacy and choices.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5">
                            <div className="hero-img position-relative circle-shape-images">

                                <img src={privacy} alt="doctor-img" className="image-doctor" width='400' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="type-website">Type of website: Ecommerce</h3>
                            <h3 className="type-website">Effective date: 1st day of October, 2023</h3>
                            <hr />
                        </div>
                        <div className="col-lg-6"></div>
                    </div><br/>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4 className="type-website">How Long We Store Personal Data</h4>
                            <p className="user-data-privicy">User data will be stored until the purpose the data was collected for has been achieved.</p> <br />

                            <p className="user-data-privicy">You will be notified if your data is kept for longer than this period.</p> <br />
                            <h4 className="type-website">How We Protect Your Personal Data</h4>
                            <p className="user-data-privicy">In order to protect your security, we use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination.</p>
                            <p className="user-data-privicy">While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.</p>

                            <h4 className="type-website">Children</h4>
                            <p className="user-data-privicy">We do not knowingly collect or use personal data from children under 13 years of age. If we learn that we have collected personal data from a child under 13 years of age, the personal data will be deleted as soon as possible. If a child under 13 years of age has provided us with personal data their parent or guardian may contact our privacy officer.</p>

                            <h4 className="type-website">How to Access, Modify, Delete, or Challenge the Data Collected</h4>
                            <p className="user-data-privicy">If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, or if you would like your data to be deleted or modified in any way, please contact our privacy officer here:</p>
                            <h5 className="type-website">Greg Yandrick</h5>
                            <h5 className="type-website">Email: greg@pulsecertifications.com</h5>
                            <h5 className="type-website">Phone No.: (717) 317-7211</h5>
                            <h5 className="type-website">Address: 1101 Wansford Rd, Mechanicsburg, PA 17050</h5>
                                <br />
                            <h4 className="type-website">Do Not Track Notice</h4>
                            <p className="user-data-privicy">Do Not Track ("DNT") is a privacy preference that you can set in certain web browsers. We do not track the users of our Site over time and across third party websites and therefore do not respond to Website Privacy Policy Page 4 of 6 browser-initiated DNT signals. We are not responsible for and cannot guarantee how any third parties who interact with our Site and your data will respond to DNT signals.</p>

                            <br/>
                            <h4 className="type-website">Cookie Policy</h4>
                            <p className="user-data-privicy">A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.</p>
                            
                            <br/>
                            <h5 className="type-website">We use the following types of cookies on our Site:</h5>
                            <ul>
                                <li className="user-data-privicy">Functional cookies <br />Functional cookies are used to remember the selections you make on our Site so that your selections are saved for your next visits;</li>
                                <li className="user-data-privicy">Analytical cookies <br />Analytical cookies allow us to improve the design and functionality of our Site by collecting data on how you access our Site, for example data on the content you access, how long you stay on our Site, etc;</li>
                                <li className="user-data-privicy">Targeting cookies <br />Targeting cookies collect data on how you use the Site and your preferences. This allows us to personalize the information you see on our Site for you;</li>
                                <li className="user-data-privicy">Third-Party Cookies <br />Third-party cookies are created by a website other than ours. We may use third-party cookies to achieve the following purposes:</li>
                                <br /><p className="user-data-privicy">Monitor user preferences to tailor advertisements around their interests.</p>
                            </ul>
                            <br />
                            <h4 className="type-website">Modifications</h4>
                            <p className="user-data-privicy">This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.</p>
                            
                            <br />
                            <h4 className="type-website">Contact Information</h4>
                            <p className="user-data-privicy">If you have any questions, concerns or complaints, you can contact our privacy officer:</p>
                            <br />
                            <h5 className="type-website">Greg Yandrick</h5>
                            <h5 className="type-website">Email: greg@pulsecertifications.com</h5>
                            <h5 className="type-website">Phone No.: (717) 317-7211</h5>
                            <h5 className="type-website">Address: 1101 Wansford Rd, Mechanicsburg, PA 17050</h5>
                        </div>
                    </div>
                </div>
            </section>
            <br />

            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>
    )    
}