import React from "react";
import Modal from 'react-bootstrap/Modal';

export default function SendNotificationModal(props)
{
    return(
        <>
             <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                <Modal.Header>
                    <div className="col-lg-11">
                    </div>
                    <div className="col-lg-1">
                        <button onClick={props.onHide}  class="send-notify-modal">❌</button>

                    </div>
                </Modal.Header>
                
                <Modal.Body>
                    <p className="remove-class-title text-center">
                    {/* We will send a Re-Notification now. */}We sent a re-notification
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                            {/* <button className="remove-yes-btn">Yes</button>
                            <button className="remove-no-btn" onClick={props.onHide} >X</button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
               
            </Modal>

        </>
    )
}