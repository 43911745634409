import React, { useEffect, useState } from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import Search from "../../../assets/manager-images/images/Search.svg";
import Right from "../../../assets/manager-images/images/Right.svg";
import Close from "../../../assets/manager-images/images/Close.svg";
import Error from "../../../assets/manager-images/images/Error.svg";
import Assigned from "../../../assets/manager-images/images/Assigned.svg";
import UseMyTeam from "../../../hooks/manager/UseMyTeam";
import { Watch, ThreeDots } from "react-loader-spinner";
import moment from "moment/moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import info from "../../../assets/web-images/images/info.svg";
import SendNotificationModal from "../../../components/managerSite/Dashboard/SendNotificationModal";
import UseSendReNotify from "../../../hooks/manager/UseSendReNotify";
import AssignedExpired from "../../../assets/manager-images/images/Assigne_Expired.svg";
import ScrollToTop from "react-scroll-to-top";
import axiosConfig from '../../../api/Config'

function TrackProgressPage() {
  const [trackProgress, setTrackProgress] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [pageParams, setPageParams] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState([]);
  const [userName, setUserName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);

  const [reNotifyLoading, setReNotifyLoading] = useState(false);
  const { isReNotify, sendReNotification } = UseSendReNotify();
  const [certificateLoading, setCertificateLoading] = useState({});

  const { getTeamData, loading, recallApiWithNewParams, loadMoreLoading } = UseMyTeam(
    searchParams,
    pageParams
  );

  useEffect(() => {
    if (getTeamData.status === "success") {
      if (getTeamData.data.data.length > 0) {
        // setTrackProgress([...trackProgress, ...getTeamData.data.data]);
        const combinedArray = [...trackProgress, ...getTeamData.data.data];
        const uniqueEmailMap = new Map();
        combinedArray.forEach((user) => {
          const email = user.email;
          if (!uniqueEmailMap.has(email)) {
            uniqueEmailMap.set(email, user);
          }
        });
        const uniqueArray = Array.from(uniqueEmailMap.values());
        setTrackProgress(uniqueArray);
        setPerPage(getTeamData.data);
        setCurrentPage(getTeamData.data.current_page);
      }
    }
  }, [getTeamData]);

  useEffect(() => {
    if (modalClosed) {
      const userData = async () => {
        try {
          const getdata = await axiosConfig.get(`my-team-member`).then((res) => {
            console.log(res.data.data.data);
            return res.data.data.data;
          }).catch((err) => { return err.status; });
          setCertificateLoading(getdata)
          setTrackProgress(getdata)
          console.log('certificateLoading', certificateLoading);
        } catch (error) {
          console.error('Error during user Retrive :', error);
        }
      };
      userData();
      setModalClosed(false);
    }
  }, [modalClosed]);

  const loadMore = () => {
    let cPage = currentPage;
    const params = {
      page: cPage + 1,
      user_name: userName
    };
    // setPageParams(params);
    recallApiWithNewParams(params);
    setTimeout(() => {
      window.scrollTo(0, document.documentElement.scrollHeight);
    }, 1000);
  };

  const handleSearch = (e) => {
    // setTrackProgress([]);
    // const params = {
    //   user_name: e.target.value,
    // };
    // // setSearchParams(params);
    // recallApiWithNewParams(params);


    const searchTerm = e.target.value;
    if (e.keyCode === 13) {
      if (searchTerm.length >= 3) {

        setTrackProgress([]);
        setUserName(e.target.value)
        const params = {
          user_name: e.target.value,
        };
        recallApiWithNewParams(params);
      } else {
        setUserName(searchTerm)
        if (searchTerm === '') {

          const params = {
            user_name: e.target.value,
          };
          recallApiWithNewParams(params);
        }
      }
    }
  };

  const onSendReNotification1 = async (certificateTypeId, assignTo) => {
    try {
      setReNotifyLoading(true);
      const { responseCode: reNotify } = await sendReNotification({
        certificate_id: certificateTypeId,
        user_id: assignTo,
      });
      if (reNotify.data.status === "success") {
        setModalShow(true);
      } else {
        console.log("Something went wrong .");
      }
    } catch (error) {
      console.log("Something went wrong .");
    }
    setReNotifyLoading(false);
  };

  const onSendReNotification = async (certificateTypeId, assignTo, ucId) => {
    try {
      // Set loading state for the specific certificate
      setCertificateLoading((prevLoading) => ({
        ...prevLoading,
        [ucId]: true,
      }));

      const { responseCode: reNotify } = await sendReNotification({
        certificate_id: certificateTypeId,
        user_id: assignTo,
        uc_id: ucId
      });

      if (reNotify.data.status === "success") {
        setModalShow(true);
      } else {
        console.log("Something went wrong.");
      }
    } catch (error) {
      console.log("Something went wrong.");
    } finally {
      // Reset loading state for the specific certificate
      setCertificateLoading((prevLoading) => ({
        ...prevLoading,
        [ucId]: false,
      }));
    }
  };


  const renderCertificate = (certificateData, certificateName) => {
    let findReCertificate = undefined;
    if (certificateName === "bls_certification") {
      findReCertificate = certificateData.filter(
        (subItem) => subItem.short_name === "bls_re_certification"
      );
    }

    if (certificateName === "acls_certification") {
      findReCertificate = certificateData.filter(
        (subItem) => subItem.short_name === "acls_re_certification"
      );
    }

    if (certificateName === "pals_certification") {
      findReCertificate = certificateData.filter(
        (subItem) => subItem.short_name === "pals_re_certification"
      );
    }

    if (findReCertificate === undefined || findReCertificate.length === 0) {
      findReCertificate = certificateData.filter(
        (subItem) => subItem.short_name === certificateName
      );
    }

    if (findReCertificate !== undefined) {
      return (
        <>
          {findReCertificate.map((certificate) => (
            <>
              {certificate.certificate_status != "n/a" && (
                <>
                  <div className="col-lg-4 col-6  track-progress-page">
                    <div className="card add-cards-changes">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="bls-view-details-title-track">
                            {(certificate.short_name === "bls_certification" ||
                              certificate.short_name ===
                              "bls_re_certification") && <>BLS </>}
                            {(certificate.short_name === "acls_certification" ||
                              certificate.short_name ===
                              "acls_re_certification") && <>ACLS </>}
                            {(certificate.short_name === "pals_certification" ||
                              certificate.short_name ===
                              "pals_re_certification") && <>PALS </>}
                          </div>
                        </div>

                        <div className="col-lg-2 col-2">
                          {certificate.certificate_status === "assigned" && (
                            <>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id="myteams-title"
                                    className="myteam"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="teams-cards">
                                      <ul>
                                        <li>
                                          <span className="assigned-date-name">
                                            Expires on:{" "}
                                          </span>
                                          <br />{" "}
                                          <span className="assigned-date">
                                            {moment(
                                              certificate.expiration_date
                                            ).format("M/D/YYYY")}
                                          </span>
                                        </li>
                                        <li>
                                          <span className="assigned-date-name">
                                            Last notified date:{" "}
                                          </span>
                                          <br />
                                          <span className="assigned-date">
                                            {certificate.last_notify_date !=
                                              null ? (
                                              <>
                                                {moment(
                                                  certificate.last_notify_date
                                                ).format("M/D/YYYY")}
                                              </>
                                            ) : (
                                              <> - </>
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <img
                                    src={info}
                                    alt="info"
                                    width="30"
                                    style={{ pointerEvents: "none" }}
                                    className="info-img-teams"
                                  />{" "}
                                  <br />
                                </span>
                              </OverlayTrigger>
                            </>
                          )}
                          {certificate.certificate_status ===
                            "assign_expired" && (
                              <>
                                <OverlayTrigger
                                  key="bottom"
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="myteams-title"
                                      className="myteam"
                                      style={{ backgroundColor: "white" }}
                                    >
                                      <div className="teams-cards">
                                        <ul>
                                          {/* <li><span className="assigned-date-name">Assigned on: </span> <br /> <span className="assigned-date">1/1/2024</span> </li> */}
                                          <li>
                                            <span className="assigned-date-name">
                                              Expires on:{" "}
                                            </span>
                                            <br />{" "}
                                            <span className="assigned-date">
                                              {moment(
                                                certificate.expiration_date
                                              ).format("M/D/YYYY")}
                                            </span>
                                          </li>
                                          <li>
                                            <span className="assigned-date-name">
                                              Last notified date:{" "}
                                            </span>
                                            <br />
                                            <span className="assigned-date">
                                              {certificate.last_notify_date !=
                                                null ? (
                                                <>
                                                  {moment(
                                                    certificate.last_notify_date
                                                  ).format("M/D/YYYY")}
                                                </>
                                              ) : (
                                                <> - </>
                                              )}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <span className="d-inline-block">
                                    <img
                                      src={info}
                                      alt="info"
                                      width="30"
                                      style={{ pointerEvents: "none" }}
                                      className="info-img-teams"
                                    />{" "}
                                    <br />
                                  </span>
                                </OverlayTrigger>
                              </>
                            )}
                        </div>
                      </div>

                      {certificate.certificate_status === "assigned" && (
                        <>
                          <div className="row" id="progress-track">
                            <div className="col-lg-3 col-3">
                              <img src={Assigned} width="50" alt="right1" />
                            </div>
                            <div className="col-lg-9 col-9">
                              <span className="valid-till">Expiration:</span>{" "}
                              <br />
                              <span className="manager-sub-title-completed valid-date">
                                {moment(certificate.assign_date).format(
                                  "M/D/YYYY"
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            {/* <button
                              type="button"
                              className="manager-renotify"
                              onClick={() =>
                                onSendReNotification(
                                  certificate.certificate_type_id,
                                  certificate.assign_to
                                )
                              }
                              disabled={reNotifyLoading}
                            >

                              {reNotifyLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Re-Notify"}
                            </button> */}
                            <button
                              type="button"
                              className="manager-renotify"
                              onClick={() =>
                                onSendReNotification(
                                  certificate.certificate_type_id,
                                  certificate.assign_to,
                                  certificate.id
                                )
                              }
                              disabled={certificateLoading[certificate.id]}
                            >
                              {certificateLoading[certificate.id] ? (
                                <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />
                              ) : (
                                "Re-Notify"
                              )}
                            </button>
                          </div>
                        </>
                      )}
                      {certificate.certificate_status === "assign_expired" && (
                        <>
                          <div className="row" id="progress-track">
                            <div className="col-lg-3 col-3">
                              <img
                                src={AssignedExpired}
                                width="50"
                                alt="right1"
                              />
                            </div>
                            <div className="col-lg-9 col-9">
                              <span className="valid-till">Expiration:</span>{" "}
                              <br />
                              <span className="manager-sub-title-completed valid-date">
                                {moment(certificate.expiration_date).format(
                                  "M/D/YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            {/* <button
                              type="button"
                              className="manager-renotify"
                              onClick={() =>
                                onSendReNotification(
                                  certificate.certificate_type_id,
                                  certificate.assign_to
                                )
                              }
                              disabled={reNotifyLoading}
                            >
                              {reNotifyLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Re-Notify"}
                            </button> */}
                            <button
                              type="button"
                              className="manager-renotify"
                              onClick={() =>
                                onSendReNotification(
                                  certificate.certificate_type_id,
                                  certificate.assign_to,
                                  certificate.id
                                )
                              }
                              disabled={certificateLoading[certificate.id]}
                            >
                              {certificateLoading[certificate.id] ? (
                                <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />
                              ) : (
                                "Re-Notify"
                              )}
                            </button>
                          </div>
                        </>
                      )}
                      {certificate.certificate_status === "active" && (
                        <div className="row" id="progress-track">
                          <div className="col-lg-3 col-3">
                            <img src={Right} width="50" alt="right1" />
                          </div>
                          <div className="col-lg-9 col-9">
                            <span className="valid-till">Expiration:</span>{" "}
                            <br />
                            <span className="manager-sub-title-completed valid-date">
                              {moment(certificate.expiration_date).format(
                                "M/D/YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {certificate.certificate_status === "expiring_soon" && (
                        <>
                          <div className="row" id="progress-track">
                            <div className="col-lg-3 col-3">
                              <img src={Error} width="50" alt="error11" />
                            </div>
                            <div className="col-lg-9 col-9">
                              <span className="valid-till">Expiration:</span>{" "}
                              <br />
                              <span className="manager-sub-title-completed valid-date">
                                {moment(certificate.expiration_date).format(
                                  "M/D/YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="row"></div>
                        </>
                      )}
                      {certificate.certificate_status === "expired" && (
                        <div className="row" id="progress-track">
                          <div className="col-lg-3 col-3">
                            <img src={Close} width="50" alt="right1" />
                          </div>
                          <div className="col-lg-9 col-9">
                            <span className="valid-till">Expiration:</span>{" "}
                            <br />
                            <span className="manager-sub-title-completed valid-date">
                              {moment(certificate.expiration_date).format(
                                "M/D/YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <Header />

      <section className="pt-10 pb-5 feature-promo bg-light">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-12">
              <div className="d-flex" id="search-btn-id">
                <span className="track-progress-span">
                  <img src={Search} width="20" alt="search" height="20" />
                </span>
                <input
                  type="text"
                  className="progress-search-btn"
                  placeholder="Search"
                  onKeyDown={handleSearch}
                />
              </div>
            </div>
          </div>

          {!loading && (
            <>
              {trackProgress.length === 0 && (
                <>
                  <div className="text-center">
                    <h5 className="valid-till">No Track Progress Found.</h5>
                  </div>
                </>
              )}
              {trackProgress.map((item, index) => (
                <>
                  <div className="card view-teams-card pt-2 pb-2">
                    <div className="row">
                      <div className="col-lg-1 col-3">
                        <div className="team-user-name text-capitalize">{`${item.first_name.charAt(
                          0
                        )}${item.last_name.charAt(0)}`}</div>
                      </div>
                      <div className="col-lg-11 col-9">
                        <div className="teams-name-title">
                          <h6 className="mb-3 text-capitalize">
                            <span> Name:</span>{" "}
                            {`${item.first_name} ${item.last_name}`}
                          </h6>
                          <h6>
                            <span>Email</span> {item.email}{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-5">
                    {renderCertificate(item.certification, "bls_certification")}
                    {renderCertificate(
                      item.certification,
                      "acls_certification"
                    )}
                    {renderCertificate(
                      item.certification,
                      "pals_certification"
                    )}
                  </div>
                </>
              ))}
            </>
          )}

          {trackProgress.length !== 0 &&
            <>
              {currentPage < perPage.last_page && (
                <button onClick={loadMore} className="load-more" type="button">
                  {loadMoreLoading ? 'Loading...' : 'Load More'}
                </button>
              )}
            </>
          }
          <div className="container d-flex align-items-center justify-content-center vh-80">
            {loading && (
              <Watch
                height="80"
                width="80"
                radius="48"
                color="#2f99a9"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            )}
          </div>
          <div className="container d-flex align-items-center justify-content-center vh-80">
            {loadMoreLoading && !loading && (
              <Watch
                height="70"
                width="70"
                radius="48"
                color="#2f99a9 "
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            )}
          </div>
        </div>
        <ScrollToTop
          className="scroll-to-button"
          color="white"
          width="20"
          height="20"
          smooth
        />
      </section>

      {/* <SendNotificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
      <SendNotificationModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setModalClosed(true);
        }}
      />
    </>
  );
}

export default TrackProgressPage;
