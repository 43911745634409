import { useState, useEffect } from "react";

import Chapter from '../api/CertificateChapter';

const UseChapter = ({sub_type}) => {
    const [ChapterName, setChapterName] = useState([]);
  
    useEffect(() => {
      const getChapterName = async () => {
        try {
          const response = await Chapter.get({
            params: { sub_type },
          });
  
          setChapterName(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getChapterName();
    }, []);
  
    return {ChapterName };
   
}



export default UseChapter;