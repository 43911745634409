import React, { useState, useEffect } from "react";
import Header from "../../../components/emplyoeeSite/Layouts/Header";
import { Tab, Nav } from 'react-bootstrap';

import Footer from "../../../components/website/Layouts/Footer";
import UseChapter from "../../../hooks/UseChapter";
import { useNavigate } from 'react-router-dom';
import Rightcheck from '../../../assets/web-images/images/Right11.svg'
import { useParams } from 'react-router-dom';
import { ThreeDots } from "react-loader-spinner";
import PrintAlgorithm from "../../../components/website/Algorithms/Modal/PrintAlgorithm";
import UseEmployeeQuestionsAnswer from '../../../hooks/UseEmployeeQuestionsAnswer'
import Cookies from 'universal-cookie'
import Questions from "../../../api/Questions";
import Chapter from "../../../api/CertificateChapter";
import UseaddNextSection from "../../../hooks/UseaddNextSection";
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../components/website/CertificateCard";

export default function MainPage() {

    const type = useParams();
    const { sub_type } = type;

    const [ChapterName, setChapterName] = useState([]);
    const [chapterNameSlug, setChapterNameSlug] = useState('');
    const [nextChapterId, setNextChapterId] = useState('');
    const [nextChapterTopicId, setNextChapterTopicId] = useState('');
    const [loading, setLoading] = useState(true);
    const [chapterLoading, setChapterLoading] = useState(false);
    const [algorithmModal, setAlgorithmModal] = useState(false);
    const [printImageUrl, setPrintImageUrl] = useState("");


    // review questions
    const [errorTestQus, setErrorTestQus] = useState('');
    const [anwserArray, setAnswerArray] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [allAnswerSelected, setAllAnswerSelected] = useState(true);
    const [responseQuestion, setResponseQuestion] = useState({});
    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const [quizSubmitted, setQuizSubmitted] = useState(false);
    const [getQuestions, setGetQuestions] = useState([]);
    const [getTotalIncorrect, setGetTotalIncorrect] = useState();
    const [getTotalCorrect, setGetTotalCorrect] = useState();
    const [getExamResult, setGetExamResult] = useState();
    const [getCertificateType, setGetCertificateType] = useState();
    const [showReExamButton, setShowReExamButton] = useState(true);

    const { ChapterName: chapterNameApi } = UseChapter({
        sub_type,
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchChapterName();
    }, [chapterNameApi]);

    const fetchChapterName = async () => {

        if (chapterNameApi.status === "success") {
            setLoading(false);
            let chapterData = chapterNameApi.data;
            if (chapterData.length > 0) {
                let [chapter] = chapterData;

                if (chapter) {
                    let chapterSlug = chapter.chapter_name_slug;
                    let divId = "left-tabs-example-tab-" + chapterNameSlug;
                    if (chapter.exam_result === "pass") {
                        setResponseQuestion({
                            test_name: chapterSlug,
                            topic_id: ""
                        });
                    }

                    setChapterNameSlug(chapterSlug)

                    let div = document.getElementById(divId);
                    setTimeout(() => {
                        if (div !== null) {
                            div.click();
                        }
                    }, 500);
                }
            }
            setChapterName(chapterNameApi.data);
        }
    }

    const fetchChapterAfterAnswer = async (chapterName = "", isTopic = false) => {
        const response = await Chapter.get({
            params: { sub_type },
        }).then((res) => {
            return res.data;
        });
        if (response.status !== undefined && response.status === "success") {
            const data = response.data;

            let chapterSlug = "";
            let exam_result = "";
            if (!isTopic) {
                const findChapter = data.find((item) => item.chapter_name_slug === chapterName);
                if (findChapter) {
                    chapterSlug = findChapter.chapter_name_slug;
                    exam_result = findChapter.exam_result;
                }

            } else {
                let findChapter = data.find(item => item.chapter_topic.some(topic => topic.topic_name_slug === chapterName));
                if (findChapter === undefined) {
                    findChapter = data.find(item => item.chapter_name_slug === chapterName);
                }
                if (findChapter !== undefined) {
                    chapterSlug = chapterName;
                    exam_result = findChapter.exam_result;
                }
            }

            if (chapterSlug !== "") {
                let divId = "left-tabs-example-tab-" + chapterSlug;
                if (exam_result === "pass") {
                    setResponseQuestion({
                        test_name: chapterSlug,
                        topic_id: ""
                    });
                }

                setChapterNameSlug(chapterSlug);
                // let div = document.getElementById(divId);
                // if (div !== null) {
                //     div.click();
                // }
            }
            setChapterName(data);
        }
    }

    const handleChapterNameSlug = (slug, examResult = "", topic_id = "") => {
        if (slug !== "" && examResult === "" && topic_id !== "") {
            setChapterNameSlug(slug);
            setResponseQuestion({});
            setQuizSubmitted(false);
            setGetExamResult(undefined);
            let divId = "left-tabs-example-tab-" + slug;
            let div = document.getElementById(divId);
            if (div !== null) {
                div.click();
            }
        } else {
            setResponseQuestion({});
            setQuizSubmitted(false);
            setChapterNameSlug(slug);
            setGetExamResult(undefined);
            if (examResult === "pass") {
                setResponseQuestion({
                    test_name: slug,
                    topic_id,
                });
            }
        }
    };


    const handleNextChapter = (nextChapterSlug) => {
        setChapterNameSlug(nextChapterSlug);
        setTimeout(() => {
            let divId = "left-tabs-example-tab-" + nextChapterSlug;
            let div = document.getElementById(divId);
            if (div !== null) {
                div.click();
            }
        }, 0);
        
    };

    const {isCreating, addedNextSection} = UseaddNextSection();

    const handleNextChapterForNoQus = async(nextChapterSlug,id,tId) => {
        
        setChapterNameSlug(nextChapterSlug);
        setNextChapterId(id);
        if (tId == 6) {
            tId = 5
        } else if(tId == 7) {
            tId = 6
        }else{
            setNextChapterTopicId(tId); 
        }
        const{
            responseCode:nextSection
        } = await addedNextSection({'chapter_id': id ,'topic_id':tId});
        if (nextSection.status === 'success') {
            setTimeout(() => {
                let divId = "left-tabs-example-tab-" + nextChapterSlug;
                let div = document.getElementById(divId);
                if (div !== null) {
                    div.click();
                }
            }, 0);
            setResponseQuestion({
                nextChapterSlug, tId
            });
            setChapterNameSlug(chapterNameSlug);
            await fetchChapterAfterAnswer(nextChapterSlug, tId !== '');
        }

        
        
    };

    const extractImageSrc = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const imgElement = doc.querySelector('img.clickable-img');

        if (imgElement) {
            return imgElement.getAttribute('src');
        }
        return null;
    };

    const openPrintModel = (imageUrl) => {
        if (extractImageSrc(imageUrl) !== null) {
            setPrintImageUrl(extractImageSrc(imageUrl));
            setAlgorithmModal(true);
        }
    }

    // review question onchange function 
    const handleChange = (e, questionId, topicQuestionLength = 0, chapterQuestionsLength = 0) => {
        const selectedValue = e.target.value;
        setSelectedAnswer(selectedValue);

        const dataArray = anwserArray;
        const index = dataArray.findIndex(item => item.question_id === questionId);
        if (index === -1) {
            const newAnswer = {
                employee_id: userDetails.id,
                question_id: questionId,
                answer_value: selectedValue
            };
            dataArray.push(newAnswer);
        } else {
            dataArray[index].answer_value = selectedValue;
        }
        setAnswerArray([...dataArray]);

        const [questions] = ChapterName;

        if (questions.chapter_questions.length === anwserArray.length) {
            setAllAnswerSelected(false);
        } else if (topicQuestionLength === anwserArray.length) {
            setAllAnswerSelected(false);
        }
        else if (chapterQuestionsLength === anwserArray.length) {
            setAllAnswerSelected(false);
        }
    };


    // review question onsubmit function 

    const { isCreate, createAnswer } = UseEmployeeQuestionsAnswer();

    useEffect(() => {
        getResultAnswer();
    }, [responseQuestion]);

    const getResultAnswer = async () => {
        const getResult = await Questions.getResult({
            params: {
                test_name: responseQuestion.test_name,
                topic_id: responseQuestion.topic_id ? responseQuestion.topic_id : '',
            },
        }).then((res) => {
            return res.data;
        }).catch((err) => { return err.message; });

        if (getResult.status === 'success') {
            console.log(getResult.data,'getResult.data');
            setGetQuestions(getResult.data);
            setGetTotalIncorrect(getResult.total_incorrect);
            setGetTotalCorrect(getResult.total_correct);
            setGetExamResult(getResult.result);
            setGetCertificateType(getResult.certificate_type_slug);
            setQuizSubmitted(true);
        } else {
            setGetQuestions([]);
            setQuizSubmitted(false);
        }
    }

    const handleSubmit = async (test_name = chapterNameSlug, topic_id = '') => {
        setChapterLoading(true)
        if (!allAnswerSelected) {
            const {
                responseCode: createAnswerResponseCode
            } = await createAnswer({ 'answers': anwserArray, 'chapter_name_slug': test_name, 'topic_id': topic_id });

            if (createAnswerResponseCode.status === "success") {
                setResponseQuestion({
                    test_name, topic_id
                });
                // setChapterNameSlug(chapterNameSlug);
                await fetchChapterAfterAnswer(test_name, topic_id !== '');
               
                window.scrollTo(0, document.body.scrollHeight - 1300);
               
            }
        }
        setChapterLoading(false);
    };

    function handleReExamClick() {
        setQuizSubmitted(false)
    }

    return (
        <>
            <Header />
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-5">
                        <div className="card text-center title-section">
                            <>
                                <p className="title">{sub_type.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}</p>
                            </>
                        </div>
                        <div className="row pt-5">
                            {!loading && (
                                <Tab.Container id="left-tabs-example" defaultActiveKey={chapterNameSlug} onSelect={() => window.scrollTo(0, 0)}>
                                    <div className="col-lg-4">
                                        <div className="card employee-home">
                                            <Nav variant="pills" className="flex-column">
                                                {
                                                    ChapterName.map((item) =>
                                                        <Nav.Item key={item.chapter_name_slug}>
                                                            <Nav.Link eventKey={item.chapter_name_slug}
                                                                className={`home-tabs-color ${item.chapter_name_slug.includes('final_test') ? item.exam_finish === false : item.exam_finish === false && item.chapter_have_question === true ? 'disabled' : ''}`}
                                                                onClick={() => {
                                                                    handleChapterNameSlug(item.chapter_name_slug, item.exam_question_result);
                                                                    if (sub_type === 'basic_life_support' && item.chapter_name_slug === 'final_test') {
                                                                        navigate(`/employee/start-final-test/basic_life_support`);
                                                                    }
                                                                    if (sub_type === 'basic_life_support_re_certification' && item.chapter_name_slug === 'final_test_bls') {
                                                                        navigate(`/employee/start-final-test/basic_life_support_re_certification`);
                                                                    }
                                                                    if (sub_type === 'advanced_cardiovascular_life_support' && item.chapter_name_slug === 'acls_final_test') {
                                                                        navigate(`/employee/start-final-test/advanced_cardiovascular_life_support`);
                                                                    }
                                                                    if (sub_type === 'advanced_cardiovascular_life_support_re_certification' && item.chapter_name_slug === 'acls_re_final_test') {
                                                                        navigate(`/employee/start-final-test/advanced_cardiovascular_life_support_re_certification`);
                                                                    }
                                                                    if (sub_type === 'pediatric_advanced_life_support' && item.chapter_name_slug === 'pals_final_test') {
                                                                        navigate(`/employee/start-final-test/pediatric_advanced_life_support`);
                                                                    }
                                                                    if (sub_type === 'pediatric_advanced_life_support_re_certification' && item.chapter_name_slug === 'pals_re_final_test') {
                                                                        navigate(`/employee/start-final-test/pediatric_advanced_life_support_re_certification`);
                                                                    }
                                                                    if (sub_type === 'basic_life_support' && item.chapter_name_slug === 'final_test' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/basic_life_support`);
                                                                    }
                                                                    if (sub_type === 'basic_life_support_re_certification' && item.chapter_name_slug === 'final_test_bls' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/basic_life_support_re_certification`);
                                                                    }
                                                                    if (sub_type === 'advanced_cardiovascular_life_support' && item.chapter_name_slug === 'acls_final_test' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/advanced_cardiovascular_life_support`);
                                                                    }
                                                                    if (sub_type === 'advanced_cardiovascular_life_support_re_certification' && item.chapter_name_slug === 'acls_re_final_test' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/advanced_cardiovascular_life_support_re_certification`);
                                                                    }
                                                                    if (sub_type === 'pediatric_advanced_life_support' && item.chapter_name_slug === 'pals_final_test' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/pediatric_advanced_life_support`);
                                                                    }
                                                                    if (sub_type === 'pediatric_advanced_life_support_re_certification' && item.chapter_name_slug === 'pals_re_final_test' && item.final_exam_result === 'pass') {
                                                                        navigate(`/employee/final-exam-result/pediatric_advanced_life_support_re_certification`);
                                                                    }
                                                                }}
                                                                disabled={item.chapter_name_slug.includes('final_test') ? !item.exam_finish : (!item.exam_finish && item.chapter_have_question)}
                                                            >
                                                                {item.chapter_name}
                                                                {item.chapter_name_slug !== 'final_test' && item.chapter_name_slug !== 'pals_final_test' && item.chapter_name_slug !== 'acls_final_test' && item.chapter_name_slug !== 'final_test_bls' && item.chapter_name_slug !== 'pals_re_final_test'  && item.chapter_name_slug !== 'acls_re_final_test' && (
                                                                    <>
                                                                        
                                                                        
                                                                        {item.chapter_questions.length !== 0  && item.exam_question_result === 'pass' && item.chapter_have_question === false ? (
                                                                            <img src={Rightcheck} alt="right-check" width='20' className="float-end" />
                                                                        ) : item.is_next_section && item.exam_question_result === 'pass'  ? (
                                                                            <img src={Rightcheck} alt="right-check" width='20' className="float-end" />
                                                                        ) : (
                                                                            null
                                                                        )}

                                                                       

                                                                    </>
                                                                )}
                                                                {item.chapter_name_slug === 'pals_final_test' && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                                {item.chapter_name_slug === 'pals_re_final_test' && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                                {item.chapter_name_slug === 'final_test'  && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                                { item.chapter_name_slug === 'final_test_bls' && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                                {item.chapter_name_slug === 'acls_final_test' && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                                {item.chapter_name_slug === 'acls_re_final_test' && (<>{item.final_exam_result === 'pass' ? (<img src={Rightcheck} alt="right-check" width='20' className="float-end" />) : null}</>)}
                                                            </Nav.Link>

                                                            {
                                                                item.chapter_topic.map((topics) =>
                                                                    <Nav.Link eventKey={topics.topic_name_slug}
                                                                        className='home-tabs-color addTopices'
                                                                        onClick={() => {
                                                                            handleChapterNameSlug(topics.topic_name_slug, topics.is_pass ? 'pass' : '', topics.id);
                                                                        }}
                                                                        disabled={!topics.exam_finish}
                                                                    >
                                                                        {topics.topic_name}
                                                                        
                                                                        

                                                                        {topics.chapter_topic_questions.length !== 0 && topics.is_pass && topics.have_question ? (
                                                                            <img src={Rightcheck} alt="right-check" width='20' className="float-end" />
                                                                        ) : topics.is_topic_next_section ? (
                                                                            <img src={Rightcheck} alt="right-check" width='20' className="float-end" />
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                       
                                                                    </Nav.Link>
                                                                )
                                                            }
                                                        </Nav.Item>
                                                    )
                                                }
                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">

                                        <>
                                            <Tab.Content>
                                                {
                                                    ChapterName.map((item, index) =>
                                                        <>
                                                            <Tab.Pane eventKey={item.chapter_name_slug} key={item.chapter_name_slug}>
                                                                <section className="pt-3 text-black">
                                                                    <div className="container">
                                                                        <div className="row general-border">
                                                                            <div className="col-lg-12">
                                                                                <p className="general-course">{item.chapter_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-5">
                                                                            <>
                                                                                <div key={index}>
                                                                                    <div className="general-content" dangerouslySetInnerHTML={{ __html: item.chapter_content }} onClick={() => openPrintModel(item.chapter_content)} />
                                                                                </div>
                                                                            </>
                                                                        </div>
                                                                        <div className="text-left mt-3">
                                                                            {item.is_question &&
                                                                                <>
                                                                                    <div className="card p-3">
                                                                                        <p className="review-qus-page">Review Questions:</p>
                                                                                        <div className='row qus-name-changes'>
                                                                                            {chapterNameSlug === 'bls_for_adults' &&
                                                                                                <>
                                                                                                    <p className="test-res-qus res-qus test-sample">
                                                                                                        Megacode Scenario: You are a witness to a middle-aged woman collapse and become unresponsive. You are the first responder on the scene.
                                                                                                    </p>
                                                                                                </>
                                                                                            }

                                                                                            {quizSubmitted ? (
                                                                                                <>

                                                                                                    {getQuestions.map((item, index) => (
                                                                                                        <>
                                                                                                            <div className="row pt-3">

                                                                                                                <p className="test-res-qus res-qus test-sample">
                                                                                                                    {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: item.question }} />
                                                                                                                </p>

                                                                                                                {
                                                                                                                    item.correct_answer === item.answer_value ?
                                                                                                                        <>
                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={item.option_1 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={item.option_2 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                                                                                </div>
                                                                                                                            </div>


                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={item.option_3 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus mb-5'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={item.option_4 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={
                                                                                                                                        item.option_1 === item.answer_value ?
                                                                                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                                                                                            : item.option_1 === item.answer_value
                                                                                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={
                                                                                                                                        item.option_2 === item.answer_value ?
                                                                                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                                                                                            : item.option_2 === item.answer_value
                                                                                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={
                                                                                                                                        item.option_3 === item.answer_value ?
                                                                                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                                                                                            : item.option_3 === item.answer_value
                                                                                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus mb-5'>
                                                                                                                                <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                    <div className={
                                                                                                                                        item.option_4 === item.answer_value ?
                                                                                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                                                                                            : item.option_4 === item.answer_value
                                                                                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                }

                                                                                                            </div>
                                                                                                            <hr />
                                                                                                        </>
                                                                                                    ))}

                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-7"></div>
                                                                                                        <div className="col-lg-5">

                                                                                                            <div className="box-row-res start-test-res">
                                                                                                                <div>
                                                                                                                    <i className="fa fa-check pass-check"></i> <span className="pass-qus">{getTotalCorrect}</span>
                                                                                                                </div>
                                                                                                                <div className="fail-qus">
                                                                                                                    <div className="fail-qus-res ml-15">

                                                                                                                        <i className="fa fa-times fail-titme" aria-hidden="true"></i> <span className="pass-qus">{getTotalIncorrect}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="col-lg-7">
                                                                                                            
                                                                                                        </div>
                                                                                                        <div className="col-lg-5">

                                                                                                            {getExamResult !== 'Failed' ?
                                                                                                                <>

                                                                                                                    <div className="test-result-btn-green">
                                                                                                                        <a>Test Result: Pass</a>
                                                                                                                    </div>

                                                                                                                </> : <>

                                                                                                                    <div className="test-result-btn">
                                                                                                                        <a>Test Result: Fail</a>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div>
                                                                                                        {item.chapter_questions.map((questionItem, index) => (
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    <label className="test-sample">
                                                                                                                        {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: questionItem.question.question }} />
                                                                                                                    </label>

                                                                                                                </div>

                                                                                                                <div className="row">
                                                                                                                    <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                        <div>
                                                                                                                            <input id={`${questionItem.id}_${'option1'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"
                                                                                                                                value={questionItem.question.option_1}
                                                                                                                                
                                                                                                                                onChange={(e) => handleChange(e, questionItem.question_id, item.chapter_questions.length)}
                                                                                                                            />
                                                                                                                            <label htmlFor={`${questionItem.id}_${'option1'}`} className="radio-custom-label">{questionItem.question.option_1}</label>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                        <div>
                                                                                                                            <input id={`${questionItem.id}_${'option2'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"
                                                                                                                                value={questionItem.question.option_2} onChange={(e) => handleChange(e, questionItem.question_id, item.chapter_questions.length)} />
                                                                                                                            <label htmlFor={`${questionItem.id}_${'option2'}`} className="radio-custom-label">{questionItem.question.option_2}</label>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                        <div>
                                                                                                                            <input id={`${questionItem.id}_${'option3'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.question.option_3}
                                                                                                                                onChange={(e) => handleChange(e, questionItem.question_id, item.chapter_questions.length)} />
                                                                                                                            <label htmlFor={`${questionItem.id}_${'option3'}`} className="radio-custom-label">{questionItem.question.option_3}</label>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                        <div>
                                                                                                                            <input id={`${questionItem.id}_${'option4'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.question.option_4}
                                                                                                                                onChange={(e) => handleChange(e, questionItem.question_id, item.chapter_questions.length)} />
                                                                                                                            <label htmlFor={`${questionItem.id}_${'option4'}`} className="radio-custom-label">{questionItem.question.option_4}</label>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <hr />
                                                                                                                <br />
                                                                                                                {errorTestQus && <div className="error">{errorTestQus}</div>}
                                                                                                            </>
                                                                                                        ))}

                                                                                                       

                                                                                                    </div>
                                                                                                </>
                                                                                            )}



                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="row">
                                                                                        <div className="col-lg-8 text-left">
                                                                                           

                                                                                            {getExamResult === undefined &&
                                                                                                <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(item.chapter_name_slug, item.chapter_topic_id) }} disabled={allAnswerSelected}>
                                                                                                    {chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}
                                                                                                </a>
                                                                                            }
                                                                                            {getExamResult === "Passed" && responseQuestion.test_name === undefined && item.exam_result !== 'pass' &&
                                                                                                <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(item.chapter_name_slug, item.chapter_topic_id) }} disabled={allAnswerSelected}>
                                                                                                    {chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}
                                                                                                </a>
                                                                                            }
                                                                                            {getExamResult === 'Failed' &&
                                                                                                <>
                                                                                                    <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(item.chapter_name_slug, item.chapter_topic_id) }} disabled={allAnswerSelected}>{chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}</a>
                                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                                    
                                                                                                    {quizSubmitted && (
                                                                                                        <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={handleReExamClick}>Re-Exam</a>
                                                                                                    )}
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-lg-4 text-end">{
                                                                                            getExamResult !== undefined && getExamResult !== "Failed" && responseQuestion.test_name !== undefined ? (
                                                                                                <>
                                                                                                    {(responseQuestion.test_name === 'post_cardiac_arrest_care' || responseQuestion.test_name === 'post_cardiac_arrest_care_acls' || 
                                                                                                    responseQuestion.test_name === 'choking_relief_for_adults,_children_and_infants' || responseQuestion.test_name === 'choking_relief_for_adults,_children_and_infants_bls' ||
                                                                                                    responseQuestion.test_name === 'post_cardiac_arrest_care_pals_re' || responseQuestion.test_name === 'post_cardiac_arrest_care_pals') &&

                                                                                                        <> 
                                                                                                        <a className="btn btn-primary next-section-blue mt-4" onClick={() => { handleNextChapter(item.next_chapter_slug) }}>Begin Final Test</a>
                                                                                                        </> 
                                                                                                    }
                                                                                                    {(responseQuestion.test_name !== 'post_cardiac_arrest_care' && responseQuestion.test_name !== 'post_cardiac_arrest_care_acls' &&
                                                                                                    responseQuestion.test_name !== 'choking_relief_for_adults,_children_and_infants' && responseQuestion.test_name !== 'choking_relief_for_adults,_children_and_infants_bls' &&
                                                                                                    responseQuestion.test_name !== 'post_cardiac_arrest_care_pals_re' && responseQuestion.test_name !== 'post_cardiac_arrest_care_pals'
                                                                                                    ) &&
                                                                                                        <a className="btn btn-primary next-section-blue mt-4" onClick={() => { handleNextChapter(item.next_chapter_slug) }}>
                                                                                                            Next Section
                                                                                                            </a>
                                                                                                    }

                                                                                                </>
                                                                                            ) : getExamResult === 'Failed' && responseQuestion.test_name !== undefined ? (
                                                                                                <>
                                                                                                
                                                                                                    {responseQuestion.test_name !== 'choking_relief_for_adults,_children_and_infants_bls'&& 
                                                                                                        responseQuestion.test_name !== 'choking_relief_for_adults,_children_and_infants' && 
                                                                                                        responseQuestion.test_name !== 'post_cardiac_arrest_care_pals_re' && 
                                                                                                        responseQuestion.test_name !== 'post_cardiac_arrest_care_pals' && 
                                                                                                        responseQuestion.test_name !== 'post_cardiac_arrest_care' && 
                                                                                                        responseQuestion.test_name !== 'post_cardiac_arrest_care_acls' && 
                                                                                                        (
                                                                                                            <a className="btn btn-next-section-click mt-4">Next Section</a>
                                                                                                        )
                                                                                                    }
                                                                                                    
                                                                                                    { responseQuestion.test_name === 'post_cardiac_arrest_care'&&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    { responseQuestion.test_name === 'post_cardiac_arrest_care_acls'&&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    { responseQuestion.test_name === 'choking_relief_for_adults,_children_and_infants_bls'&&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    {responseQuestion.test_name === 'choking_relief_for_adults,_children_and_infants' &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    { responseQuestion.test_name === 'post_cardiac_arrest_care_pals'&&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    {responseQuestion.test_name === 'post_cardiac_arrest_care_pals_re' &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                
                                                                                                    {chapterNameSlug === "post_cardiac_arrest_care"  &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    {chapterNameSlug === "post_cardiac_arrest_care_acls"  &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    
                                                                                                    {/* {chapterNameSlug === 'post_cardiac_arrest_care_pals' || chapterNameSlug === 'post_cardiac_arrest_care_pals_re'
                                                                                                    &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    } */}

                                                                                                        {chapterNameSlug === 'post_cardiac_arrest_care_pals' &&
                                                                                                            <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                        }
                                                                                                        {chapterNameSlug === 'post_cardiac_arrest_care_pals_re' &&
                                                                                                            <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                        }
                                                                                                    
                                                                                                    { chapterNameSlug === 'choking_relief_for_adults,_children_and_infants_bls'&&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    {chapterNameSlug === 'choking_relief_for_adults,_children_and_infants' &&
                                                                                                        <a className="btn btn-next-section-click mt-4">Begin Final Test</a>
                                                                                                    }
                                                                                                    {chapterNameSlug !== "post_cardiac_arrest_care" && chapterNameSlug !== "post_cardiac_arrest_care_acls" &&
                                                                                                    chapterNameSlug !== 'choking_relief_for_adults,_children_and_infants' && chapterNameSlug !== 'choking_relief_for_adults,_children_and_infants_bls' &&
                                                                                                    chapterNameSlug !== 'post_cardiac_arrest_care_pals' && chapterNameSlug !== 'post_cardiac_arrest_care_pals_re' &&
                                                                                                        <><a className="btn btn-next-section-click mt-4">Next Section</a>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                            }

                                                                            {(!item.chapter_have_question && !item.is_question) &&
                                                                                <div className="row">
                                                                                    <div className="col-lg-12 text-end">
                                                                                        {item.chapter_questions.length === 0 ? <><a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleNextChapterForNoQus(item.next_chapter_slug,item.id) }}>Next Section</a></> :
                                                                                        <><a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleNextChapter(item.next_chapter_slug) }}>Next Section</a></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </section>


                                                            </Tab.Pane>

                                                            {
                                                                item.chapter_topic.map((topics,) =>
                                                                    <>
                                                                        <Tab.Pane eventKey={topics.topic_name_slug} key={topics.topic_name_slug}>
                                                                            <section className="pt-3 text-black">
                                                                                <div className="container">
                                                                                    <div className="row general-border">
                                                                                        <div className="col-lg-12">
                                                                                            <p className="general-course">{topics.topic_name}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-5">
                                                                                        <>
                                                                                            <div className="general-content" key={index} dangerouslySetInnerHTML={{ __html: topics.topic_content }} onClick={() => openPrintModel(topics.topic_content)} />
                                                                                        </>
                                                                                    </div>
                                                                                    <div className="text-left mt-3">
                                                                                        {topics.have_question === true && (

                                                                                            <>


                                                                                                <div className="card p-3">
                                                                                                    <p className="review-qus-page">Review Questions:</p>
                                                                                                    <div className='row qus-name-changes'>



                                                                                                        {quizSubmitted ? (
                                                                                                            <>
                                                                                                                {getQuestions.map((item, index) => (
                                                                                                                    <>
                                                                                                                        <div className="row pt-5">

                                                                                                                            <p className="test-res-qus res-qus test-sample">
                                                                                                                                {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: item.question }} />
                                                                                                                            </p>

                                                                                                                            {
                                                                                                                                item.correct_answer === item.answer_value ?
                                                                                                                                    <>
                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={item.option_1 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={item.option_2 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={item.option_3 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus mb-5'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={item.option_4 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <>
                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={
                                                                                                                                                    item.option_1 === item.answer_value ?
                                                                                                                                                        "rounded-box answer-check-btn wrong-answer"
                                                                                                                                                        : item.option_1 === item.answer_value
                                                                                                                                                            ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                            : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                                                                                            </div>

                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={
                                                                                                                                                    item.option_2 === item.answer_value ?
                                                                                                                                                        "rounded-box answer-check-btn wrong-answer"
                                                                                                                                                        : item.option_2 === item.answer_value
                                                                                                                                                            ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                            : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={
                                                                                                                                                    item.option_3 === item.answer_value ?
                                                                                                                                                        "rounded-box answer-check-btn wrong-answer"
                                                                                                                                                        : item.option_3 === item.answer_value
                                                                                                                                                            ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                            : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        <div className='col-lg-6 qus-radio-btn-change main-page-review-qus mb-5'>
                                                                                                                                            <div className="res-sample-test-1 main-page-radio-btn-changes">
                                                                                                                                                <div className={
                                                                                                                                                    item.option_4 === item.answer_value ?
                                                                                                                                                        "rounded-box answer-check-btn wrong-answer"
                                                                                                                                                        : item.option_4 === item.answer_value
                                                                                                                                                            ? "rounded-box answer-check-btn right-answer"
                                                                                                                                                            : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                    </>
                                                                                                                ))}

                                                                                                                <div className="row">
                                                                                                                    <div className="col-lg-7"></div>
                                                                                                                    <div className="col-lg-5">

                                                                                                                        <div className="box-row-res start-test-res">
                                                                                                                            <div>
                                                                                                                                <i className="fa fa-check pass-check"></i> <span className="pass-qus">{getTotalCorrect}</span>
                                                                                                                            </div>
                                                                                                                            <div className="fail-qus">
                                                                                                                                <div className="fail-qus-res ml-15">

                                                                                                                                    <i className="fa fa-times fail-titme" aria-hidden="true"></i> <span className="pass-qus">{getTotalIncorrect}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        
                                                                                                                    </div>
                                                                                                                    <div className="col-lg-5">

                                                                                                                        {getExamResult !== 'Failed' ?
                                                                                                                            <>

                                                                                                                                <div className="test-result-btn-green">
                                                                                                                                    <a>Test Result: Pass</a>
                                                                                                                                </div>

                                                                                                                            </> : <>

                                                                                                                                <div className="test-result-btn">
                                                                                                                                    <a>Test Result: Fail</a>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }

                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </>)
                                                                                                            :
                                                                                                            (<>
                                                                                                                {topics.chapter_topic_questions.map((questionItem, index) => (
                                                                                                                    <>
                                                                                                                        <div>
                                                                                                                            <label className="test-sample">
                                                                                                                                {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: questionItem.question.question }} />
                                                                                                                            </label>

                                                                                                                        </div>

                                                                                                                        <div className="row">
                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>
                                                                                                                                <div>

                                                                                                                                    <input id={`${questionItem.id}_${'option1'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"
                                                                                                                                        value={questionItem.question.option_1}
                                                                                                                                        onChange={(e) => handleChange(e, questionItem.question_id, topics.chapter_topic_questions.length)} />
                                                                                                                                    <label htmlFor={`${questionItem.id}_${'option1'}`} className="radio-custom-label">{questionItem.question.option_1}</label>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                                <div>
                                                                                                                                    <input id={`${questionItem.id}_${'option2'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio"
                                                                                                                                        value={questionItem.question.option_2} onChange={(e) => handleChange(e, questionItem.question_id, topics.chapter_topic_questions.length)} />
                                                                                                                                    <label htmlFor={`${questionItem.id}_${'option2'}`} className="radio-custom-label">{questionItem.question.option_2}</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                                <div>
                                                                                                                                    <input id={`${questionItem.id}_${'option3'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.question.option_3}
                                                                                                                                        onChange={(e) => handleChange(e, questionItem.question_id, topics.chapter_topic_questions.length)} />
                                                                                                                                    <label htmlFor={`${questionItem.id}_${'option3'}`} className="radio-custom-label">{questionItem.question.option_3}</label>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='col-lg-6 qus-radio-btn-change main-page-review-qus'>

                                                                                                                                <div>
                                                                                                                                    <input id={`${questionItem.id}_${'option4'}`} className="radio-custom" name={`${questionItem.id}_${'name'}`} type="radio" value={questionItem.question.option_4}
                                                                                                                                        onChange={(e) => handleChange(e, questionItem.question_id, topics.chapter_topic_questions.length)} />
                                                                                                                                    <label htmlFor={`${questionItem.id}_${'option4'}`} className="radio-custom-label">{questionItem.question.option_4}</label>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                        <br />
                                                                                                                        {errorTestQus && <div className="error">{errorTestQus}</div>}

                                                                                                                    </>
                                                                                                                ))}

                                                                                                            </>)
                                                                                                        }




                                                                                                    </div>
                                                                                                </div>


                                                                                               

                                                                                                <div className="row">
                                                                                                    <div className="col-lg-8 text-left">
                                                                                                        {getExamResult === undefined &&
                                                                                                            <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(topics.topic_name, topics.id) }} disabled={allAnswerSelected}>
                                                                                                                {chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}
                                                                                                            </a>
                                                                                                        }
                                                                                                        {getExamResult === "Passed" && responseQuestion.test_name === undefined && item.exam_result !== 'pass' &&
                                                                                                            <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(topics.topic_name, topics.id) }} disabled={allAnswerSelected}>{chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}</a>
                                                                                                        }
                                                                                                        {getExamResult === 'Failed' &&
                                                                                                            <>
                                                                                                                <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleSubmit(topics.topic_name, topics.id) }} disabled={allAnswerSelected}>{chapterLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Check Answers"}</a>
                                                                                                                &nbsp;&nbsp;&nbsp;
                                                                                                                {quizSubmitted && (
                                                                                                                    <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={handleReExamClick}>Re-Exam</a>
                                                                                                                )}
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 text-end">
                                                                                                        <div className="col-lg-6 text-end w-100">
                                                                                                            {getExamResult === 'Passed' && topics.is_pass === true ?
                                                                                                                <>
                                                                                                                    <a className="btn btn-primary mt-4 home-page-start-test-btn"
                                                                                                                        onClick={() => {
                                                                                                                            (topics.is_pass && topics.have_question) && (handleChapterNameSlug(topics.next_topic_slug, '', topics.next_topic_id))
                                                                                                                        }}>Next Section</a>
                                                                                                                </> :
                                                                                                                <>

                                                                                                                    <a className="btn btn-next-section-click mt-4">Next Section</a>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                        }

                                                                                        {topics.chapter_topic_questions.length === 0 &&
                                                                                            <div className="row">
                                                                                                <div className="col-lg-12 text-end">
                                                                                                    <a className="btn btn-primary mt-4 home-page-start-test-btn" onClick={() => { handleNextChapterForNoQus(topics.next_topic_slug,item.id, topics.next_topic_id) }}>Next Section</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        }


                                                                                    </div>
                                                                                </div>
                                                                            </section>


                                                                        </Tab.Pane>

                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                            </Tab.Content>
                                        </>
                                    </div>
                                </Tab.Container>

                            )}
                            <div className="container d-flex align-items-center justify-content-center vh-80">
                                {loading && (
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="48"
                                        color="#2f99a9 "
                                        ariaLabel="watch-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PrintAlgorithm
                show={algorithmModal}
                onHide={() => setAlgorithmModal(false)}
                printImageUrl={printImageUrl}
            />



            <section className="feature-promo bg-light pt-5 employees-home-section" id="box-set">
                <div className="container">
                    <CertificateCard />
                </div>
            </section>

            <Footer /> 
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )
}