import React from "react";
import Header from "../../../components/website/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import aclsImgLogo from '../../../assets/web-images/images/ACLS-1.svg';
import AclsCard from '../../../assets/web-images/images/ACLS-CARD-1.svg';
import bls from '../../../assets/web-images/images/01-BLS-10.svg';
import Pacl from '../../../assets/web-images/images/03-PALS-10.svg';
import Arrow from '../../../assets/web-images/images/Arrow.svg';
import Carousel from 'react-bootstrap/Carousel';
import BlsImg from '../../../assets/web-images/images/BLS.svg';
import AclsImg from '../../../assets/web-images/images/ACLS.svg';
import PaclImg from '../../../assets/web-images/images/PALS.svg'
import Dp from '../../../assets/web-images/images/doctors-p.jpg';
import Logo from '../../../assets/web-images/images/Pulse-Logo.svg';
import Mail from '../../../assets/web-images/images/Mail.svg';
import BlsSmall from '../../../assets/web-images/images/Bls-small.svg';
import AclsSmall from '../../../assets/web-images/images/Acls-small.svg';
import PaclSmall from '../../../assets/web-images/images/Pals-small.svg';
import ScrollToTop from 'react-scroll-to-top';

export default function AdvancedLifeSupport() {

    const getCertificateCart = localStorage.getItem('certificateCart');
    const onClickAddToCart = (certificateId) => {
        const currentCart = new Set(JSON.parse(localStorage.getItem('certificateCart')) || []);
        const isAlreadyInCart = currentCart.has(certificateId);
        if (!isAlreadyInCart) {
            currentCart.add(certificateId);
            localStorage.setItem('certificateCart', JSON.stringify(Array.from(currentCart)));
        }
    }

    return (
        <>
            <Header />

            <section className="ptb-120 feature-promo bg-light" id="bls-class" >
                <div className="container bls-bg-img">
                    <div className="row tex-center ptb-6">
                        <img src={aclsImgLogo} width='30' height='70' alt="aclsImgLogo" />
                        <p className="bls-title">ACLS</p>
                        <p className="bls-title-sub-1">(Advanced Cardiovascular Life Support)</p>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row bls-re-crft">
                        <div className="col-lg-6 bl-col-mlb b-1-mt">
                            <div className="row">
                                <div className="col-lg-8 col-10">
                                    <h3 className="bls-certf-title-1">ACLS Re-Certification</h3>
                                </div>
                                <div className="col-lg-4 col-2">

                                    <h3 className="span-rs">$165</h3>
                                </div>
                            </div>

                            <ul className="content-list list-unstyled pt-3">
                                <li className="bls-li">Designed for providers with an active or recently expired (within 6 months) ACLS certification.</li>
                                <li className="bls-li">Entirely online. Complete on any of your devices.</li>
                                <li className="bls-li">Pass with a score of 80%.</li>
                                <li className="bls-li">Receive CME/CEU credits.</li>
                                <li className="bls-li">No in-person skills test.</li>
                            </ul>

                            <div className="text-center">
                                {getCertificateCart != null && getCertificateCart.includes(8) ?
                                    <><a className="add-to-cart-btn-a" href="/course-cart"> View Cart </a></>:
                                    <><a className="add-to-cart-btn-a" href="/course-cart" onClick={() => onClickAddToCart(5)}> Add to Cart </a></>
                                }
                            </div>

                        </div>
                        <div className="col-lg-6 bl-col-mlb">
                            <div className="card bls-card-big">

                                <img src={AclsCard} alt="Bls-Card" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row bls-re-crft">
                    <div className="col-lg-6 bl-col-mlb">
                            <div className="card bls-card-big">

                                <img src={AclsCard} alt="Bls-Card" />
                            </div>
                        </div>
                        <div className="col-lg-6 bl-col-mlb b-1-mt-md">
                            <div className="row">
                                <div className="col-lg-8 col-10">
                                    <h3 className="bls-certf-title-1">ACLS Certification</h3>
                                </div>
                                <div className="col-lg-4 col-2">

                                    <h3 className="span-rs">$195</h3>
                                </div>
                            </div>

                            <ul className="content-list list-unstyled pt-3">
                                <li className="bls-li">Certify for the first time.</li>
                                <li className="bls-li">Entirely online. Complete on any of your devices.</li>
                                <li className="bls-li">Pass with a score of 80%.</li>
                                <li className="bls-li">Receive CME/CEU credits.</li>
                                <li className="bls-li">No in-person skills test.</li>
                            </ul>

                            <div className="text-center">
                                {getCertificateCart != null && getCertificateCart.includes(7) ?
                                    <><a className="add-to-cart-btn-a" href="/course-cart"> View Cart </a></>:
                                    <><a className="add-to-cart-btn-a" href="/course-cart" onClick={() => onClickAddToCart(2)}> Add to Cart </a></>
                                }
                            </div>

                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row bls-advanced-1 ml-3 mr-3">
                        <div className="row mt-5">
                            <div className="col-lg-8">
                                <h3 className="bls-advanced-title">Advanced Provider Bundle</h3>
                            </div>
                            <div className="col-lg-4">
                                <h3 className="span-bls-rs">$330</h3>
                            </div>
                        </div>

                        <div className="row mt-4 mb-4">
                            <div className="col-lg-4">
                                <div className="card bls-card-big-1">
                                    <img src={BlsSmall} alt="blssmall" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card bls-card-big-1">
                                    <img src={AclsSmall} alt="aclsSmall" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card bls-card-big-1">
                                    <img src={PaclSmall} alt="paclsmall" />
                                </div>
                            </div>

                            <div className="row mt-5 mb-5">
                                <div>
                                    <h4 className="crtf-title">Renew all 3 certifications today!</h4>
                                    <h4 className="crtf-title">Free BLS certification with our bundle package.</h4>
                                    <div className="text-center mt-5">
                                        {getCertificateCart != null && getCertificateCart.includes(2) ?
                                            <><a className="add-to-cart-btn-a" href="/course-cart"> View Cart </a></>:
                                            <><a className="add-to-cart-btn-a" href="/course-cart" onClick={() => onClickAddToCart(7)}> Add to Cart </a></>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row bls-advanced">
                        <div className="col-lg-5">
                            <div className="avd-img-div">
                                <div className="card text-end">
                                    <img src={Dp} alt="img-doctors" className="img-doctors-1" />
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7 bg-color-avd">
                            <div className="mtb-avd">
                                <div className="mt-5">
                                    <img src={Logo} alt="log-img1" width='200px' />
                                </div>
                                <p className="courses-titles mt-5 mb-5">
                                    All Courses are  <b>100% Online</b> and <b>Guaranteed</b> to be <b>Accepted</b> or <b>Your Money Back</b>.
                                </p>
                                <hr />

                                <p className="for-more-details mt-5">For more details:</p>
                                <img src={Mail} alt="mail" width='30' /> <a href="mailto:greg@pulsecertifications.com" className="email-avd">greg@pulsecertifications.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="feature-promo bg-light pt-5">
                <div className="container">
                    <div className="row bls-advanced">
                        <div className="about-bls-1">
                            <h3 className="bls-adv-title">About ACLS (Advanced Cardiovascular Life Support)</h3>
                            <p className="bls-avd-sub-title mb-5">
                                This course builds on the foundation of lifesaving BLS skills, emphasizing the importance of continuous,
                                high-quality CPR and early use of an AED. This course is based on the up-to-date information
                                from the American Heart Association Guidelines Update for CPR and Emergency Cardiovascular Care (ECC).
                            </p>
                            <h6 className="bls-adv-title t1">This Course is for:</h6>
                            <p className="bls-avd-sub-title mb-5">
                                This course is designed for healthcare professionals who either direct or participate in the management of cardiopulmonary arrest or other cardiovascular emergencies and for personnel in emergency response.
                            </p>
                            <h6 className="bls-adv-title t1">What will you learn:</h6>
                            <ul className="content-list list-unstyled pt-3">
                                <li className="bls-avd-sub-title st1">Basic life support skills, including effective chest compressions, use of a bag-mask ventilation device, and use of an AED.</li>
                                <li className="bls-avd-sub-title st1">Recognition and early management of respiratory and cardiac arrest.</li>
                                <li className="bls-avd-sub-title st1">Recognition and early management of peri-arrest conditions such as symptomatic bradycardia.</li>
                                <li className="bls-avd-sub-title st1">Airway management.</li>
                                <li className="bls-avd-sub-title st1">Related pharmacology.</li>
                                <li className="bls-avd-sub-title st1">Management of acute coronary syndrome and stroke.</li>
                                <li className="bls-avd-sub-title st1">Effective communication as a member and leader of a resuscitation team.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="instant">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mt-5">
                            <h1 className="instant-title">Instant Card Copy</h1>
                            <p className="instant-sub-text">Upon successful completion of your course exam, you will receive a printable, digital copy of your certification card.
                            </p>
                        </div>
                        <div className="col-lg-7" id="carousel-Instant">
                            <div className="main-carousel">
                                <div className="slider-box text-center">
                                    <Carousel interval={5000}>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={BlsImg}
                                                alt="First slide"
                                                width='500'
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={AclsImg}
                                                alt="Second slide"
                                                width='500'
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={PaclImg}
                                                alt="Third slide"
                                                width='500'
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">

                                <div className="setup-process-wrap">
                                    <ul className="setup-process-list d-block list-unstyled mb-0 box-shadow-ul ">
                                        <div className="row ">
                                            <div className="col-lg-6 box-border-add">
                                                <li className="setup-process-item py-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <img src={bls} width='60' height='46' alt="aclsq" />
                                                            <span className="session-2-title-bls bls-page-2">
                                                                BLS
                                                            </span>
                                                            <h6 className="ads-sub-bls-page">(Basic Life Support)</h6>
                                                            <p className="session-2-sub-title-2">Renew your BLS or certify for the first time. 100% Online.</p>
                                                            <a href='/bls' className="view-more-bls"><span className="view-span-after">View More</span>  <img src={Arrow} alt="arrow" width='20' /> </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>

                                            <div className="col-lg-6 box-border-add Pacl-11">


                                                <li className="setup-process-item py-4 px-2 bls-1-2">
                                                    <div className="">
                                                        <div className="text-start">
                                                            <img src={Pacl} width='60' height='46' alt="pcls" />
                                                            <span className="session-2-title-bls bls-page-2">
                                                                PALS
                                                            </span>
                                                            <h6 className="ads-sub-bls-page">(Pediatric Advanced Life Support)</h6>
                                                            <p className="session-2-sub-title-2">Renew your PALS or certify for the first time. 100% Online.</p>
                                                            <a href='/pals' className="view-more-bls"><span className="view-span-after">View More</span>  <img src={Arrow} alt="arrow" width='20' /> </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>
    )
}