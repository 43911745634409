import { useState } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";
const UseDiscoutPercentage = () => {

    const [isUpdateDiscount, setIsUpdateDiscount] = useState(false);
    const EditDiscoutPercentage = async (body) => {
        setIsUpdateDiscount(true);
    
        let responseCode;
    
        try {
          const response = await Superadmin.updateDiscoutPercentage(body);
          responseCode = response.data;
        } catch (error) {
          responseCode = error.response.status;
        }
    
        setIsUpdateDiscount(false);
    
        return { responseCode };
      };
    
      return { isUpdateDiscount, EditDiscoutPercentage };
}


export default UseDiscoutPercentage;