import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Calendar from '../../../../assets/manager-images/images/calendar.svg';
import DatePicker from "react-datepicker";
import moment from "moment";
import ReAssignCourseBalanceModal from "./ReAssignCourseBalanceModal";

function ReAssignCourseModal(props) {

    const commonDateFormat = "MM/dd/Y";
    const [selectedDate, setSelectedDate] = useState("");
    const [checkDate,setCheckDate] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [openBalanceModal,setOpenBalanceModal] = useState(false);
    const [certificateType,setCertificateType] = useState('')

    const onChangeSelectDate = (date) => {
        setSelectedDate(date);
    };
    const handleSelectDate =(type)=> {
        let success = true;
        setCheckDate(false);
        setErrorMessage("");
        const todayDate = moment().format('Y-MM-DD');
        let finalSelectedDate = todayDate;
        if (type === "schedule_assignment") {
            if (!selectedDate) {
                setErrorMessage("Please select a date");
                setCheckDate(true);
                success = false;
            }
            finalSelectedDate = moment(selectedDate).format('Y-MM-DD');
        }
        if (success) {
            localStorage.setItem(props.certificateName, finalSelectedDate);
            props.onHide();
            setCertificateType(props.certificateName)
            setOpenBalanceModal(true);
        }
    }
    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
                id='addTeamMember'
            >
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="Expired-trigger" id="Expired-team-datePicker">
                                <p className="choose-date">Choose a date to assign the course </p>
                                <div className="">
                                    <span className="calendar-img-flex-assign">
                                        <img src={Calendar} className="calendar-teams" alt="calendar" />
                                    </span>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={onChangeSelectDate}
                                        dateFormat={commonDateFormat}
                                        className="date-picker"
                                        placeholderText="__/__/__"
                                    />
                                </div>
                                <div>

                                {checkDate && (<p className="error mb-0 mt-3">{errorMessage}</p>)}
                                </div>
                                <div className="btn-class-div">
                                    <button className="btn-schedule" onClick={() => { handleSelectDate('schedule_assignment') }}>
                                        Schedule Course Assignment
                                    </button>
                                </div>
                                <div className="btn-class-div">
                                    <button className="btn-schedule"  onClick={() => { handleSelectDate('assignNow') }}>
                                        Assign Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ReAssignCourseBalanceModal
                show={openBalanceModal}
                onComfirm={() => { setOpenBalanceModal(!openBalanceModal) }}
                certificateType={certificateType}
                certificateAmountBls = {props.amount2}
                certificateAmountAcls = {props.amount1}
                certificateAmountPals = {props.amount3}
            />
        </>
    )
}

export default ReAssignCourseModal;
