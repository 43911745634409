import React, { useState,useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import UseDeleteUserAssignCertificate from "../../../../hooks/manager/UseDeleteUserAssignCertificate";
import {ThreeDots} from "react-loader-spinner";
export default function RemoveAssignCertificate(props) {

    const [loading, setLoading] = useState(false);
    const {isDeleteAssignCertificate, CertificateDelete} = UseDeleteUserAssignCertificate();

    const handleRemoveCertificate = async(e) => {
        e.preventDefault();
        setLoading(true);
        const { certificateIds } = props;
        const { usercertificateIds } = props;
        const{
            responseCode:removeCertificate
        } = await CertificateDelete({'certificate_id': certificateIds,'user_certificate_id':usercertificateIds});

        if (removeCertificate.status === 'success') {
            props.onHide({
                certificateIds: certificateIds,
                usercertificateIds: usercertificateIds,
            });
        }
        setLoading(false);
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                
                <Modal.Body>
                    <p className="remove-class-title">
                    Are you sure you want to rescind this assigned course?
                    <br />
                    Your balance will be refunded for the cost of the course
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                            <button className="remove-yes-btn" onClick={handleRemoveCertificate} disabled={loading} >
                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Yes"}
                            </button>
                            <button className="remove-no-btn" onClick={props.onHide} >No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
               
            </Modal>
        </>
    )
}
