import React, { useState, useEffect } from "react";
import Header from "../../../components/website/Layouts/Header";
import Footer from "../../../components/website/Layouts/Footer";
import { useParams } from 'react-router-dom';
import UseSampleTestResult from "../../../hooks/UseSampleTestResult";
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../components/website/CertificateCard";

export default function SampleTestResult() {

    const slug = useParams();
    
    const { name_slug } = slug;
    const { employee_id } = slug;
    const [getQuestions, setGetQuestions] = useState([]);
    const [getTotalIncorrect, setGetTotalIncorrect] = useState();
    const [getTotalCorrect, setGetTotalCorrect] = useState();
    const [getCertificateType, setGetCertificateType] = useState();

    const { getQuestions: QuestionApi } = UseSampleTestResult({
        name_slug,employee_id
    });


    useEffect(() => {
        fetchChapterName();
    });

    const fetchChapterName = async () => {

        if (QuestionApi.status === "success") {
            setGetQuestions(QuestionApi.data);
            setGetTotalIncorrect(QuestionApi.total_incorrect);
            setGetTotalCorrect(QuestionApi.total_correct);
            setGetCertificateType(QuestionApi.certificate_type_slug);

        }

    }


    return (
        <>
            <Header />
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                        <div className="step-form">

                            <div className="card">
                                <div className="row">
                                    <div className="col-lg-10 col-7">
                                        <p className="sample-test-review">Sample Test Result </p>
                                    </div>
                                    <div className="col-lg-2 col-5">
                                        <div className="box-row-res">
                                            <div>
                                                <i className="fa fa-check pass-check"></i> <span className="pass-qus">{getTotalCorrect}</span>
                                            </div>
                                            <div className="fail-qus">
                                                <div className="fail-qus-res ml-15">

                                                    <i className="fa fa-times fail-titme" aria-hidden="true"></i> <span className="pass-qus">{getTotalIncorrect}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    getQuestions.map((item, index) => (
                                        <>
                                            <div className="row pt-5">
                                                <p className="test-res-qus test-sample"> 
                                                
                                                
                                                {index + 1}. &nbsp;<sanp className="moblie-view-ml-span" key={item.id} dangerouslySetInnerHTML={{ __html: item.question }} />
                                                </p>

                                                {
                                                    item.correct_answer === item.answer_value ?
                                                        <>
                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={item.option_1 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={item.option_2 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={item.option_3 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change mb-5'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={item.option_4 === item.correct_answer ? "rounded-box answer-check-btn right-answer" : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={
                                                                        item.option_1 === item.answer_value ?
                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                            : item.option_1 === item.correct_answer
                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                : "rounded-box answer-check-btn"}></div>{item.option_1}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={
                                                                        item.option_2 === item.answer_value ?
                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                            : item.option_2 === item.correct_answer
                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                : "rounded-box answer-check-btn"}> </div>{item.option_2}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={
                                                                        item.option_3 === item.answer_value ?
                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                            : item.option_3 === item.correct_answer
                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                : "rounded-box answer-check-btn"}></div>{item.option_3}
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 qus-radio-btn-change mb-5'>
                                                                <div className="res-sample-test-1">
                                                                    <div className={
                                                                        item.option_4 === item.answer_value ?
                                                                            "rounded-box answer-check-btn wrong-answer"
                                                                            : item.option_4 === item.correct_answer
                                                                                ? "rounded-box answer-check-btn right-answer"
                                                                                : "rounded-box answer-check-btn"}></div>{item.option_4}
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                            <hr />
                                        </>
                                    ))


                                }
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    <CertificateCard />
                </div>
            </section>

            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
        </>
    )

}