import React, { useState, useEffect } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import Search from '../../../assets/manager-images/images/Search.svg';
import Arrow from '../../../assets/super-admin-images/images/Arrow.svg';
import UseToDoList from "../../../hooks/superAdmin/UseToDoList";
import { Watch, ThreeDots } from "react-loader-spinner";
import ScrollToTop from 'react-scroll-to-top';

function ToDoListPage() {

    const [listuser, setListuser] = useState([]);
    const [perPage, setPerPage] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [userName, setUserName] = useState('');
    const [statusTab, setStatusTab] = useState('pending');
    const [statusName, setStatusName] = useState('pending');
    const { allToDo, loading, recallApiWithNewParams, loadMoreLoading } = UseToDoList(searchParams, pageParams);


    useEffect(() => {

        if (allToDo.status === "success") {
            if (allToDo.data.data.length > 0) {
                const combinedArray = [...listuser, ...allToDo.data.data];
                const uniqueEmailMap = new Map();
                combinedArray.forEach(user => {
                    const email = user.email;
                    const certificate = user.short_name;
                    const key = `${email}_${certificate}`;
                    if (!uniqueEmailMap.has(key)) {
                        uniqueEmailMap.set(key, user);
                    }
                });

                const uniqueArray = Array.from(uniqueEmailMap.values());
                setListuser(uniqueArray);
                setPerPage(allToDo.data);
                setCurrentPage(allToDo.data.current_page);
            }
        }
    }, [allToDo]);



    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
            user_name: userName,
            status: statusName
        }
        recallApiWithNewParams(params);

        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);

    }


    const handleSearch = (e) => {

        const searchTerm = e.target.value;
        if (e.keyCode === 13) {
            if (searchTerm.length >= 3) {

                setListuser([]);
                setUserName(e.target.value)
                const params = {
                    user_name: e.target.value,
                    status: statusName
                }
                recallApiWithNewParams(params);
            } else {
                setUserName(searchTerm)
                if (searchTerm === '') {
                    const params = {
                        user_name: e.target.value,
                        status: statusName
                    }
                    recallApiWithNewParams(params);
                }
            }
        }
    }
    const handleStatusTabChange = (statusData) => {
        if (statusData !== statusTab) {
            setListuser([]);
            setStatusTab(statusData);
            setStatusName(statusData);

            const params = {
                user_name: userName,
                status: statusData
            }
            recallApiWithNewParams(params);
        }
    }

    return (
        <>
            <Header />
            <section className="pt-10 pb-5 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-lg-4 col-4 progress-search-col">
                            <div className="d-flex" >
                                <span className="track-progress-span">
                                    <img src={Search} alt="search" width='20' height='20' />
                                </span>
                                <input type="text" className="progress-search-btn" placeholder="Search" onKeyDown={handleSearch} />
                            </div>
                        </div>
                        <div className="col-lg-8 col-8 progress-search-col" id="to-do-list-tab">
                            <div className="container">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button className="nav-link active pending-list" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" onClick={() => handleStatusTabChange('pending')}>Pending</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link completed-list" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" onClick={() => handleStatusTabChange('completed')}>Completed</button>
                                    </li>
                                </ul>


                            </div>

                        </div>
                    </div>



                    <div className="row pt-5">
                        <div className="tab-content">
                            {statusTab === 'pending' &&
                                <>
                                    <div id="tab1" className="tab-pane fade show active">
                                        <table className="table" id="todo-list-table">
                                            <thead className="thead-course">
                                                <tr>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Title</th>
                                                    <th className="text-center">Organization</th>
                                                    <th className="text-center">Status</th>
                                                    <th className="text-center">Email</th>
                                                    <th className="text-center">Type</th>
                                                    <th className="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {!loading && (
                                                    <>

                                                        {
                                                            listuser.map((ele) => {
                                                                return (
                                                                    <tr className="tr-user" key={ele.id}>
                                                                        <td className="text-center" data-label="Name">{ele.first_name}</td>
                                                                        <td className="text-center" data-label='Title'>{ele.title}</td>
                                                                        <td className="text-center" data-label='Organization'>{ele.organization}</td>
                                                                        <td className="text-center" data-label='Status'>{ele.user_status}</td>
                                                                        <td className="text-center" data-label='Email'>{ele.email}</td>
                                                                        <td className="text-center" data-label='Type'>
                                                                            {ele.short_name === 'bls_certification' && <>BLS</>}
                                                                            {ele.short_name === 'bls_re_certification' && <>BLS Re-Certificate</>}
                                                                            {ele.short_name === 'acls_certification' && <>ACLS</>}
                                                                            {ele.short_name === 'acls_re_certification' && <>ACLS Re-Certificate</>}
                                                                            {ele.short_name === 'pals_certification' && <>PALS</>}
                                                                            {ele.short_name === 'pals_re_certification' && <>PALS Re-Certificate</>}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <a href={`/super-admin/view-details/${ele.u_id}/${ele.ct_id}`}>
                                                                                <img src={Arrow} width='10' alt="arrow" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )}

                                            </tbody>
                                        </table>
                                        <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loading && (
                                                <Watch
                                                    height="80"
                                                    width="80"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>
                                        <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loadMoreLoading && !loading && (
                                                <Watch
                                                    height="70"
                                                    width="70"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>

                                        {listuser.length > 0 &&
                                            <>
                                                {currentPage < perPage.last_page &&
                                                    (<button onClick={loadMore} className="load-more" type="button">
                                                        {loadMoreLoading ? 'Loading...' : 'Load More'}
                                                    </button>)
                                                }
                                            </>
                                        }

                                        {!loading && !loadMoreLoading && (
                                            <>
                                                {listuser.length === 0 && (
                                                    <>
                                                        <div className="text-center">
                                                            <h5 className="valid-till">No To Do List Found.</h5>
                                                        </div>
                                                    </>
                                                )}
                                            </>)}
                                    </div>
                                </>
                            }
                            {statusTab === 'completed' &&
                                <>
                                    <div id="tab2" className="tab-pane fade show active">
                                        <table className="table" id="todo-list-table">
                                            <thead className="thead-course">
                                                <tr>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Title</th>
                                                    <th className="text-center">Organization</th>
                                                    <th className="text-center">Status</th>
                                                    <th className="text-center">Email</th>
                                                    <th className="text-center">Type</th>
                                                    <th className="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {!loading && (
                                                    <>

                                                        {
                                                            listuser.map((ele) => {
                                                                return (
                                                                    <tr className="tr-user" key={ele.id}>
                                                                        <td className="text-center" data-label="Name">{ele.first_name}</td>
                                                                        <td className="text-center" data-label='Title'>{ele.title}</td>
                                                                        <td className="text-center" data-label='Organization'>{ele.organization}</td>
                                                                        <td className="text-center" data-label='Status'>{ele.user_status}</td>
                                                                        <td className="text-center" data-label='Email'>{ele.email}</td>
                                                                        <td className="text-center" data-label='Type'>
                                                                            {ele.short_name === 'bls_certification' && <>BLS</>}
                                                                            {ele.short_name === 'bls_re_certification' && <>BLS Re-Certificate</>}
                                                                            {ele.short_name === 'acls_certification' && <>ACLS</>}
                                                                            {ele.short_name === 'acls_re_certification' && <>ACLS Re-Certificate</>}
                                                                            {ele.short_name === 'pals_certification' && <>PALS</>}
                                                                            {ele.short_name === 'pals_re_certification' && <>PALS Re-Certificate</>}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <a href={`/super-admin/view-details/${ele.u_id}/${ele.ct_id}`}>
                                                                                <img src={Arrow} width='10' alt="arrow" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )}

                                            </tbody>
                                        </table>
                                        <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loading && (
                                                <Watch
                                                    height="80"
                                                    width="80"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>
                                        <div className="container d-flex align-items-center justify-content-center vh-80">
                                            {loadMoreLoading && !loading && (
                                                <Watch
                                                    height="70"
                                                    width="70"
                                                    radius="48"
                                                    color="#2f99a9 "
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>

                                        {listuser.length > 0 &&
                                            <>
                                                {currentPage < perPage.last_page &&
                                                    (<button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button>)
                                                }
                                            </>
                                        }

                                        {!loading && !loadMoreLoading && (
                                            <>
                                                {listuser.length === 0 && (
                                                    <>
                                                        <div className="text-center">
                                                            <h5 className="valid-till">No To Do List Found.</h5>
                                                        </div>
                                                    </>
                                                )}
                                            </>)}
                                    </div>
                                </>
                            }


                        </div>
                    </div>
                </div>
                <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
            </section>
        </>
    )

}


export default ToDoListPage;