import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Header from "../../../../components/website/Layouts/Header";
import Footer from "../../../../components/website/Layouts/Footer";
import { useParams } from 'react-router-dom';
import UseSampleTest from '../../../../hooks/UseSampleTest';
import Cookies from 'universal-cookie'
import UseSampleTestQuestionAnswer from '../../../../hooks/UseSampleTestQuestionAnswer';
import { useNavigate }  from 'react-router-dom';
import { ThreeDots } from "react-loader-spinner";
import ScrollToTop from 'react-scroll-to-top';
import CertificateCard from "../../../../components/website/CertificateCard";

export default function Stepfroms() {
    const type = useParams();
    const { short_name } = type;
    
    const cookies = new Cookies();

    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const [currentStep, setCurrentStep] = useState(1);
    
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [answerArray, setAnswerArray] = useState([]);
    const [sampleTest, setSampleTestGet] = useState([]);
    const [errorTestQus, setErrorTestQus] = useState('');


    const [allAnswerSelected,setAllAnswerSelected] = useState(false);
    const [loading,setLoading] = useState(false);

    const { getQuestions: sampleTestApi } = UseSampleTest({
        short_name,
    });

    const {isCreate , createAnswer} = UseSampleTestQuestionAnswer();

    const navigate = useNavigate();

    useEffect(() => {
        fetchSampleTest();
    }, [sampleTestApi]);

    const fetchSampleTest = async () => {
        if (sampleTestApi.status === "success") {
            setSampleTestGet(sampleTestApi.data.sample_test_questions);
        }

    }

    const handleChange = (e, questionId) => {
        const selectedValue = e.target.value;
        setSelectedAnswer(selectedValue);
    
        setAnswerArray(prevArray => {
            const dataArray = [...prevArray];
            const index = dataArray.findIndex(item => item.s_question_id === questionId);
    
            if (index === -1) {
                const newAnswer = {
                    employee_id: userDetails && userDetails.id ? userDetails.id : '',
                    s_question_id: questionId,
                    answer_value: selectedValue
                };
                dataArray.push(newAnswer);
            } else {
                dataArray[index].answer_value = selectedValue;
            }
    
            const questions = sampleTest[0]; // Assuming sampleTest is an array, adjust as needed
    
            if (questions.sample_questions.length === dataArray.length) {
                setAllAnswerSelected(true);
            } else {
                setAllAnswerSelected(false);
            }
    
            return dataArray;
        });
    };

    const handleNext = (e) => {
        e.preventDefault();
        const currentQuestionId = sampleTest[currentStep - 1].id;
        const isAnswerSelected = answerArray.some(item => item.s_question_id === currentQuestionId);
    
        if (isAnswerSelected) {
            if (currentStep < 10) {
                console.log(isAnswerSelected,'isAnswerSelected');
                setCurrentStep(currentStep + 1);
            } else {
                console.log(allAnswerSelected);
                setAllAnswerSelected(true); // Set a state indicating all questions are answered
            }
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const lastQuestionId = sampleTest[9].id; // Assuming the last question is at index 9
        const isLastAnswerSelected = answerArray.some(item => item.s_question_id === lastQuestionId);
    
        console.log(lastQuestionId,'lastQuestionId');
        if (!isLastAnswerSelected) {
            return; // Don't proceed with submission if the last answer is not selected
        }
        setLoading(true);
        const { responseCode: sampleTestAnswer } = await createAnswer({
            'answers': answerArray,
            'name_slug': short_name
        });
    
        if (sampleTestAnswer.status === 'success') {
            const employeeId = sampleTestAnswer.data.employee_id;
            navigate(`/sample-test-result/${short_name}/${employeeId}`);
        } else {
            console.log('Error submitting the test.');
        }
        setLoading(false);
    };
    


    const renderStepContent = (step) => {

        return sampleTest.map((item, index) => {
            {
                switch (step) {
                    case (index + 1):
                        return (
                            <div className='row qus-name-changes'>
                                <div>
                                    <label className="test-sample">
                                        {index + 1}. &nbsp;<span className="moblie-view-ml-span" dangerouslySetInnerHTML={{ __html: item.sample_questions.question }} />
                                    </label> <br />
                                </div>
                                <div className='col-lg-6 qus-radio-btn-change'>

                                    <div>
                                        <input id={`${item.id}_${'option1'}`} className="radio-custom" name={`${item.id}_${'name'}`} type="radio"
                                            value={item.sample_questions.option_1}
                                            onChange={(e) => handleChange(e, item.id)} />
                                        <label htmlFor={`${item.id}_${'option1'}`} className="radio-custom-label">{item.sample_questions.option_1}</label>
                                    </div>
                                </div>
                                <div className='col-lg-6 qus-radio-btn-change'>

                                    <div>
                                        <input id={`${item.id}_${'option2'}`} className="radio-custom" name={`${item.id}_${'name'}`} type="radio"
                                            value={item.sample_questions.option_2}
                                            onChange={(e) => handleChange(e, item.id)} />
                                        <label htmlFor={`${item.id}_${'option2'}`} className="radio-custom-label">{item.sample_questions.option_2}</label>
                                    </div>

                                </div>
                                <div className='col-lg-6 qus-radio-btn-change'>

                                    <div>
                                        <input id={`${item.id}_${'option3'}`} className="radio-custom" name={`${item.id}_${'name'}`} type="radio"
                                            value={item.sample_questions.option_3}
                                            onChange={(e) => handleChange(e, item.id)} />
                                        <label htmlFor={`${item.id}_${'option3'}`} className="radio-custom-label">{item.sample_questions.option_3}</label>
                                    </div>

                                </div>
                                <div className='col-lg-6 qus-radio-btn-change'>

                                    <div>
                                        <input id={`${item.id}_${'option4'}`} className="radio-custom" name={`${item.id}_${'name'}`} type="radio"
                                            value={item.sample_questions.option_4}
                                            onChange={(e) => handleChange(e, item.id)} />
                                        <label htmlFor={`${item.id}_${'option4'}`} className="radio-custom-label">{item.sample_questions.option_4}</label>
                                    </div>
                                </div>
                                {errorTestQus && <div className="error">{errorTestQus}</div>}
                            </div>
                        );

                    default:
                        return null;

                }
            }

        })
    };

    const stepCount = renderStepContent.length; // Total number of steps
    const percentage = (currentStep * 10) / stepCount;

    return (

        <>
            <Header />
            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <p className="pals-title-test">{short_name.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-10 col-6">
                                    <p className="pals-title-test-sub">Completed</p>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className="progress-bar-c">
                                        <CircularProgressbar
                                            value={percentage}
                                            text={`${percentage.toFixed(0)}%`}
                                            styles={buildStyles({
                                                textSize: '20px',
                                                pathColor: `rgba(0, 56, 108, ${percentage / 100})`,
                                                textColor: '#787878',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#00386c',
                                            })}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                            <div className="step-form">
                                <div className="card">
                                    <div className="progress-bar">
                                        <div className="row">
                                            <div className="col-lg-8 col-8">
                                                <p className="sample-test-review">Review Questions </p>
                                            </div>
                                            <div className="col-lg-4 col-4">
                                                <p className="step-number-qus">{currentStep}/10</p>
                                            </div>
                                        </div>
                                    </div>
                                    {renderStepContent(currentStep)}

                                    <div className="button-group-step1">
                                        {currentStep < 10 ? (
                                            <button type="button" className="btn-next" onClick={handleNext} disabled={allAnswerSelected} >
                                                Next
                                            </button>
                                        ) : (
                                            <button type="submit" className="btn-submit-sample submit-test-sample-btn" onClick={handleSubmit} disabled={allAnswerSelected || loading}>
                                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Submit Test"}
                                            </button>
                                        )}
                                    </div>

                                </div>
                            </div>
                    </div>
                </div>
            </section>

            <section className="feature-promo bg-light pt-5" id="box-set">
                <div className="container">
                    
                    <CertificateCard />
                </div>
            </section>



            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>

        </>
    )
}