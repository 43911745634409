import { useState, useEffect } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";
const UseUserProfile = () => {
    const [getUserProfile, setUserProfile] = useState([]);
  
    useEffect(() => {
      const getUserProfilelist = async () => {
        try {
          const response = await Superadmin.getProfile();
          setUserProfile(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      getUserProfilelist();
    }, []);
  
    return {getUserProfile};
}



export default UseUserProfile;