import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import UseProcessToAddFund from "../../../hooks/manager/UseProcessToAddFund";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function ProcessToAddFund(props) {
    const secretKey = process.env.REACT_APP_API_LIVE;
    const digitPattern = /^\d+(\.\d{1,2})?$/;
    // const certificateAmounts = localStorage.getItem('CertificateAmount') || '';

    const cAmounts = localStorage.getItem('CertificateAmount') || '';
    const decryptedBytes = cAmounts ? CryptoJS.AES.decrypt(cAmounts, secretKey) : null;
    let certificateAmounts = decryptedBytes ? decryptedBytes.toString(CryptoJS.enc.Utf8) : '';
    const certificateAmountFloat = parseFloat(certificateAmounts);
    if (!isNaN(certificateAmountFloat)) {
        certificateAmounts = certificateAmountFloat.toFixed(2);
    }

    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState(null);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setAmount(certificateAmounts);
    }, [certificateAmounts]);

    const { isAddFund, AddFundApi } = UseProcessToAddFund();

    const onAddAmont = (e) => {
        const value = e.target.value;
        const isValidInput = /^\d*$/.test(value);
        setAmount(value);
        setIsValid(isValidInput);
        setAmountError('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!amount) {
            setAmountError('Amount is required');
        } else if (!digitPattern.test(amount)) {
            setAmountError('Please enter a valid number.');
            return;
        }

        const enteredAmount = parseFloat(amount);

        if (enteredAmount < certificateAmounts) {
            setAmountError('Amount cannot be smaller than the Certificate Amount.');
            return;
        } else if (enteredAmount > 9999) {
            setAmountError('Amount cannot exceed 9999.');
            return;
        } else if (enteredAmount < 1) {
            setAmountError('Amount must be at least 1.');
            // setAmountError('Amount must be greater than 0.');
            return;
        }

        if (amount) {
            setLoading(true);
            
            try {
                const data = {
                    amount: enteredAmount,
                    successUrl: props.successUrl ?? "",
                };
    
                const { responseCode: paymentGet } = await AddFundApi(data);
    
                if (paymentGet) {
                    // localStorage.setItem('addFunds', enteredAmount);
                    const encryptedAmount = CryptoJS.AES.encrypt(enteredAmount.toString(), secretKey).toString();
                    localStorage.setItem('addFunds',encryptedAmount);
                    window.location.href = paymentGet.data.url;
                    localStorage.removeItem('CertificateAmount');
                    // localStorage.removeItem('addFunds');
                } else {
                    toast.error("Something went wrong while redirecting to the payment page. Please try after sometime.");
                }
            } catch (error) {
                console.error('Something went wrong:', error);
                toast.error("Something went wrong while processing the amount. Please try again later.");
            }
        }

        setLoading(false);
    }

    return (
        <>
            <>
                <Modal
                    {...props}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="remove-modal"
                    id='add-fund-modal'
                >

                    <Modal.Body>
                        <form onSubmit={handleSubmit} >
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <p className="remove-class-title"> */}
                                    <div className="d-flex">
                                        <span className="dolleradd">
                                            <i className="fa fa-usd" aria-hidden="true"></i>
                                        </span>
                                        <input className="add-fund" type="text" value={amount} onChange={onAddAmont} name="amount" maxLength='7' placeholder="Add Funds" />
                                    </div>
                                    {/* <div className="d-flex add-member-teams-data">
                                    <span className="name-img-flex">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    </span>

                                    <input className="remove-class-title" type="text" value={amount} onChange={onAddAmont} name="amount" maxLength='10' placeholder="Add Funds"/>

                                </div> */}
                                    {amountError && <div className="error">{amountError}</div>}
                                    {/* {!isValid && <p style={{ color: 'red' }}>Please enter a valid number.</p>} */}
                                    {/* </p> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <button className="remove-no-btn" onClick={props.onHide} >Cancel</button> &nbsp; */}
                                    {/* <button type="submit" className="remove-yes-btn">Confirm</button> */}
                                    <div className="submit-btn-add-team">
                                        <button type="submit" className="btn btn-primary mt-4 add-fund-btn-model" disabled={loading}>
                                            {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Add Funds"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </Modal.Body>

                </Modal>
            </>
        </>
    )
}

export default ProcessToAddFund;