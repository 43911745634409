import React, { useState, useEffect } from "react";
import Header from "../../../components/emplyoeeSite/Layouts/Header";
import Arrow from '../../../assets/manager-images/images/Arrow.svg';
import UseCertificate from "../../../hooks/UseCertificate";
import CertificateModal from "../../../components/emplyoeeSite/Certificate/CertificateModal";
import CertificateDisclaimer from "../../../components/emplyoeeSite/Certificate/CertificateDisclaimer";
import { Watch } from "react-loader-spinner";

function CertificationType() {

    const [certificateModalShow, setCertificateModalShow] = useState(false);
    const [printImageUrl, setPrintImageUrl] = useState("");
    const [certificateSlug, setCertificateSlug] = useState("");
    const [certificateId, setCertificateId] = useState("");
    const [finalExamDate, setfinalExamDate] = useState("");
    const [userName, setUserName] = useState("");
    const [certificateActiveDate, setCertificateActiveDate] = useState("");
    const [userCertificateId, setUserCertificateId] = useState("");
    const [email, setEmail] = useState("");
    const [CertificateType, setCertificateTypes] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [disclaimerModalShow, setDisclaimerModalShow] = useState(false);
    const [slug, setSlug] = useState('');

    const { certificateTypes, loading } = UseCertificate();

    useEffect(() => {
        fetchCertificateType();
    }, [certificateTypes]);

    const fetchCertificateType = () => {
        // setLoading(false);
        if (certificateTypes.status === "success") {
            setCertificateTypes(certificateTypes.data);
        }
    }

    const openPrintModel = (imageUrl, name, date, slug, activeDate, cId, ucId, uEmail) => {
        setPrintImageUrl(imageUrl);
        setUserName(name);
        setfinalExamDate(date);
        setCertificateSlug(slug);
        setCertificateActiveDate(activeDate);
        setCertificateId(cId);
        setUserCertificateId(ucId);
        setEmail(uEmail);
        setCertificateModalShow(true);
    }

    const openDisclaimer = (name) => {
        setSlug(name)
        setDisclaimerModalShow(true)
    }

    return (
        <>
            <Header />
            <section className="feature-promo bg-light mb-5" id="employee-certification-type">
                <div className="container">
                    <div className="row">
                        <div className="container d-flex align-items-center justify-content-center vh-80">
                            {loading && (
                                <Watch
                                    height="80"
                                    width="80"
                                    radius="48"
                                    color="#2f99a9 "
                                    ariaLabel="watch-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            )}
                        </div>

                        {!loading && (
                            <>
                                {CertificateType.length !== 0 ?

                                    <>
                                        {CertificateType.map((item) =>
                                            <>
                                                <div className="col-lg-4 col-6 main-card mb-5">
                                                    <div className="card main-card-div">
                                                        <div className="col-lg-12">
                                                            <img src={item.certificate_icon} className="blsIconEmp" alt="BLS-ICON" />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            {item.full_name === "Basic Life Support" && (<p className="employee-home-title">Basic <br />Life <br /> Support</p>)}
                                                            {item.full_name === "Advanced Cardiovascular Life Support" && (<p className="employee-home-title">Advanced Cardiovascular Life Support</p>)}
                                                            {item.full_name === "Pediatric Advanced Life Support" && (<p className="employee-home-title">Pediatric Advanced Life Support</p>)}
                                                            {
                                                                item.short_name === "bls_re_certification" && (<p className="employee-home-title">Basic <br />Life <br /> Support <br /> Re-Certification</p>)
                                                            }

                                                            {
                                                                item.short_name === "acls_re_certification" && (<p className="employee-home-title">Advanced Cardiovascular Life Support<br /> Re-Certification</p>)
                                                            }
                                                            {
                                                                item.short_name === "pals_re_certification" && (<p className="employee-home-title">Pediatric Advanced Life Support<br /> Re-Certification</p>)
                                                            }
                                                        </div>
                                                        <div className="col-lg-12">

                                                            {item.exam_result === 'pass' ?
                                                                <>
                                                                    <a className="btn-employee-start" onClick={() => openPrintModel(item.full_name, item.user_name, item.final_exam_pass_date, item.name_slug, item.active_date, item.id, item.user_certificate_id, item.email)}>View Certificate</a>
                                                                </>
                                                                :
                                                                <>
                                                                    <a className="btn-employee-start" onClick={() => openDisclaimer(item.name_slug)} >Start <img src={Arrow} alt="arrow" width='20' /> </a>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>


                                            </>
                                        )}
                                    </>

                                    : <>
                                        {certificateTypes.check_team_user !== 'yes' ?
                                            <>
                                                <p className="no-course-certificate mt-5">Currently, you don't have any certificates. Add a new certificate by clicking the 'Add To Cart' button.</p>
                                                <div className="text-center mt-5">

                                                    <a className="certificate-add-to-cart" href="/course-cart">Add To cart</a>
                                                </div>
                                            </> :
                                            <>
                                                <p className="no-course-certificate mt-5">Currently, you don't have any certificates.</p>
                                            </>
                                        }
                                    </>


                                }




                            </>
                        )}
                    </div>
                </div>
            </section>
            <CertificateModal
                show={certificateModalShow}
                onHide={() => setCertificateModalShow(false)}
                printImageUrl={printImageUrl}
                userName={userName}
                finalExamDate={finalExamDate}
                certificateSlug={certificateSlug}
                certificateActiveDate={certificateActiveDate}
                userCertificateId={userCertificateId}
                certificateId={certificateId}
                email={email}
            />
            <CertificateDisclaimer
                show={disclaimerModalShow}
                onHide={() => setDisclaimerModalShow(false)}
                slug={slug}
            />

        </>
    )

}

export default CertificationType;