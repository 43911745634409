import { useState, useEffect } from "react";

import Help from '../api/Help';

const UseDiscountLevel = () => {
    const [discount, setDiscount] = useState([]);
    useEffect(() => {
      const getDiscount = async () => {
        try {
          const response = await Help.getDiscountLevel();
          if (response.data.status === "success") {
            setDiscount(response.data);
          }
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getDiscount();
    }, []);
  
    return {discount};
   
}

export default UseDiscountLevel;