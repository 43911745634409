import React,{useEffect, useState} from "react";
import logo from '../../../assets/web-images/images/Pulse-Logo.svg'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axiosConfig from '../../../api/Config';
import CryptoJS from 'crypto-js';

export default function Header() {
    const secretKey = process.env.REACT_APP_API_LIVE;

    const { user_id } = useParams();
    const { certificate_type } = useParams();

    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    const cookies = new Cookies();
    const userRole = cookies.get("role") ? cookies.get("role") : '';

    const [userRoles ,setUserRoles] = useState([]);
    const [loading ,setLoading] = useState(false);

    const logOut = () => {
        cookies.remove('token', { path: '/' });
        cookies.remove('user', { path: '/' });
        cookies.remove('role', { path: '/' });
        localStorage.clear();

        setTimeout(function () {
            window.location.href = "/";
        }, 1000);
    }
   

    useEffect(() => {
        
        const getUserData = async () => {
            const response = await axiosConfig.get('/get-user-role');
            setLoading(true);
            if (response.data.status === 'success') {
                setUserRoles(response.data.status);
            }
            setLoading(false);
        };
        getUserData();
        
    }, []);

    
    const  onLoginAsEmployee = () =>{
        if (userRoles === 'success') {         
            const updatedUserCookie = 'employee';    
            // cookies.set('role', updatedUserCookie, { path: '/' });
            const encryptedRole = CryptoJS.AES.encrypt(updatedUserCookie, secretKey).toString();
            cookies.set('role', encryptedRole, { path: '/' });
            // const role = cookies.get('role');
            const encryptedUserRole = cookies.get('role') || '';
            const decryptedRoleBytes = encryptedUserRole ? CryptoJS.AES.decrypt(encryptedUserRole, secretKey) : null;
            const role = decryptedRoleBytes ? decryptedRoleBytes.toString(CryptoJS.enc.Utf8) : '';
            if (role === 'employee') {
                window.location.href = "/employee";
            }
        }
    }
    
    return (
        <>

            <header className="main-header position-absolute w-100" id="border-color">
                <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10" id='navbar-padding'>
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link to="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                            <img src={logo} alt="logo" className="logo-img" height='65' />
                        </Link>
                        <Link className="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop">
                            <i className="flaticon-menu" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"
                                data-bs-toggle="offcanvas" role="button"></i>
                        </Link>
                        <div className="clearfix"></div>
                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li className="nav-item">
                                    {/* <Link className={ currentPath === "/manager/course-details" ? "nav-link active" : "nav-link"} to='/manager' >Dashbaord</Link> */}
                                    <Link className={(currentPath === '/manager' || currentPath === `/manager/course-details/${certificate_type}`) ? 'nav-link active' : 'nav-link'} to="/manager">Dashboard</Link>

                                </li>                                
                                <li className="nav-item">
                                    <Link className={(currentPath === '/manager/teams' || currentPath === '/manager/add-member' || currentPath === `/manager/view-teams/${user_id}` ) ? 'nav-link active' : 'nav-link'} to="/manager/teams">My Team</Link>

                                </li>

                                <li className="nav-item">
                                    <Link className={ currentPath === "/manager/track-progress" ? "nav-link active": "nav-link"} to="/manager/track-progress">Track Progress</Link>

                                </li>

                                <li className="nav-item">
                                    <Link className={ currentPath === "/manager/assign-courses" ? "nav-link active": "nav-link"} to="/manager/assign-courses">Assign Courses</Link>

                                </li>

                                <li className="nav-item">
                                    <Link className={ currentPath === "/manager/purchase-credits" ? "nav-link active": "nav-link"} to="/manager/purchase-credits">Purchase Credits</Link>

                                </li>
                                <li className="nav-item">
                                    {!loading && userRoles !== '' && userRoles === 'success' &&
                                        <><a className="nav-link"  href="#" onClick={()=>onLoginAsEmployee()}>Switch to Employee</a></>
                                    }
                                </li>
                                <li className="nav-item">
                                    <a className= "nav-link" href="" onClick={logOut}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
                    <div className="offcanvas-header d-flex align-items-center mt-4">
                        <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="flaticon-cancel"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body z-10">
                        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                                <a  className={ currentPath === "/manager" ? "nav-link active" : "nav-link"} href='/manager'>Dashboard</a> 
                            </li>
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/manager/teams" ? "nav-link active" : "nav-link"}  href="/manager/teams">My Team</a> 
                            </li>
                            
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/manager/track-progress" ? "nav-link active" : "nav-link"} href="/manager/track-progress">Track Progress</a> 
                            </li>
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/manager/assign-courses" ? "nav-link active" : "nav-link"} href="/manager/assign-courses">Assign Courses</a> 
                            </li>
                            <li className="nav-item dropdown">
                                <a className={ currentPath === "/manager/purchase-credits" ? "nav-link active" : "nav-link"} href="/manager/purchase-credits">Purchase Credits</a> 
                            </li>
                            <li className="nav-item">
                                    {!loading && userRoles !== '' && userRoles === 'success' &&
                                        <><a className="nav-link"  href="#" onClick={()=>onLoginAsEmployee()}>Switch to Employee</a></>
                                    }
                            </li>
                            <li className="nav-item dropdown">
                                <a className= "nav-link" onClick={logOut} href="">Logout</a>
                            </li>
                           
                        </ul>
                       
                    </div>
                </div>
            </header>

        </>
    )
}