import { useState } from "react";

import manager from "../../api/manager/manager";

const UsePurchaseAndAssignCourse = () => {
    const [isPurchase, setIsPurchase] = useState(false);
  
    const PurchaseApi = async (body) => {
      setIsPurchase(true);
  
      let responseCode;
  
      try {
        const response = await manager.purchaseAndAssignCourse(body);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsPurchase(false);
  
      return { responseCode };
    };
  
    return { isPurchase, PurchaseApi };
  };


export default UsePurchaseAndAssignCourse;