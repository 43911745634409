

// import { useState, useEffect } from "react";

// import manager from '../../api/manager/manager';

// const UseCertificateCourseDetails = (certificate_type,certificate_status) => {
//     const [getCourseList, setGetCourseList] = useState([]);
//     const [loading, setLoading] = useState(true);
//     useEffect(() => {
//       getCourseData({
//         certificate_status: certificate_status,
//         certificate_type: certificate_type,
//       });
//     }, []);

//     const getCourseData = async (params) => {
      
//       try {
//         const response = await manager.getCourseDetails(params);
//         setLoading(false);
//         setGetCourseList(response.data);
//       } catch (error) {
//         setLoading(false);
//         console.log(error);
//       }

//     };


//     const recallApiWithNewParams = (certificate_type,certificate_status) => {
//       setLoading(true);
//       getCourseData({certificate_type, certificate_status});
//   };
      
//     return {getCourseList,loading, recallApiWithNewParams};
   
// }

// export default UseCertificateCourseDetails;





import { useState, useEffect } from "react";

import manager from '../../api/manager/manager';

const UseCertificateCourseDetails = ({ certificate_type },params) => {
    const [getCourseList, setGetCourseList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadMoreLoading,setLoadMoreLoading] = useState(true);
    useEffect(() => {
      getCourseData();
    }, [certificate_type, params]);

    const getCourseData = async (params) => {
      
      try {
        const response = await manager.getCourseDetails(certificate_type,params);
        setLoading(false);
        setLoadMoreLoading(false);
        setGetCourseList(response.data);
      } catch (error) {
        setLoading(false);
        setLoadMoreLoading(false);
        console.log(error);
      }

    };


    const recallApiWithNewParams = (newParams) => {
      setLoadMoreLoading(true);
      getCourseData(newParams);
  };
      
    return {getCourseList,loading, recallApiWithNewParams,loadMoreLoading};
   
}

export default UseCertificateCourseDetails;



