import { useState, useEffect } from "react";

import manager from '../../api/manager/manager';

const UseManagerCurrentBalance = ({manager_id}) => {
    const [getUserCurrentBalance, setUserCurrentBalance] = useState([]);
  
    useEffect(() => {
      const getManagerCurrentBalances = async () => {
        try {
          const response = await manager.getManagerCurrentBalance({
            params: { manager_id },
          });
  
          setUserCurrentBalance(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getManagerCurrentBalances();
    }, []);
  
    return {getUserCurrentBalance };
   
}



export default UseManagerCurrentBalance;