import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import CryptoJS from "crypto-js";

function ReAssignCourseBalanceModal(props) {    
    const  secretKey  = process.env.REACT_APP_API_LIVE;

    const handleCertificateAmount = () => {
        if (props.certificateType === 'bls_certificate_a_date') {
            // localStorage.setItem('BLS',props.certificateAmountBls);
            const amountToEncrypt = String(props.certificateAmountBls); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('BLS', encryptedAmount);
            localStorage.removeItem('BLS_E');
            localStorage.removeItem('select_bls_certificate');
        }else if(props.certificateType === 'acls_certificate_a_date')
        {
            // localStorage.setItem('ACLS',props.certificateAmountAcls)        
            const amountToEncrypt = String(props.certificateAmountAcls); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('ACLS',encryptedAmount)        
            localStorage.removeItem('ACLS_E');
            localStorage.removeItem('select_acls_certificate');
        }else{
            // localStorage.setItem('PALS',props.certificateAmountPals)
            const amountToEncrypt = String(props.certificateAmountAcls); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('PALS',encryptedAmount)
            localStorage.removeItem('PALS_E');
            localStorage.removeItem('select_pals_certificate');

        }
        props.onComfirm();
    }

  return (
    <>
       <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="remove-modal"
    >
        <ModalBody>
            
            <p>${props.certificateType === 'bls_certificate_a_date'&& <>{props.certificateAmountBls.toFixed(2)}</>}{props.certificateType === 'acls_certificate_a_date'&& <>{props.certificateAmountAcls.toFixed(2)}</>}{props.certificateType === 'pals_certificate_a_date'&& <>{props.certificateAmountPals.toFixed(2)}</>} will be debited from your balance.</p>
            
            <div className="row">
                <div className="col-lg-12 pt-5">
                    <div className="remove-member">
                        <button className="remove-yes-btn" onClick={handleCertificateAmount}>Ok</button>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
    </>
  )
}

export default ReAssignCourseBalanceModal