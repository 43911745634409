import { useState, useEffect } from "react";

import CertificateType from '../api/CertificateTypes';

const UseCertificateList = () => {
    const [certificateTypes, setCertificateTypes] = useState([]);
    useEffect(() => {
      const getCertificateTypes = async () => {
        try {
          const response = await CertificateType.getCertificateList();
          if (response.data.status === "success") {
            setCertificateTypes(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getCertificateTypes();
    }, []);
  
    return {certificateTypes };
   
}



export default UseCertificateList;