import { useState, useEffect } from "react";

import manager from '../../api/manager/manager';

const UserMyTeams = (initialParams) => {
    const [getTeamData, setTeamData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadMoreLoading,setLoadMoreLoading] = useState(true);
    // Load data on initial render
    useEffect(() => {
        getMyTeamData(initialParams);
    }, [initialParams]);

    const getMyTeamData = async (params) => {
        try {
            const response = await manager.getMyTeams(params);
            setLoading(false);
            setLoadMoreLoading(false);
            setTeamData(response.data);
        } catch (error) {
            setLoading(false);
            setLoadMoreLoading(false);
            console.log(error);
        }
    };

    // Function to recall API with new parameters
    const recallApiWithNewParams = (newParams) => {
        setLoadMoreLoading(true);
        getMyTeamData(newParams);
    };

    return { getTeamData, loading, recallApiWithNewParams,loadMoreLoading };
};

export default UserMyTeams;