import { useState } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";

const UsemoveCertificateCompleted = () => {

    const [isMoveCertificate, setIsMoveCertificate] = useState(false);
    const MoveToCompleted = async (body) => {
        setIsMoveCertificate(true);

        let responseCode;
        try {
            const response = await Superadmin.moveCertificateCompleted(body);
            responseCode = response.data;
        } catch (error) {
            responseCode = error.response.status;
        }

        setIsMoveCertificate(false);

        return { responseCode };
    };

    return { isMoveCertificate, MoveToCompleted };
}

export default UsemoveCertificateCompleted;