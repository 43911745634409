import {useState} from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";

const UseAddUser = () => {
    const [isAddUser, setIsAddUser] = useState(false);
  
    const AddNewUser = async (data) => {
      setIsAddUser(true);
  
      let responseCode;
  
      try {
          responseCode = await Superadmin.createUser(data);
      } catch (error) {
        responseCode = error.response;
      }
  
      setIsAddUser(false);
  
      return { responseCode };
    };
  
    return { isAddUser, AddNewUser };
  };


export default UseAddUser;