import React, { useEffect, useState } from "react";
import Header from "../../../../components/website/Layouts/Header";
import Footer from "../../../../components/website/Layouts/Footer";
import { ThreeDots } from "react-loader-spinner";
import UseTeamMemberAdd from "../../../../hooks/UseTeamMemberAdd";
import UseSelfAssignAdd from "../../../../hooks/UseSelfAssignAdd";
import UsePurchaseAndAssignCourse from "../../../../hooks/manager/UsePurchaseAndAssignCourse";
import Cookies from 'universal-cookie'
import html2pdf from 'html2pdf.js';
import UseOrderDetails from "../../../../hooks/UseOrderDetails";
import UseSendOrderDetails from "../../../../hooks/UseSendOrderDetails";
import UseGetUserFund from "../../../../hooks/manager/UseGetUserFund";
import UseSendFundDetails from "../../../../hooks/manager/UseSendFundDetails";
import print from '../../../../assets/web-images/images/Print-order.svg';
import Mail from '../../../../assets/web-images/images/Mail-White.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment/moment";
import { useParams } from 'react-router-dom';
import axiosConfig from "../../../../api/Config";
import CryptoJS from "crypto-js";

export default function PaymentSuccess() {
    const secretKey = process.env.REACT_APP_API_LIVE;
    const id = useParams();
    const { checkout_id } = id;

    const [removeLocalstorage, setRemoveLocalStorage] = useState(localStorage.getItem('certificateCart'));
    const [addMemberError, setAddMemeberError] = useState("");
    const [isForMember, setIsForMember] = useState(false);

    const [userDataRetrive,setUserDataRetrive] = useState({});
    
    useEffect(() => {
        window.gtag('event', 'ads_conversion_PURCHASE_1', {
          // event_parameters
        });
    }, []);

    useEffect(() => {
        const userData = async () => {
            try {
                const userRetrive = await axiosConfig.get(`/get-user-retrive/${checkout_id}`).then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.log(err);
                });
                if (userRetrive.status === 'success') {
                    setUserDataRetrive(userRetrive.data);
                }
            } catch (error) {
                console.error('Error during user Retrive :', error);
            }
        };
        userData();
    }, []);


    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    // order details 
    const [getOrderDetail, setOrderDetail] = useState();
    const [getFundDetail, setFundDetail] = useState({});
    const [orderLoading, setOrderLoading] = useState(true);
    const [loadingManager, setLoadingManager] = useState(false);
    const [sendOrderDetail, setSendOrderDetail] = useState();
    const [loading, setLoading] = useState(false);
    const [fundLoading, setFundLoading] = useState(true);
    const [sendfundLoading, setSendFundLoading] = useState(false);
    
    const { isAddTeamMember, AddNewTeamMember } = UseTeamMemberAdd();
    const { isAddSelftCertificateAssign, AddSelfAssignCertificate } = UseSelfAssignAdd();
    const { isPurchase, PurchaseApi } = UsePurchaseAndAssignCourse();
    // new
    // useEffect(() => {
    //     // const getSavedMemberData = localStorage.getItem('addTeamData');

     
    //     const encryptedAddMemberFromStorage = localStorage.getItem('addTeamData') || '' ;
    //     const addMemberDecryptedBytes = encryptedAddMemberFromStorage ? CryptoJS.AES.decrypt(encryptedAddMemberFromStorage, secretKey) : null;
    //     const getSavedMemberData = addMemberDecryptedBytes ? addMemberDecryptedBytes.toString(CryptoJS.enc.Utf8) : '';
    //     console.log(getSavedMemberData);
    //     if (getSavedMemberData) {
    //         const saveData = async () => {
    //             await saveMember(JSON.parse(getSavedMemberData));
    //         };
    //         saveData();
    //     }
    //     const getSelfAssignData = localStorage.getItem('selfData');
    //     if (getSelfAssignData) {
    //         const SelfsaveData = async () => {
    //             await saveSelfMember(JSON.parse(getSelfAssignData));
    //         };
    //         SelfsaveData();
    //     }
    //     // const getAssignCourse = localStorage.getItem('addAssignData');

    //     const encryptedAssignDataFromStorage = localStorage.getItem('addAssignData');
    //     const assignDataDecryptedBytes = CryptoJS.AES.decrypt(encryptedAssignDataFromStorage, secretKey);
    //     const getAssignCourse = assignDataDecryptedBytes.toString(CryptoJS.enc.Utf8);
    //     if (getAssignCourse) {
    //         const assignCourse = async () => {
    //             await saveAssignCourse(JSON.parse(getAssignCourse));
    //         };
    //         assignCourse();
    //     }
    // }, []);

    useEffect(() => {
        try {
            // Retrieve and decrypt data from localStorage for addTeamData
            const encryptedAddMemberFromStorage = localStorage.getItem('addTeamData') || '';
            const addMemberDecryptedBytes = encryptedAddMemberFromStorage ? CryptoJS.AES.decrypt(encryptedAddMemberFromStorage, secretKey) : null;
            const getSavedMemberData = addMemberDecryptedBytes ? addMemberDecryptedBytes.toString(CryptoJS.enc.Utf8) : '';

            if (getSavedMemberData) {
                const saveData = async () => {
                    await saveMember(JSON.parse(getSavedMemberData));
                };
                saveData();
            }

            // const getSelfAssignData = localStorage.getItem('selfData');

            const encryptedSelfAssignFromStorage = localStorage.getItem('selfData') || '';
            const addSelfAssignDecryptedBytes = encryptedSelfAssignFromStorage ? CryptoJS.AES.decrypt(encryptedSelfAssignFromStorage, secretKey) : null;
            const getSelfAssignData = addSelfAssignDecryptedBytes ? addSelfAssignDecryptedBytes.toString(CryptoJS.enc.Utf8) : '';

            if (getSelfAssignData) {
                const SelfsaveData = async () => {
                    await saveSelfMember(JSON.parse(getSelfAssignData));
                };
                SelfsaveData();
            }

            // Retrieve and decrypt data from localStorage for addAssignData
            const encryptedAssignDataFromStorage = localStorage.getItem('addAssignData') || '';
            const assignDataDecryptedBytes = encryptedAssignDataFromStorage ? CryptoJS.AES.decrypt(encryptedAssignDataFromStorage, secretKey) : null;
            const getAssignCourse = assignDataDecryptedBytes ? assignDataDecryptedBytes.toString(CryptoJS.enc.Utf8) : '';

            if (getAssignCourse) {
                const assignCourse = async () => {
                    await saveAssignCourse(JSON.parse(getAssignCourse));
                };
                assignCourse();
            }
        } catch (error) {
            // Handle decryption errors
            console.error('Error decrypting data:', error);
        }
    }, [secretKey]);
    

    const onClick = () => {
        setRemoveLocalStorage(localStorage.clear());
        localStorage.removeItem('addFunds');
        if (userDetails.user_role !== undefined) {
            if (userDetails.user_role.role_id === 2) {
                window.location.href = '/manager';
            } else {
                window.location.href = '/employee';
            }
        } else {
            window.location.href = '/';
        }

    }

    const removeItemsFromLocalStorage = () => {
        localStorage.removeItem('bls_certificate_a_date');
        localStorage.removeItem('select_bls_certificate');
        localStorage.removeItem('acls_certificate_a_date');
        localStorage.removeItem('select_acls_certificate');
        localStorage.removeItem('pals_certificate_a_date');
        localStorage.removeItem('select_pals_certificate');
        localStorage.removeItem('BLS');
        localStorage.removeItem('BLS_E');
        localStorage.removeItem('ACLS');
        localStorage.removeItem('ACLS_E');
        localStorage.removeItem('PALS');
        localStorage.removeItem('PALS_E');
        localStorage.removeItem('addTeamData')
    }

    const saveMember = async (finalPostDetails) => {
        setIsForMember(true);

        const {
            responseCode: addTeamMember
        } = await AddNewTeamMember(finalPostDetails);
        if (addTeamMember.data.status === 'success') {
            removeItemsFromLocalStorage();
            window.location.href = "/manager/teams";
            localStorage.removeItem('addFunds');
        } else {
            setAddMemeberError(addTeamMember.data.message);
        }
    }
    const saveSelfMember = async (finalPostDetails) => {
        setIsForMember(true);

        const {
            responseCode: selfAssign
        } = await AddSelfAssignCertificate(finalPostDetails);
        if (selfAssign.data.status === 'success') {
            removeItemsFromLocalStorage();
            window.location.href = "/manager/teams";
            localStorage.removeItem('selfData');
            localStorage.removeItem('addFunds');
        } else {
            setAddMemeberError(selfAssign.data.message);
        }
    }
    

    const saveAssignCourse = async (finalPostDetails) =>{
        setIsForMember(true);
        const {
            responseCode: assignCourse
        } = await PurchaseApi(finalPostDetails);
        if (assignCourse.data.status === 'success') {
            removeItemsFromLocalStorage();
            window.location.href = "/manager/assign-courses";
            localStorage.removeItem('addAssignData');
            localStorage.removeItem('addFunds');
            localStorage.removeItem('ScheduleAssignment');
        } else {
            setAddMemeberError(assignCourse.data.message);
        }

    }

    const { orderDetails: orderDetailsApi } = UseOrderDetails();
    

    useEffect(() => {
        fetchOrderDetails();
    }, [orderDetailsApi]);

    const fetchOrderDetails = async () => {

        if (orderDetailsApi.status === 'success') {
            setOrderLoading(false);
            setOrderDetail(orderDetailsApi.data);
        }
    }
    if (!orderLoading) {
        console.log(getOrderDetail.payment_method);
        console.log(getOrderDetail.discount);
        console.log(getOrderDetail.total_payment);
    }

    const handlePdfPrint = () => {
        let certificateContent = "";
        if (!orderLoading) {
            certificateContent = getOrderDetail.order_details.map((item) => `
                <tr key=${item.id}>
                    <td class='text-capitalize'>${item.certificate_type.full_name}</td>
                    <td>$${item.certificate_type.amount}</td>                   
                </tr>
            `).join('');
        }
        const subTotal = getOrderDetail.sub_total
        const discountPercentage = getOrderDetail.discount
        const discountValue = subTotal * (discountPercentage / 100)
        
        const moment = require('moment-timezone');
        const utcTimestamp = moment.utc(getOrderDetail.created_at);
        const estTimestamp = utcTimestamp.tz('America/New_York');
        const formattedTimestamp = estTimestamp.format('M/DD/YYYY hh:mm A');

        const invoiceContent = `
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Receipt</title>
            <style>
                .invoice-container {
                    max-width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    border: 1px solid #e5e5e5;
                    border-radius: 15px;
                }
        
                .invoice-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                }
        
                .invoice-details {
                    margin-top: 4%;
                    text-align: right;
                    font-size: 40px;
                    color: #00386c;
                }
        
                .invoice-details div {
                    margin-bottom: 10px;
                }
        
                .invoice-info {
                    margin-bottom: 40px;
                }
        
                .invoice-info table {
                    width: 100%;
                    border-collapse: collapse;
                }
        
                .invoice-info th,
                .invoice-info td {
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: left;
                }
        
                .invoice-info th {
                    background-color: #f7f7f7;
                }
        
                .invoice-total {
                    text-align: right;
                }
                td.order-price-data{
                    text-align: end;
                    border: none;
                }
            </style>
        </head>
        
        <body>
            
            <div class="invoice-container mt-5">
                <div class="invoice-header">
                    <div class="invoice-details">
                        <div>Receipt</div>
                    </div>
                    <div class="invoice-logo">
                        <img src=${`${process.env.REACT_APP_IMAGES}/assets/Pulse-Logo.png`}
                            alt="Company Logo" width="225" height="90" />
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="flex: 1; ">
                    ${!orderLoading && `
                        ${getOrderDetail.order_users.map((data) =>
                            `
                                <p>Hello, ${data.user.first_name} ${data.user.last_name}</p>
                                <p>Email: ${data.user.email}</p>
                            `
                        )}`
                    }
                    </div>
                        ${!orderLoading &&
                            `<div style="flex: 1; text-align: right;">
                                <p>Order Id: ${getOrderDetail.order_number}</p>
                                <p>Date Time: ${formattedTimestamp}</p>
                                <p>Payment Method: ${getOrderDetail.payment_method}</p>
                            </div>`
                        }
                </div>
                <br />
                <div class="invoice-info">
                    <table>
                        <tr>
                            <th>Certificate Name</th>
                            <th>Price</th>
                            
                        </tr>
                        ${certificateContent}

                        ${!orderLoading &&
                            `
                            <tr>
                                <td class="order-price-data" colspan="2">Sub Total: $${getOrderDetail.sub_total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td class="order-price-data" colspan="2">Discount: $${discountValue.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td class="order-price-data" colspan="2">Total: $${getOrderDetail.total_payment.toFixed(2)}</td>
                            </tr>
                            
                            `
                        }
                    </table>
                </div>
                
            </div>
        </body>
        
        </html>  
        `;

        const input = document.createElement('div');
        input.innerHTML = invoiceContent;
        html2pdf().from(input).save('certificate invoice.pdf');
    };

    const {sendInvoice, sendOrder} = UseSendOrderDetails();

    const sendMail = async () => {
        setLoading(true);

        const {
            responseCode: sendOeder
        } = await sendOrder();

        if (sendOeder.data.status === 'success') {
            toast.success("Receipt sent successfully.");
        }
        setLoading(false);
    };

    // manager add 
    const sendManagerReceipt =async () =>{
        try {
            setLoadingManager(true);
            const data = {
                get_user_id: userDataRetrive.get_user_id,
                get_user_email: userDataRetrive.get_user_email,
            }
            const sendManagerReceiptMail = await axiosConfig.post(`/send-manager-receipt`,data).then((res) => {
                return res.data;
            }).catch((err) => {
                console.log(err);
            });

            if (sendManagerReceiptMail.status === 'success') {
                toast.success("Receipt sent successfully.");
              
            }
            setLoadingManager(false)
        } catch (error) {
            console.error('Error during payment:', error);
            // Handle error case here
        }
    }

    // manager add Funds details
    const cAmounts = localStorage.getItem('addFunds') ? localStorage.getItem('addFunds') : '';;
    const decryptedBytes = cAmounts ? CryptoJS.AES.decrypt(cAmounts, secretKey) : null;
    let getSaveAmountData = decryptedBytes ? decryptedBytes.toString(CryptoJS.enc.Utf8) : '';
    const saveAmount = parseFloat(getSaveAmountData);
    if (!isNaN(saveAmount)) {
        getSaveAmountData = saveAmount.toFixed(2);
    }
    // const getSaveAmountData = localStorage.getItem('addFunds') ? localStorage.getItem('addFunds') : '';
    
    const { userFundDetails: userFundDetailApi } = UseGetUserFund();

    useEffect(() => {
        fetchFundDetails();
    }, [userFundDetailApi]);

    const fetchFundDetails = async () => {

        if (userFundDetailApi.status === 'success') {
            setFundLoading(false);
            setFundDetail(userFundDetailApi.data);
        }
    }

    if (!fundLoading) {
        console.log(userFundDetailApi);
        
    }


    const handleFundPrint = () => {
        
        
        let FundsContent = "";
        if (!fundLoading) {
            FundsContent = `
            <tr>
                <td>Account Balance Deposit</td>
                <td class=text-center>$${Number(getFundDetail.amount).toFixed(2)}</td>
            </tr>`;
        }

        const invoiceContent = `
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Receipt</title>
            <style>
                .invoice-container {
                    max-width: 700px;
                    margin: 0 auto;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    border: 1px solid #e5e5e5;
                    border-radius: 15px;
                }
        
                .invoice-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                }
        
                .invoice-details {
                    margin-top: 4%;
                    text-align: right;
                    font-size: 40px;
                    color: #00386c;
                }
        
                .invoice-details div {
                    margin-bottom: 10px;
                }
        
                .invoice-info {
                    margin-bottom: 40px;
                }
        
                .invoice-info table {
                    width: 100%;
                    border-collapse: collapse;
                }
        
                .invoice-info th,
                .invoice-info td {
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: left;
                }
        
                .invoice-info th {
                    background-color: #f7f7f7;
                }
        
                .invoice-total {
                    text-align: right;
                }
                td.order-price-data{
                    text-align: end;
                    border: none;
                }

                th.remove-right-border {
                    border-right: 0;
                }
                th.remove-left-border {
                    border-left: 0;
                }
            </style>
        </head>
        
        <body>
            
            <div class="invoice-container mt-5">
                <div class="invoice-header">
                    <div class="invoice-details">
                        <div>Receipt</div>
                    </div>
                    <div class="invoice-logo">
                        <img src=${`${process.env.REACT_APP_IMAGES}/assets/Pulse-Logo.png`}
                            alt="Company Logo" width="225" height="90" />
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="flex: 1; ">
                    ${!fundLoading && 
                        // ${getOrderDetail.order_users.map((data) =>
                            `
                                <p>Hello, ${getFundDetail.first_name} ${getFundDetail.last_name}</p>
                                <p>Email: ${getFundDetail.email}</p>
                            `
                        // )}`
                    }
                    </div>
                        ${!fundLoading &&
                            `<div style="flex: 1; text-align: right;">
                                
                                <p>Date Time: ${moment(getFundDetail.created_at).format('M/D/YYYY')} ${getFundDetail.created_at.split('T')[1].slice(0, 5)}</p>
                                
                            </div>`
                        }
                </div>
                <br />
                <div class="invoice-info">
                    <table>
                        <tr>
                            <th class='remove-right-border'></th>
                            <th class='text-center remove-left-border'>Amount</th>                           
                        </tr>
                        ${FundsContent}
                        

                    </table>
                </div>
                
            </div>
        </body>
        
        </html>  
        `;

        const input = document.createElement('div');
        input.innerHTML = invoiceContent;
        html2pdf().from(input).save('Payment Receipt.pdf');
    };



    const {sendFundData, sendFunds} = UseSendFundDetails();

    const sendFundMail = async () => {
        setSendFundLoading(true);

        const {
            responseCode: sendFund
        } = await sendFunds();

        if (sendFund.data.status === 'success') {
            toast.success("Receipt sent successfully.");
        }
        setSendFundLoading(false);
    };

    return (
        <>
            <div className="login-header">
                <Header />
            </div>
            <section className="pt-5 feature-promo bg-light">
                <div className="container">
                    <div className="row payment-success-row order-details-payment">
                        <div className="col-md-12">
                            <div className="text-center">
                                
                                {userDetails.user_role !== undefined && userDetails.user_role.role_id === 3 &&
                                    <>
                                        <div className="payment-titles">
                                            <button type="submit" className="btn btn-primary btn-sm"  onClick={handlePdfPrint}><img src={print} alt="logo" width='30' height='30' />  Print Receipt</button> &nbsp;&nbsp;&nbsp;
                                            <button type="submit"  className="btn btn-primary btn-sm checkout-success-pay"  onClick={() => sendMail()} disabled={loading} >
                                            {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:<> <img src={Mail} alt="logo" width='30' height='30' />  Mail Receipt  </>}
                                               </button>
                                        </div>
                                    </>
                                }
                                {checkout_id !== undefined  &&
                                    <>
                                        <div className="payment-titles">
                                            <button type="submit"  className="btn btn-primary btn-sm checkout-success-pay"  onClick={() => sendManagerReceipt()} disabled={loadingManager} >
                                            {loadingManager ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:<> <img src={Mail} alt="logo" width='30' height='30' />  Mail Receipt  </>}
                                               </button>
                                        </div>
                                    </>
                                }
                                {getSaveAmountData !== ''  && userDetails.user_role !== undefined && userDetails.user_role.role_id === 2 &&
                                    <>
                                        <div className="payment-titles">
                                            <button type="submit" className="btn btn-primary btn-sm"  onClick={handleFundPrint}><img src={print} alt="logo" width='30' height='30' />  Print Receipt</button> &nbsp;&nbsp;&nbsp;
                                            <button type="submit"  className="btn btn-primary btn-sm checkout-success-pay"  onClick={() => sendFundMail()} disabled={sendfundLoading} >
                                            {sendfundLoading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:<> <img src={Mail} alt="logo" width='30' height='30' />  Mail Receipt  </>}
                                            </button>
                                        </div>
                                    </>
                                }
                            </div> <br />
                            <div className="text-center pt-5">
                                <div className="payment-titles">
                                    <h1><strong>Thank you!</strong></h1>
                                    <h6>
                                        {
                                            isForMember ? <strong>Your payment was successful.</strong> : <strong>Your payment was successful.</strong>
                                        }
                                    </h6>
                                    <hr />
                                    {
                                        isForMember ?
                                            <div className="mt-2">
                                                <p>Please be patient and do not refresh this page. Once it's done, you will be redirected to the team page.</p>
                                            </div>
                                            :
                                            <a className="btn btn-primary btn-sm backcolore-change" href='#' onClick={onClick}
                                                role="button">Continue to Home page</a>
                                    }
                                    {
                                        isForMember && (
                                            <div className="justify-content-center">
                                                <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        <ToastContainer />
        </>
    )

}