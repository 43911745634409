import { useState, useEffect } from "react";

import Help from "../../api/Help";

const UseSendFundDetails = () => {
    const [sendFundData, setSendFundData] = useState(false);
  
    const sendFunds = async (data) => {
      setSendFundData(true);
  
      let responseCode;
  
      try {
        const response = await Help.sendFundDetails(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setSendFundData(false);
  
      return { responseCode };
    };
  
    return { sendFundData, sendFunds };
  };


export default UseSendFundDetails;