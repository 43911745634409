// import { useState, useEffect } from "react";

// import Superadmin from "../../api/SuperAdmin/Superadmin";

// const UseManagerUsers = ({manager_id},params) => {
//     const [getManagerUsers, setGetManagerUsers] = useState([]);

//     useEffect(() => {
//       const getgetManagerUsers = async () => {
//         try {
//           const response = await Superadmin.getManagerUsersList({
//             params: { manager_id },
//           },params);

//           setGetManagerUsers(response.data);
//         } catch (error) {
//           console.log(error);
//         }
//       };

//       getgetManagerUsers();
//     }, []);

//     return {getManagerUsers };

// }
// export default UseManagerUsers;


import { useEffect, useState } from 'react';
import Superadmin from '../../api/SuperAdmin/Superadmin';

const useManagerUsers = ({ manager_id }, params) => {
  const [getManagerUsers, setGetManagerUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [manager_id, params]);

  const fetchData = async (params) => {
    try {
      const response = await Superadmin.getManagerUsersList(manager_id, params);

      setLoading(false);
      setLoadMoreLoading(false);
      setGetManagerUsers(response.data);
    } catch (error) {
      setLoading(false);
      setLoadMoreLoading(false);
      console.error(error);
    }
  };

  const recallApiWithNewParams = (newParams) => {

    setLoadMoreLoading(true);
    fetchData(newParams);
  };

  return { getManagerUsers, loading, recallApiWithNewParams, loadMoreLoading };
};

export default useManagerUsers;
