import axiosConfig from './Config';


const CertificateTypes ={
    get: () => axiosConfig.get(`get-certificate-types`),
    getCertificate: () => axiosConfig.get(`get-certificate`),
    getCertificateList: () => axiosConfig.get(`get-certificate-list`),
    SendCertificate: (body) => axiosConfig.post(`send-certificate`, body), 
}

export default CertificateTypes;