import React from "react";
import Modal from 'react-bootstrap/Modal';

function RequestInformation(props) {

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                
                <Modal.Body>
                    <p className="remove-class-title">
                        Your information request was submitted. Expect an email from our team within 24 hours!
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-no-btn" onClick={props.onHide} >Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
               
            </Modal>
        </>
    )
}

export default RequestInformation;