import React, { useEffect, useState } from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import Right from '../../../assets/manager-images/images/Right.svg';
import Error from '../../../assets/manager-images/images/Error.svg';
import Assigned from '../../../assets/manager-images/images/Assigned.svg';
import Close from '../../../assets/manager-images/images/Close.svg';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { Watch } from 'react-loader-spinner';
import UseMyTeam from "../../../hooks/manager/UseMyTeam";
import PurchaseAndAssignCourse from "../../../components/managerSite/AssignCourse/PurchaseAndAssignCourse";
import AssignedExpired from "../../../assets/manager-images/images/Assigne_Expired.svg";
import axiosConfig from "../../../api/Config";
import ScrollToTop from 'react-scroll-to-top';


function AssignCourses() {
    const dateformate = "MM/dd/yy"
    const [AssignDetails, setAssignDetails] = useState([]);
    const [pageParams, setPageParams] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState([]);
    const [purchaseCertificate, setPurchaseCertificate] = useState(false);
    const [amount, setAmount] = useState('');
    const [certificateId, setcertificateId] = useState('');
    const [userId, setuserId] = useState('');
    const [certificateStatus, setCertificateStatus] = useState('');
    const { getTeamData, loading, recallApiWithNewParams, loadMoreLoading } = UseMyTeam(pageParams);

    useEffect(() => {
        if (getTeamData.status === "success") {
            if (getTeamData.data.data.length > 0) {
                setAssignDetails([...AssignDetails, ...getTeamData.data.data]);
                setPerPage(getTeamData.data);
                setCurrentPage(getTeamData.data.current_page);
            }
        }
    }, [getTeamData]);

    const loadMore = () => {
        let cPage = currentPage;
        const params = {
            page: cPage + 1,
        }
        recallApiWithNewParams(params);
        setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
        }, 1000);
    }

    const fetchData = async (originalAmount) => {
        try {
            const response = await axiosConfig.get('/get-count');
            const getDiscount = response.data.data;
            if (getDiscount.discount > 0) {
                const discount = getDiscount.discount;
                const discountedPrice = originalAmount * (discount / 100);
                const finalPrice = originalAmount - discountedPrice;

                setAmount(finalPrice);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const modelshow = (value, id, uId, status) => {
        setPurchaseCertificate(true)
        setcertificateId(id)
        setuserId(uId)
        fetchData(value);
        setCertificateStatus(status)
    }
    const renderCertificate = (item, certificateName, index) => {
        let certification = item.certification;
        let user_certificate = undefined;
        if (certificateName === "basic_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'basic_life_support_re_certification')
        }

        if (certificateName === "advanced_cardiovascular_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'advanced_cardiovascular_life_support_re_certification')
        }

        if (certificateName === "pediatric_advanced_life_support") {
            user_certificate = certification.find((citem) => citem.name_slug === 'pediatric_advanced_life_support_re_certification')
        }
        if (user_certificate === undefined) {
            user_certificate = certification.find((citem) => citem.name_slug === certificateName);
        }

        if (user_certificate !== undefined) {
            return (
                <div className="text-center">
                    {user_certificate.certificate_status === "n/a" && (
                        <>
                            <a className="cursor-pointer" onClick={() => modelshow(user_certificate.amount, user_certificate.certificate_type_id, user_certificate.assign_to, user_certificate.certificate_status)} >
                                <span className="sub-course-detail">
                                    N/A
                                </span>
                            </a>
                        </>
                    )}
                    {user_certificate.certificate_status === 'assigned' && (
                        <>
                            <img src={Assigned} width="35" alt="right" /> <br />
                            <span className="sub-course-detail">
                                {moment(user_certificate.assign_date).format('M/D/YYYY')}
                            </span>
                        </>
                    )}
                    {user_certificate.certificate_status === 'active' && (
                        <>
                            <img src={Right} width="35" alt="right" /> <br />
                            <span className="sub-course-detail">
                                {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                            </span>
                        </>
                    )}
                    {user_certificate.certificate_status === 'assign_expired' && (
                        <div>
                            <img src={AssignedExpired} width="35" alt="right" /> <br />
                            <span className="sub-course-detail">
                                {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                            </span>
                        </div>
                    )}


                    {user_certificate.certificate_status === 'expiring_soon' && (
                        <>
                            <a className="cursor-pointer" onClick={() => modelshow(user_certificate.amount, user_certificate.certificate_type_id, user_certificate.assign_to, user_certificate.certificate_status)} >
                                <img src={Error} width="35" alt="Close" style={{ pointerEvents: 'none' }} />
                                <br />
                                <span className="sub-course-detail">
                                    {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}

                    {user_certificate.certificate_status === 'expired' && (
                        <>
                            <a className="cursor-pointer" onClick={() => modelshow(user_certificate.amount, user_certificate.certificate_type_id, user_certificate.assign_to, user_certificate.certificate_status)} >
                                <img src={Close} width="35" alt="Close" style={{ pointerEvents: 'none' }} />
                                <br />
                                <span className="sub-course-detail">
                                    Expired on: {moment(user_certificate.expiration_date).format('M/D/YYYY')}
                                </span>
                            </a>
                        </>
                    )}



                </div>
            )
        }
        return null;
    };

    return (<>
        <Header />

        <section className="pt-10 pb-10 feature-promo bg-light" id="assign-course-page-1">
            <div className="container">
                <div className="row">
                    <table className="table table-striped">
                        <thead className="thead-course">
                            <tr>
                                <th className="text-center course-detail-th">Name</th>

                                <th className="text-center course-detail-th">BLS</th>

                                <th className="text-center course-detail-th">ACLS</th>

                                <th className="text-center course-detail-th">PALS</th>
                            </tr>
                        </thead>

                        {!loading && (
                            <>
                                <tbody>
                                    {AssignDetails.map((item, index) => (
                                        <tr className="tr-border-bg" key={index}>
                                            <td className="text-center" data-label="Name">
                                                <span className="sub-course-detail text-capitalize">{item.first_name} {item.last_name}</span>
                                            </td>
                                            <td data-label="BLS" className="text-center">
                                                {
                                                    renderCertificate(item, 'basic_life_support') || <span className="sub-course-detail">-</span>
                                                }
                                            </td>
                                            <td data-label="ACLS" className="text-center">
                                                {
                                                    renderCertificate(item, 'advanced_cardiovascular_life_support') || <span className="sub-course-detail">-</span>
                                                }
                                            </td>
                                            <td data-label="PALS" className="text-center">
                                                {
                                                    renderCertificate(item, 'pediatric_advanced_life_support') || <span className="sub-course-detail">-</span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        )}

                    </table>
                    {!loading && AssignDetails.length === 0 && (
                        <>
                            <div className="text-center">
                                <h5 className="valid-till">No assign course list found.</h5>
                            </div>
                        </>
                    )}
                    <div className="float-right">

                        {currentPage < perPage.last_page &&
                            (<button onClick={loadMore} className="load-more" type="button">{loadMoreLoading ? 'Loading...' : 'Load More'}</button>)
                        }
                    </div>
                    <div className="container d-flex align-items-center justify-content-center vh-80">
                        {loading && (
                            <Watch
                                height="80"
                                width="80"
                                radius="48"
                                color="#2f99a9 "
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        )}
                    </div>
                    <div className="container d-flex align-items-center justify-content-center vh-80">
                        {loadMoreLoading && !loading && (
                            <Watch
                                height="70"
                                width="70"
                                radius="48"
                                color="#2f99a9 "
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20" />
        </section>

        <PurchaseAndAssignCourse
            show={purchaseCertificate}
            onHide={() => setPurchaseCertificate(false)}
            amount={amount}
            certificateId={certificateId}
            userId={userId}
            certificateStatus={certificateStatus}
        />
    </>)
}

export default AssignCourses;