import React, { useState } from "react";
import Header from "../../../components/superAdmin/Layouts/Header";
import UseAddUser from '../../../hooks/superAdmin/UseAddUser';
import {ThreeDots} from "react-loader-spinner";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddUser() {
    const digitPattern = /^\d+$/;
    const phonePattern = /^\d+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [title, setTitle] = useState('');
    const [blsCourses, setBlsCourses] = useState('');
    const [blsReCourses, setBlsReCourses] = useState('');
    const [aclsCourses, setAclsCourses] = useState('');
    const [aclsReCourses, setAclsReCourses] = useState('');
    const [palsCourses, setPalsCourses] = useState('');
    const [palsReCourses, setPalsReCourses] = useState('');
    const [discount, setDiscount] = useState('');

    const [errorFirstName, setFirstNameError] = useState(null);
    const [errorLastName, setLastNameError] = useState(null);
    const [errorEmail, setEmailError] = useState(null);
    const [errorPhone, setPhoneError] = useState(null);
    const [errorOrganization, setOrganizationError] = useState(null);
    const [errorTitle, setTitleError] = useState(null);
    const [errorBlsCourses, setBlsCoursesError] = useState(null);
    const [errorBlsReCourses, setBlsReCoursesError] = useState(null);
    const [errorAclsCourses, setAclsCoursesError] = useState(null);
    const [errorAclsReCourses, setAclsReCoursesError] = useState(null);
    const [errorPalsCourses, setPalsCoursesError] = useState(null);
    const [errorPalsReCourses, setPalsReCoursesError] = useState(null);
    const [errorDiscount, setDiscountError] = useState(null);

    const [coursesError, setCoursesError] = useState(null);

    const { isAddUser, AddNewUser } = UseAddUser();

    // on change
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setFirstNameError('');
    };
    
    const handleLastNameChange = (e) => {
        setLastName(e.target.value); 
        setLastNameError('');   
    };
    
    const handleEmailChange = (e) => {
        const enteredEmail = e.target.value;    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmail(enteredEmail);
        if (!emailPattern.test(enteredEmail)) {
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
    };
    
    const handlePhoneChange = (e) => {
        const enteredPhone = e.target.value;      
        const phonePattern = /^\d+$/;
        setPhone(enteredPhone);
        if (!phonePattern.test(enteredPhone)) {
          setPhoneError('Phone must contain only numbers');
        } else {
          setPhoneError('');
        }
      };
    
    const handleOrganizationChange = (e) => {
        setOrganization(e.target.value);
        setOrganizationError('');
    };
    
    const handleTitleChange = (e) => {
        setTitle(e.target.value);   
        setTitleError('');
    };

    const handleBlsCoursesChange = (e) => {
        const enteredBlsCourses = e.target.value;
      
        // Validation regex pattern for only digits
        const digitPattern = /^\d+$/;
      
        setBlsCourses(enteredBlsCourses);
      
        if (!digitPattern.test(enteredBlsCourses)) {
          setBlsCoursesError('Bls Course must contain only numbers');
        } else {
          setBlsCoursesError('');
        }
    };

    const handleBlsReCoursesChange = (e) => {
        const enteredBlsReCourses = e.target.value;      
        const digitPattern = /^\d+$/;
        setBlsReCourses(enteredBlsReCourses);
        if (!digitPattern.test(enteredBlsReCourses)) {
          setBlsReCoursesError('Bls Re-certificate Course must contain only numbers');
        } else {
          setBlsReCoursesError('');
        }
    };
    
    const handleAclsCoursesChange = (e) => {
        const enteredAclsCourses = e.target.value;
      
        // Validation regex pattern for only digits
        const digitPattern = /^\d+$/;
      
        setAclsCourses(enteredAclsCourses);
      
        if (!digitPattern.test(enteredAclsCourses)) {
          setAclsCoursesError('Acls Course must contain only numbers');
        } else {
          setAclsCoursesError('');
        }
    };

    const handleAclsReCoursesChange = (e) => {
       
       const enteredAclsCourses = e.target.value;
        const digitPattern = /^\d+$/;
        setAclsReCourses(enteredAclsCourses);
      
        if (!digitPattern.test(enteredAclsCourses)) {
          setAclsReCoursesError('Acls Re-certificate Course must contain only numbers');
        } else {
            setAclsReCoursesError('');
        }
    };
    
    const handlePalsCoursesChange = (e) => {
        const enteredPalsCourses = e.target.value;
        const digitPattern = /^\d+$/;
        setPalsCourses(enteredPalsCourses);
        if (!digitPattern.test(enteredPalsCourses)) {
          setPalsCoursesError('Pals Course must contain only numbers');
        } else {
          setPalsCoursesError('');
        }
    };
      
      
    const handlePalsReCoursesChange = (e) => {
        const enteredPalsReCourses = e.target.value;
        const digitPattern = /^\d+$/;
        setPalsReCourses(enteredPalsReCourses);
      
        if (!digitPattern.test(enteredPalsReCourses)) {
          setPalsReCoursesError('Pals Re-certificate Course must contain only numbers');
        } else {
          setPalsReCoursesError('');
        }
    };
      
      
    const handleDiscountChange = (e) => {
        const enteredDiscount = e.target.value;
        const digitPattern = /^\d+$/;
        setDiscount(enteredDiscount);
      
        if (!digitPattern.test(enteredDiscount)) {
          setDiscountError('Discount must contain only numbers');
        }else if (parseInt(enteredDiscount, 10) <= 0) {
            setDiscountError('Discount must be greater than 0');
        }  else {
          setDiscountError('');
        }
    };

    //

    const validateCourses = () => {
        const courses = [blsCourses, blsReCourses, aclsCourses, aclsReCourses, palsCourses, palsReCourses];
      
        if (courses.every(course => parseInt(course, 10) <= 0)) {
          setCoursesError('At least one course should have a value greater than 0');
          return false;
        } else {
          setCoursesError(null);
          return true;
        }
      };
      


    const handleSubmit = async () => {

        if (!firstName) {
            setFirstNameError('First Name is required');
        }
        if (!lastName) {
            setLastNameError('Last Name is required');
        }

        if (!email) {
            setEmailError('Email is required');
        }else if (!emailPattern.test(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        if (!phone) {
            setPhoneError('Phone is required');
        }else if (!phonePattern.test(phone)) {
            setPhoneError('Phone must contain only numbers');
            return;
        } 

        if (!organization) {
            setOrganization('Organization is required');
        }
        
        if (!title) {
            setTitleError('Title is required');
        }
        if (!blsCourses) {
            setBlsCoursesError('Bls Course is Required');
        }else if (!digitPattern.test(blsCourses)) {
            setBlsCoursesError('Bls Course must contain only numbers');
            return;
        }
        if (!blsReCourses) {
            setBlsReCoursesError('Bls Re-certificate Course is Required');
        }else if (!digitPattern.test(blsReCourses)) {
            setBlsReCoursesError('Bls Re-certificate Course must contain only numbers');
            return;
        }

        if (!aclsCourses) {
            setAclsCoursesError('Acls Course is Required');
        }else if (!digitPattern.test(aclsCourses)) {
            setAclsCoursesError('Acls Course must contain only numbers');
        }

        if (!aclsReCourses) {
            setAclsReCoursesError('Acls Re-certificate Course is Required');
        }else if (!digitPattern.test(aclsReCourses)) {
            setAclsReCoursesError('Acls Re-certificate Course must contain only numbers');
        }

        if (!palsCourses) {
            setPalsCoursesError('Pals Course is Required');
        }else if (!digitPattern.test(palsCourses)) {
            setPalsCoursesError('Pals Course must contain only numbers');
            return;
        }

        if (!palsReCourses) {
            setPalsReCoursesError('Pals Re-certificate Course is Required');
        }else if (!digitPattern.test(palsReCourses)) {
            setPalsReCoursesError('Pals Re-certificate Course must contain only numbers');
            return;
        }

        if (!discount) {
            setDiscountError('Discount is Required');
        }else if (!digitPattern.test(discount)) {
            setDiscountError('Discount must contain only numbers');
            return;
        }else if (parseInt(discount, 10) <= 0) {
            setDiscountError('Discount must be greater than 0');
            return;
        }
       
        const areCoursesValid = validateCourses();

        if (!areCoursesValid) {
          return;
        }
        if (firstName && lastName && email && phone && organization && title && blsCourses && blsReCourses && aclsCourses && aclsReCourses && palsCourses && palsReCourses && discount ) {
            setLoading(true);

            try {

                const data = {
                    "first_name" : firstName,
                    "last_name" : lastName,
                    "email" : email,
                    "phone_number": phone,
                    "organization" : organization,
                    "title" : title,
                    "bls_courses" : blsCourses,
                    "bls_re_courses" : blsReCourses,
                    "acls_courses" : aclsCourses,
                    "acls_re_courses":aclsReCourses,
                    "pals_courses":palsCourses,
                    "pals_re_courses": palsReCourses,
                    "discount": discount
            }
                const {
                    responseCode: addMember
                } = await AddNewUser(data);
                
                if (addMember.data.status === 'success') {
                    window.location.href = "/super-admin/users";
                } else {
                    setLoading(false);
                    toast.error(addMember.data.message);
                   
                }
            }catch (error) {
                setLoading(false);
                toast.error('Something went wrong');
                
            }
            setLoading(false);
        }



        
    }

    




    return (
        <>
            <Header />

            <section className="pt-10 pb-10 feature-promo bg-light manager-tab-listing">
                <div className="container" id="add-user-manager">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-7 col-lg-7">
                            <div className="">
                                <p className="add-new-team-member-title">Add New Manager </p>
                            </div>
                        </div>
                    </div>
                    <form >
                        <div className="row mt-5">
                            <div className="col-lg-4 padding-add-teams-0">
                                <label htmlFor="first_name" className="mb-1">First Name</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="first_name" id="first_name" onChange={handleFirstNameChange} maxLength='40' />
                                </div>
                                <p className="error">{errorFirstName}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="last_name" className="mb-1">Last Name</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="last_name" id="last_name" onChange={handleLastNameChange} maxLength='40' />
                                </div>
                                <p className="error">{errorLastName}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="email" className="mb-1">Email</label>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control"  name="email" id="email" onChange={handleEmailChange} maxLength='99'/>
                                </div>
                                <p className="error">{errorEmail}</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4 padding-add-teams-0">
                                <label htmlFor="phone_number" className="mb-1">Phone Number</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="phone_number" id="phone_number" onChange={handlePhoneChange} maxLength='15'/>
                                </div>
                                <p className="error">{errorPhone}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="organization" className="mb-1">Organization</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="organization" id="organization" onChange={handleOrganizationChange} maxLength='100' />
                                </div>
                                <p className="error">{errorOrganization}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="title" className="mb-1">Title</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="title" id="title" onChange={handleTitleChange} maxLength='100'/>
                                </div>
                                <p className="error">{errorTitle}</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4 padding-add-teams-0">
                                <label htmlFor="bls_courses" className="mb-1">Bls Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="bls_courses" id="bls_courses" onChange={handleBlsCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorBlsCourses}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="bls_re_courses" className="mb-1">Bls Re-Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="bls_re_courses" id="bls_re_courses" onChange={handleBlsReCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorBlsReCourses}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="acls_courses" className="mb-1">Acls Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="acls_courses" id="acls_courses" onChange={handleAclsCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorAclsCourses}</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4 padding-add-teams-0">
                                <label htmlFor="acls_re_courses" className="mb-1">Acls Re-Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="acls_re_courses" id="acls_re_courses" onChange={handleAclsReCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorAclsReCourses}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="pals_courses" className="mb-1">Pals Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="pals_courses" id="pals_courses" onChange={handlePalsCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorPalsCourses}</p>
                            </div>
                            <div className="col-lg-4">
                                <label htmlFor="pals_re_courses" className="mb-1">Pals Re-Certificate Courses</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control"  name="pals_re_courses" id="pals_re_courses" onChange={handlePalsReCoursesChange} maxLength='4' />
                                </div>
                                <p className="error">{errorPalsReCourses}</p>
                            </div>
                        </div>
                        <p className="error">{coursesError}</p>
                        <div className="row mt-3">
                            <div className="col-lg-4 padding-add-teams-0">
                                <label htmlFor="discount" className="mb-1">Discount</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="discount" id="discount" onChange={handleDiscountChange} maxLength='2'/>
                                </div>
                                <p className="error">{errorDiscount}</p>
                            </div>
                        </div>
                        <div className="submit-btn-add-team">
                            <button type="button" className="btn btn-primary mt-4" onClick={handleSubmit} disabled={loading}>
                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
            <ToastContainer />
        </>
    )


}

export default AddUser;