import { useState, useEffect } from "react";

import SampleTest from '../api/SampleTest';

const UseSampleTestResult = ({name_slug,employee_id}) => {
    const [getQuestions, setQuestions] = useState([]);
  
    useEffect(() => {
      const getQuestion = async () => {
        try {
          const response = await SampleTest.getSampleTestResult({
            params: { name_slug , employee_id},
          });
          setQuestions(response.data);
        } catch (error) {
          console.log(error);
        }
  
      };
  
      getQuestion();
    }, []);
  
    return {getQuestions};
   
}



export default UseSampleTestResult;