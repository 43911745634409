import { useState } from "react";

import manager from "../../api/manager/manager";

const UseProcessToAddFund = () => {
    const [isAddFund, setIsAddFund] = useState(false);
  
    const AddFundApi = async (body) => {
      setIsAddFund(true);
  
      let responseCode;
  
      try {
        const response = await manager.processToAddFund(body);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsAddFund(false);
  
      return { responseCode };
    };
  
    return { isAddFund, AddFundApi };
  };


export default UseProcessToAddFund;