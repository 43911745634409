import React,{useState} from "react";
import Print from '../../../../assets/web-images/images/Print.svg'
import { Link } from "react-router-dom";
import PrintAlgorithm from "../../../../components/website/Algorithms/Modal/PrintAlgorithm";

export default function Pals() {

    const [modalShow, setModalShow] = useState(false);
    const [printImageUrl, setPrintImageUrl] = useState("");
    const [printName, setPrintName] = useState("");

    const algorithamsArray = [
        {
            name: "PALS Assessment Sequence Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/12.svg`
        },
        {
            name: "PALS Cardiac Arrest Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/13.svg`
        },
        {
            name: " PALS Bradycardia Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/14.svg`
        },
        {
            name: "PALS Tachycardia Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/15.svg`
        },
        {
            name: "PALS Septic Shock Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/16.svg`
        },
        {
            name: "PALS Shock Management after ROSC Algorithm",
            imageUrl: `${process.env.REACT_APP_IMAGES}/assets/images/Algorithms/17.svg`
        },
    ]

    const openPrintModel = (imageUrl,name) => {
        setPrintImageUrl(imageUrl);
        setPrintName(name);
        setModalShow(true);
    }

    return (

        <>
            <section className="pt-3 text-black">
            <div className="container">
                <div className="row pt-5">
                    {algorithamsArray.map((item,index) => 
                        <div className="col-lg-4">
                        <div className="card pals-print-img">
                            <img src={item.imageUrl} alt="bls-1" onClick={() => openPrintModel(item.imageUrl, item.name)}/>
                            <div className="row mt-4">
                                <div className="col-lg-8">
                                    <p className="bls-algo">{item.name}</p>
                                </div>
                                <div className="col-lg-4">
                                    <div className="print-title-div">
                                        <Link className="print-title" onClick={() => openPrintModel(item.imageUrl,item.name) } > <img alt="print" src={Print} width='20' height='30' /> Print </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    
                   

                </div>


               
            </div>
        </section>

        <PrintAlgorithm
                show={modalShow}
                onHide={() => setModalShow(false)}
                printImageUrl={printImageUrl}
                printName={printName}
            />
        
        </>

    )
}