import React, { useState, useEffect } from "react";
import Header from "../../../components/website/Layouts/Header";
import Remove from '../../../assets/emplyoee-images/images/remove.svg'
import Plus from '../../../assets/emplyoee-images/images/Plus.svg'
import UseCertificateTypes from '../../../hooks/UseCertificateTypes';
import UsePayment from '../../../hooks/UsePayment';
import { ThreeDots } from "react-loader-spinner";
import Cookies from 'universal-cookie';
import UseDiscountLevel from "../../../hooks/UseDiscountLevel";
import UseDiscountUsers from "../../../hooks/UseDiscountUsers";
import axiosConfig from '../../../api/Config';
import {toast, ToastContainer} from 'react-toastify';

export default function CourseCart() {

    const [CertificateType, setCertificateTypes] = useState([]);
    const [certificateCart, setCertificateCart] = useState([]);
    const [helpers, setHelper] = useState('');
    const [addDiscount, setDiscount] = useState('');
    const [getDiscountDetail, setDiscountDetail] = useState({});
    const [discountloading, setDiscountLoading] = useState(true);

    const [couponCode, setCouponCode] = useState('');
    const [checkoutDisable, setCheckoutDisable] = useState(false);
    const [discountUser, setDiscountUser] = useState('');
    const [error, setError] = useState("");

    const { certificateTypes: CertificateTypeGet } = UseCertificateTypes()
    const [loading, setLoading] = useState(false);
    const { isPaymentApi, PaymentData } = UsePayment();
    const discountValue = helpers.amount

    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const token = cookies.get("token") ? cookies.get("token") : '';
    useEffect(() => {
        fetchCertificateType();
    }, [CertificateTypeGet]);

    const fetchCertificateType = async () => {
        setCertificateTypes(CertificateTypeGet);
    }
    useEffect(() => {
        cartDataShow();
    }, []);

    const cartDataShow = () => {
        const storedCart = localStorage.getItem('certificateCart');
        if (storedCart) {
            setCertificateCart(JSON.parse(storedCart));
        }
    }


    // const { allDiscounts, loadingDiscounts, recallApiWithNewParams } = UseDiscountUsers(couponParams);

    // useEffect(() => {
    //     if (allDiscounts.status === "success") {
    //         setListDiscount([allDiscounts.data]);
    //     }
    // }, [allDiscounts]);


    const handleCouponCode = async (e) => {
        const code = e.target.value
        const params = {
            coupon_code: code,
        }

        const couponCode = await axiosConfig.post(`/get-discount`, params, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
        if (couponCode.status === 'success') {
            setDiscountUser(couponCode.data.discount_percentage);
            setCouponCode(couponCode.data.coupon_code);
            setError('');
            setCheckoutDisable(false);
        } else {
            setCheckoutDisable(true);
            setError('This code is invalid.');
            if (code === '') {
                setError('');
                setCheckoutDisable(false);
            }
        }

    }

    const handleClick = (itemId) => {
        const updatedCart = [...certificateCart, itemId];

        setCertificateCart(updatedCart);

        localStorage.setItem('certificateCart', JSON.stringify(updatedCart));
    };

    const handleRemove = (itemId) => {
        const updatedCart = certificateCart.filter((id) => id !== itemId);
        setCertificateCart(updatedCart);
        localStorage.setItem('certificateCart', JSON.stringify(updatedCart));
    };

    const key = 'certificateCart';
    const CartValue = JSON.parse(localStorage.getItem(key));
    const sessionCount = CartValue ? CartValue.length : 0;

    const subtotal = CertificateType.filter(item => certificateCart.includes(item.id)).reduce((total, item) => total + parseFloat(item.amount), 0);
    const discount = subtotal * discountValue;
    const subtotalAfterDiscount = discount / 100;
    const totals = CertificateType.filter(item => certificateCart.includes(item.id)).reduce((total, item) => total + parseFloat(item.amount), 0)
    const FinalTotal = totals - subtotalAfterDiscount;

    const onChangeDiscount = (e) => {
        setDiscount(e.target.value)
    }


    const handleCheckout = async (e) => {
        e.preventDefault();
        setLoading(true);
        const sessionData = localStorage.getItem('certificateCart');
        const parsedData = JSON.parse(sessionData);
        const localStorageIds = parsedData;
        let paymentGet;
        // const {
        //     responseCode: paymentGet
        // } = await PaymentData({ 'certificate_type_id': localStorageIds,'coupon_code':couponCode });
        if (couponCode) {
            const { responseCode } = await PaymentData({
                certificate_type_id: localStorageIds,
                coupon_code: couponCode
            });
            if (responseCode.status !== 'error') {
                paymentGet = responseCode;
            }else{
                toast.error(responseCode.message);
            }
        } else {
            const { responseCode } = await PaymentData({
                certificate_type_id: localStorageIds,
                coupon_code: ''
            });
            if (responseCode.status !== 'error') {
                paymentGet = responseCode;
            }else{
                toast.error(responseCode.message);
            }
            
        }

        if (userDetails === "") {
            window.location.href = '/login';
            localStorage.setItem('CourseCart', JSON.stringify(window.location.pathname));
            localStorage.setItem('CouponCode', couponCode);

        } else {
            if (paymentGet) {
                window.location.href = paymentGet.url;
            }
        }
        setLoading(false);
    };


    const { discount: DiscountLevel } = UseDiscountLevel();
    useEffect(() => {
        if (DiscountLevel.status === 'success') {
            setDiscountLoading(false);
            setDiscountDetail(DiscountLevel.data)
        }
    }, [DiscountLevel]);

    const getCertificateCart = localStorage.getItem('certificateCart');
    const isItemInCart = getCertificateCart && (getCertificateCart.includes(3) || getCertificateCart.includes(2) || getCertificateCart.includes(1));
    const isBundleCart = getCertificateCart && (getCertificateCart.includes(7));
    return (
        <>
            <div className="login-header">
                <Header />
            </div>

            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row">
                        <h4 className="shopping-cart">Shopping Cart</h4>
                        <p className="item-carts">You have {sessionCount} items in your cart.</p>
                        <div className="col-lg-8">
                            {
                                CertificateType.map((item) =>
                                    <>
                                        {certificateCart.includes(item.id) && (
                                            <div className="card" id='course-cart-purchase'>
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        {item.short_name === 'apb_certificate' || item.short_name === 'apb_re_certificate' ?
                                                            <img src={item.certificate_image} alt="bls" width="300" height="90" /> :
                                                            <img src={item.certificate_image} alt="bls" />
                                                        }
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <p className="title-certification-card">
                                                            {item.short_name === 'bls_certification' && <>BLS Certification</>}
                                                            {item.short_name === 'bls_re_certification' && <>BLS Re-Certification</>}
                                                            {item.short_name === 'acls_certification' && <>ACLS Certification</>}
                                                            {item.short_name === 'acls_re_certification' && <>ACLS Re-Certification</>}
                                                            {item.short_name === 'pals_certification' && <>PALS Certification</>}
                                                            {item.short_name === 'pals_re_certification' && <>PALS Re-Certification</>}
                                                            {item.short_name === 'apb_certificate' && <>Advanced Provider Bundle Certification</>}
                                                            {item.short_name === 'apb_re_certificate' && <>Advanced Provider Bundle Re-Certification</>}
                                                        </p>

                                                        <br />
                                                        <p className="title-cretification-price">{item.amount && `$${Number(item.amount).toFixed(2)}`}</p>
                                                    </div>
                                                    <div className="col-lg-1 text-end">
                                                        <a className="remove-course-cart-page" onClick={() => handleRemove(item.id)}>
                                                            <img src={Remove} alt="remove" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )


                                        }

                                    </>
                                )
                            }

                            <div className="row mb-5" id="additional">
                                <h4 className="shopping-cart">Add Additional Certifications</h4>
                                {
                                    CertificateType.map((item) =>
                                        <>
                                            {!certificateCart.includes(item.id) && (
                                                <>
                                                    <div className="col-lg-12 mt-5">
                                                        <div className="row">
                                                            <div className="col-lg-9">
                                                                <h5 className="acls-cart-title">
                                                                    {item.short_name === 'bls_certification' && <>BLS Certification</>}
                                                                    {item.short_name === 'bls_re_certification' && <>BLS Re-Certification</>}
                                                                    {item.short_name === 'acls_certification' && <>ACLS Certification</>}
                                                                    {item.short_name === 'acls_re_certification' && <>ACLS Re-Certification</>}
                                                                    {item.short_name === 'pals_certification' && <>PALS Certification</>}
                                                                    {item.short_name === 'pals_re_certification' && <>PALS Re-Certification</>}
                                                                    {item.short_name === 'apb_certificate' && <>Advanced Provider Bundle Certification</>}
                                                                    {item.short_name === 'apb_re_certificate' && <>Advanced Provider Bundle Re-Certification</>}

                                                                </h5>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <button href="#" className="add-to-cart-btn" onClick={() => handleClick(item.id)}
                                                                    // disabled={
                                                                    //     (item.id === 7 && (getCertificateCart.includes(1) || getCertificateCart.includes(2) || getCertificateCart.includes(3))) ||
                                                                    //     (item.id === 1 && getCertificateCart.includes(7)) ||
                                                                    //     (item.id === 2 && getCertificateCart.includes(7)) ||
                                                                    //     (item.id === 3 && getCertificateCart.includes(7)) ||
                                                                    //     (item.id === 8 && (getCertificateCart.includes(4) || getCertificateCart.includes(5) || getCertificateCart.includes(6))) ||
                                                                    //     (item.id === 4 && getCertificateCart.includes(8)) ||
                                                                    //     (item.id === 5 && getCertificateCart.includes(8)) ||
                                                                    //     (item.id === 6 && getCertificateCart.includes(8))
                                                                    // }
                                                                    disabled={
                                                                        (item.id === 7 &&
                                                                            (getCertificateCart && (getCertificateCart.includes(1) || getCertificateCart.includes(2) || getCertificateCart.includes(3)))) ||
                                                                        (item.id === 1 && getCertificateCart && getCertificateCart.includes(7)) ||
                                                                        (item.id === 2 && getCertificateCart && getCertificateCart.includes(7)) ||
                                                                        (item.id === 3 && getCertificateCart && getCertificateCart.includes(7)) ||
                                                                        (item.id === 8 &&
                                                                            (getCertificateCart && (getCertificateCart.includes(4) || getCertificateCart.includes(5) || getCertificateCart.includes(6)))) ||
                                                                        (item.id === 4 && getCertificateCart && getCertificateCart.includes(8)) ||
                                                                        (item.id === 5 && getCertificateCart && getCertificateCart.includes(8)) ||
                                                                        (item.id === 6 && getCertificateCart && getCertificateCart.includes(8))
                                                                    }
                                                                > <img src={Plus} alt="plus" width='15' /> Add to Cart</button>
                                                            </div>
                                                        </div>

                                                        <p className="title-cretification-price">
                                                            {item.amount && `$${Number(item.amount).toFixed(2)}`}
                                                        </p>
                                                    </div>
                                                    <hr />
                                                </>
                                            )}
                                        </>
                                    )
                                }
                                <p className="copy-text-2023">© 2023 Pulse Certification LLC. All Rights Reserved.</p>
                            </div>

                        </div>
                        <div className="col-lg-4">
                            {
                                certificateCart.length > 0 && (

                                    <>
                                        <div className="card bls-data-1">
                                            <h5 className="purchase-course">Course Purchase Summary</h5>



                                            <table className="table">
                                                <tbody>
                                                    {CertificateType.filter(item => certificateCart.includes(item.id)).map((item) => (
                                                        <tr key={item.id}>
                                                            <td className="course-td-none">
                                                                {item.short_name === 'bls_certification' && <>BLS Certification</>}
                                                                {item.short_name === 'bls_re_certification' && <>BLS Re-Certification</>}
                                                                {item.short_name === 'acls_certification' && <>ACLS Certification</>}
                                                                {item.short_name === 'acls_re_certification' && <>ACLS Re-Certification</>}
                                                                {item.short_name === 'pals_certification' && <>PALS Certification</>}
                                                                {item.short_name === 'pals_re_certification' && <>PALS Re-Certification</>}
                                                                {item.short_name === 'apb_certificate' && <>Advanced Provider Bundle Certification</>}
                                                                {item.short_name === 'apb_re_certificate' && <>Advanced Provider Bundle Re-Certification</>}
                                                                :
                                                            </td>
                                                            <td className="purchase-course text-capitalize" data-label={item.short_name.replace(/_/g, ' ')}> {item.amount && `$${Number(item.amount).toFixed(2)}`}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td className="course-td-none">Subtotal:</td>
                                                        <td className="purchase-course" data-label="Tax and fees:">
                                                            {subtotal && `$${Number(subtotal).toFixed(2)}`}
                                                        </td>
                                                    </tr>
                                                    {/* {userDetails !== '' &&
                                                        <>
                                                            {userDetails.user_role.role_id === 2 &&
                                                                <>
                                                                    {userDetails.discount_status === 'active' ?
                                                                        <>
                                                                            <tr className="dis-tr">

                                                                                <td className="course-td-none d-flex">Discount Code <input className="course-cart-discount-added" type="text" onChange={onChangeDiscount} value={userDetails.discount_code} readOnly /> :</td>
                                                                                <td className="purchase-course" data-label="Discount:">
                                                                                   
                                                                                    <>(-) ${(subtotal * (userDetails.discount_percentage / 100)).toFixed(2)}</>

                                                                                </td>
                                                                            </tr>
                                                                        </> :
                                                                        <>
                                                                            <td className="course-td-none d-flex">Discount ({getDiscountDetail.discount}%):</td>
                                                                            <td className="purchase-course" data-label="Discount:">
                                                                                <>(-) ${(subtotal * (getDiscountDetail.discount / 100)).toFixed(2)}</>
                                                                            </td>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    } */}
                                                    <tr className="dis-tr window-lp-view">
                                                        <td className="course-td-none d-flex">Discount Code <input className="course-cart-discount-added" type="text" onChange={handleCouponCode} /> :
                                                        </td>
                                                        {error && <span style={{ color: 'red', "border-left": "1px solid #dddddd" }}>{error}</span>}
                                                        {!error ?
                                                            <>
                                                                <td className="purchase-course discount-minus" data-label="Discount:"><>(-) ${(subtotal * (discountUser / 100)).toFixed(2)}</></td>
                                                            </> :
                                                            <>
                                                                <td className="purchase-course discount-minus" data-label="Discount:"><>(-) ${(0).toFixed(2)}</></td>
                                                            </>
                                                        }
                                                    </tr>
                                                    <tr className="dis-tr mobile-tab-view">
                                                        <td className="purchase-course" data-label="Discount Code:"><input className="course-cart-discount-added" type="text" onChange={handleCouponCode} /> 
                                                            
                                                            {!error ?
                                                                <>
                                                                    <td className="purchase-course discount-minus" ><>(-) ${(subtotal * (discountUser / 100)).toFixed(2)}</></td>
                                                                </> :
                                                                <>
                                                                    <td className="purchase-course discount-minus"><>(-) ${(0).toFixed(2)}</></td>
                                                                </>
                                                            }
                                                        </td>
                                                        {error && <span style={{ color: 'red' }}>{error}</span>}
                                                    </tr>


                                                    <tr className="dis-tr">
                                                        <td className="course-td-none">Total:</td>
                                                        <td className="purchase-course" data-label="Total:">

                                                            {/* {userDetails !== '' ?
                                                                <>
                                                                    {userDetails.user_role.role_id === 2 ? 
                                                                        <>
                                                                            {userDetails.discount_status === 'active' ?
                                                                                <>${(subtotal - subtotal * (userDetails.discount_percentage / 100)).toFixed(2)}</>
                                                                                :
                                                                                <>${(subtotal - subtotal * (getDiscountDetail.discount / 100)).toFixed(2)}</>
        
                                                                            }
                                                                        
                                                                        </>
                                                                        :
                                                                        <>
                                                                        {subtotal && `$${Number(subtotal).toFixed(2)}`}
                                                                        </>
                                                                    }
                                                                </> : <>{subtotal && `$${Number(subtotal).toFixed(2)}`}</>
                                                            } */}
                                                            {discountUser != '' && !error ?
                                                                <>${(subtotal - subtotal * (discountUser / 100)).toFixed(2)}</> :
                                                                <>{subtotal && `$${Number(subtotal).toFixed(2)}`}</>
                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>



                                            <div className="text-center mt-2">
                                                <button type="submit" className="add-to-cart-btn-a course-cart-Proceed" onClick={handleCheckout} disabled={loading || checkoutDisable}>
                                                    {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Create Account and Proceed to Checkout"}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}

                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}