import { useState } from "react";

import Auth from '../api/Auth';

const UseForgotPassword = () => {
    const [isForgortPasswordApi, setIsForgortPasswordApi] = useState(false);
  
    const forgortPasswordApi = async (data) => {
      setIsForgortPasswordApi(true);
  
      let responseCode;
  
      try {
        const response = await Auth.forgotPassword(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsForgortPasswordApi(false);
  
      return { responseCode };
    };
  
    return { isForgortPasswordApi, forgortPasswordApi };
  };


export default UseForgotPassword;