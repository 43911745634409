import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authUtils } from './utils/authUtils';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

const cookies = new Cookies();

const PrivateRoutes = ({ path }) => {
    const auth = authUtils(); // Assuming this function checks for user authentication
    const secretKey = process.env.REACT_APP_API_LIVE;
    // const userRole = cookies.get('role'); // Assuming you have a role stored in the cookies

    const encryptedRole = cookies.get('role') || '';
    const decryptedRoleBytes = encryptedRole ? CryptoJS.AES.decrypt(encryptedRole, secretKey) : null;
    const userRole = decryptedRoleBytes ? decryptedRoleBytes.toString(CryptoJS.enc.Utf8) : '';


    if (auth && userRole) {
        const role = userRole;
        if (role === 'super_admin' && (path.includes('/manager') || path.includes('/employee'))) {
            return <Navigate to="/super-admin" />;
        } else if (role === 'manager' && (path.includes('/super-admin') || path.includes('/employee'))) {
            return <Navigate to="/manager" />;
        } else if (role === 'employee' && (path.includes('/manager') || path.includes('/super-admin'))) {
            return <Navigate to="/employee" />;
        } else if (role === 'manager' && path.includes('/manager')) {
            return <Outlet />;
        } else if (role === 'super_admin' && path.includes('/super-admin')) {
            return <Outlet />;
        } else if (role === 'employee' && path.includes('/employee')) {
            return <Outlet />;
        }
    }

    // Clear cookies before redirecting to login-page
    cookies.remove('role');
    cookies.remove('token');
    cookies.remove('user');
    return <Navigate to="/login" />;
};

export default PrivateRoutes;
