import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import UsemoveCertificateCompleted from "../../../hooks/superAdmin/UsemoveCertificateCompleted";
import {ThreeDots} from "react-loader-spinner";

function PhysicallyAssignedCertificateModal(props) {

    const [loading, setLoading] = useState(false);
    const [statusChange, setStatusChanges] = useState(1);

    const { isMoveCertificate, MoveToCompleted } = UsemoveCertificateCompleted();
    const handleMoveToCompleted = async () => {
        setLoading(true);
        const {
            responseCode: certificateMove
        } = await MoveToCompleted({ 'user_id': props.UserId,'certificate_type_id':props.CertificateId, 'certificate_status': statusChange });

        if (certificateMove.status === 'success') {
            window.history.back();
        }
        setLoading(false);
    };
   
    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove-modal"
            >
                <Modal.Body>
                    <p className="remove-class-title">
                        Are you sure you want to move this certificate to completed?
                    </p>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                            <button type="submit" className="remove-yes-btn" onClick={()=>handleMoveToCompleted()} disabled={loading}>
                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40}/> : "Yes"}
                            </button>
                                <button className="remove-no-btn" onClick={props.onHide}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>          
           
        </>
    )
}

export default PhysicallyAssignedCertificateModal;