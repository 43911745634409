import React, {useState,useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import UseDiscoutPercentage from "../../../hooks/superAdmin/UseDiscoutPercentage";
import {ThreeDots} from "react-loader-spinner";

function CouponCodeModal(props) {
    const [discount, setDiscount] = useState({});
    const [discountStatus, setDiscountStatus] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [discountPercentageError, setDiscountPercentageError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disloading, setDisLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [props.showData]);

    const fetchData = () => {
        setLoading(false);
        setDiscount(props.showData)
    }
    const {isUpdateDiscount, EditDiscoutPercentage} = UseDiscoutPercentage();

    
    const onDiscountChanges = (e) => {
        const updatedDiscount = { ...discount };
        const newDiscountStatus = e.target.checked ? 'active' : 'inactive';
        if (newDiscountStatus === 'active') {
            setDiscountStatus(true);
        } else {
            setDiscountStatus(false);
        }
        updatedDiscount.discount_status = newDiscountStatus;
        setDiscount(updatedDiscount);
    }
    

    const onDiscountPercentageChanges = (e) => {
        setDiscountPercentage(e.target.value);
        setDiscount({
            ...discount,
            discount_percentage: e.target.value,
            discount_status: discount.discount_status
        });
        setDiscountPercentageError('');
    };

    const onDiscountPercentageChanges1 = (e) => {
        const updatedDiscount = { ...discount };
        updatedDiscount.discount.discount_percentage = e.target.value;
        setDiscountPercentage(e.target.value);
        setDiscount(updatedDiscount);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        setDisLoading(true);
        
        
        // if (!discountPercentage || discountPercentage <= 0) {
        //     setDiscountPercentageError('Discount percentage must be greater than 0');
        // } else {
            // setDiscountPercentageError(''); // Reset the error if the discount percentage is valid
            const data = {
                user_id: props.showData.id,
                discount_percentage: discountPercentage || discount.discount_percentage,
                discount_status: discountStatus || discount.discount_status,
            };
    
            const {
                responseCode: updateDiscout
            } = await EditDiscoutPercentage(data);
    
            if (updateDiscout.status === 'success') {
                window.location.href = '/super-admin/coupon-code-list';
            } else {
                console.log('Something went wrong.');
            }
        // }
    
        setDisLoading(false);
    };
    

    

    return (
        <>
            {!loading &&
                <>
                
                    <Modal
                        show={props.show}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className=""
                    >
                        <Modal.Body>
                        

                            <div className="row coupon-code-div mt-3">
                            
                                <div className="col-md-6 col-6 mt-3">
                                    <label>Discount Status :</label>
                                </div>
                                <div className="col-md-6 col-6">
                                    <Form.Check
                                        type="switch"
                                        id="discount-status-switch"
                                        label=""
                                        checked={discount.discount_status === 'active'}
                                        onChange={onDiscountChanges}
                                        className="new-discout-switch mt-3"
                                    /> 
                                </div>
                                <div className="col-md-6  col-7 mt-3">
                                    <label>Discount Percentage :</label>
                                </div>
                                <div className="col-md-6 col-5 mt-3">
                                    <input type="text" onChange={onDiscountPercentageChanges} value={discount.discount_percentage} maxLength='2'/>
                                </div>
                                {/* <div className="col-md-2"></div><div className="col-md-10">{discountPercentageError && <div className="error">{discountPercentageError}</div>}</div> */}
                                {/* <br />{discountPercentageError && <div className="error">{discountPercentageError}</div>} */}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="remove-member mt-5">
                                        <button className="remove-no-btn" onClick={props.onHide}>Cancel</button> &nbsp;
                                    <button type="submit" className="remove-yes-btn" onClick={handleSubmit} disabled={disloading}>
                                        {disloading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Update"}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>          
                </>
            }
           
        </>
    )
}

export default CouponCodeModal;