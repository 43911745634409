import axiosConfig from './Config';


const Teams ={
    createTeams: (data) => axiosConfig.post(`add-team-member`, data),
    createManagerUser: (data) => axiosConfig.post(`add-manager-user`, data),
    getcertificatecount: () => axiosConfig.get(`get-certificate-count`),
    createSelfAssign: (data) => axiosConfig.post(`add-self-assign`, data),
}

export default Teams;