import { useState } from "react";

import CertificateChapter from "../api/CertificateChapter";
const UseaddNextSection = () => {
    const [isCreating, setIsCreating] = useState(false);
  
    const addedNextSection = async (body) => {
      setIsCreating(true);
  
      let responseCode;
  
      try {
        const response = await CertificateChapter.addNextSection(body);
        responseCode = response.data;
      } catch (error) {
        responseCode = {status: "error"};
      }
  
      setIsCreating(false);
  
      return { responseCode };
    };
  
    return { isCreating, addedNextSection };
};


export default UseaddNextSection;