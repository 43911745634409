import { useState } from "react";

import Signup from '../api/Auth';

const UseSignUp = () => {
    const [isSignUpApi, setIsSignUpApi] = useState(false);
  
    const signupApi = async (data) => {
      setIsSignUpApi(true);
  
      let responseCode;
  
      try {
        const response = await Signup.signUp(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsSignUpApi(false);
  
      return { responseCode };
    };
  
    return { isSignUpApi, signupApi };
  };


export default UseSignUp;