import React from "react";
import Header from '../../components/website/Layouts/Header';
import Footer from '../../components/website/Layouts/Footer';
import Doctor from '../../assets/web-images/images/Doctor-Image.png'
import ScrollToTop from 'react-scroll-to-top';

export default function AboutUs()
{
    return(
        <>
            <Header />

            <section className="hero-section ptb-120 text-white bg-gradient" id="main-class" >
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-lg-7 mt-5">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className='main-title'>About us.</h1> <hr /> <br />
                                <div className="row sub-title-row">
                                <p className="pulse-about-us-page"><b>Pulse Certifications </b> was started by a CRNA and an emergency department RN. After years of repeated, less-than-efficient education programs, we felt there was a need for a service that could provide vital, high-quality education to busy health care providers. We love what we do, and we value doing it well. We know that the best health care providers are life-long learners, and we aim to help you continue to grow—without wasting your time.</p>
                                </div>
                            </div>
                            {/* <p className="always-connected">Always Connected <span className="about-us-always">|</span> Always Secure</p> */}
                        </div>
                        <div className="col-lg-5 mt-5">
                            <div className="hero-img position-relative circle-shape-images">

                                <img src={Doctor} alt="doctor-img" className="image-doctor" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <br />
            <ScrollToTop className="scroll-to-button" smooth  color="white" width="20" height="20"/>
        </>
    )    
}