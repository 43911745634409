import { useState } from "react";

import Superadmin from "../../api/SuperAdmin/Superadmin";
const UseUserDelete = () => {

    const [isDeleteUser, setIsDeleteUser] = useState(false);
    const DeleteUser = async (body) => {
        setIsDeleteUser(true);
    
        let responseCode;
    
        try {
          const response = await Superadmin.userDelete(body);
          responseCode = response.data;
        } catch (error) {
          responseCode = error.response.status;
        }
    
        setIsDeleteUser(false);
    
        return { responseCode };
      };
    
      return { isDeleteUser, DeleteUser };
}


export default UseUserDelete;