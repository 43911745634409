import React from "react";
import Right from '../../../../assets/manager-images/images/Right.svg'
import Close from '../../../../assets/manager-images/images/Close.svg'
import Error from '../../../../assets/manager-images/images/Error.svg'
import Arrow  from '../../../../assets/manager-images/images/Arrow.svg'
export default function CertificateCard(props) {
    return (
        <div className="col-lg-4 col-6 manager-card-size">
            <div className="card add-cards-changes">
                <div className="row">
                    <div className="col-lg-6 col-4">
                        <img src={props.item.image} width='70' alt="bls-1" />
                    </div>
                    <div className="col-lg-6 col-8">
                        {
                            props.item.is_recertification === 1 && (<p className="re-certificate">Re-Certification</p>)
                        }
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <p className="manager-re-certificate-title">
                            {props.item.full_name === 'Basic Life Support' && <>BLS Certification </>}
                            {props.item.full_name === 'Advanced Cardiovascular Life Support' && <>ACLS Certification </>}
                            {props.item.full_name === 'Pediatric Advanced Life Support' && <>PALS Certification </>}
                            {props.item.full_name === 'Basic Life Support Re-Certification' && <>BLS Re-Certification </>}
                            {props.item.full_name === 'Advanced Cardiovascular Life Support Re-Certification' && <>ACLS Re-Certification </>}
                            {props.item.full_name === 'Pediatric Advanced Life Support Re-Certification' && <>PALS Re-Certification </>}
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-2">
                        <img src={Right} width='25' alt="right-8" />
                    </div>
                    <div className="col-lg-10 col-10">
                        <p className="manager-sub-title-completed">{props.item.active_count} Up to date</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-2">
                        <img src={Close} width='25' alt="close-11" />
                    </div>
                    <div className="col-lg-10 col-10">
                        <p className="manager-sub-title-completed">{props.item.expired_count} Expired</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-2">
                        <img src={Error} width='25' alt="error-11" />
                    </div>
                    <div className="col-lg-10 col-10">
                        <p className="manager-sub-title-completed">{props.item.expiring_count} Expiring soon</p>
                    </div>
                </div>

                <div className="float-right">
                    <a className="manager-view-details" href={`/manager/course-details/${props.item.short_name}`}>View Details <img src={Arrow} alt="arrow-view-details" width='23' /></a>
                </div>
            </div>
        </div>
    )

}