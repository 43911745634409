import React, {useState,useEffect} from "react";
import google from '../../../assets/web-images/images/google-icon.svg'
import LoginImg from '../../../assets/web-images/images/Login1Image.png'
import Header from "../../../components/website/Layouts/Header";
import {ThreeDots} from "react-loader-spinner";
import UseLogin from "../../../hooks/UseLogin";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import axiosConfig from "../../../api/Config";
import { gapi } from "gapi-script";
// import GoogleLogin  from 'react-google-login';
import GoogleLogin from '@leecheuk/react-google-login';
import CryptoJS from 'crypto-js';


export default function Login() {
    const secretKey = process.env.REACT_APP_API_LIVE;

    const cookies = new Cookies();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onEmailChange = (e) =>{
        setEmail(e.target.value);
        setEmailError('');
    }
    const onPasswordChange = (e) =>{
        setPassword(e.target.value);
        setPasswordError('');
    }

    const {isLoginApi , login} = UseLogin();
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Email is required');
        }
        if (!password) {
            setPasswordError('Password is required');
        }


        if (email && password) {
            setLoading(true);

            try {

                const{
                    responseCode:loginApi
                } = await login({email,password});
                
                
                if (loginApi.data.status === 'success') {
                    // document.cookie = `user=${JSON.stringify(loginApi.data.data)}`;
                    cookies.set('user', loginApi.data.data, { path: '/' });
                    cookies.set('token', loginApi.data.token.access_token, { path: '/' });
                    // cookies.set('role', loginApi.data.data.user_role.role.name, { path: '/' });
                    const roleValue = loginApi.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });


                    const sampleTestPreviousLocation = JSON.parse(localStorage.getItem('SampleTest'));
                    if (localStorage.getItem('CourseCart')) {
                        handleCheckout(loginApi.data.token.access_token);
                    }else if(sampleTestPreviousLocation){
                        localStorage.removeItem('SampleTest'); 
                        window.location.href = '/sample-test'; 
                    }else {
                        if (loginApi.data.data.user_role.role.name !== undefined) {
                            if (loginApi.data.data.user_role.role.name === "manager") {
                                if (loginApi.data.data.is_first_time_login === 1) {
                                    window.location.href = "/change-password";
                                } else {
                                    window.location.href = "/manager";
                                }
                            } else if(loginApi.data.data.user_role.role.name === "super_admin"){
                                window.location.href = "/super-admin"
                            }else {
                                if (loginApi.data.data.is_first_time_login === 1) {
                                    window.location.href = "/change-password";
                                } else {
                                    window.location.href = "/employee";
                                }
                            }
                        } else {
                            window.history.back();
                        }
                    }
                   
                }else{
                    toast.error(loginApi.data.message);
                    // toast.error('Your username or password is incorrect.');
                }
            }catch (error) {
                toast.error('Your username or password is incorrect.');
            }
            setLoading(false);

        }
    };


    const handleCheckout = async (bearerToken) => {
        try {
            const sessionData = localStorage.getItem('certificateCart');
            const localStorageIds = JSON.parse(sessionData);
            const couponCodeGet = localStorage.getItem('CouponCode') ? localStorage.getItem('CouponCode') : '';
            const data = {
                certificate_type_id: localStorageIds,
                coupon_code: couponCodeGet,
            }
            const doPayment = await axiosConfig.post(`/payment-methods`,data,{
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                console.log(err);
            });

            if (doPayment) {
                console.log(doPayment);
                localStorage.removeItem('certificateCart');
                localStorage.removeItem('CourseCart');
                localStorage.removeItem('CouponCode');
                if (doPayment.status !== 'error') {
                    window.location.href = doPayment.url;
                }else{
                    toast.error(doPayment.message);
                    if (doPayment.message === 'You have not allowed to directly purchase certificate.') {
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 5000);
                    }else{
                        setTimeout(() => {
                            window.location.href = '/course-cart';
                        }, 5000);
                    }
                }
            }
        } catch (error) {
            console.error('Error during payment:', error);
        }

    };

    // Login With google

    useEffect(() => {
        function start() {
          gapi.client.init({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email',
          });
        }
    
        gapi.load('client:auth2', start);
    }, []);

    const responseGoogle = (response) => {
        const tokenId = response.tokenId;
        const userDetils = response.profileObj;
        const googleId = response.googleId;
        axiosConfig
            .post('/google-login', { token: tokenId, user: userDetils, googleId: googleId })
            .then((response) => {
                if (response.data.status === 'success add user') {
                    document.cookie = `user=${JSON.stringify(response.data.data)}`;
                    cookies.set('token', response.data.token, { path: '/' });
                    // cookies.set('role', response.data.data.user_role.role.name, { path: '/' });

                    const roleValue = response.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });


                    window.location.href = '/other-details'
                } else {
                    document.cookie = `user=${JSON.stringify(response.data.data)}`;
                    cookies.set('token', response.data.token, { path: '/' });
                    cookies.set('role', response.data.data.user_role.role.name, { path: '/' });
                    const roleValue = response.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });
                    window.location.href = '/employee'
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };



    return (
        <>


            <div className="login-header">
                <Header />
            </div>


            <section className="sign-up-in-section bg-white ptb-120">
                <div className="container">
                    <div className="card login-b">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card login-img">
                                    <img src={LoginImg} alt="login-logo" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <h1 className="welcome-title mt-5">Welcome!</h1>
                                    <p className="login-titles">Please provide access details to login to your system</p>
                                </div>
                                <form className="register-form" onSubmit={handleSubmit} autoComplete="off">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label htmlFor="email" className="mb-1">Email</label>
                                            <div className="input-group mb-3">
                                                <input type="email1" className="form-control" value={email} name="email1" onChange={onEmailChange} id="email" autoComplete="off" />
                                            </div>
                                                {emailError && <div className="error">{emailError}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="password" className="mb-1 password-label">Password </label>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" value={password} name="password" onChange={onPasswordChange} id="password" maxLength="15" autoComplete="off" />
                                            </div>
                                                {passwordError && <div className="error">{passwordError}</div>}
                                        </div>
                                        <div className="text-end mt-2">
                                            <a href="/forgot-password"> <p className="forgot-password">Forgot password? </p></a>
                                        </div>

                                        <div className="col-12 text-center btn-submit">
                                        <button type="submit" className="btn btn-login" disabled={loading}>
                                            {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Log In"}
                                        </button>
                                            <p className="mt-3 mb-3">OR</p>
                                            {/* <a href="#"  className="btn google-btn">
                                                <img src={google} alt="google" className="me-3" />
                                                <span>Login with Google</span>
                                            </a>       */}
                                            <GoogleLogin
                                            className="btn google-btn"
                                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                buttonText="Login with Google"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </div>

                                        <div className="text-center">
                                            
                                            <p className="forgot-password">Don't have an account? <a className="sign-up" href="/signup">Sign Up</a></p>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}