import { useState } from "react";

import UserDetails from '../api/Auth';

const UseUserOtherDetail = () => {
    const [isUserDetailsApi, setIsUserDetailsApi] = useState(false);
  
    const userDetailApi = async (data) => {
        setIsUserDetailsApi(true);
  
      let responseCode;
  
      try {
        const response = await UserDetails.otherDetails(data);
        responseCode = response;

      } catch (error) {
        responseCode = error.response;
      }
  
      setIsUserDetailsApi(false);
  
      return { responseCode };
    };
  
    return { isUserDetailsApi, userDetailApi };
  };


export default UseUserOtherDetail;