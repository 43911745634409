import axiosConfig from './Config';


const Payment ={
    PaymentMethod: (data) => axiosConfig.post(`payment-methods`,data),
    getOrderDetails: () => axiosConfig.get(`get-order-details`),
    sendOrderDetails: (data) => axiosConfig.post(`send-order-details`,data),
    discountUsers:(params)=> axiosConfig.post(`get-discount`,params),
}

export default Payment;