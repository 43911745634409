import React, { useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import BLSDisclaimer from '../../../assets/Disclaimer/BLSDisclaimer.pdf'
import BLSReCertification from '../../../assets/Disclaimer/BLSRe-Certification.pdf'
import ACLSDisclaimer from '../../../assets/Disclaimer/ACLSDisclaimer.pdf'
import ACLSReCertification from '../../../assets/Disclaimer/ACLSRe-Certification.pdf'
import PALSDisclaimer from '../../../assets/Disclaimer/PALSDisclaimer.pdf'
import PALSReCertification from '../../../assets/Disclaimer/PALSRe-Certification.pdf'
import {ThreeDots} from "react-loader-spinner";

export default function CertificateDisclaimer(props) {

    const [loading ,setLoading] = useState(false);

    const onClickAccept =() =>{
        setLoading(true);
        window.location.href = `/employee/${props.slug}`
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>

                    {props.slug === 'basic_life_support' &&
                        <>
                            <iframe
                                src={`${BLSDisclaimer}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }
                    {props.slug === 'basic_life_support_re_certification' &&
                        <>
                            <iframe
                                src={`${BLSReCertification}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }
                    {props.slug === 'advanced_cardiovascular_life_support' &&
                        <>
                            <iframe
                                src={`${ACLSDisclaimer}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }
                    {props.slug === 'advanced_cardiovascular_life_support_re_certification' &&
                        <>
                            <iframe
                                src={`${ACLSReCertification}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }
                    {props.slug === 'pediatric_advanced_life_support' &&
                        <>
                            <iframe
                                src={`${PALSDisclaimer}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }
                    {props.slug === 'pediatric_advanced_life_support_re_certification' &&

                        <>
                            <iframe
                                src={`${PALSReCertification}#toolbar=0&navpanes=0`}
                                title="Example Website"
                                width="100%"
                                height="700px"
                                frameborder="0"
                            />
                        </>
                    }


                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member">
                                <button className="remove-no-btn" onClick={props.onHide}> Decline </button> &nbsp;
                                <button className="btn-employee-accept-cards" onClick={() => onClickAccept()} disabled={loading}>
                                    {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Accept"}
                                    {/* Accept */}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )

}