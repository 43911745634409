import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import AddNewTeamMember from "./pages/managerSite/MyTeams/SubPage/AddNewTeamMember";
import AddEmployee from "./pages/superAdmin/User/AddNewTeamMember";
import SelfAssignAdd from "./pages/managerSite/MyTeams/SubPage/SelfAssignAdd";
import Home from './pages/website/Home/HomePage';
import BasicLifeSupport from "./pages/website/Certifications/BasicLifeSupport";
import AdvancedLifeSupport from "./pages/website/Certifications/AdvancedLifeSupport";
import PediatricLifeSupport from "./pages/website/Certifications/PediatricLifeSupport";
import AdvancedProviderBundle from "./pages/website/Certifications/AdvancedProviderBundle";
import Algorithms from "./pages/website/Algorithms/Algorithms";
import Group from "./pages/website/Groups/GroupPage";
import Login from "./pages/website/Auth/Login";
import Signup from "./pages/website/Auth/Signup";
import UserOtherDetails from "./pages/website/Auth/OtherDetails";
import ForgotPassword from "./pages/website/Auth/ForgotPassword";
import ResetPassword from "./pages/website/Auth/ResetPassword";
import SampleTestPage from "./pages/website/Sample/SampleTest";
import SampleTestType from "./pages/website/Sample/StepForm/Stepfroms";
import SampleTestResult from "./pages/website/Sample/SampleTestResult";
import AboutUs from "./pages/website/AboutUs";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";

import EmployeeHome from "./pages/employeeSite/Home/CertificationType";
import SubTypes from "./pages/employeeSite/Home/MainPage";
import StartFinalTest from "./pages/employeeSite/StartTest/FinalExam";
import FinalExamResult from "./pages/employeeSite/StartTest/FianlExamResult";
import CourseCart from "./pages/employeeSite/Cart/CourseCart";
import PaymentSuccess from "./pages/employeeSite/Cart/Payment/PaymentSuccess";
import PaymentFail from "./pages/employeeSite/Cart/Payment/PaymentError";
import SurveyCertificate from "./pages/employeeSite/Home/SurveyCertifications/CertificationSurvey";
import PurchaseHistory from "./pages/employeeSite/PurchaseHistory/PurchaseHistory";
import ChangePassword from "./pages/employeeSite/Auth/ChangePassword";

import DashboardPage from "./pages/managerSite/Dashboard/DashboardPage";
import CourseDetails from "./pages/managerSite/Dashboard/CourseDetails";
import TeamsPage from "./pages/managerSite/MyTeams/Myteam";
import ViewTeams from "./pages/managerSite/MyTeams/ViewTeams";
import TrackProgress from "./pages/managerSite/TrackProgress/TrackProgressPage";
import AssignCourses from "./pages/managerSite/AssignCourses/AssignCourses";
import PurchaseCredits from "./pages/managerSite/PurchaseCredits/PurchaseCredits";

import ToDoList from "./pages/superAdmin/ToDoList/ToDoListPage";
import ToDoViewDetails from "./pages/superAdmin/ToDoList/ToDoViewDetails";
// import SalesReportPage from "./pages/superAdmin/SalesReport/SalesReportPage";
import UserPage from "./pages/superAdmin/User/UserPage";
import ManagerEmployeeList from "./pages/superAdmin/User/ManagerEmployeeList";
import CouponCodeList from "./pages/superAdmin/User/CouponCodeList";
import AddUser from "./pages/superAdmin/User/AddUser";
import Profile from "./pages/superAdmin/Profile/Profile";
import DiscountList from "./pages/superAdmin/Settings/DiscountList";
import PageNotFound from "./pages/PageNotFound";
const SalesReportPage = React.lazy(() => import('./pages/superAdmin/SalesReport/SalesReportPage'));




const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {
  return (
    <>
      <Router history={Router}>
        <React.Suspense fallback={loading}>
          <Routes>
            {/* webroutes start */}
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/bls' element={<BasicLifeSupport />}></Route>
            <Route exact path='/acls' element={<AdvancedLifeSupport />}></Route>
            <Route exact path='/pals' element={<PediatricLifeSupport />}></Route>
            <Route exact path='/apb' element={<AdvancedProviderBundle />}></Route>
            <Route exact path='/algorithm' element={<Algorithms />}></Route>
            <Route exact path='/groups' element={<Group />}></Route>
            <Route exact path='/login' element={<Login />}></Route>
            <Route exact path='/signup' element={<Signup />}></Route>
            <Route exact path='/other-details' element={<UserOtherDetails />}></Route>
            <Route exact path='/forgot-password' element={<ForgotPassword />}></Route>
            <Route exact path='/reset-password/:token' element={<ResetPassword />}></Route>
            <Route exact path='/about-us' element={<AboutUs />}></Route>
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>

            <Route exact path='/sample-test' element={<SampleTestPage />}></Route>

            <Route exact path='/sample-test/:short_name?' element={<SampleTestType />}></Route>
            <Route exact path='/sample-test-result/:name_slug?/:employee_id?' element={<SampleTestResult />}></Route>
            {/* webroutes end */}

            {/* EmployeeRoutes start */}
            
              <Route path='/employee/*' element={<PrivateRoutes path="/employee" />}>
                <Route index element={<EmployeeHome />} />
                <Route path=':sub_type' element={<SubTypes />} />
                <Route path='start-final-test/:test_name?' element={<StartFinalTest />} />
                <Route path='final-exam-result/:test_name?' element={<FinalExamResult />} />
                <Route path='certificate-survey/:test_name?' element={<SurveyCertificate />} />
                <Route path='purchase-history' element={<PurchaseHistory />} />
              </Route>
              <Route path="/change-password" element={<ChangePassword />} />
              <Route exact path='/course-cart' element={<CourseCart />}></Route>
              <Route exact path='/payment-success/:checkout_id?' element={<PaymentSuccess />}></Route>
              <Route exact path='/payment-fail' element={<PaymentFail />}></Route>


            {/* EmployeeRoutes end */}

            {/* ManagerRoutes start */}
              <Route path='/manager/*' element={<PrivateRoutes path="/manager" />}>
                <Route index element={<DashboardPage />} />
                <Route path='course-details/:certificate_type' element={<CourseDetails />} />
                <Route path='teams' element={<TeamsPage />} />
                <Route path='view-teams/:user_id' element={<ViewTeams />} />
                <Route path='add-member' element={<AddNewTeamMember />} />
                <Route path='self-assign-course' element={<SelfAssignAdd />} />
                <Route path='track-progress' element={<TrackProgress />} />
                <Route path='assign-courses' element={<AssignCourses />} />
                <Route path='purchase-credits' element={<PurchaseCredits />} />
              </Route>
            {/* ManagerRoutes end */}

            {/* SuperAdminRoutes start */}
              <Route path='/super-admin/*' element={<PrivateRoutes path="/super-admin" />}>
                <Route index element={<ToDoList />} />
                <Route path='view-details/:user_id/:certificate_id' element={<ToDoViewDetails />} />
                <Route path='sales-report' element={<SalesReportPage />} />
                <Route path='users' element={<UserPage />} />
                <Route path='manager-users/:manager_id' element={<ManagerEmployeeList />} />
                <Route path='coupon-code-list' element={<CouponCodeList />} />
                <Route path='add-manager' element={<AddUser />} />
                <Route path='profile' element={<Profile />} />
                <Route path=':manager_id/add-manager-employee' element={<AddEmployee />} />
                <Route path='discount-list' element={<DiscountList />} />
              </Route>
            {/* SuperAdminRoutes end */}

            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </React.Suspense>
      </Router>

    </>

  );
}

export default App;
