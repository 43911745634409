import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ThreeDots } from "react-loader-spinner";
import UseAddDiscount from "../../../hooks/superAdmin/UseAddDiscount";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function DiscountSettingModal(props) {

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        couponCode: "",
        discountPercentage: "",
        discountStatus: "",
    });
    const[couponCodeError, setcouponCodeError]= useState(null);
    const[discountPercentageError, setDiscountPercentageError]= useState(null);


    const { isAddDiscount, AddNewDiscount } = UseAddDiscount();

    const handleInputChange = (e) => {
        const { name, checked } = e.target;
        if (name === "discountStatus") {
            setFormData({ ...formData, [name]: checked });
        }
    };
    const handleCouponCodeChange1 = (e) => {
        const { name, value, } = e.target;
        setFormData({ ...formData, [name]: value });
        setcouponCodeError('')
    };
    const handleDiscountPercentageChange1 = (e) => {
        const { name, value, } = e.target;
        setFormData({ ...formData, [name]: value });
        setDiscountPercentageError('')
    };

    const handleCouponCodeChange = (e) => {
        const { name, value } = e.target;
        if (/^[a-zA-Z0-9]+$/.test(value) || value === "") {
            setFormData({ ...formData, [name]: value });
            setcouponCodeError('');
        } else {
            setcouponCodeError('Coupon code should only contain alphabetical characters');
        }
    };
    
    const handleDiscountPercentageChange = (e) => {
        const { name, value } = e.target;
        if (/^\d*$/.test(value) || value === "") {
            setFormData({ ...formData, [name]: value });
            setDiscountPercentageError('');
        } else {
            setDiscountPercentageError('Discount percentage should only contain numeric characters');
        }
    };


    const handleSubmit = async () => {
        if (!formData.couponCode) {
            setcouponCodeError('Coupon code is required');
        }else{
            setcouponCodeError('');

        }
        if (!formData.discountPercentage) {
            setDiscountPercentageError('Discount percentage is required');
        }
        if (formData.couponCode && formData.discountPercentage) {
            setLoading(true);
            const data = {
                "coupon_code": formData.couponCode,
                "discount_percentage": formData.discountPercentage,
                "discount_status": formData.discountStatus,
            }
            const {
                responseCode: addDiscount
            } = await AddNewDiscount(data);
            if (addDiscount.data.status === 'success') {
                window.location.href = "/super-admin/discount-list";
            } else {
                // setcouponCodeError(addDiscount.data.message)
                toast.error(addDiscount.data.message);
            }
    
            setLoading(false);
        }  
    };


    return (
        <>
            <Modal
                show={props.show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=""
            >
                <Modal.Body>
                    <div className="row coupon-code-div mt-3">
                        <div className="col-md-6 col-6 mt-3 mb-3"><label>Coupon Code :</label></div>
                        <div className="col-md-6 col-6 mt-3 "><input type="text" value={formData.couponCode} name="couponCode" id="coupon_code" onChange={handleCouponCodeChange} /></div>
                        {couponCodeError && <div className="error discount-error-add">{couponCodeError}</div>}
                        <div className="col-md-6 col-6 mt-3 mb-3"><label>Discount Percentage :</label></div>
                        <div className="col-md-6 col-6 mt-3 mb-3"><input type="text" maxLength='2' value={formData.discountPercentage} name="discountPercentage" id="discount_percentage" onChange={handleDiscountPercentageChange} /></div>
                        {discountPercentageError && <div className="error discount-error-add">{discountPercentageError}</div>}
                        <div className="col-md-6 col-6 mt-3"><label>Discount Status :</label></div>
                        <div className="col-md-6 col-6"><Form.Check type="switch" id="discount-status-switch" label="" className="new-discout-switch mt-3" checked={formData.discountStatus} onChange={handleInputChange} name="discountStatus" /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="remove-member mt-5">
                                <button className="remove-no-btn" onClick={props.onHide}>Cancel</button> &nbsp;

                                <button type="submit" className="remove-yes-btn" onClick={handleSubmit} disabled={loading}>
                                    {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} /> : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </>

    )
}

export default DiscountSettingModal;