import React, { useState,useEffect } from "react";
import LoginImg from '../../../assets/web-images/images/signupImage.png'
import Header from "../../../components/website/Layouts/Header";
import google from '../../../assets/web-images/images/google-icon.svg'
import { useNavigate }  from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import UseSignUp from "../../../hooks/UseSignUp";
import { ThreeDots } from "react-loader-spinner";
import { gapi } from "gapi-script";
// import GoogleLogin  from 'react-google-login';
import GoogleLogin from '@leecheuk/react-google-login';
import axiosConfig from "../../../api/Config";
import CryptoJS from 'crypto-js';


export default function Signup() {

    const secretKey = process.env.REACT_APP_API_LIVE;

    const cookies = new Cookies();

    const [loading, setLoading] = useState(false);
    const[email, setEmail]= useState('');
    const[password, setPassword]= useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const[emailError, setEmailError]= useState(null);
    const[passwordError, setPasswordError]= useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);

    const {isSignUpApi , signupApi} = UseSignUp();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Email is required');
        }
        if (!password) {
            setPasswordError('Password is required');
        }
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm password is required');
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
        }
        if (!first_name) {
            setFirstNameError('First Name is required');
        }
        if (!last_name) {
            setLastNameError('Last Name is required');
        }

        if (email && password && confirmPassword && password === confirmPassword && first_name && last_name) {
            setLoading(true);

            try {

                const data = {
                    email,password,first_name, last_name
                }
                const{
                    responseCode:signUp
                } = await signupApi(data);
                
                if (signUp.data.status === 'success') {
                    document.cookie = `user=${JSON.stringify(signUp.data.data)}`;
                    cookies.set('token', signUp.data.token.access_token, { path: '/' });
                    // cookies.set('role', signUp.data.data.user_role.role.name, { path: '/' });

                    const roleValue = signUp.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });


                    window.location.href = "/other-details";
                }else{
                    toast.error('This email address is already exist on our system.');
                }
            }catch (error) {
                toast.error('Something went wrong .');
            }
            setLoading(false);
        }
       
    };

    const onEmailChanges = (e) =>{
        setEmail(e.target.value);
        setEmailError('');
    }
    const onPasswordChanges= (e) =>{
        setPassword(e.target.value);
        setPasswordError('');
    }
    const onConfirmPasswordChanges = (e) =>{
        setConfirmPassword(e.target.value);
        setConfirmPasswordError('');
    }

    const onFirstNameChanges = (e) =>{
        setFirstName(e.target.value);
        setFirstNameError('');
    }
    const onLastNameChanges = (e) =>{
        setLastName(e.target.value);
        setLastNameError('');
    }


     // Login With google

     useEffect(() => {
        function start() {
          gapi.client.init({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email',
          });
        }
    
        gapi.load('client:auth2', start);
    }, []);

    const responseGoogle = (response) => {
        const tokenId = response.tokenId;
        const userDetils = response.profileObj;
        const googleId = response.googleId;
        axiosConfig
            .post('/google-login', { token: tokenId, user: userDetils, googleId: googleId })
            .then((response) => {
                if (response.data.status === 'success add user') {
                    // document.cookie = `user=${JSON.stringify(response.data.data)}`;
                    cookies.set('user', response.data.data, { path: '/' });
                    cookies.set('token', response.data.token, { path: '/' });
                    // cookies.set('role', response.data.data.user_role.role.name, { path: '/' });
                    const roleValue = response.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });
                    window.location.href = '/other-details'
                } else {
                    // document.cookie = `user=${JSON.stringify(response.data.data)}`;
                    cookies.set('user', response.data.data, { path: '/' });
                    cookies.set('token', response.data.token, { path: '/' });
                    // cookies.set('role', response.data.data.user_role.role.name, { path: '/' });
                    const roleValue = response.data.data.user_role.role.name;
                    const encryptedRole = CryptoJS.AES.encrypt(roleValue, secretKey).toString();
                    cookies.set('role', encryptedRole, { path: '/' });
                    window.location.href = '/employee'
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

  
    return (
        <>


            <div className="login-header">
                <Header />
            </div>
           

            {/* new design */}
            <section className="sign-up-in-section bg-white ptb-120">
                <div className="container">
                    <div className="card signup-card-1">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 img-col-6-padding">
                                <div className="card signup-img">
                                    <img src={LoginImg} alt="login-logo" height='754' />
                                </div>
                            </div>
                            <div className="col-lg-6 form-col-6-padding">
                                <>
                                <div className="row">
                                    <h1 className="welcome-title">Create an Account</h1>
                                    <p className="login-titles">Please provide your details</p>
                                </div>
                                <form onSubmit={handleSubmit} className="mt-5 register-form">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="firstname" className="mb-1">First Name</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" onChange={onFirstNameChanges} id="first_name" name="first_name" />
                                            </div>
                                            {firstNameError && <div className="error">{firstNameError}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="lastname" className="mb-1">Last Name</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" onChange={onLastNameChanges} id="last_name" name="last_name" />
                                            </div>
                                            {lastNameError && <div className="error">{lastNameError}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="email" className="mb-1">Email</label>
                                            <div className="input-group mb-3">
                                                <input type="email" className="form-control" id="email" onChange={onEmailChanges} name="email"  />
                                            </div>
                                            {emailError && <div className="error">{emailError}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="password" className="mb-1">Password </label>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" onChange={onPasswordChanges} name="password" id="password"  maxLength="15"/>
                                            </div>
                                            {passwordError && <div className="error">{passwordError}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="password" className="mb-1">Confirm Password </label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" onChange={onConfirmPasswordChanges} id="c-password" name="c-password" maxLength="15" />
                                            </div>
                                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                        </div>
                                        <div className="col-12 text-center btn-submit">
                                            <button type="submit" className="btn btn-login" disabled={loading}>
                                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Submit"}
                                            </button>
                                            <p className="mt-3 mb-3">OR</p>
                                            {/* <a href="https://accounts.google.com/" className="btn google-btn">
                                                <img src={google} alt="google-1" className="me-3" />
                                                <span>Signup with Google</span>
                                            </a> */}
                                            <GoogleLogin
                                                className="btn google-btn"
                                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                buttonText="Sign up with Google"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </div>
                                        <div className="text-center">
                                            <p className="forgot-password">Already have an account ? <a className="sign-up" href="/login">Sign in</a></p>
                                        </div>
                                    </div>
                                </form>

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}