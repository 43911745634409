import React from "react";
import logo from '../../../../assets/web-images/images/Pulse-Logo.svg';

export default function AdvancedCardiovascularLifeSupportBack(props) {

    return (
        <>
            <div className="row">
                <div className="col-lg-12 pt-3 pb-3 ">
                    <div className="card  aclsCertificate" id="bls-front-img">
                        <div className="text-center">
                            <img src={`${process.env.REACT_APP_IMAGE}/Pulse-Logo.png`} alt="logo" />
                            <h5 className="palse-back-card-mb acls-mo-mb-size ">ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5> 
                            <h6 className="text-name-bls-h6 bls-back-h6 mb-mobile-view-back">Training Center Name: <span className="text-name-bls-span" >Pulse Certifications, LLC</span></h6>
                            <h6 className="text-name-bls-h6 bls-back-h6 mb-mobile-view-back">Training Center Info: <span className="text-name-bls-span" >275 Cumberland Parkway #227, Mechanicsburg, PA 17055</span></h6>
                            <h6 className="text-name-bls-h6 bls-back-h6 mb-mobile-view-back">Certification Number: <span className="text-name-bls-span" >{props.userCertificateId}</span></h6>
                            <h6 className="text-name-bls-h6 bls-back-h6 mb-mobile-view-back">Holder's Signature: <span className="text-name-bls-span" ></span></h6>

                            <h4>PULSECERTIFICATIONS.COM</h4>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6"></div>

            </div>
        </>
    )
}