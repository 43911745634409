import React,{useState,useEffect} from "react";
import LoginImg from '../../../assets/web-images/images/forgot-password.png'

import Header from "../../../components/website/Layouts/Header";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate }  from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useParams } from 'react-router-dom';
import UseCheckForgotPassword from "../../../hooks/UseCheckForgotPassword";
import UseResetPassword from "../../../hooks/UseResetPassword";

export default function ResetPassword() {
    
    const { token } = useParams();

    const [loading , setLoading] = useState(false);
    const [new_password, setNewPassword] = useState('');
    const [c_new_password, setConfirmNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);


    useEffect(() => {
        checkLink();
    },[]);

    const { isCheckForgortPasswor, checkforgortPassword } = UseCheckForgotPassword();

    const checkLink = async () => {
        const {
            responseCode: checkForgotPasswordLink
        } = await checkforgortPassword({'token': token });

        if (checkForgotPasswordLink.data.status === 'error') {
            toast.error('This link is expired, Please request again for reset password.');
        } else {
            console.log('success');
        }

    };


    const onPasswordChanges= (e) =>{
        setNewPassword(e.target.value);
        setNewPasswordError('');
    }
    const onConfirmPasswordChanges = (e) =>{
        setConfirmNewPassword(e.target.value);
        setConfirmPasswordError('');
    }

    const { isResetPassword, resetPasswordApi } = UseResetPassword();


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!new_password) {
            setNewPasswordError('New password is required');
        }
        if (!c_new_password) {
            setConfirmPasswordError('Confirm New Password is required');
        }

        if (new_password !== c_new_password) {
            setConfirmPasswordError('Confirm password and New Password dose not match');
        }
       
        if (new_password && c_new_password && new_password === c_new_password) {
            setLoading(true);

            try {

                const{
                    responseCode:resetPassword
                } = await resetPasswordApi({token,new_password,c_new_password});
                
                if (resetPassword.data.status === 'success') {
                    toast.success(resetPassword.data.message);
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000);                   
                }else{
                    toast.error('This link is Invalid, Please request again for reset password.');
                }
            }catch (error) {
                toast.error('This link is Invalid, Please request again for reset password.');
            }
            setLoading(false);
        }
    };


    return (
        <>


            <div className="login-header">
                <Header />
            </div>

            <section className="sign-up-in-section bg-white ptb-120">
                <div className="container">
                    <div className="card shadow-lg fpc">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 fp-main">
                                <div className="card fpb">
                                    <img src={LoginImg} alt="login-image4" className="reset-password-img" height='466' />
                                </div>
                            </div>
                            <div className="col-lg-6 fp-padding">
                                <h3 className="welcome-title mt-5">Reset your Password?</h3>
                                <p className="fpf-titles">Don't worry. Type your new password and Confirm Password .</p>

                                <form onSubmit={handleSubmit} className="register-form">
                                    <div className="row">
                                    <div className="col-sm-12">
                                            <label htmlFor="password" className="mb-1 password-label">New Password </label>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" onChange={onPasswordChanges} name="new_password"  id="new_password" maxLength="15"/>
                                            </div>
                                            {newPasswordError && <div className="error">{newPasswordError}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="password" className="mb-1 password-label">Confirm New Password </label>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" onChange={onConfirmPasswordChanges} name="c_new_password"  id="c_new_password" maxLength="15"/>
                                            </div>
                                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                        </div>

                                        <div className="col-12 text-center btn-submit mt-3">
                                            <button type="submit" className="btn btn-login" disabled={loading}>
                                                {loading ? <ThreeDots color="#000" wrapperClass="d-block" height={30} width={40} />:"Reset Password"}
                                            </button>
                                        
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>



                    </div>
                </div>
            </section>


            <ToastContainer />
        </>
    )
}