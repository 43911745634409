import React,{useState,useEffect,useRef} from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import info from '../../../assets/web-images/images/info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PurchaseHistory from "./PurchaseHistory";
import WalletHistory from "./WalletHistory";
import ProcessToAddFund from "../../../components/managerSite/PurchaseCredit/ProcessToAddFund";
import UseUserCurrentBalance from "../../../hooks/manager/UseUserCurrentBalance";
import UseDiscountLevel from "../../../hooks/UseDiscountLevel";
import ScrollToTop from 'react-scroll-to-top';

function PurchaseCredits() {

    const historyRef = useRef();
    const walletHistoryRef = useRef();
    const [historyComponent, setHistoryComponent] = useState(false);
    const [walletComponent, setWalletComponent] = useState(false);

    const [addFund, setAddFund] = useState(false);
    const [loading, setLoading] = useState(true);
    const [discountloading, setDiscountLoading] = useState(true);
    const [getDiscountDetail, setDiscountDetail] = useState({});

    const [getcurrentBalance, setCurrentBalance] = useState({});
    const { getUserCurrentBalance: CurrentBalance } = UseUserCurrentBalance();
    useEffect(() => {
        if (CurrentBalance.status === 'success') {
            setLoading(false);
            setCurrentBalance(CurrentBalance.data)
        }
    },[CurrentBalance]);

    // course history changes   
    const handleHistoryClick = (event) => {
        if (historyRef.current && !historyRef.current.contains(event.target)) {
            setHistoryComponent(false);
        }
    };
        
    const historyClickBtn = () => {
        setHistoryComponent(!historyComponent);
    };
    
    const handleWalletHistoryClick = (event) => {
        if (walletHistoryRef.current && !walletHistoryRef.current.contains(event.target)) {
            setWalletComponent(false);
        }
    };

    const walletHistoryClickBtn = () => {
        setWalletComponent(!walletComponent)
    }

    const { discount: DiscountLevel } = UseDiscountLevel();
    useEffect(() => {
        if (DiscountLevel.status === 'success') {
            setDiscountLoading(false);
            setDiscountDetail(DiscountLevel.data)
        }
    },[DiscountLevel]);

    // const currentBalance = parseFloat(getcurrentBalance.current_balance);
    // const formattedCurrentBalance = currentBalance.toFixed(2);
    const currentBalance = getcurrentBalance.current_balance !== undefined ? parseFloat(getcurrentBalance.current_balance) : 0;
    const formattedCurrentBalance = currentBalance.toFixed(2);
    return (
        <>
            <Header />

            <section className="pt-10 feature-promo bg-light">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="card Purchase-card">
                            <p className="current-balance">Current Balance</p>
                            {!loading && formattedCurrentBalance !== 0.00 ? 
                                <><p className="current-balance-title">${formattedCurrentBalance}</p></> 
                                :
                                <><p className="current-balance-title">${formattedCurrentBalance}</p></>
                            }
                        </div>
                    </div>

                    <div className="pt-5 pb-5">
                        <div className="card">
                            <div className="row p-2">
                                <div className="col-lg-10 col-10 mt-3">
                                   <span className="current-balance"> Your Discount Level</span>
                                </div>
                                <div className="col-lg-2 col-2 mt-3">
                                    <div className="text-end info-hover-1">
                                        <div className="row">
                                            <OverlayTrigger key='bottom' 
                                                placement='bottom' overlay={<Tooltip id="info-hover--credits" className="tooltip-titles info-hover-1" style={{ backgroundColor: 'white' }} >

                                                    <div className="data-table-course">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" scope="col">Number of Courses</th>
                                                                    <th scope="col" className="text-center">Discount Applied</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center td-color" data-label="Number of Courses">5</td>
                                                                    <td className="text-center td-color" data-label="Discount Applied">10%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center td-bg-color" data-label="Discount Applied">10</td>
                                                                    <td className="text-center td-bg-color" data-label="Number of Courses" >15%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center td-color" data-label="Number of Courses">15</td>
                                                                    <td className="text-center td-color" data-label="Discount Applied">20%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center td-bg-color" data-label="Discount Applied">20</td>
                                                                    <td className="text-center td-bg-color" data-label="Number of Courses">25%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center td-color" data-label="Number of Courses">25</td>
                                                                    <td className="text-center td-color" data-label="Discount Applied">30%</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Tooltip>}>
                                                <span className="d-inline-block tooltip-span">
                                                    <img src={info} width='30' style={{ pointerEvents: 'none' }} className="info-icon" alt="info" />
                                                </span>
                                            </OverlayTrigger>

                                        </div>
                                    </div>
                                </div>

                                <div className="current-balance-title">
                                {getDiscountDetail.discount}% based on the past assigned courses.
                                </div>

                                <div className="current-balance-title mt-3 mb-3">
                                    {getDiscountDetail.remaining_certificates !== 0 && getDiscountDetail.next_discount !== 0 &&
                                    
                                    <>
                                    
                                        if you assign <b>{getDiscountDetail.remaining_certificates}</b> more courses your discount will increase to <b>{getDiscountDetail.next_discount}%</b>
                                    </>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>


                    <div onClick={handleHistoryClick}>
                        <div className="card history-card">
                            <button className="current-balance" onClick={historyClickBtn}>Course Assignment History</button>
                        </div>
                        {historyComponent && (<div ref={historyRef}><PurchaseHistory /></div>)}
                    </div>
                    <div onClick={handleWalletHistoryClick}>
                        <div className="card history-card mt-5">
                            <button className="current-balance" onClick={walletHistoryClickBtn}>Wallet History</button>
                        </div>
                        {walletComponent && (<div ref={walletHistoryRef}><WalletHistory /></div>)}
                    </div>

                    <div className="pt-5 pb-5">
                        <button className="add-funds" onClick={() => setAddFund(true)} > Add Funds</button>
                    </div>
                </div>
                <ScrollToTop className="scroll-to-button" smooth color="white" width="20" height="20"/>
            </section>

            <ProcessToAddFund
                show={addFund}
                onHide={() => setAddFund(false)}
        />
        </>
    )
}

export default PurchaseCredits