import React, {useEffect, useState} from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import axiosConfig from "../../../../api/Config";
import Cookies from 'universal-cookie'
import CryptoJS from "crypto-js";

function ExpiredConfirmBalanceDebited(props) {
    const  secretKey  = process.env.REACT_APP_API_LIVE;
    const cookies = new Cookies();
    const userDetails = cookies.get("user") ? cookies.get("user") : '';
    const [discount, setDiscount] = useState(0);
    useEffect( () => {
        const fetchDataAndCleanup = async () => {
            await fetchData();
        };

        fetchDataAndCleanup();
    },[]);

    

    const fetchData = async () => {

        if (userDetails.user_role !== undefined) {
            if (userDetails.user_role.role.name === 'super_admin') {
                try {
                    const response = await axiosConfig.get(`/get-manager-discount/${props.manager_id}`);
                    const getDiscount = response.data.data;
                    if (getDiscount.discount > 0) {
                        const discount = getDiscount.discount;
                        setDiscount(discount);
                    }
                } catch (error) {
                    console.log(error);
                }
            }else{
                try {
                    const response = await axiosConfig.get('/get-count');
                    const getDiscount = response.data.data;
                    if (getDiscount.discount > 0) {
                        const discount = getDiscount.discount;
                        setDiscount(discount);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        
    };

    let certification = props.CertificateType;
    let amount1, amount2, amount3;
    const CertificateAclsLocalStorage = localStorage.getItem('select_acls_certificate');
    const CertificateBlsLocalStorage = localStorage.getItem('select_bls_certificate');
    const CertificatePalsLocalStorage = localStorage.getItem('select_pals_certificate');

    certification.forEach((citem, index) => {
        if (index === 0) {
            if (discount > 0){
                if (CertificateAclsLocalStorage === 'acls-certification') {
                    const originalAmount = 195;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount1 = originalAmount - discountedPrice;
                }else{
                    const originalAmount = citem.amount;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount1 = originalAmount - discountedPrice;
                }
            } else {
                amount1 = citem.amount;
            }
        } else if (index === 1) {
            if (discount > 0){
                if (CertificateBlsLocalStorage === 'bls-certification') {
                    const originalAmount = 95;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount2 = originalAmount - discountedPrice;
                } else {
                    
                    const originalAmount = citem.amount;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount2 = originalAmount - discountedPrice;
                }
            } else {
                amount2 = citem.amount;
            }
        } else if (index === 2) {
            if (discount > 0){
                if (CertificatePalsLocalStorage === 'pals-certification') {
                    const originalAmount = 195;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount3 = originalAmount - discountedPrice;
                }else{
                    const originalAmount = citem.amount;
                    const discountedPrice = originalAmount * (discount / 100);
                    amount3 = originalAmount - discountedPrice;
                }
                
            } else {
                amount3 = citem.amount;
            }
        }
    });

    const handleCertificateAmount = () => {
        if (props.certificateName === 'bls_certificate_e_date') {
            // localStorage.setItem('BLS_E',amount2)
            const amountToEncrypt = String(amount2); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('BLS_E',encryptedAmount)
            localStorage.removeItem('BLS');
        }else if(props.certificateName === 'acls_certificate_e_date')
        {
            // localStorage.setItem('ACLS_E',amount1)  
            const amountToEncrypt = String(amount1); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('ACLS_E',encryptedAmount)
            localStorage.removeItem('ACLS');      
        }else{
            // localStorage.setItem('PALS_E',amount3)
            const amountToEncrypt = String(amount3); // Ensure the value is a string
            const encryptedAmount = CryptoJS.AES.encrypt(amountToEncrypt, secretKey).toString();
            localStorage.setItem('PALS_E',encryptedAmount)
            localStorage.removeItem('PALS');      

        }
        props.onHide();
    }


  return (
    <>
    <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="remove-modal"
    >
        <ModalBody>
            <p>We’ll assign that course now. ${props.certificateName === 'bls_certificate_e_date'&& <>{amount2 && `${Number(amount2).toFixed(2)}`}</>}{props.certificateName === 'acls_certificate_e_date'&& <>{amount1 && `${Number(amount1).toFixed(2)}`}</>}{props.certificateName === 'pals_certificate_e_date'&& <>{amount3 && `${Number(amount3).toFixed(2)}`}</>} will be debited from 
            {userDetails.user_role.role_id === 1 ? <> manager balance </>: <> your balance</>}
            
            </p>
            <div className="row">
                <div className="col-lg-12">
                    <div className="remove-member">
                        <button className="remove-yes-btn" onClick={handleCertificateAmount}>Ok</button>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>

    </>
  )
}

export default ExpiredConfirmBalanceDebited;