import React, { useState, useEffect } from "react";
import Header from "../../../components/managerSite/Layouts/Header";
import Right from '../../../assets/manager-images/images/Right.svg';
import print from '../../../assets/web-images/images/Print.svg';
import Error from '../../../assets/manager-images/images/Error.svg'
import Close from '../../../assets/manager-images/images/Close.svg'
import Assigned from '../../../assets/manager-images/images/Assigned.svg'
import RemoveMember from "../../../components/managerSite/MyTeams/Modal/removeMember";
import EditDateModal from "../../../components/managerSite/MyTeams/Modal/EditDateModal";
import RemoveAssignCertificate from "../../../components/managerSite/MyTeams/Modal/RemoveAssignCertificate";
import { useParams } from 'react-router-dom';
import UseMemberDeatils from "../../../hooks/manager/UseMemberDeatils";
import { Watch } from 'react-loader-spinner';
import moment from "moment";
import CertificateModal from "../../../components/emplyoeeSite/Certificate/CertificateModal";
import AssignedExpired from "../../../assets/manager-images/images/Assigne_Expired.svg";
import logo from '../../../assets/web-images/images/Pulse-Logo.svg';
import axiosConfig from '../../../api/Config'

function ViewTeams() {
    const { user_id } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [modalEditDateShow, setModalEditDateShow] = useState(false);
    const [modalDeleteCertificate, setModalDeleteCertificate] = useState(false);
    const [memberDetails, setMemberDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [certificateModalShow, setCertificateModalShow] = useState(false);
    const [printImageUrl, setPrintImageUrl] = useState("");
    const [userName, setUserName] = useState("");
    const [certificateId, setCertificateId] = useState("");
    const [userCertificateId, setUserCertificateId] = useState("");
    const [email, setEmail] = useState("");
    const [certificateSlug, setCertificateSlug] = useState("");
    const [certificateData, setCertificateDate] = useState('');
    const [certificateStatus, setCertificateStatus] = useState('');
    const [usercertificateIds, setUserCertificateIds] = useState('');
    const [certificateIds, setCertificateIds] = useState('');
    const [certificateActiveDate, setCertificateActiveDate] = useState("");
    const [finalExamDate, setfinalExamDate] = useState("");
    const [userCertificateStatus, setUserCertificateStatus] = useState('');

    const { getMemberDetails: memberDeatilsApi } = UseMemberDeatils({
        user_id,
    });
    useEffect(() => {
        fetchMemberDetails();
    }, [memberDeatilsApi]);

    const fetchMemberDetails = () => {
        if (memberDeatilsApi.status === 'success') {
            setLoading(false);
            setMemberDetails(memberDeatilsApi.data)
        }
    }

    const openPrintCertificate = (
        userName, printImageUrl,
        certificateId, ucId, email, certificateSlug, date, activeDate
    ) => {
        setUserName(userName);
        setPrintImageUrl(printImageUrl);
        setCertificateId(certificateId);
        setUserCertificateId(ucId);
        setEmail(email);
        setCertificateSlug(certificateSlug);
        setfinalExamDate(date);
        setCertificateActiveDate(activeDate);
        setCertificateModalShow(true);
    }


    const openEditDate = (date, status, uid) => {
        setModalEditDateShow(true);
        setCertificateDate(date)
        setCertificateStatus(status)
        setUserCertificateIds(uid)
    }

    const onDeleteAssignCertificate = (uid, cId) => {
        setModalDeleteCertificate(true);
        setUserCertificateIds(uid)
        setCertificateIds(cId)
    }



    const memberDetailGet = async () => {
        const getdata = await axiosConfig.get(`get-member-details/${user_id}`).then((res) => {
            return res.data;
        }).catch((err) => { return err.message; });

        if (getdata.status === 'success') {
            setMemberDetails(getdata.data)
        }
    }
    const closeDeleteCertificateModal = () => {
        setModalDeleteCertificate(false);
        memberDetailGet();
        setMemberDetails(memberDeatilsApi.data)
    };

    useEffect(() => {
        const userData = async () => {
            try {
                const data = {
                    'user_id': user_id
                }
                const getdata = await axiosConfig.post(`user-certificate-status`, data).then((res) => {
                    return res.data;
                }).catch((err) => { return err.status; });
                setUserCertificateStatus(getdata)
            } catch (error) {
                console.error('Error during user Retrive :', error);
            }
        };
        userData();
    }, []);

    const removeUser = async (id) => {
        setModalShow(true)
        // const data = {
        //     'user_id': id
        // }
        // const getdata = await axiosConfig.post(`user-certificate-status`, data).then((res) => {
        //     return res.data;
        // }).catch((err) => { return err.status; });
        // setUserCertificateStatus(getdata)
    }

    const renderCertificate = (certificateData, certificateName) => {

        let findReCertificate = undefined;
        if (certificateName === 'bls_certification') {
            findReCertificate = certificateData.filter((subItem) => subItem.short_name === "bls_re_certification")
        }

        if (certificateName === 'acls_certification') {
            findReCertificate = certificateData.filter((subItem) => subItem.short_name === "acls_re_certification")
        }

        if (certificateName === 'pals_certification') {
            findReCertificate = certificateData.filter((subItem) => subItem.short_name === "pals_re_certification")
        }

        if (findReCertificate === undefined || findReCertificate.length === 0) {
            findReCertificate = certificateData.filter((subItem) => subItem.short_name === certificateName)
        }

        if (findReCertificate !== undefined) {
            return (
                <>
                    {
                        findReCertificate.map((item) =>
                            <>
                                {item.certificate_status !== 'n/a' &&
                                    <>
                                        <div className="col-lg-4">

                                            <div className="card add-cards-changes view-teams-add-card">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="bls-view-details-title view-teams-certificate-type">
                                                        {(item.short_name === 'bls_certification' || item.short_name === 'bls_re_certification') && <>BLS </>}
                                                        {(item.short_name === 'acls_certification' || item.short_name === 'acls_re_certification') && <>ACLS </>}
                                                        {(item.short_name === 'pals_certification' || item.short_name === 'pals_re_certification') && <>PALS </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="card view-d-card">
                                                        {(item.short_name === "acls_certification" || item.short_name === "acls_re_certification") &&
                                                            <>
                                                                <div className="card  aclsCertificate-view" id="bls-front-img-view">
                                                                    <div className="text-center">
                                                                        <img src={logo} alt="logo" />
                                                                        <h5>ADVANCED CARDIOVASCULAR <br /> LIFE SUPPORT PROVIDER</h5>
                                                                        <p>This card certifies that this individual has successfully completed the
                                                                            course requirements and cognitive evaluation in accordance with the
                                                                            curriculum of the Pulse Certifications Advanced Cardiovascular Life
                                                                            Support (ACLS) course, based on ECC guidelines.</p>
                                                                        <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize">{memberDetails.memberData.user.first_name}</span></h6>
                                                                        <div className="d-flex flex-div view-team-add">
                                                                            <h6>Issued: <span>{item.final_exam_pass_date !== null ? <>{moment(item.final_exam_pass_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>  <h6>Expires: <span>{item.final_exam_pass_date !== null ? <>{moment(item.expiration_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>
                                                                        </div>

                                                                        <h4>PULSECERTIFICATIONS.COM</h4>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                        {(item.short_name === "bls_certification" || item.short_name === "bls_re_certification") &&
                                                            <>
                                                                <div className="card " id="bls-front-img-view">
                                                                    <div className="text-center">
                                                                        <img src={logo} alt="logo" />
                                                                        <h5 className="">BASIC LIFE SUPPORT <br /> PROVIDER</h5>
                                                                        <p>This card certifies that this individual has successfully completed the
                                                                            course requirements and cognitive evaluation in accordance with the
                                                                            curriculum of the Pulse Certifications Basic Life Support (BLS)
                                                                            course, based on ECC guidelines.</p>
                                                                        <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{memberDetails.memberData.user.first_name}</span></h6>
                                                                        <div className="d-flex flex-div view-team-add">
                                                                            <h6>Issued: <span>{item.final_exam_pass_date !== null ? <>{moment(item.final_exam_pass_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>  <h6>Expires: <span>{item.final_exam_pass_date !== null ? <>{moment(item.expiration_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>
                                                                        </div>
                                                                        <h4>PULSECERTIFICATIONS.COM</h4>
                                                                    </div>
                                                                </div>


                                                            </>
                                                        }
                                                        {(item.short_name === "pals_certification" || item.short_name === "pals_re_certification") &&
                                                            <>
                                                                <div className="card  palsCertificate" id="bls-front-img-view">
                                                                    <div className="text-center">
                                                                        <img src={logo} alt="logo" />
                                                                        <h5>PEDIATRIC ADVANCED LIFE <br /> SUPPORT PROVIDER</h5>
                                                                        <p>This card certifies that this individual has successfully completed the
                                                                            course requirements and cognitive evaluation in accordance with the
                                                                            curriculum of the Pulse Certifications Pediatric Advanced Life
                                                                            Support (PALS) course, based on ECC guidelines.</p>
                                                                        <h6 className="text-name-bls-h6">Name: <span className="text-name-bls-span text-capitalize" >{memberDetails.memberData.user.first_name}</span></h6>
                                                                        <div className="d-flex flex-div view-team-add">
                                                                            <h6>Issued: <span>{item.final_exam_pass_date !== null ? <>{moment(item.final_exam_pass_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>  <h6>Expires: <span>{item.final_exam_pass_date !== null ? <>{moment(item.expiration_date).format('M/D/YYYY')}</> : <> ---- </>}</span></h6>
                                                                        </div>

                                                                        <h4>PULSECERTIFICATIONS.COM</h4>
                                                                    </div>

                                                                </div>

                                                            </>
                                                        }
                                                    </div>
                                                </div>


                                                {item.certificate_status === 'assigned' && (
                                                    <div className="row view-teams-margin">
                                                        <div className="col-lg-3 col-2">
                                                            <img src={Assigned} width='60' alt="right1" />
                                                        </div>

                                                        {moment(item.active_date).format('M/D/YYYY') === '0000-00-00' ?
                                                            <>
                                                                <div className="col-lg-7 col-8">
                                                                    <span className="valid-till">Valid till:</span> <br />
                                                                    <span
                                                                        className="manager-sub-title-completed valid-date">

                                                                        {moment(item.assign_date).format('M/D/YYYY')}
                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => openEditDate(moment(item.assign_date).format('YYYY-MM-DD'), item.certificate_status, item.id)}><i className="fa fa-pencil-square edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className="col-lg-7"><span className="valid-till">Valid till:</span> <br /> <span className="valid-till">N/A</span></div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => onDeleteAssignCertificate(item.id, item.certificate_type_id)}><i className="fa fa-trash edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )}
                                                {item.certificate_status === 'active' && (
                                                    <div className="row view-teams-margin">
                                                        <div className="col-lg-3 col-2">
                                                            <img src={Right} width='60' alt="right1" />
                                                        </div>
                                                        {moment(item.active_date).format('M/D/YYYY') === '0000-00-00' ?
                                                            <div className="col-lg-9"> <span className="valid-till">N/A</span></div> :
                                                            <>
                                                                <div className="col-lg-7 col-8">
                                                                    <span className="valid-till">Valid till:</span> <br />
                                                                    <span
                                                                        className="manager-sub-title-completed valid-date">

                                                                        {moment(item.expiration_date).format('M/D/YYYY')}
                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => openEditDate(moment(item.expiration_date).format('YYYY-MM-DD'), item.certificate_status, item.id)}><i className="fa fa-pencil-square edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )}

                                                {item.certificate_status === 'expiring_soon' && (
                                                    <div className="row view-teams-margin">
                                                        <div className="col-lg-3 col-2">
                                                            <img src={Error} width='60' alt="right1" />
                                                        </div>
                                                        {moment(item.active_date).format('M/D/YYYY') === '0000-00-00' ?
                                                            <div className="col-lg-9"> <span className="valid-till">N/A</span></div> :
                                                            <>
                                                                <div className="col-lg-7 col-8">
                                                                    <span className="valid-till">Valid till:</span> <br />
                                                                    <span
                                                                        className="manager-sub-title-completed valid-date">
                                                                        {moment(item.expiration_date).format('M/D/YYYY')}
                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => openEditDate(moment(item.expiration_date).format('YYYY-MM-DD'), item.certificate_status, item.id)}><i className="fa fa-pencil-square edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )}
                                                {item.certificate_status === 'expired' && (
                                                    <div className="row view-teams-margin">
                                                        <div className="col-lg-3 col-2">
                                                            <img src={Close} width='60' alt="right1" />
                                                        </div>
                                                        {moment(item.active_date).format('M/D/YYYY') === '0000-00-00' ?
                                                            <div className="col-lg-9"> <span className="valid-till">N/A</span></div> :
                                                            <>

                                                                <div className="col-lg-7 col-8">
                                                                    <span className="valid-till">Valid till:</span> <br />
                                                                    <span
                                                                        className="manager-sub-title-completed valid-date">

                                                                        {moment(item.expiration_date).format('M/D/YYYY')}

                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-2 col-2">

                                                                    <div><a href="#" onClick={() => openEditDate(moment(item.expiration_date).format('YYYY-MM-DD'), item.certificate_status, item.id)}><i className="fa fa-pencil-square edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )}

                                                {item.certificate_status === 'assign_expired' && (
                                                    <div className="row view-teams-margin">
                                                        <div className="col-lg-3 col-2">
                                                            <img src={AssignedExpired} width='60' alt="right1" />
                                                        </div>
                                                        {moment(item.active_date).format('M/D/YYYY') === '0000-00-00' ?
                                                            <>
                                                                <div className="col-lg-7 col-8">
                                                                    <span className="valid-till">Valid till:</span> <br />
                                                                    <span
                                                                        className="manager-sub-title-completed valid-date">

                                                                        {moment(item.expiration_date).format('M/D/YYYY')}
                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => openEditDate(moment(item.expiration_date).format('YYYY-MM-DD'), item.certificate_status, item.id)}><i className="fa fa-pencil-square edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className="col-lg-7"><span className="valid-till">Valid till:</span> <br /> <span className="valid-till">N/A</span></div>
                                                                <div className="col-lg-2 col-2">
                                                                    <div><a href="#" onClick={() => onDeleteAssignCertificate(item.id, item.certificate_type_id)}><i className="fa fa-trash edit-date-class" aria-hidden="true"></i></a> </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                )}

                                                <div className="float-right">
                                                    {item.final_exam_pass_date !== null ?
                                                        <>
                                                            <a className="manager-view-details-print"
                                                                onClick={() => {
                                                                    openPrintCertificate(
                                                                        `${memberDetails.memberData.user.first_name} ${memberDetails.memberData.user.last_name}`,
                                                                        item.full_name,
                                                                        item.type_id,
                                                                        item.id,
                                                                        memberDetails.memberData.email,
                                                                        item.name_slug,
                                                                        item.final_exam_pass_date,
                                                                        item.active_date,
                                                                    );
                                                                }}
                                                            >
                                                                <img src={print} alt="arrow-view-details" width='23' /> Print
                                                            </a>
                                                        </> :
                                                        <>
                                                            <div className="final-exam-not-pass">

                                                                <a className=""></a>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        )
                    }
                </>

            )
        }
    }

    return (
        <>
            <Header />

            <section className="pt-10 pb-5 feature-promo bg-light manager-tab-listing" id='tab-listing'>
                <div className="container">

                    <div className="container d-flex align-items-center justify-content-center vh-80">
                        {loading && (
                            <Watch
                                height="80"
                                width="80"
                                radius="48"
                                color="#2f99a9 "
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        )}
                    </div>

                    {!loading && (
                        <>

                            <div className="card view-teams-card view-all pt-3 pb-3">
                                <div className="row">
                                    <div className="col-lg-1 col-2">
                                        <div
                                            className="team-user-name">{`${memberDetails.memberData.user.first_name.charAt(0)}${memberDetails.memberData.user.last_name.charAt(0)}`}</div>
                                    </div>
                                    <div className="col-lg-9 col-7">
                                        <div className="teams-name-title">
                                            <h6 className="mb-3">
                                                <span> Name:</span> {`${memberDetails.memberData.user.first_name} ${memberDetails.memberData.user.last_name}`}
                                            </h6>
                                            <h6><span>Email:</span> {memberDetails.memberData.user.email} </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-3">
                                        <div className="teams-name-title">
                                            <h6 className="m-0">
                                                <span>Status:</span> {memberDetails.memberData.user.user_status.replace(/\b\w/g, (match) => match.toUpperCase())}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-5">

                                {renderCertificate(memberDetails.userCertificates, 'bls_certification')}
                                {renderCertificate(memberDetails.userCertificates, 'acls_certification')}
                                {renderCertificate(memberDetails.userCertificates, 'pals_certification')}

                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-12">

                                    {memberDetails.memberData.user_id !== memberDetails.memberData.manager_id &&
                                        <>

                                            <button className="teams-remove-member" onClick={() => removeUser(memberDetails.memberData.user_id)}>
                                                Remove member
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>

                        </>
                    )}


                </div>
            </section>

            <CertificateModal
                show={certificateModalShow}
                onHide={() => setCertificateModalShow(false)}
                printImageUrl={printImageUrl}
                userName={userName}
                certificateSlug={certificateSlug}
                userCertificateId={userCertificateId}
                certificateId={certificateId}
                email={email}
                finalExamDate={finalExamDate}
                certificateActiveDate={certificateActiveDate}
                isFromManager={true}
            />

            <RemoveMember
                show={modalShow}
                onHide={() => setModalShow(false)}
                MemberId={user_id}
                userCertificateStatus={userCertificateStatus}
            />
            <EditDateModal
                show={modalEditDateShow}
                onHide={() => setModalEditDateShow(false)}
                MemberId={user_id}
                certificateData={certificateData}
                certificateStatus={certificateStatus}
                usercertificateIds={usercertificateIds}
            />
            <RemoveAssignCertificate
                show={modalDeleteCertificate}
                onHide={closeDeleteCertificateModal}
                userId={user_id}
                usercertificateIds={usercertificateIds}
                certificateIds={certificateIds}
            />


        </>
    )

}

export default ViewTeams;